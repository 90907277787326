import React, { useState, useEffect } from "react";
import global from "../../global-variables";
import Header from "../layout/header";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import WOW from "wowjs";

import { Container, Row, Col } from "reactstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Modal from "react-bootstrap/Modal";
import KCDHProjectNav from "../layout/kcdhproject-nav";

import axios from "axios";
import globalVariables from "../../global-variables";
import Metatag from "../../hooks/Metatag";

export default function KCDHProjects() {

  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [getResearchid, setResearchid] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = (e, p_res_id) => {
    e.preventDefault();
    setShow(true);
    //get faculty id
    setResearchid(p_res_id);
  };

  const [getresearchTypeList, setResearchTypeList] = useState("");

  const [researchType_id, setRtype_id] = useState();

  // things to be done when the page is loaded
  useEffect(() => {
    // make the object for getting the research type list
    let _obj = new Object();
    _obj.rtype_status = 1;
    axios
      .post(
        globalVariables.variables.strApiUrl + "/researchApi/researchTypeList",
        _obj
      )
      .then((response) => {
        if(response.data === 'error')
        {
          navigate('/error-page')
        }
        else
        {
          setResearchTypeList(response.data[0]);
          setRtype_id(response.data[0][0].rtype_id);
        }
      });
  }, []);

  const [researchList, setResearchList] = useState([]);

  useEffect(() => {
    let _obj = new Object();
    _obj.res_rtype_id = researchType_id;
    _obj.res_status = 1;
    axios
      .post(
        globalVariables.variables.strApiUrl + "/researchApi/getresearchList",
        _obj
      )
      .then((response_researchList) => {
        if(response_researchList.data === 'error')
        {
          navigate('/error-page')
        }
        else
        {
        setResearchList(response_researchList.data[0]);
        }
      });
  }, [researchType_id]);

  const [researchCategory, setResearchCategoryList] = useState(null);
  useEffect(() => {
    let obj = new Object();
    obj.rcategory_status = 1;

    axios
      .post(
        globalVariables.variables.strApiUrl + "/rcategoryApi/getrcategoryList",
        obj
      )
      .then((response) => {
        if(response.data === 'error')
        {
          navigate('/error-page')
        }
        else
        {
          setResearchCategoryList(response.data[0]);
        }
      });
  }, []);

  useEffect(() => {
    // add class for home header and remove inner class
    $(".fixed-top").addClass("bg-header");

    // initiate the wow effect
    new WOW.WOW({
      live: false,
    }).init();
  }, [researchList]);

  return (
    <>
      <Header main_className="bg-header"></Header>

      {/* <Metatag title='KCDH Projects' keywords='KCDH Projects' description='KCDH Projects'/> */}
      {/* Visual Panel */}
      <Container fluid className="bg-visual-inside p-0">
        <div className="visual-inside-big">
          <Row className="g-md-0">
            <Col md={6} className="order-2 order-md-1">
              <Container className="d-flex align-items-end h-100">
                <div className="d-inline-block m-0 align-self-end col-lg-6 col-xl-6 py-xl-3 p-2 text-white">
                  <h1 className="fw-light text-white m-0 align-self-center wow fadeInUp">
                    KCDH Projects
                  </h1>
                </div>
              </Container>
            </Col>
            <div className="col-md-6 order-1 order-md-2">
              <img
                src={
                  global.variables.strDomainUrl + "images/visual-research2.jpg"
                }
                className="img-fluid"
                alt=""
                title=""
              />
            </div>
          </Row>
        </div>
      </Container>

      {/* Container */}

      <Container className="my-5 position-relative">
        <Row>
          <Col md={4} lg={3} className="mb-3">
            <KCDHProjectNav
              submenutitle={getresearchTypeList[0]?.rtype_name}
              getresearchTypeList={getresearchTypeList}
              researchCategory={researchCategory}
            ></KCDHProjectNav>
          </Col>
          <Col md={8} lg={9} className="ps-md-4">
            {getresearchTypeList != "" ? (
              getresearchTypeList.length > 0 ? (
                <Row className="row-cols-1 g-3 px-2 px-md-0">
                  {
                  // researchList.length > 0 ?
                   (
                    researchList.map((val_research) => {
                      return (
                        <>
                          {/* {val_research.res_rtype_id === researchType_id ? ( */}
                            <Col className="border p-4 rounded-3 h-100 my-3">
                              <div>
                                <h5 className="text-dark wow fadeInUp">
                                  {val_research.res_title}
                                </h5>
                                <ul className="list-group list-group-flush px-0 mb-3 projects">
                                  {val_research.res_kcdh_faculty != null &&
                                  val_research.res_kcdh_faculty != "" ? (
                                    <li className="list-group-item border-0 p-0 wow fadeInUp">
                                      <b>KCDH Faculty:</b>{" "}
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: val_research.res_kcdh_faculty,
                                        }}
                                      ></span>
                                    </li>
                                  ) : null}
                                  {val_research.res_kcdh_partner != null &&
                                  val_research.res_kcdh_partner != "" ? (
                                    <li className="list-group-item border-0 p-0 wow fadeInUp">
                                      <b>KCDH Partner:</b>{" "}
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: val_research.res_kcdh_partner,
                                        }}
                                      ></span>
                                    </li>
                                  ) : null}
                                </ul>
                                <Link
                                  onClick={(e) =>
                                    handleShow(e, val_research.res_id)
                                  }
                                  className="btn btn-warning rounded-pill px-4 text-uppercase fw-bold btn-sm py-2 mt-auto mt-2 research-projects"
                                >
                                  Read More
                                </Link>
                              </div>
                            </Col>
                          {/* ) : null} */}
                        </>
                      );
                    })
                  )
                  //  :
                  //  (
                  //   <Col className="border p-4 rounded-3 h-100 my-3 text-center">
                  //     <span className="text-center">
                  //       <b>No records found</b>
                  //     </span>
                  //   </Col>
                  // )
                  }
                </Row>
              ) : (
                <Col className="border p-4 rounded-3 h-100 my-3 text-center">
                  <span className="text-center">
                    <b>No records found</b>
                  </span>
                </Col>
              )
            ) : (
              <Col className="border p-4 rounded-3 h-100 my-3 text-center">
                <span className="text-center">Loading...</span>
              </Col>
            )}
          </Col>
        </Row>
      </Container>

      {/* Breadcrumb */}
      <Breadcrumb className="breadcrumb-panel mt-auto">
        <Breadcrumb.Item href={globalVariables.variables.strDomainUrl}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Research</Breadcrumb.Item>
        <Breadcrumb.Item active>KCDH Projects</Breadcrumb.Item>
      </Breadcrumb>

      {/* PROJECT DETAILS */}
      <Modal
        show={show}
        size="xl"
        scrollable
        centered
        onHide={handleClose}
        dialogClassName="p-2 popup"
      >
        <Modal.Header
          closeButton
          className="border-0 align-items-start"
        ></Modal.Header>
        <Modal.Body>
          {researchList.map((val) => {
            return (
              <>
                {val.res_id == getResearchid ? (
                  <>
                    <Row className="row-cols-1 p-md-4 p-2" key={val.res_id}>
                      <Col>
                        <h5 className="text-dark">{val.res_title}</h5>

                        <ul className="list-group list-group-flush px-0 mb-3 projects">
                        {val.res_kcdh_faculty != "" &&
                        val.res_kcdh_faculty != null ? (
                          <li className="list-group-item border-0 p-0 wow fadeInUp">
                            <b>KCDH Faculty:</b>{" "}
                            <span
                              dangerouslySetInnerHTML={{
                                __html: val.res_kcdh_faculty,
                              }}
                            ></span>
                            </li>
                            ) : null}
                        {val.res_kcdh_partner != "" &&
                        val.res_kcdh_partner != null ? (
                          <li className="list-group-item border-0 p-0 wow fadeInUp">
                            <b>KCDH Partner:</b>{" "}
                            <span
                              dangerouslySetInnerHTML={{
                                __html: val.res_kcdh_partner,
                              }}
                              ></span>
                          </li>
                        ) : null}
                        {val.res_description != "" &&
                        val.res_description != null ? (
                          <p>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: val.res_description,
                              }}
                              ></span>
                          </p>
                        ) : null}
                        </ul>
                        {/* {
                              val.project_type != "" && val.project_type != null ?
                                <b className="text-dark">Project Type: {val.project_type}</b> :
                                null
                            } */}
                      </Col>
                    </Row>
                  </>
                ) : null}
              </>
            );
          })}
        </Modal.Body>
      </Modal>
      {/* PROJECT DETAILS */}
    </>
  );
}
