import React, { useEffect } from "react";
import global from "../../global-variables";
import Header from "../layout/header";
import { Link } from "react-router-dom";
import $ from "jquery";
import WOW from "wowjs";


import { Container, Row, Col } from "reactstrap";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import PublicationsNav from "../layout/publications-nav";
import Metatag from "../../hooks/Metatag";
import globalVariables from "../../global-variables";

export default function Publications2022() {

  useEffect(() => {
    // add class for home header and remove inner class
    $(".fixed-top").addClass("bg-header");

    // initiate the wow effect
    new WOW.WOW({
      live: false,
    }).init();

  }, []);

  return (
    <>
      <Header main_className="bg-header"></Header>

      <Metatag title='Publications by Koita Centre for Digital Health'
               description='Publications by Koita Centre for Digital Health'
               keywords="Publications, Publications by KCDH"
               />

      {/* Visual Panel */}
      <Container fluid className="bg-visual-inside p-0" >
        <div className="visual-inside-big">
          <Row className="g-md-0">
            <Col md={6} className="order-2 order-md-1">
              <Container className="d-flex align-items-end h-100">
                <div className="d-inline-block m-0 align-self-end col-lg-6 col-xl-6 py-xl-3 p-2 text-white">
                  <h1 className="fw-light text-white m-0 align-self-center wow fadeInUp">
                    Publications
                  </h1>
                  <p className="mb-lg-0 wow fadeInUp">
                    Papers published in Journals
                  </p>
                </div>
              </Container>
            </Col>
            <div className="col-md-6 order-1 order-md-2">
              <img src={global.variables.strDomainUrl + "images/visual-research2.jpg"} className="img-fluid" alt="" title="" />
            </div>
          </Row>
        </div>
      </Container>

      {/* Container */}
      <Container className="my-5 position-relative">
        <Row>
          <Col md={4} lg={3} className="mb-3">
            <PublicationsNav submenutitle="2022" publications2022_class="active"></PublicationsNav>
          </Col>
          <Col md={8} lg={9} className="ps-md-4">
            <div className="rounded-3 border p-md-4">
              <h2 className="text-primary mb-0">2022</h2>
              <Row className="row-cols-1 publications">
                <Col>
                  Tandon, T., Piccolo, M., Ledermann, K. et al., “Relationship between behavioral and mood responses to monetary rewards in a sample of Indian students with and without reported pain”, Sci Rep 12, 20242 (2022), <Link href="https://doi.org/10.1038/s41598-022-24821-2" class="text-decoration-underline text-primary" target="_blank">https://doi.org/10.1038/s41598-022-24821-2</Link>
                </Col>
                <Col>
                  Suraj Kothawade, Akshit Shrivastava, Venkat Iyer, Ganesh Ramakrishnan, Rishabh Iyer, “DIAGNOSE: Avoiding Out-of-distribution Data using Submodular Information Measures”, In Proceedings of the First International Workshop, MILLanD 2022, Held in Conjunction with MICCAI 2022, Singapore, September 22, 2022, <Link href="https://link.springer.com/book/9783031167591" class="text-decoration-underline text-primary" target="_blank">https://link.springer.com/book/9783031167591</Link>
                </Col>
                <Col>
                  Suraj Kothawade, Atharv Savarkar, Venkat Iyer, Ganesh Ramakrishnan, Rishabh Iyer, “CLINICAL: Targeted Active Learning for Imbalanced Medical Image Classification”, In Proceedings of the First International Workshop, MILLanD 2022, Held in Conjunction with MICCAI 2022, Singapore, September 22, 2022, <Link href="https://link.springer.com/book/9783031167591" class="text-decoration-underline text-primary" target="_blank">https://link.springer.com/book/9783031167591</Link>
                </Col>
                <Col>
                  Murali, K., and Hari M. Varma. "Laser speckle simulation tool based on stochastic differential equations for bio imaging applications." Biomedical Optics Express 13.12 (2022): 6745-6762.
                </Col>
                <Col>
                  Paul, Ria, K. Murali, and Hari M. Varma. "High-density diffuse correlation tomography with enhanced depth localization and minimal surface artefacts." Biomedical Optics Express 13.11 (2022): 6081-6099.
                </Col>
                <Col>
                  Paul, Ria, K. Murali, Sumana Chetia, and Hari M. Varma. "A simple algorithm for diffuse optical tomography (DOT) without matrix inversion." Biomed. Phys. Eng. Express 8 (2022) 045001
                </Col>
                <Col>
                  Paul, Ria, Soumyajit Sarkar, K. Murali, and Hari M. Varma “A modular design approach to build a portable small-animal cerebral blood flow imaging platform” fNIRS 2022 conference, Boston, US
                </Col>
                <Col>
                  Paul, R., Murali, K, and Hari M Varma, A Spatially Weighted Filter Design for High Density Diffuse Correlation Tomography : Biophotonics Conference : Biomedical Optics 24-27 April, 2022 Fort Lauderdale, Florida, United States
                </Col>
                <Col>
                  Murali, K, and Hari M Varma. , A stochastic differential equation based algorithm to simulate laser speckles for deep-tissue blood flow imaging applications: Biophotonics Conference: Biomedical Optics 24-27 April, 2022 Fort Lauderdale, Florida, United States
                </Col>
                <Col>
                  Prof Sanjeeva Srivastava et al., Insights on Proteomics-Driven Body Fluid-Based Biomarkers of Cervical Cancer, MDPI proteomes, Mar, 2022
                </Col>
                <Col>
                  Prof Sanjeeva Srivastava et al., Clinical Proteomics for Meningioma: An Integrated Workflow for Quantitative Proteomics and Biomarker Validation in Formalin-Fixed Paraffin-Embedded Tissue Samples, OMICS: A Journal of Integrative Biology, Sep, 2022
                </Col>
                <Col>
                  Prof Sanjeeva Srivastava et al., A protein microarray-based serum proteomic investigation reveals distinct autoantibody signature in colorectal cance, Proteomics Cliical Applications, Nov, 2022
                </Col>
                <Col>
                  Prof Sanjeeva Srivastava et al., The First Pituitary Proteome Landscape From Matched Anterior and Posterior Lobes for a Better Understanding of the Pituitary Gland, Molecular and Celular Proteomics, Dec, 2022
                </Col>
                <Col>
                  Prof Sanjeeva Srivastava et al., Deep Phosphoproteome Landscape of Interhemispheric Functionality of Neuroanatomical Regions of the Human Brain, Journal of Proteome Research, Nov, 2022
                </Col>
                <Col>
                  Prof Sanjeeva Srivastava et al., Evaluation of autoantibody signatures in pituitary adenoma patients using human proteome arrays, Proteomics Clinical Applications, Aug, 2022
                </Col>
                <Col>
                  Prof Sanjeeva Srivastava et al., Metabolomics Profiling of Pituitary Adenomas by Raman Spectroscopy, Attenuated Total Reflection-Fourier Transform Infrared Spectroscopy, and Mass Spectrometry of Serum Samples, Analytical Chemistry, Aug, 2022
                </Col>
                <Col>
                  Prof Sanjeeva Srivastava et al., Semen Proteomics of COVID-19 Convalescent Men Reveals Disruption of Key Biological Pathways Relevant to Male Reproductive Function, ACS Omega, Mar, 2022
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>

      {/* Breadcrumb */}
      <Breadcrumb className="breadcrumb-panel mt-auto">
        <Breadcrumb.Item href={globalVariables.variables.strDomainUrl}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Research</Breadcrumb.Item>
        <Breadcrumb.Item active>Publications</Breadcrumb.Item>
      </Breadcrumb>
    </>
  );
}