import { useState, useEffect } from "react";
import { GetRoadblockList } from "../../api/roadblock-functions";
import globalVariables from "../../global-variables";
import FsLightbox from "fslightbox-react";
import { useNavigate } from "react-router-dom";
export default function RoadBlockPopup() {

    const navigate = useNavigate();
    const [toggler, setToggler] = useState(true);
    const [banner_path, setBannerPath] = useState([]);

    const OnGetRoadblockSuccess = (p_response) => {

        // check if the roadblock is active then display it
        if (p_response[0].roadblock_status === 1) {

            let _arrSource = [];
            _arrSource.push(globalVariables.variables.strApiUrl + "/roadblock-image/" + p_response[0].roadblock_banner);

            // set the banner for the road block
            setBannerPath(_arrSource);


            let _strUrl = "";
            let _strTarget = "_blank";

            // check the type of the redirect to bind the link
            if (p_response[0].roadblock_isurl !== 3) {

                // depending on the selection make the link
                if (p_response[0].roadblock_isurl === 1) {
                    _strUrl = p_response[0].roadblock_url;

                    // check whether url to be opened internal then set it
                    if (p_response[0].roadblock_target === 2) {
                        _strTarget = "_self";
                    }

                }
                else {
                    _strUrl = globalVariables.variables.strApiUrl + "/roadblock-documents/" + p_response[0].roadblock_file;
                }

                // open the popup after the element is added
                setTimeout(() => {
                    document.getElementById("btnRoadblockPopupTrigger").click();

                    // hiding the element till the link is added
                    document.getElementById("divRoadblockPopupContainer").classList.add("d-none");

                }, 600);

                // add the link after the popup is opened
                setTimeout(() => {

                    // bind the anchor tag beside roadblock container
                    // `element` is the element you want to wrap
                    let element = document.getElementsByClassName("fslightboxs")[0];
                    
                    let parent = element.parentNode;
                    let wrapper = document.createElement('a');

                    // set the properties of the anchor tag
                    wrapper.href = _strUrl;
                    wrapper.target = _strTarget;

                    // set the wrapper as child (instead of the element)
                    parent.replaceChild(wrapper, element);

                    // set element as child of wrapper
                    wrapper.appendChild(element);

                    // showing the popup
                    document.getElementById("divRoadblockPopupContainer").classList.remove("d-none");

                }, 600);
            }
            else{
                // open the popup after the element is added
                setTimeout(() => {
                    document.getElementById("btnRoadblockPopupTrigger").click();
                }, 500);
            }



        }

    }

    useEffect(() => {

        // call the function to get the roadblock
        GetRoadblockList(OnGetRoadblockSuccess,navigate);

    }, []);

    return (

        banner_path.length > 0 ?

            <div id="divRoadblockPopupContainer">

                {/* Toggler button for showing the roadblock */}
                <button onClick={() => setToggler(!toggler)} id="btnRoadblockPopupTrigger" className="d-none">
                    Toggle Lightbox
                </button>

                <FsLightbox
                    toggler={toggler}
                    sources={banner_path}
                />
            </div>
            :
            null

    )

}