import React, { useState, useEffect } from "react";
import global from "../../global-variables";
import Header from "../layout/header";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import WOW from "wowjs";
import { GetNewsOnHomePageList } from "../../api/news-functions";
import { Container, Row, Col } from "reactstrap";
import Image from 'react-image-webp';
import Slider from 'react-slick';
import { Parallax } from 'react-parallax';
import VisualpanelBlock from "./visualpanel-block";
import globalVariables from "../../global-variables";
import Roadblock_Popup from './roadblock-popup'
import Metatag from '../../hooks/Metatag';
import { GetResearchCategoryList } from "../../api/research-category-functions";
import CookieConsent from "react-cookie-consent";

export default function Home() {
  // const [toggler, setToggler] = useState(true);

  const navigate = useNavigate()
  const [newsOnHomePageList, setNewsOnHomePageList] = useState();

  const [getrcategoryList, setRcategoryList] = useState(null);


  const OnGetResearchCategorySuccess = (p_response) => {

    // set the list for looping
    setRcategoryList(p_response);
  }
  // things to be done when the page is loaded
  useEffect(() => {

    // make the object for getting the research category list
    let _obj = new Object();
    _obj.rcategory_status = 1;

    // call the function to get the research category
    GetResearchCategoryList(_obj, OnGetResearchCategorySuccess, navigate);

  }, []);

  useEffect(() => {
    // add class for home header and remove inner class
    $(".fixed-top").addClass("bg-header");

    // initiate the wow effect
    new WOW.WOW({
      live: false,
    }).init();

  }, [newsOnHomePageList, getrcategoryList]);

  var settings = {
    fade: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    autoplay: true,
    autoplaySpeed: 10000,
  };


  // success function when the list is retrived
  const OnGetNewsHomeSuccess = (p_response) => {
    // set the list for looping
    setNewsOnHomePageList(p_response);
  }

  // things to be done when the page is loaded
  useEffect(() => {

    // call the function to get the news
    GetNewsOnHomePageList(OnGetNewsHomeSuccess);

  }, []);

  return (
    <>
      <Header main_className="bg-header"></Header>
      <Metatag title='Koita Centre for Digital Health'
        description='KCDH offers Academic Programs in Digital Health, Research Activities on Digital Health and Academic Courses in Healthcare Informatics'
        keywords='KCDH, Digital Health Academic Programs, Courses in Healthcare Informatics, Digital Health Research, Digital Health Programs at at IIT Bombay'
      />

      {/* Slider Container */}
      <VisualpanelBlock />


      {/* Intro Container */}
      <Container>
        <Row>
          <Col lg="5" className="my-md-5 my-3 py-3 pe-lg-5">
            <h1 className="h2 text-primary wow fadeInUp">
              Koita Centre for Digital Health (KCDH)
            </h1>
            <p>The Koita Centre for Digital Health (KCDH) set up at IIT Bombay, is the first of its kind in India, focused on driving academic programs, research, and industry collaborations in Digital Health.</p>
            <p>Improving the quality, accessibility, and affordability of healthcare is one of the world’s biggest priorities. Digital Health has a profound impact on the quality of care and efficiency of healthcare delivery. Consequently, there is substantial focus globally on enhancing Digital Health and Informatics. In India too, the launch of the Ayushman Bharat Digital Health Mission (ABDM) in 2021 is driving Digital Health adoption at a national scale.</p>
            <Link to="aboutus/" className="btn btn-warning rounded-pill px-4 text-uppercase fw-bold btn-sm py-2 wow fadeInUp">More</Link>
          </Col>
          <Col lg="7" className="ps-md-5 quote">
            <div className="my-5 ms-md-5 py-3 ps-md-5 pe-md-4">
              <div className="ps-md-3 py-md-0 py-5">
                <h2 className="text-primary wow fadeInUp">Message from Director, IIT B</h2>
                <p>The centre will enable an ecosystem that can help address the healthcare challenges that have been so glaringly exposed by the Covid-19 pandemic. Digital Health and Informatics need cross-functional expertise. At KCDH, we hope to enable seamless cross-functionality across these streams. This new initiative opens up exciting opportunities for our graduates, including spearheading research, launching startups, and much more.</p>
                <Row className="d-flex align-items-end mt-md-4">
                  <Col md="4" lg="6" xl="auto">
                    <Image className="img-fluid rounded-3 wow fadeInRight" alt="Prof. Subhasis Chaudhuri" title="Prof. Subhasis Chaudhuri" width={160} height={160} loading="lazy"
                      src={(global.variables.strDomainUrl + "images/pic-iitb-director.jpg")}
                      webp={(global.variables.strDomainUrl + "images/pic-iitb-director.webp")}
                    />
                  </Col>
                  <Col>
                    <h5 className="fw-bold text-dark m-0 wow fadeInUp"> Prof. Subhasis Chaudhuri </h5>
                    <h6 className="fw-bold text-dark m-0 wow fadeInUp">
                      Director, IIT Bombay.
                      <br />June 2021
                    </h6>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Container>


      <Container className="mb-5">
        <div className="border rounded-3 p-3 pt-md-4 h-100">
          <h2 className="text-primary pb-4 text-center">KCDH Vision</h2>
          <div className="col list mt-5">
            <div className="bg-light p-4 h-100 rounded-3">
              <div className="icon-circle bg-primary d-flex align-items-center justify-content-center m-auto mb-3 icon-mt-n80 border border-white border-5 wow fadeInUp">
                <Image className="img-fluid rounded-3 wow fadeInRight" alt="KCDH Vision" title="KCDH Vision" width={100} height={100} loading="lazy"
                  src={(global.variables.strDomainUrl + "images/icon-vision.png")}
                  webp={(global.variables.strDomainUrl + "images/icon-vision.webp")}
                />
              </div>
              <h6 className="text-dark fw-normal m-0 text-center col-lg-11 m-auto">
                To become a globally renowned centre in Digital Health and Health Informatics.
                The centre will drive research, entrepreneurship and employment in Digital
                Health to transform healthcare in India, partnering closely with clinical
                professionals and healthcare organizations
              </h6>
            </div>
          </div>
        </div>
      </Container>


      <Container fluid className="bg-light py-5">
        <Row>
          <div className="col-xxl-6 col-xl-7 col-md-10 mx-auto">
            <div className="ratio ratio-16x9">
              <iframe src="https://www.youtube.com/embed/6TTOE99JOEk" className="rounded-3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
            </div>
          </div>
        </Row>
      </Container>

      <Container fluid className="bg-light px-0">
        <Parallax blur={0} bgImage={global.variables.strDomainUrl + "images/bg-academics.jpg"} bgImageAlt="KCDH Academics" strength={700}>
          <Container className="parallax-banner-info py-2">
            <div className="container py-5">
              <h2 className="text-white text-center mb-5">KCDH Academics</h2>
              <Row className="row-cols-1 row-cols-md-2 row-cols-lg-4 g-3 g-md-4 pb-3 justify-content-center">
                <Col>
                  <Link to="academics/">
                    <div className="bg-white p-3 p-xl-4 rounded-3 h-100 position-relative text-center overflow-hidden">
                      <Image className="img-fluid rounded-3 wow fadeInRight" alt="Ph.D. Program - Digital Health" title="Ph.D. Program - Digital Health" width={284} height={256} loading="lazy"
                        src={(global.variables.strDomainUrl + "images/pic-academics4.jpg")}
                        webp={(global.variables.strDomainUrl + "images/pic-academics4.webp")}
                      />
                      <h6 className="px-xl-3 pt-3 m-0 text-center text-dark wow fadeInUp">
                        Ph.D. Program
                        <br />- Digital Health
                      </h6>
                    </div>
                  </Link>
                </Col>
                <Col>
                  <Link to="academics/">
                    <div className="bg-white p-3 p-xl-4 rounded-3 h-100 position-relative text-center overflow-hidden">
                      <Image className="img-fluid rounded-3 wow fadeInRight" alt="M.S. by Research - Healthcare Informatics" title="M.S. by Research - Healthcare Informatics" width={284} height={256} loading="lazy"
                        src={(global.variables.strDomainUrl + "images/pic-academics2.jpg")}
                        webp={(global.variables.strDomainUrl + "images/pic-academics2.webp")}
                      />
                      <h6 className="px-xl-3 pt-3 m-0 text-center text-dark wow fadeInUp">
                      M.S. by Research
                        <br />- Healthcare Informatics
                      </h6>
                    </div>
                  </Link>
                </Col>
                <Col>
                  <Link to="academics/">
                    <div
                      className="bg-white p-3 p-xl-4 rounded-3 h-100 position-relative text-center overflow-hidden">
                      <Image className="img-fluid rounded-3 wow fadeInRight" alt="Interdisciplinary Dual Degree (IDDDP) with Master’s - Healthcare Informatics" title="Interdisciplinary Dual Degree (IDDDP) with Master’s - Healthcare Informatics" width={284} height={256} loading="lazy"
                        src={(global.variables.strDomainUrl + "images/pic-academics1.jpg")}
                        webp={(global.variables.strDomainUrl + "images/pic-academics1.webp")}
                      />
                      <h6 className="px-xl-3 pt-3 m-0 text-center text-dark wow fadeInUp">
                        Interdisciplinary Dual Degree (IDDDP) with Master’s
                        <br />- Healthcare Informatics
                      </h6>
                    </div>
                  </Link>
                </Col>
                <Col>
                  <Link to="academics/">
                    <div className="bg-white p-3 p-xl-4 rounded-3 h-100 position-relative text-center overflow-hidden">
                      <Image className="img-fluid rounded-3 wow fadeInRight" alt="Minor Program - Healthcare Informatics" title="Minor Program - Healthcare Informatics" width={284} height={256} loading="lazy"
                        src={(global.variables.strDomainUrl + "images/pic-academics3.jpg")}
                        webp={(global.variables.strDomainUrl + "images/pic-academics3.webp")}
                      />
                      <h6 className="px-xl-3 pt-3 m-0 text-center text-dark wow fadeInUp">
                        Minor Program
                        <br />- Healthcare Informatics
                      </h6>
                    </div>
                  </Link>
                </Col>
              </Row>
            </div>
          </Container>
        </Parallax>
      </Container>

      <Container className="my-5 pt-4">
        <h2 className="text-primary text-center mb-5">KCDH Research</h2>
        <Row className="row-cols-1 row-cols-md-2 row-cols-lg-3 g-3 g-xl-4 g-xxl-5 pb-3 research">
          {
            getrcategoryList != null ?
              getrcategoryList.length > 0 ?
                getrcategoryList.map((val) => {
                  return (
                    <Col>
                      <Link to="research/">
                        <div className="bg-light p-4 p-xl-4 rounded-3 h-100 text-center position-relative overflow-hidden">
                          <img src={global.variables.strApiUrl + "/researchcategory-image/" + val.rcategory_image} width="380" height="175" alt={val.rcategory_name} title={val.rcategory_name} className="img-fluid rounded-3 border border-white border-4 wow fadeIn" loading="lazy" />
                          <h6 className="px-3 pt-3 m-0 text-dark text-center wow fadeInUp"> {val.rcategory_name} </h6>
                        </div>
                      </Link>
                    </Col>

                  )
                })
                :
                <Col>
                  <span className="text-center">No Records Found</span>
                </Col>
              :
              <Col>
                <span className="text-center">Loading...</span>
              </Col>
          }
        </Row>
      </Container>

      <Container fluid className="px-0 my-5">
        <Container>
          <Row xs={1} lg={2} className="g-4 g-lg-5">
            {
              newsOnHomePageList != null ?

                newsOnHomePageList.length > 0 ?
                  <Col>
                    {
                      newsOnHomePageList.map((val) => {
                        return (
                          <div className="border rounded-3 px-md-5 py-md-4 p-3 h-100 d-flex align-items-start flex-column" key={val.news_id}>
                            <h2 className="text-primary mb-3">News &amp; Events</h2>
                            <div className="row h-100">
                              <div className="col-md-5 mb-2">
                                {
                                  val.news_images != null && val.news_images != "" ?
                                    <img src={globalVariables.variables.strApiUrl + "/news-main-image/" + val.news_images} width="284" height="284" alt={val.news_headline} title="" className="img-fluid rounded-3 border p-2" loading="lazy" />
                                    :
                                    null
                                }
                              </div>
                              <div className="col d-flex flex-column">
                                <h5 className="mb-2 text-dark">
                                  {val.news_headline}
                                </h5>
                                <p className="wow fadeInUp">

                                  {/*when from and to date are diffrent  */}
                                  <b>Date: </b>
                                  {
                                    val.news_date != val.news_to_date ?
                                      <>

                                        {
                                          val.from_mon != val.to_mon ?
                                            <>
                                              {` ${val.from_day}-${val.from_mon}-${val.from_year} to ${val.to_day}-${val.to_mon}-${val.to_year}`}

                                            </>
                                            :
                                            <>
                                              {` ${val.from_day}-${val.from_mon}-${val.from_year} to ${val.to_day}-${val.to_mon}-${val.to_year}`}
                                            </>

                                        }

                                      </>

                                      :
                                      // when from and to date same 
                                      <>
                                        {
                                          //when from and to date's month is same
                                          val.from_mon != val.to_mon ?
                                            <>
                                              {` ${val.from_day}-${val.from_mon}-${val.from_year} to ${val.to_day}-${val.to_mon}-${val.to_year}`}
                                            </>
                                            :
                                            <>
                                              {` ${val.from_day}-${val.from_mon}-${val.from_year}`}
                                            </>
                                        }
                                      </>


                                  }

                                </p>
                                {
                                  val.news_details != null && val.news_details != "" ?
                                    < p className="wow fadeInUp" dangerouslySetInnerHTML={{ __html: val.news_details + "..." }} />
                                    :
                                    null
                                }
                                <div className="d-flex mt-auto">
                                  <Link to="news-events/" className="btn btn-warning rounded-pill px-4 text-uppercase fw-bold btn-sm py-2 mt-auto">Read more</Link>
                                </div>
                              </div>
                            </div>
                          </div>

                        )
                      })
                    }
                  </Col>

                  :
                  null
                :
                <Col>
                  <div className="mt-lg-5 mt-4 wow fadeInUp">
                    Loading ...
                  </div>
                </Col>

            }
            <Col>
              <div className="border rounded-3 px-md-5 py-md-4 p-3 h-100">
                <div className="hm-testimonial-panel list-hm">
                  <div className="test-slide">
                    <h2 className="text-primary mb-3">Partnerships</h2>
                    <p className="wow">KCDH is establishing partnerships with healthcare organizations (Digital Health Partners) to foster research and innovation in digital health, and access clinical expertise and data. These DHP partnerships are critical for KCDH to create rich research and academic opportunities in Digital Health.</p>
                    <b>Partnership opportunities for:</b>
                    <ul>
                      <li> Hospital and Healthcare Providers </li>
                      <li>HealthTech Organizations</li>
                      <li>Government (State and Central)</li>
                    </ul>
                  </div>
                </div>
                <Link to="partnerships/" className="btn btn-warning rounded-pill px-4 text-uppercase fw-bold btn-sm py-2 mt-auto">More</Link>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>

      <Container>
        <div className="mb-5 px-md-5 py-md-4 pb-md-5 p-3 border rounded-3 overflow-hidden">
          <div className="col-lg-8 mx-auto">
            <h2 className="text-primary mb-3 text-center wow fadeInUp"><span>Students Testimonials</span></h2>
            <Container className="wow fadeInUp">
              <Slider {...settings}>
                <Row className="d-flex align-items-start">
                  <div className="col-lg-3 text-center">
                    <img src={global.variables.strDomainUrl + "images/siddhant-kolke.jpg"} alt="Siddhant Kolke" title="Siddhant Kolke" className="img-fluid rounded-3 mb-md-0 mx-auto mb-3" loading="lazy" />
                  </div>
                  <div className="col-lg-9">
                    <p>Any person wanting to break barriers across fields on the forefront of medicine, technology and healthcare should definitely look into minor in Healthcare Informatics at KCDH. You will be solving incredibly relevant problems in healthcare industry with medical professionals, engineers and economists in tandem.</p>
                    <p className="tm_name fw-bold">
                      Minor in Healthcare Informatics, 2019-23, KCDH <br />
                      - Siddhant Kolke
                    </p>
                  </div>
                </Row>
                <Row className="d-flex align-items-start">
                  <div className="col-lg-3 text-center">
                    <img src={global.variables.strDomainUrl + "images/shardul-kamble.jpg"} alt="Shardul Kamble" title="Shardul Kamble" className="img-fluid rounded-3 mb-md-0 mx-auto mb-3" loading="lazy" />
                  </div>
                  <div className="col-lg-9">
                    <p>KCDH's dynamic health-tech environment fueled my journey as an IDDDP student. Career guidance at KCDH enabled me to secure data science internships, shaping my success and deepening my passion for healthcare analytics and fueling my dedication to a career in transformative digital healthcare.</p>
                    <p className="tm_name fw-bold">
                      IDDDP in Healthcare Informatics, 2012-24, KCDH <br />
                      - Shardul Kamble
                    </p>
                  </div>
                </Row>
                <Row className="d-flex align-items-start">
                  <div className="col-lg-3 text-center">
                    <img src={global.variables.strDomainUrl + "images/nivedya-nambiar.jpg"} alt="Nivedya Nambiar" title="Nivedya Nambiar" className="img-fluid rounded-3 mb-md-0 mx-auto mb-3" loading="lazy" />
                  </div>
                  <div className="col-lg-9">
                    <p>KCDH offered a one-of-a-kind experience, enabling me to explore intersection of healthcare with electrical engineering. Global seminars and faculty connections were pivotal, shaping my career path. I wholeheartedly recommend KCDH to multidisciplinary enthusiasts. My hands-on R&D project on EEG data refinement was eye-opening, highlighting practical applications. Grateful for an impactful experience at KCDH.</p>
                    <p className="tm_name fw-bold">
                      KCDH Alumni, Minor - Batch 2023 <br />
                      - Nivedya Nambiar
                    </p>
                  </div>
                </Row>
                <Row className="d-flex align-items-start">
                  <div className="col-lg-3 text-center">
                    <img src={global.variables.strDomainUrl + "images/chirag.jpg"} alt="Chirag P" title="Chirag P" className="img-fluid rounded-3 mb-md-0 mx-auto mb-3" loading="lazy" />
                  </div>
                  <div className="col-lg-9">
                    <p>KCDH has been an enlightening and endearing experience, combining knowledge and lasting friendships. Working on a year long project in a stimulating environment enhanced my expertise in the ML domain. Experiencing cutting-edge research and motivated peers fueled my drive to work harder. Highly recommend KCDH for those aspiring to excel in medical or AI research.</p>
                    <p className="tm_name fw-bold">
                      KCDH Alumni, IDDDP - Batch 2023<br />
                      - Chirag P
                    </p>
                  </div>
                </Row>
                <Row className="d-flex align-items-start">
                  <div className="col-lg-3 text-center">
                    <img src={global.variables.strDomainUrl + "images/aryan-khanna.jpg"} alt="Aryan Khanna" title="Aryan Khanna" className="img-fluid rounded-3 mb-md-0 mx-auto mb-3" loading="lazy" />
                  </div>
                  <div className="col-lg-9">
                    <p>Studying healthcare economics, delving into health ethics, and exploring Wearable Health Technology were standout moments. Minor in Health Informatics  not only enhanced my career prospects but also improved my daily comprehension of health.</p>
                    <p className="tm_name fw-bold">
                      KCDH Alumni, Minor - Batch 2023 <br />
                      - Aryan Khanna
                    </p>
                  </div>
                </Row>
                <Row className="d-flex align-items-start">
                  <div className="col-lg-3 text-center">
                    <img src={global.variables.strDomainUrl + "images/akshit-srivastava.jpg"} alt="Akshit Srivastava" title="Akshit Srivastava" className="img-fluid rounded-3 mb-md-0 mx-auto mb-3" loading="lazy" />
                  </div>
                  <div className="col-lg-9">
                    <p>IDDDP was a game-changer for my career. Unsure about pursuing a PhD, the program allowed me to effectively apply my research skills. Through the submission of two papers, I expanded my horizons in healthcare informatics. The profound impact of this program continues to resonate throughout my academic and professional journey, marking it as a pivotal chapter of growth and achievement.</p>
                    <p className="tm_name fw-bold">
                      KCDH Alumni, IDDDP - Batch 2023 <br />
                      - Akshit Srivastava
                    </p>
                  </div>
                </Row>
                <Row className="d-flex align-items-start">
                  <div className="col-lg-3 text-center">
                    <img src={global.variables.strDomainUrl + "images/atharv-savarkar.jpg"} alt="Atharv Savarkar" title="Atharv Savarkar" className="img-fluid rounded-3 mb-md-0 mx-auto mb-3" loading="lazy" />
                  </div>
                  <div className="col-lg-9">
                    <p>My journey at KCDH has transformed my technical and interpersonal skills through hands-on healthcare projects. The unique career preparation in informatics sets KCDH apart. I highly recommend the IDDDP program for its innovative curriculum and supportive environment. My achievements, including research papers and the Undergraduate Research Award, reflect the impactful nature of KCDH's programs.</p>
                    <p className="tm_name fw-bold">
                      KCDH Alumni, IDDDP - Batch 2023<br />
                      - Atharv Savarkar
                    </p>
                  </div>
                </Row>
                <Row className="d-flex align-items-start">
                  <div className="col-lg-3 text-center">
                    <img src={global.variables.strDomainUrl + "images/saksham-srivastava.jpg"} alt="Saksham Srivastava" title="Saksham Srivastava" className="img-fluid rounded-3 mb-md-0 mx-auto mb-3" loading="lazy" />
                  </div>
                  <div className="col-lg-9">
                    <p>Thanks to the steadfast support from KCDH, I secured a meaningful internship with the Bill Gates Foundation, where I streamlined the public pharma supply chain for the UP government. These two years not only broadened my knowledge but also fueled a passion for making a significant impact in the realms of digital healthcare and management.</p>
                    <p className="tm_name fw-bold">
                      KCDH Alumni, IDDDP - Batch 2023 <br />
                      - Saksham Srivastava
                    </p>
                  </div>
                </Row>
                <Row className="d-flex align-items-start">
                  <div className="col-lg-3 text-center">
                    <img src={global.variables.strDomainUrl + "images/rahul-shanbag.jpg"} alt="Rahul Shanbag" title="Rahul Shanbag" className="img-fluid rounded-3 mb-md-0 mx-auto mb-3" loading="lazy" />
                  </div>
                  <div className="col-lg-9">
                    <p>KCDH's exceptional efforts in placements and internships are setting a benchmark for other departments. I  highly recommend  KCDH for its pioneering work, supportive faculty, and efficient administration. My placement at Arthur D. Little was undoubtedly shaped by my KCDH background.</p>
                    <p className="tm_name fw-bold">
                      KCDH Alumni, IDDDP - Batch 2023 <br />
                      - Rahul Shanbag
                    </p>
                  </div>
                </Row>
                <Row className="d-flex align-items-start">
                  <div className="col-lg-3 text-center">
                    <img src={global.variables.strDomainUrl + "images/chirag-raju.jpg"} alt="Chirag Raju" title="Chirag Raju" className="img-fluid rounded-3 mb-md-0 mx-auto mb-3" loading="lazy" />
                  </div>
                  <div className="col-lg-9">
                    <p>"My interest in mathematics, statistics and fascination towards medicine led me to enrol for IDDDP at KCDH. I am fascinated by the medical datasets, genome datasets, epidemiological datasets, and high resolution images"</p>
                    <p className="tm_name fw-bold">
                      IDDDP Student, 2018-23, KCDH <br />
                      - Chirag Raju
                    </p>
                  </div>
                </Row>
                <Row className="d-flex align-items-start">
                  <div className="col-lg-3 text-center">
                    <img src={global.variables.strDomainUrl + "images/atharv-savarkar.jpg"} alt="Atharv Savarkar" title="Atharv Savarkar" className="img-fluid rounded-3 mx-auto mb-3" loading="lazy" />
                  </div>
                  <div className="col-lg-9">
                    <p>"Major motivation for me to pursue IDDDP in Healthcare Informatics was interesting projects. At KCDH, projects are application oriented and can be implemented in real world."</p>
                    <p className="tm_name fw-bold">
                      IDDDP Student, 2018-23, KCDH <br />
                      - Atharv Savarkar
                    </p>
                  </div>
                </Row>
              </Slider>
            </Container>
          </div>
        </div>
      </Container>

      {/* <FsLightbox
        toggler={toggler}
        sources={[global.variables.strDomainUrl + "/images/visualpanel/mob-visual.jpg"]}
      /> */}

      <Roadblock_Popup />
      <CookieConsent
        location="bottom"
        buttonText="GOT IT"
        buttonClasses="btn rounded-0 text-white px-3"
        containerClasses="cookie-wrapper"
        contentClasses="cookie-content"
        cookieName="cookiesAccepted"
        expires={150}
        className="cookie-popup-inner"
      >We use cookies to ensure that we give you the best experience and to analyze our website traffic and performance; we never collect any personal data.</CookieConsent>
    </>
  );
}