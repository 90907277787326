
import { useState, useEffect} from "react";
import { GetVisualPanelList } from "../../api/visualpanel-functions";
import globalVariables from "../../global-variables";
import Carousel from "react-bootstrap/Carousel";
import { Container } from "reactstrap";
import { useNavigate } from "react-router-dom";

export default function VisualpanelBlock() {

    const navigate = useNavigate();
    // constant to be used on the page
    const [index, setIndex] = useState(0);
    const [visualpanels, setVisualpanelList] = useState([]);

    // success function when the list is retrived
    const OnGetVisualpanelSuccess = (p_response) => {
        // set the list for looping
        setVisualpanelList(p_response);
    }

    // select index change event of the visual panel
    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    // things to be done when the page is loaded
    useEffect(() => {

        // make the object for getting the visual panel list
        let _objVP = new Object();
        _objVP._visualpanel_id = null;
        _objVP._fieldname = null;
        _objVP._isactive = 1;

        // call the function to get the roadblock
        GetVisualPanelList(_objVP, OnGetVisualpanelSuccess, navigate);

    }, []);

    return (
        <div className="container-fluid bg-light px-0 h-100">
            <div className="d-md-none d-block">
                <img className="img-fluid" src={globalVariables.variables.strDomainUrl + "images/visualpanel/mob-visual.jpg"} alt="The Koita Centre for Digital Health - KCDH set up at IIT Bombay" title="The Koita Centre for Digital Health - KCDH set up at IIT Bombay" width={767} height={362} loading="lazy" />
            </div>
            <div className="visual-container-1920 d-md-block d-none">
                <Carousel fade
                    activeIndex={index}
                    onSelect={handleSelect}
                    interval={3000}
                    nextIcon={false}
                    prevIcon={false}
                >
                    {
                        // check if the records is loaded then bind it
                        visualpanels.length > 0 ?

                            visualpanels.map((val) => {
                                return (
                                    <Carousel.Item>
                                        <img className="img-fluid"
                                            src={globalVariables.variables.strApiUrl + "/visualpanel/" + val.visualpanel_pic }
                                            alt={val.visualpanel_title} title={val.visualpanel_title} width={1920} height={690}/>

                                        <Carousel.Caption className="slider-caption">
                                            <Container className="d-md-block d-none">
                                                <div className="caption-container">
                                                    <div className="h1 fw-bolder" style={{ color: val.visualpanel_title_color }}>
                                                        {val.visualpanel_title}
                                                    </div>
                                                    {
                                                        val.visualpanel_caption != null && val.visualpanel_caption != "" ?
                                                            <small className="h2 d-block fw-light" style={{ color: val.visualpanel_caption_color }}>
                                                                {val.visualpanel_caption}
                                                            </small>
                                                            :
                                                            null
                                                    }
                                                    <div>
                                                        {val.visualpanel_link != null && val.visualpanel_link != "" ?
                                                            val.visualpanel_isextlink == "1" ?
                                                                <a href={val.visualpanel_link} className="btn btn-light px-4 py-2 fw-light rounded-pill mt-4 fw-bold" target="_blank">Read More</a> :
                                                                <a href={val.visualpanel_link} className="btn btn-light px-4 py-2 fw-light rounded-pill mt-4 fw-bold">Read More</a>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                </div>
                                            </Container>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                    
                                )
                            })
                            :
                            // <Carousel.Item>
                            //     <img className="d-block w-100" src={globalVariables.variables.strDomainUrl + "images/visualpanel/visual1.jpg"} alt="" />
                            //     <Carousel.Caption className="slider-caption">
                            //         <Container className="d-md-block d-none">
                            //         <div className="caption-container">
                            //             <h2 className="h1">
                            //             {/* Driving education, <br /> research, and collaboration<br /> in <b>Digital Health</b> */}
                            //             </h2>
                            //             <div>
                            //             {/* <Link to="" className="btn btn-light px-4 py-2 fw-light rounded-pill mt-4 fw-bold">Read More</Link> */}
                            //             </div>
                            //         </div>
                            //         </Container>
                            //     </Carousel.Caption>
                            // </Carousel.Item>
                            null
                    }
                </Carousel>
            </div>
        </div>
    );


}