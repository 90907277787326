import React, { useState, useEffect } from "react";
import global from "../../global-variables";
import Header from "../layout/header";
import { Link } from "react-router-dom";
import $ from "jquery";
import WOW from "wowjs";

import { Container, Row, Col } from "reactstrap";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Modal from 'react-bootstrap/Modal';
import { Course } from './course-popup.js';
import globalVariables from "../../global-variables";
import Metatag from "../../hooks/Metatag";

export default function PHDProgram() {
  const [show, setShow] = useState(false);
  const [getcourseid, setCourseid] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = (e, course_id) => {
    e.preventDefault();
    setShow(true);
    //get Course id
    setCourseid(course_id);
  }

  useEffect(() => {
    // add class for home header and remove inner class
    $(".fixed-top").addClass("bg-header");

    // initiate the wow effect
    new WOW.WOW({
      live: false,
    }).init();

  }, []);

  useEffect(() => {

    window.initializeFancyboxMedia();
  }, [])

  return (
    <>
      <Header main_className="bg-header"></Header>

      <Metatag title='Ph.D. Program in Digital Health'
        description='KCDH and IIT Bombay offers Ph.D. Program in Digital Health'
        keywords="Ph.D. Program, Ph.D. Program in Digital Health, Ph.D. Program at IIT Bombay, IIT Bombay Ph.D. Program in Digital Health, Ph.D. Program Overview, Ph.D. Program Eligibility, IIT Bombay Ph.D. Information brochure, Ph.D. Program in Digital Health Curriculum Structure"
      />

      {/* Visual Panel */}
      <Container fluid className="bg-visual-inside p-0" >
        <div className="visual-inside-big">
          <Row className="g-md-0">
            <Col md={6} className="order-2 order-md-1">
              <Container className="d-flex align-items-end h-100">
                <div className="d-inline-block m-0 align-self-end col-lg-6 col-xl-6 py-xl-3 p-2 text-white">
                  <h1 className="fw-light text-white m-0 align-self-center wow fadeInUp">
                    Ph.D. Program
                  </h1>
                </div>
              </Container>
            </Col>
            <div className="col-md-6 order-1 order-md-2">
              <img src={global.variables.strDomainUrl + "images/visual-students-corner.jpg"} className="img-fluid" alt="" title="" />
            </div>
          </Row>
        </div>
      </Container>


      {/* Container */}
      <Container className="my-5">
        <Row>
          <Col md={3}>
            <h2 className="text-primary wow fadeInUp">Program Overview</h2>
          </Col>
          <Col md={9} className="ps-md-5">
            <p>Ph.D. Program in Digital Health is a program that provides an opportunity for students to fine-tune their informatics and data analytics skills as well as gain a firm grounding in the area of healthcare and its related domains. It gives students an opportunity to learn about biomedical ethics, to apply domain skills such as AI/ML in the hospital system as well as at the community level for public health informatics and in a broader sense for global health.</p>
          </Col>
        </Row>
      </Container>
      <Container>
        <hr className="px-5" />
      </Container>
      <Container className="my-5">
        <Row>
          <Col md={3}>
            <h2 className="text-primary wow fadeInUp">
              Important dates related to Ph. D admission
            </h2>
          </Col>
          <Col md={9} className="ps-md-5 list">
            <ul>
              <li>Online Written Test: April 21, 2024 </li> 
              <li>Interviews for shortlisted candidates : April 30 / May 1, 2024</li>
              <li>2nd round of interview: May 6, 2024</li> 
            </ul>
          </Col>
        </Row>
      </Container>
      <Container>
        <hr className="px-5" />
      </Container>
      <Container className="my-5">
        <Row>
          <Col md={3}>
            <h2 className="text-primary wow fadeInUp">
              Tentative Topics for PhD admission
            </h2>
          </Col>
          <Col md={9} className="ps-md-5 list">
            <div className="wow fadeInUp">
              <Link className="btn btn-warning rounded-pill px-4 fw-bold text-uppercase" to="../images/pdf/phd-topics-for-autumn-2023-admission.pdf" target="_blank" rel="noreferrer">View Tentative
                Topics for PhD admission</Link>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <hr className="px-5" />
      </Container>
      <Container className="my-5">
        <Row>
          <Col md={3}>
            <h2 className="text-primary wow fadeInUp">
              Shortlisted candidates list for written test
            </h2>
          </Col>
          <Col md={9} className="ps-md-5 list">
            <div className="wow fadeInUp">
              <Link className="btn btn-warning rounded-pill px-4 fw-bold text-uppercase" to="../images/pdf/kcdh-phd-shortlisted-candidates-list-autumn-2023.pdf" target="_blank" rel="noreferrer">View Shortlisted candidates list for written test</Link>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <hr className="px-5" />
      </Container>
      <Container className="my-5">
        <Row>
          <Col md={3}>
            <h2 className="text-primary wow fadeInUp">
              Ph.D. Entrance Sample Paper
            </h2>
          </Col>
          <Col md={9} className="ps-md-5">
            <div className="wow fadeInUp">
              <Link className="btn btn-warning rounded-pill px-4 fw-bold text-uppercase" to="../images/pdf/kcdh-Ph-d-2022-23-entrance-sample-paper.pdf" target="_blank" rel="noreferrer">View Sample paper</Link>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <hr className="px-5" />
      </Container>
      <Container className="container my-5">
        <Row>
          <Col md={3}>
            <h2 className="text-primary wow fadeInUp">
              IIT Bombay Ph.D. Information brochure
            </h2>
          </Col>
          <Col md={9} className="ps-md-5">
            <p>For important guidelines, important dates, eligibility criteria, admission process, fee structure
              or any other information related to Ph.D. courses at IIT Bombay, refer to <Link
                to="https://www.iitb.ac.in/newacadhome/Ph.D.Brochure2023-24.pdf" target="_blank" rel="noreferrer"
                className="text-primary text-decoration-underline">IITB Information Brochure for Ph.D.
                admissions.</Link></p>
            <p>For information related to Ph.D. in Digital Health at Koita Centre for Digital Health, refer B27
              on Page no. 48 of the brochure.</p>
          </Col>
        </Row>
      </Container>
      <Container>
        <hr className="px-5" />
      </Container>
      <Container className="my-5">
        <Row>
          <Col md={3}>
            <h2 className="text-primary wow fadeInUp">
              Apply for Ph.D. in Digital Health
            </h2>
          </Col>
          <Col md={9} className="ps-md-5">
            <p class="m-0">
              To apply for admission for Ph.D. Program for Academic year (2023-24) - Spring semester visit: <Link to="https://www.iitb.ac.in/newacadhome/phd.jsp" target="_blank" rel="nofollow" class="text-primary text-decoration-underline">https://www.iitb.ac.in/newacadhome/phd.jsp</Link>
            </p>
          </Col>
        </Row>
      </Container>
      <Container>
        <hr className="px-5" />
      </Container>
      <Container className="my-5">
        <Row>
          <Col md={3}>
            <h2 className="text-primary wow fadeInUp">Eligibility Criteria</h2>
          </Col>
          <Col md={9} className="ps-md-5">
            <div className="">
              <ol style={{ listStyleType: 'upper-latin' }}>
                <li class="mb-2">M.E./M.Tech/MS degrees in Engineering/Technology</li>
                <li class="mb-2">MS (Master of Surgery) / MD / MVSc / MDS / MPTh/MPharm/M.O.Th</li>
                <li class="mb-2">MBBS, BDS, BPharm, BVSc, BPTh, BOTh (degree programme to be of 4 years  or more) with 60 percent aggregate AND qualifying All India level post graduate entrance examination for corresponding disciplines such as AIIMS/NEET-PG/MCI/JIPMER/ PGI Chandigarh/AFMC­Pune/ for MBBS/BDS or GPAT (for Pharmacy graduates) or a valid CSIR/UGC/DBT/ICMR JRF not linked to ICMR project (for FA any fellowship that will provide scholarship for 5 years)</li>
                <li class="mb-2">M.Sc./ M.S./ Integrated BS-MS in Physical sciences (Physics, Chemistry or equivalent disciplines),  M.A./M.Sc./Integrated BS-MS in Mathematical sciences (Mathematics, Statistics, Biostatistics, Applied Statistics, Applied/Computational Mathematics or equivalent disciplines).  M.Sc./ M.S. / Integrated BS-MS in Life sciences (specifically Biochemistry, Biophysics, Biotechnology, Physiology, Molecular Biology or equivalent disciplines)</li>
                <li class="mb-2">B.E./B. Tech/ (Bachelor’s degree in Engineering/Technology)/  4 year BS (Mathematics, Applied/Computational Mathematics, Economics, Statistics or equivalent disciplines), with eligibility subject to specified admission  procedure, would be considered. A valid GATE score and other IITB norms as per IITB PhD  brochure will be required.</li>
              </ol>
            </div>
            <p>
              The candidates with qualifying degrees as mentioned in ‘C, D & E’ must fulfill one of the following:
            </p>
            <div className="list">
              <ul>
                <li>A valid GATE score</li>
                <li>A  valid CSIR/UGC/DBT/ICMR JRF not linked to ICMR project (for FA any fellowship that will provide scholarship for 5 years)</li>
                <li>Experience as specified earlier in  A.5 and A.6 for CT, EX, IS, PS, SF, SW category</li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <hr className="px-5" />
      </Container>
      {/* <Container className="my-5">
        <Row>
          <Col md={3}>
            <h2 className="text-primary wow fadeInUp">
            Videos
            </h2>
          </Col>
          <Col md={9} className="ps-md-5 list">
            <Link to="#" className="fancybox-media"><img src={global.variables.strDomainUrl + "images/.jpg"} alt="Videos" title="Videos" className="img-flid rounded-3" /></Link>
          </Col>
        </Row>
      </Container>
      <Container>
        <hr className="px-5" />
      </Container> */}
      <Container className="my-5">
        <Row>
          <Col md={3}>
            <h2 className="text-primary wow fadeInUp">
              Curriculum Structure and Credit Structure
            </h2>
          </Col>
          <Col md={9} className="ps-md-5 list">
            <p>
              A student undertaking the Ph.D. Program will be exposed to a broad range of theoretical and
              practical issues related to Healthcare Informatics. While the Program is interdisciplinary,
              courses and research will be organized around three broad tracks/baskets:
            </p>
            <p className="m-0"><b>Group 1:</b> Healthcare Standards, Clinical applications & Bio related courses</p>
            <div className="rounded-3 border-bottom overflow-hidden mb-4 mt-2">
              <div className="table-responsive">
                <table className="table table-bordered table-striped m-0">
                  <thead className="table-primary">
                    <tr>
                      <th style={{ width: "120px" }}>Course Code</th>
                      <th style={{ width: "350px" }}>Course Name</th>
                      <th style={{ width: "75px" }}>Credits</th>
                      <th style={{ width: "auto" }}>Department/Center</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>BB 603</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "BB603")}>Physiology for Engineers</Link>
                      </td>
                      <td>6</td>
                      <td>Biosciences and Bioengineering</td>
                    </tr>
                    <tr className="border-bottom-0">
                      <td>BB 607</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "BB607")}>Proteomics: Principles and Techniques</Link>
                      </td>
                      <td>6</td>
                      <td>Biosciences and Bioengineering</td>
                    </tr>
                    <tr>
                      <td>BB 619</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "BB619")}>Mathematics for Biologists</Link>
                      </td>
                      <td>6</td>
                      <td>Biosciences and Bioengineering</td>
                    </tr>
                    <tr>
                      <td>BB 624</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "BB624")}>Microfluidics: Physics and Applications</Link>
                      </td>
                      <td>6</td>
                      <td>Biosciences and Bioengineering</td>
                    </tr>
                    <tr>
                      <td>BB 626</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "BB626")}>Modeling Biological Systems and Processes</Link>
                      </td>
                      <td>6</td>
                      <td>Biosciences and Bioengineering</td>
                    </tr>
                    <tr>
                      <td>BB 627</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "BB627")}>Medical Imaging
                          Methods</Link>
                      </td>
                      <td>3</td>
                      <td>Biosciences and Bioengineering</td>
                    </tr>
                    <tr>
                      <td>BB 633 </td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "BB633")}>Movement Neuroscience</Link>
                      </td>
                      <td>6</td>
                      <td>Biosciences and Bioengineering </td>
                    </tr>
                    <tr>
                      <td>BB 640</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "BB640")}>Biologics</Link>
                      </td>
                      <td>3</td>
                      <td>Biosciences and Bioengineering</td>
                    </tr>
                    <tr>
                      <td>BB 645</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "BB645")}>Drug Discovery and Development</Link>
                      </td>
                      <td>3</td>
                      <td>Biosciences and Bioengineering</td>
                    </tr>
                    <tr>
                      <td>BB 656</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "BB656")}>Introduction
                          to Mechanobiology</Link>
                      </td>
                      <td>3</td>
                      <td>Biosciences and Bioengineering</td>
                    </tr>
                    <tr>
                      <td>BB 663</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "BB663")}>Medical Imaging
                          Physics</Link>
                      </td>
                      <td>3</td>
                      <td>Biosciences and Bioengineering</td>
                    </tr>
                    <tr>
                      <td>CL 662</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "CL662")}>Introduction to
                          Computational Biology</Link>
                      </td>
                      <td>6</td>
                      <td>Chemical Engineering</td>
                    </tr>
                    <tr>
                      <td>DH 301</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "DH301")}>Basic Epidemiology</Link>
                      </td>
                      <td>6</td>
                      <td>Koita Centre for Digital Health</td>
                    </tr>
                    <tr>
                      <td>DH 803</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "DH803")}>Wearable Health Technologies</Link>
                      </td>
                      <td>6</td>
                      <td>Koita Centre for Digital Health</td>
                    </tr>
                    <tr>
                      <td>DH 804</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "DH804")}>Magnetic Resonance Imaging: From Physics to Physiology</Link>
                      </td>
                      <td> </td>
                      <td> </td>
                    </tr>
                    <tr>
                      <td>DH 805</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "DH805")}>X-ray based Imaging in Healthcare</Link>
                      </td>
                      <td> </td>
                      <td> </td>
                    </tr>
                    <tr>
                      <td>ME 724</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "ME724")}>Essentials of Turbulence</Link>
                      </td>
                      <td>6</td>
                      <td>Mechanical Engineering</td>
                    </tr>
                    <tr>
                      <td>ME 780</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "ME780")}>Biofluid Mechanics</Link>
                      </td>
                      <td>6</td>
                      <td>Mechanical Engineering</td>
                    </tr>
                    <tr className="border-bottom-0">
                      <td>TD 617</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "TD617")}>Healthtech Innovation and Design</Link>
                      </td>
                      <td>6</td>
                      <td>Centre for Technology Alternatives for Rural Areas (CTARA)</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <p className="m-0"><b>Group 2:</b> Healthcare informatics & analytics</p>
            <div className="rounded-3 border overflow-hidden mb-4 mt-2">
              <div className="table-responsive">
                <table className="table table-bordered table-striped m-0">
                  <thead className="table-primary">
                    <tr>
                      <th style={{ width: "120px" }}>Course Code</th>
                      <th style={{ width: "350px" }}>Course Name</th>
                      <th style={{ width: "75px" }}>Credits</th>
                      <th style={{ width: "auto" }}>Department/Center</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>CS 419</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "CS419")}>Introduction to Machine Learning</Link>
                      </td>
                      <td>6</td>
                      <td>Computer Science & Engineering </td>
                    </tr>
                    <tr className="border-bottom-0">
                      <td>CS 631</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "CS631")}>Implementation Techniques for Relational Database Systems </Link>
                      </td>
                      <td>6</td>
                      <td>Computer Science & Engineering </td>
                    </tr>
                    <tr className="border-bottom-0">
                      <td>CS 663</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "CS663")}>Digital Image
                          Processing</Link>
                      </td>
                      <td>6</td>
                      <td>Computer Science & Engineering </td>
                    </tr>
                    <tr className="border-bottom-0">
                      <td>CS 725</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "CS725")}>Foundations of
                          Machine Learning</Link>
                      </td>
                      <td>6</td>
                      <td>Computer Science & Engineering </td>
                    </tr>
                    <tr className="border-bottom-0">
                      <td>CS 726</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "CS726")}>Advanced
                          Machine Learning</Link>
                      </td>
                      <td>6</td>
                      <td>Computer Science & Engineering </td>
                    </tr>
                    <tr>
                      <td>CS 736</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "CS736")}>Medical Image
                          Computing</Link>
                      </td>
                      <td>6</td>
                      <td>Computer Science & Engineering </td>
                    </tr>
                    <tr>
                      <td>CS 754</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "CS754")}>Advanced Image
                          Processing</Link>
                      </td>
                      <td>6</td>
                      <td>Computer Science & Engineering </td>
                    </tr>
                    <tr className="border-bottom-0">
                      <td>CS 769</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "CS769")}>Optimization in
                          Machine Learning</Link>
                      </td>
                      <td>6</td>
                      <td>Computer Science & Engineering </td>
                    </tr>
                    <tr>
                      <td>DH 306</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "DH306")}>Healthcare Performance Metrics</Link>
                      </td>
                      <td>6</td>
                      <td>Koita Centre for Digital Health</td>
                    </tr>
                    <tr className="border-bottom-0">
                      <td>DH 308</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "DH308")}>Clinical Data Management</Link>
                      </td>
                      <td>6</td>
                      <td>Koita Centre for Digital Health</td>
                    </tr>
                    <tr>
                      <td>DH 602</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "DH602")}>Machine Learning and Statistical Methods in Healthcare</Link>
                      </td>
                      <td> </td>
                      <td> </td>
                    </tr>
                    <tr>
                      <td>DH 607</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "DH607")}>Introduction to Computational Multi-omics</Link>
                      </td>
                      <td>6</td>
                      <td> </td>
                    </tr>
                    <tr>
                      <td>DH 801</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "DH801")}>Biostatistics in Healthcare</Link>
                      </td>
                      <td>6</td>
                      <td>Koita Centre for Digital Health</td>
                    </tr>
                    <tr>
                      <td>DS 303</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "DS303")}>
                          Introduction to Machine Learning
                        </Link>
                      </td>
                      <td>6</td>
                      <td>Centre for Machine Intelligence and Data Science</td>
                    </tr>
                    <tr>
                      <td>EE 610</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "EE610")}>Image Processing</Link>
                      </td>
                      <td>6</td>
                      <td>Electrical Engineering</td>
                    </tr>
                    <tr>
                      <td>EE 769</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "EE769")}>Introduction to Machine Learning</Link>
                      </td>
                      <td>6</td>
                      <td>Electrical Engineering</td>
                    </tr>
                    <tr>
                      <td>GNR 652</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "GNR652")}>
                          Machine Learning for Remote Sensing 1
                        </Link>
                      </td>
                      <td>6</td>
                      <td>Centre of Studies in Resources Engineering</td>
                    </tr>
                    <tr className="border-bottom-0">
                      <td>IE 501</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "IE501")}>Optimization Models</Link>
                      </td>
                      <td>6</td>
                      <td>Industrial Engineering and Operations Research</td>
                    </tr>
                    <tr>
                      <td>IE 615</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "IE615")}>Data Analytics in Operations Research</Link>
                      </td>
                      <td>6</td>
                      <td>Industrial Engineering and Operational Research </td>
                    </tr>
                    <tr className="border-bottom-0">
                      <td>IE 643</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "IE643")}>Deep Learning Theory & Practice</Link>
                      </td>
                      <td>6</td>
                      <td>Industrial Engineering and Operations Research</td>
                    </tr>
                    <tr className="border-bottom-0">
                      <td>ME 781</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "ME781")}>Statistical
                          Machine Learning and Data Mining</Link>
                      </td>
                      <td>6</td>
                      <td>Mechanical Engineering</td>
                    </tr>
                    <tr>
                      <td>SI 422</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "SI422")}>Regression
                          Analysis</Link>
                      </td>
                      <td>8</td>
                      <td>Mathematics</td>
                    </tr>
                    <tr>
                      <td>SI 541</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "SI541")}>Statistical Epidemiology</Link>
                      </td>
                      <td>6</td>
                      <td>Mathematics</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <p><b>Group 3:</b> Health systems & Policy & Ethics</p>
            <div className="rounded-3 border overflow-hidden mb-4 mt-2">
              <div className="table-responsive">
                <table className="table table-bordered table-striped m-0">
                  <thead className="table-primary">
                    <tr>
                      <th style={{ width: "120px" }}>Course Code</th>
                      <th style={{ width: "350px" }}>Course Name</th>
                      <th style={{ width: "75px" }}>Credits</th>
                      <th style={{ width: "auto" }}>Department/Center</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="border-bottom-0">
                      <td>DH 304</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "DH304")}>Economics of Health Care</Link>
                      </td>
                      <td>6</td>
                      <td>Koita Centre for Digital Health</td>
                    </tr>

                    <tr className="border-bottom-0">
                      <td>DH 802</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "DH802")}>Service Operations and Quality Management in Healthcare</Link>
                      </td>
                      <td>6</td>
                      <td>Koita Centre for Digital Health</td>
                    </tr>
                    <tr className="border-bottom-0">
                      <td>DH 899</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "DH899")}>Communication Skills</Link>
                      </td>
                      <td>6</td>
                      <td>Koita Centre for Digital Health</td>
                    </tr>
                    <tr className="border-bottom-0">
                      <td>ES 899/CM 899</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "ES899")}>Communication Skills</Link>
                      </td>
                      <td>6</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>HS 633</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "HS633")}>Econometrics of Programme Evaluation</Link>
                      </td>
                      <td>6</td>
                      <td>Humanities and Social Science</td>
                    </tr>
                    <tr className="border-bottom-0">
                      <td>HS 638</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "HS638")}>
                          Financial
                          Econometrics
                        </Link>
                      </td>
                      <td>6</td>
                      <td>Humanities and Social Sciences</td>
                    </tr>
                    <tr className="border-bottom-0">
                      <td>HS 426</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "HS426")}>
                          Theory and
                          Policy of Managerial Finance
                        </Link>
                      </td>
                      <td>6</td>
                      <td>Humanities and Social Sciences</td>
                    </tr>
                    <tr>
                      <td>IE 709</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "IE709")}>
                          IEOR for Health
                          Care
                        </Link>
                      </td>
                      <td>8</td>
                      <td>Industrial Engineering and Operational Research</td>
                    </tr>
                    <tr>
                      <td>PS 619</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "PS619")}>
                          Health Policy:
                          An Introduction
                        </Link>
                      </td>
                      <td>6</td>
                      <td>Ashank Desai Centre for Policy Studies</td>
                    </tr>
                    <tr className="border-bottom-0">
                      <td>SOM 633</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "SOM633")}>
                          Quality
                          Management
                        </Link>
                      </td>
                      <td>3</td>
                      <td>SJM School of Management</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <p><b>Group 4:</b> R&D Project </p>
            <div className="rounded-3 border overflow-hidden mb-4 mt-2">
              <div className="table-responsive">
                <table className="table table-bordered table-striped m-0">
                  <thead className="table-primary">
                    <tr>
                      <th style={{ width: "120px" }}>Course Code</th>
                      <th style={{ width: "350px" }}>Course Name</th>
                      <th style={{ width: "75px" }}>Credits</th>
                      <th style={{ width: "auto" }}>Department/Center</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="border-bottom-0">
                      <td>DH 307 </td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "DH307")}>R&D Project </Link>
                      </td>
                      <td>6</td>
                      <td>Koita Centre for Digital Health</td>
                    </tr>
                    <tr className="border-bottom-0">
                      <td>DH 604 </td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "DH604")}>R&D Project - I  </Link>
                      </td>
                      <td></td>
                      <td>  </td>
                    </tr>
                    <tr className="border-bottom-0">
                      <td>DH 605 </td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "DH605")}>R&D Project - II  </Link>
                      </td>
                      <td></td>
                      <td>  </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <p>The course structure and credit structure for the Ph.D. Program in Digital Health is given in the following sections.</p>
            <p>Students with MS/M.Tech or equivalent (categories A & B in eligibility criteria):</p>
            <div className="rounded-3 border overflow-hidden mb-5 mt-2">
              <div className="table-responsive">
                <table className="table table-bordered table-striped m-0">
                  <thead className="table-primary">
                    <tr>
                      <th style={{ width: "260px" }}>Credit requirements</th>
                      <th>Credit Structure</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Minimum 16 credits and Maximum 22 credits required<sup>1</sup></td>
                      <td>Communication skills + Credit seminar + as per recommendation from an
                        adviser(s) - course on Introduction to Public Health informatics</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <p>Students with M.Sc. or equivalent (category D in eligibility criteria): </p>
            <div className="rounded-3 border overflow-hidden mb-5 mt-2">
              <div className="table-responsive">
                <table className="table table-bordered table-striped m-0">
                  <thead className="table-primary">
                    <tr>
                      <th style={{ width: "260px" }}>Credit requirements</th>
                      <th>Credit Structure</th>
                      <th>Course Structure</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Minimum 34 credits and Maximum 46 credits required<sup>2</sup></td>
                      <td>Communication skills + Credit seminars (up to 2) + as per recommendation
                        from an adviser(s) - course on Introduction to Public Health informatics
                      </td>
                      <td>Students with a bio-background need to take at least one from Group 2. Any
                        non-bio student needs to take at least one from Group 1/Group 3</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <p>Students with B.Tech or equivalent (categories C & E in eligibility criteria):</p>
            <div className="rounded-3 border overflow-hidden mb-5 mt-2">
              <div className="table-responsive">
                <table className="table table-bordered table-striped m-0">
                  <thead className="table-primary">
                    <tr>
                      <th style={{ width: "260px" }}>Credit requirements</th>
                      <th>Credit Structure</th>
                      <th>Course Structure</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Minimum 44 credits and Maximum 56 credits required<sup>3</sup></td>
                      <td>Communication skills + Credit seminars (up to 2) + as per recommendation
                        from an adviser(s) - course on Introduction to Public Health informatics
                      </td>
                      <td>Students with a bio-background need to take at least one from Group 2. Any non-bio student needs to take at least one from Group 1/Group 3</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <p className="mb-0"><sup>1</sup> R4.1 of <Link to="https://www.iitb.ac.in/newacadhome/phdRules.pdf"
              target="_blank" className="text-primary text-decoration-underline">https://www.iitb.ac.in/newacadhome/phdRules.pdf</Link></p>
            <p className="mb-0"><sup>2</sup> R4.2 of <Link to="https://www.iitb.ac.in/newacadhome/phdRules.pdf"
              target="_blank" className="text-primary text-decoration-underline">https://www.iitb.ac.in/newacadhome/phdRules.pdf</Link></p>
            <p><sup>3</sup> R4.3 of <Link to="https://www.iitb.ac.in/newacadhome/phdRules.pdf" target="_blank" className="text-primary text-decoration-underline">https://www.iitb.ac.in/newacadhome/phdRules.pdf</Link></p>
            <p>Apart from the minimum credit requirement, additional credit requirements will be decided by a
              faculty advisory from the KCDH centre.</p>
            <p>The newly proposed “Introduction to Public Health informatics” course will be a core mandatory
              course for all students who join the Ph.D. program. Those who have already credited an
              equivalent course in Public Health Informatics will be eligible for a waiver.</p>
          </Col>
        </Row>
      </Container>
      <Container>
        <hr className="px-5" />
      </Container>

      <Container className="my-5">
        <Row>
          <Col md={3}>
            <h2 className="text-primary wow fadeInUp">
              Course Offered in Autumn 2024 - 25
            </h2>
          </Col>
          <Col md={9} className="ps-md-5 list">
            <div className="rounded-3 border border-bottom position-relative overflow-hidden mb-3">
              <div className="table-responsive">
                <table className="table table-bordered table-striped m-0">
                  <thead className="table-primary">
                    <tr>
                      <th style={{ width: "120px" }}>Course Code</th>
                      <th style={{ width: "auto" }}>Course Name</th>
                      <th style={{ width: "350px" }}>Instructor</th> 
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>DH 307</td>
                      <td><Link onClick={(e) => handleShow(e, "DH307")}> R & D Project </Link></td>
                      <td>Prof. Ganesh Ramakrishnan</td> 
                    </tr> 
                    <tr>
                      <td>DH 301</td>
                      <td><Link onClick={(e) => handleShow(e, "DH301")}>Basic Epidemiology </Link></td>
                      <td>Prof. Ganesh Ramakrishnan and <br></br> Dr. Kalyani Addya and Dr. Sandip Mandal</td> 
                    </tr> 
                    <tr>
                      <td>DH 803</td>
                      <td><Link onClick={(e) => handleShow(e, "DH803")}>Wearable Health Technologies</Link></td>
                      <td>Dr. Nirmal Punjabi</td> 
                    </tr>
                    <tr>
                      <td>DH 604</td>
                      <td><Link onClick={(e) => handleShow(e, "DH604")}>R&D Project - I </Link></td>
                      <td>Prof. Ganesh Ramakrishnan</td> 
                    </tr>
                    <tr>
                      <td>DH 607</td>
                      <td><Link onClick={(e) => handleShow(e, "DH607")}>Introduction to Computational Multi-omics  </Link></td>
                      <td>Prof. Saket Choudhary </td> 
                    </tr>
                    <tr>
                      <td>DH 804</td>
                      <td><Link onClick={(e) => handleShow(e, "DH804")}>Magnetic Resonance Imaging: From Physics to Physiology (Second Half) </Link></td>
                      <td>Prof. Ashutosh Kumar </td> 
                    </tr>
                    <tr>
                      <td>CL 662 </td>
                      <td><Link onClick={(e) => handleShow(e, "CL662")}>Introduction to Computational Biology</Link></td>
                      <td>Prof. Pramod Wangikar </td> 
                    </tr>
                    <tr>
                      <td>BB 633</td>
                      <td><Link onClick={(e) => handleShow(e, "BB633")}>Movement Neuroscience</Link></td>
                      <td>Prof. Neeta Kanekar</td> 
                    </tr>
                    <tr>
                      <td>BB 607 </td>
                      <td><Link onClick={(e) => handleShow(e, "BB607")}>Proteomics: Principles and Techniques </Link></td>
                      <td>Prof. Sanjeeva Srivastava </td> 
                    </tr>
                    <tr>
                      <td>IE 643</td>
                      <td><Link onClick={(e) => handleShow(e, "IE643")}>Deep Learning: Theory and Practice</Link></td>
                      <td>Prof. Balamurugan </td> 
                    </tr>
                    <tr>
                      <td>EE 769</td>
                      <td><Link onClick={(e) => handleShow(e, "EE769")}>Introduction to Machine Learning</Link></td>
                      <td>Prof. Amit Sethi </td> 
                    </tr>
                    <tr>
                      <td>CS 663</td>
                      <td><Link onClick={(e) => handleShow(e, "CS663")}>Digital Image Processing </Link></td>
                      <td>Prof. Ajit Rajwade </td> 
                    </tr>
                    <tr>
                      <td>Si 515 </td>
                      <td>Multivariate Analysis</td>
                      <td>Prof. Siuli Mukhopadhyay  </td> 
                    </tr>
                    <tr>
                      <td>BB 525</td>
                      <td>Biological Thermodynamics and Kinetic</td>
                      <td>Prof. Ranjith Padinhateeri</td> 
                    </tr> 
                  </tbody>
                </table>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
 

      {/* Breadcrumb */}
      <Breadcrumb className="breadcrumb-panel mt-auto">
        <Breadcrumb.Item href={globalVariables.variables.strDomainUrl}>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Students' Corner</Breadcrumb.Item>
        <Breadcrumb.Item active>Ph.D. Program</Breadcrumb.Item>
      </Breadcrumb>


      {/* TEAM DETAILS */}
      <Modal show={show} size="xl" centered onHide={handleClose} dialogClassName="p-2 popup-students">
        {
          Course.map((val) => {
            return (
              <div>
                {
                  val.course_id == getcourseid ?
                    <>
                      <div key={val.course_id}>
                        <Modal.Header closeButton className="border-0 align-items-start">
                          <h3 className="bg-primary text-white py-2 px-md-4 px-2 m-0 w-100">{val.course_title}</h3>
                        </Modal.Header>
                        <Modal.Body className="py-0" style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
                          <span dangerouslySetInnerHTML={{ __html: val.course_info }}></span>
                        </Modal.Body>
                      </div>
                    </> : null
                }
              </div>
            )
          })

        }
      </Modal >
      {/* TEAM DETAILS */}
    </>
  );
}