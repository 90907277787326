import React, { useState, useEffect } from "react";
import global from "../../global-variables";
import Header from "../layout/header";
import { Link , useNavigate } from "react-router-dom";
import $ from "jquery";
import WOW from "wowjs";
import { GetCareerList } from "../../api/career-functions";
import { Container, Row, Col } from "reactstrap";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import axios from "axios";
import globalVariables from "../../global-variables";
import Metatag from "../../hooks/Metatag";
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { HiOutlineMenuAlt2 } from "react-icons/hi";
export default function Careers() {


  const navigate = useNavigate();
  const [careerList, setCareerList] = useState();

  const [categoryTypeList, setCategoryTypeList] = useState([]);
  
  useEffect(() => {
    // add class for home header and remove inner class
    $(".fixed-top").addClass("bg-header");

    // initiate the wow effect
    new WOW.WOW({
      live: false,
    }).init();


  }, [careerList]);

  // document.title = "Careers"
  // success function when the list is retrived
  const OnGetCareerSuccess = (p_response) => {
    // set the list for looping
    setCareerList(p_response);
  }

  // things to be done when the page is loaded
  useEffect(() => {
    //make the object for getting the active career list
    let _objCareerList = new Object();
    _objCareerList.career_status = 1;
    // call the function to get the career
    GetCareerList(_objCareerList, OnGetCareerSuccess,navigate);

  }, []);

  useEffect(() => {

    let _obj = new Object()
    _obj.ctype_status = 1
    axios.post(globalVariables.variables.strApiUrl + "/careerApi/getcareerTypeList", _obj)
      .then((response_ctype) => {
        if(response_ctype.data === 'error')
      {
        navigate('/error-page')
      }
      else
      {
        setCategoryTypeList(response_ctype.data[0])
      }
      })
  }, [])


  return (
    <>
      <Header conversion_code='1' main_className="bg-header"></Header>
      <Metatag title='Careers at KCDH'
              description='Careers and Jobs at Koita Centre for Digital Health'
              keywords='Careers at KCDH, Careers at Koita Centre for Digital Health, Faculty Positions at KCDH, Faculty Positions Koita Centre for Digital, Recruitment for Institute Post-Doctoral Fellow position at KCDH, Recruitment for Institute Post-Doctoral Fellow position at Koita Centre for Digital Health'
              />
      {/* career */}
      <Container fluid className="bg-visual-inside p-0" >
        <div className="visual-inside-big">
          <Row className="g-md-0">
            <Col md={6} className="order-2 order-md-1">
              <Container className="d-flex align-items-end h-100">
                <div className="d-inline-block m-0 align-self-end col-lg-6 col-xl-6 py-xl-3 p-2 text-white">
                  <h1 className="fw-light text-white m-0 align-self-center wow fadeInUp">
                    We are Hiring!
                  </h1>
                  <p className="mb-lg-0">KCDH (IITB) invites applications for Faculty Positions and Post Doc
                    Positions. Applications for adjunct faculty / short-term visits are also welcome.
                  </p>
                </div>
              </Container>
            </Col>
            <div className="col-md-6 order-1 order-md-2">
              <img src={global.variables.strDomainUrl + "images/visual-careers.jpg"} className="img-fluid" alt="" title="" />
            </div>
          </Row>
        </div>
      </Container>

      {/* careers Container */}
      <Container className="my-5 careers">
        <Row>
          {
            careerList != null ?
              careerList.length > 0 ?
                <>
                  <Col md={4} lg={3} className="mb-3">
                    <Navbar collapseonselect expand="md" className='d-block p-0'>
                      <Navbar.Toggle className="offcanvas-toggle shadow-none w-100 animated fadeInUp" aria-controls={'offcanvas-menu'}>
                        <Link  className="btn btn-outline-secondary rounded-pill px-4 py-2 w-100 text-start d-flex justify-content-between align-items-center d-md-none">Why KCDH, IIT-B?<HiOutlineMenuAlt2 /></Link>
                      </Navbar.Toggle>
                      <Navbar.Offcanvas id={'offcanvasTop'} aria-labelledby={'offcanvasTop'} placement="start" responsive="md" className="p-md-0 p-2 d-block">
                        <Offcanvas.Header closeButton>
                          <Offcanvas.Title>
                          </Offcanvas.Title>
                        </Offcanvas.Header>
                        <ul className='left-navbar bg-white py-md-2 py-3 px-2 w-100 mb-3'>
                          <div className="nav flex-column me-3 mb-5 pb-5" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            <Link to={globalVariables.variables.strDomainUrl + 'careers'} className="nav-link active">Why KCDH, IIT-B?</Link>
                            {
                              categoryTypeList.map((val_ctype) => {
                                return (
                                  <>
                                    {
                                      //career_count is grater than 0 than bind career_type title
                                      val_ctype.career_count > 0  ? 
                                      <>
                                        <button className="nav-link" data-bs-toggle="" data-bs-target="" type="button" role="tab" aria-controls="" aria-selected="false" disabled> {val_ctype.ctype_name}</button>
                                        {
                                          careerList.map((val_career) => {

                                            return (
                                              <>
                                                {

                                                  //check ctype id and career ctype id
                                                  val_ctype.ctype_id === val_career.career_ctype_id ?
                                                    <Link to={globalVariables.variables.strDomainUrl + "careers/" + val_career.career_pagename} className="nav-link nav-sublink Faicon14" > {val_career.career_name}</Link>
                                                    : null
                                                }
                                              </>

                                            )

                                          })
                                        }
                                      </>
                                      : 
                                      null
                                    }


                                  </>
                                )
                              })
                            }

                          </div>

                          <Link to="https://docs.google.com/forms/d/e/1FAIpQLScxzg5kSooRj7alAuWutXhoDvWY5yM_sVxRfNQl5n0Gr4HuOQ/viewform" target="_blank">
                            <div className="bg-light p-4 rounded-3 mt-5">
                              <div className="icon-circle-110 bg-primary d-flex align-items-center justify-content-center m-auto mb-3 icon-mt-n80 border border-white border-5 wow fadeInUp">
                                <img src={global.variables.strDomainUrl + "images/icon-interest.png"} alt="" title="" className="img-fluid px-3 wow fadeInUp" />
                              </div>
                              <h5 className="text-dark text-center">
                                Submit Interest
                              </h5>
                            </div>
                          </Link>
                        </ul>
                      </Navbar.Offcanvas>
                    </Navbar>
                  </Col>

                  {/* call component here */}
                  <Col md={8} lg={9} className="ps-md-4">
                    <div className="tab-content border rounded-3 p-4" id="v-pills-tabContent">

                      <div className="tab-pane fade show active" id="Why-KCDH-IIT-B-tab" role="tabpanel" aria-labelledby="Why-KCDH-IIT-B" tabindex="0">
                        {/* <h2 className=" d-lg-none">
                        <button className="" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                          Why KCDH, IIT-B?
                        </button>
                      </h2> */}
                        <div id="collapseOne" className=" show active collapse d-lg-block" data-bs-parent="#v-pills-tabContent">
                          <div className="">
                            <p>Koita Centre for Digital Health (KCDH) at IIT Bombay is the first of its kind in India, focused on driving academic programs, research, and industry collaborations in Digital Health.</p>
                            <p>IIT Bombay is the no. 1 Institute in India for innovation and is known as the premier institute for engineering education, research and entrepreneurship. IIT Bombay has a campus of 530 acres, located at the shores of Powai lake in Mumbai, the financial capital of India. Mumbai is a vibrant global metropolis which is well-connected to the world and provides access to the best healthcare, educational, culinary, cultural and entertainment resources. </p>
                            <p>KCDH has deep technical and research cross-functional expertise in the areas such as computer science, bioinformatics, statistics, AI/ machine learning, and medical science which is needed to build excellence in Digital Health and Health Informatics. The centre has developed a strong link with leading hospitals, research organizations, and academic organizations which have strong capabilities in different branches of medicine and healthcare. The centre is creating opportunities for engineering, clinical professionals, and entrepreneurs in Digital health and creating research and employment opportunities in this domain in India.</p>
                            <p>KCDH provides seed grants, access to multidisciplinary research peer teams, research infrastructure, corporate and clinical partnerships, a global advisory board, and the brightest talent pool. Good facilities also exist for computing. Well equipped labs related to digital health i.e. Medical Imaging lab, Medical Devices lab, Consumer Health lab etc. will be made available. The Institute has a well-stocked library with close to 300,000 books and electronic subscriptions to all leading journals. The faculty members and postdoctoral fellows associated with us will have access to all the research facilities available at IIT Bombay.</p>
                            <p>A technology business incubator hosted by the Institute, called the Society for Innovation and Entrepreneurship (SINE), (<Link to="https://www.sineiitb.org" target="_blank"><u>www.sineiitb.org</u></Link>) serves to promote technology-based entrepreneurship by faculty. A research park also helps in translational research opportunities.</p>
                            <p>We work hard to recruit and retain the best people by providing a nurturing environment to help them succeed. We offer competitive salaries, comprehensive benefits, a rewarding work and life environment.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </>
                :
                <>
                  <Col lg={3} className="d-none d-lg-block">
                    <div className="nav flex-column me-3 mb-5 pb-5" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                      <Link to={globalVariables.variables.strDomainUrl + 'careers'} className="nav-link active">Why KCDH, IIT-B?</Link>
                    </div>

                    <Link to="https://docs.google.com/forms/d/e/1FAIpQLScxzg5kSooRj7alAuWutXhoDvWY5yM_sVxRfNQl5n0Gr4HuOQ/viewform" target="_blank">
                      <div className="bg-light p-4 rounded-3 mt-5">
                        <div className="icon-circle-110 bg-primary d-flex align-items-center justify-content-center m-auto mb-3 icon-mt-n80 border border-white border-5 wow fadeInUp">
                          <img src={global.variables.strDomainUrl + "images/icon-interest.png"} alt="" title="" className="img-fluid px-3 wow fadeInUp" />
                        </div>
                        <h5 className="text-dark text-center">
                          Submit Interest
                        </h5>
                      </div>
                    </Link>
                  </Col>

                  <Col lg={9}>
                    <div className="tab-content " id="v-pills-tabContent">
                      <div className="tab-pane fade show active" id="Why-KCDH-IIT-B-tab" role="tabpanel" aria-labelledby="Why-KCDH-IIT-B" tabindex="0">
                        <div id="collapseOne" className=" show active collapse d-lg-block" data-bs-parent="#v-pills-tabContent">
                          <div className="">
                            <p>Koita Centre for Digital Health (KCDH) at IIT Bombay is the first of its kind in India, focused on driving academic programs, research, and industry collaborations in Digital Health.</p>
                            <p>IIT Bombay is the no. 1 Institute in India for innovation and is known as the premier institute for engineering education, research and entrepreneurship. IIT Bombay has a campus of 530 acres, located at the shores of Powai lake in Mumbai, the financial capital of India. Mumbai is a vibrant global metropolis which is well-connected to the world and provides access to the best healthcare, educational, culinary, cultural and entertainment resources. </p>
                            <p>KCDH has deep technical and research cross-functional expertise in the areas such as computer science, bioinformatics, statistics, AI/ machine learning, and medical science which is needed to build excellence in Digital Health and Health Informatics. The centre has developed a strong link with leading hospitals, research organizations, and academic organizations which have strong capabilities in different branches of medicine and healthcare. The centre is creating opportunities for engineering, clinical professionals, and entrepreneurs in Digital health and creating research and employment opportunities in this domain in India.</p>
                            <p>KCDH provides seed grants, access to multidisciplinary research peer teams, research infrastructure, corporate and clinical partnerships, a global advisory board, and the brightest talent pool. Good facilities also exist for computing. Well equipped labs related to digital health i.e. Medical Imaging lab, Medical Devices lab, Consumer Health lab etc. will be made available. The Institute has a well-stocked library with close to 300,000 books and electronic subscriptions to all leading journals. The faculty members and postdoctoral fellows associated with us will have access to all the research facilities available at IIT Bombay.</p>
                            <p>A technology business incubator hosted by the Institute, called the Society for Innovation and Entrepreneurship (SINE), (<Link to="https://www.sineiitb.org" target="_blank"><u>www.sineiitb.org</u></Link>) serves to promote technology-based entrepreneurship by faculty. A research park also helps in translational research opportunities.</p>
                            <p>We work hard to recruit and retain the best people by providing a nurturing environment to help them succeed. We offer competitive salaries, comprehensive benefits, a rewarding work and life environment.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </>
              :
              <div className="container-fluid px-0 my-md-5 my-4">
                <Container>
                  <Row>
                    <span className="text-center">Loading...</span>
                  </Row>
                </Container>
              </div>
          }
        </Row>
      </Container>

      {/* Breadcrumb */}
      <Breadcrumb className="breadcrumb-panel mt-auto">
        <Breadcrumb.Item href={globalVariables.variables.strDomainUrl}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Careers</Breadcrumb.Item>
      </Breadcrumb>
    </>
  );
}