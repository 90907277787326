import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import global from "../../global-variables";
import { Link ,useNavigate} from "react-router-dom";
import ScrollToTop from "react-scroll-to-top"
import axios from "axios";
import { FaFacebookF, FaTwitter, FaYoutube, FaLinkedinIn, FaInstagram, FaEnvelope, FaPhone, FaMap, FaMobile, FaMobileAlt, FaChevronUp, FaPinterest, FaWhatsapp, FaBlogger } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import $ from 'jquery'
import WOW from 'wowjs';
import { scrollToTop } from './header';

export default function Footer() {

    const navigate = useNavigate;
    const [socialMediaList, setSocialMediaList] = useState([])


    const [setting, setSettingList] = useState([]);


    useEffect(() => {
        let _obj = new Object();
        _obj.setting_id = global.variables.strSetting_id;
        axios.post(global.variables.strApiUrl + "/settingApi/getCompanySettingList", _obj)
            .then((response_list) => {
                if(response_list === 'error')
                {
                    navigate('/error-page')
                }
                else{
                    // pass the result in the success function of the respective page
                    setSettingList(response_list.data[0]);
                }
            });
    }, [])

    useEffect(() => {
        let _obj = new Object();
        _obj.social_status = 1;
        setTimeout(() => {

            axios.post(global.variables.strApiUrl + "/socialApi/getSocialList", _obj)
                .then((response_list) => {
                    // pass the result in the success function of the respective page
                    setSocialMediaList(response_list.data[0]);
                });
        }, 300);

    }, [])

    useEffect(() => {

        // initiate the wow effect
        new WOW.WOW({
            live: false
        }).init();

        
    }, []);
    
    useEffect(()=>{

        window.initializeFancybox();
        
    },[socialMediaList,setting])

    const [peopleCategory, setPeopleCategoryList] = useState([]);

    useEffect(() => {
        let _obj = new Object();
        _obj.pcategory_status = 1;
        // setTimeout(() => {

            axios.post(global.variables.strApiUrl + "/peoplecategoryApi/getcategoryHavingPeopleList", _obj)
                .then((response_list) => {
                    // pass the result in the success function of the respective page
                    setPeopleCategoryList(response_list.data[0]);
                });
        // }, 300);

    }, [])

    return (
        <footer className="bg-light mt-auto" id='contact'>
            <Container className="py-md-5 py-4">
                <Row>
                    <Col xl={5} lg={6} id="address" className="text-lg-start text-center">
                        {
                            setting?.map((val) => {
                                return (<>
                                    <h6 className="m-0">{val.setting_company}</h6>
                                    <p className="wow">{val.setting_address + ","}<br />
                                        {val.setting_city} {val.setting_pin}
                                        {val.setting_state != null && val.setting_state != "" ? <>{", " + val.setting_state}</> : null}{
                                            val.setting_country != null && val.setting_country != "" ? <>{", " + val.setting_country}</> : null}.</p>

                                    <ul className="list-inline">
                                        {
                                            val.setting_phone != null && val.setting_phone != "" ?
                                                <li className="d-lg-inline-block px-0 py-0 mb-1 bg-transparent border-0 me-lg-4">
                                                    <FaPhone className="icon-16 me-2" />{val.setting_phone}
                                                </li>
                                                :
                                                null
                                        }
                                        {
                                            val.setting_mobile != null && val.setting_mobile != "" ?
                                                <li className="d-lg-inline-block px-0 py-0 mb-1 bg-transparent border-0 me-lg-4">
                                                    <FaMobileAlt className="icon-16 me-2" />{val.setting_mobile}
                                                </li>
                                                :
                                                null
                                        }
                                        <li className="d-lg-inline-block px-0 py-0 mb-1 bg-transparent border-0">
                                            <FaEnvelope className="icon-16 me-2" /><img src={global.variables.strDomainUrl + "images/email.png"} className="img-fluid" width="267" height="16" alt="Email Id" />
                                        </li>
                                        <li><FaMap className="icon-16 me-2" /><Link to={"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15077.93863059011!2d72.915878!3d19.130251!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c7cdd0997009%3A0x62c5c13f16fcda81!2sKoita%20Centre%20for%20Digital%20Health!5e0!3m2!1sen!2sus!4v1689079303434!5m2!1sen!2sus"} className="map-frame">View Location on Map</Link></li>
                                    </ul>
                                </>)
                            })
                        }
                    </Col>
                    <Col xl={7} lg={6} className="pe-xl-2 px-lg-0 d-lg-inline-block text-lg-end">
                        <div className="footer-links">
                            <ul className="list-inline text-center text-lg-end m-0">
                                <li className="d-xl-inline-block d-none">
                                    <Link to="/news-events" onClick={scrollToTop}>News &amp; Events</Link>
                                </li>
                                <li className="d-xl-inline-block d-none">
                                    <Link to="/careers" onClick={scrollToTop}>Careers</Link>
                                </li>
                                <li className="d-inline-block foot-home">
                                    <Link to="/" onClick={scrollToTop}>Home</Link>
                                </li>
                                <li className="d-xl-none d-inline-block">
                                    <Link to="">Sitemap</Link>
                                </li>
                            </ul>
                            <ul className="list-inline text-center text-lg-end mt-lg-0 mt-2 d-xl-inline-block d-none">
                                <li className="d-md-inline-block">
                                    <Link to="/aboutus" onClick={scrollToTop}>About Us</Link>
                                </li>
                                <li className="d-md-inline-block">
                                    <Link to={`/people/${peopleCategory[0]?.pcategory_pagename}`} onClick={scrollToTop}>People</Link>
                                </li>
                                <li className="d-md-inline-block">
                                    <Link to="/academics" onClick={scrollToTop}>Academics</Link>
                                </li>
                                <li className="d-md-inline-block">
                                    <Link to="/research" onClick={scrollToTop}>Research</Link>
                                </li>
                                <li className="d-md-inline-block">
                                    <Link to="/partnerships" onClick={scrollToTop}>Partnerships</Link>
                                </li>
                                <li className="d-md-inline-block">
                                    <Link to="/ph-d-program" onClick={scrollToTop}>
                                        Students' Corner
                                    </Link>
                                </li>
                                <li className="d-inline-block">
                                    <Link to="/sitemap" onClick={scrollToTop}>Site Map</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="text-lg-end text-center">
                            © {new Date().getFullYear()}. Koita Centre for Digital Health.<br className="d-md-none d-block" /> All rights reserved.
                            Site Created by <Link to="https://www.designscape.co.in/" rel="noreferrer noopener" target="_blank">Designscape</Link>
                        </div>

                        <div className="text-lg-end text-center mt-4 m-0">
                            <ul className="social_media" >
                                {
                                    socialMediaList.length > 0 ?

                                        socialMediaList?.map((val) => {
                                            return (
                                                <li className={val.social_name.toLowerCase()} key={val.social_id}>
                                                    {
                                                        val.social_img_name === 'FaLinkedinIn' ?
                                                            <Link to={val.social_url} target="_blank" rel="noreferrer noopener" >
                                                                <FaLinkedinIn className="icon-20" />
                                                            </Link>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        val.social_img_name === 'FaInstagram' ?
                                                            <Link to={val.social_url} target="_blank" rel="noreferrer noopener" >
                                                                <FaInstagram className="icon-20" />
                                                            </Link>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        val.social_img_name === 'FaFacebookF' ?
                                                            <Link to={val.social_url} target="_blank" rel="noreferrer noopener" >
                                                                <FaFacebookF className="icon-20" />
                                                            </Link>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        val.social_img_name === 'FaYoutube' ?
                                                            <Link to={val.social_url} target="_blank" rel="noreferrer noopener" >
                                                                <FaYoutube className="icon-20" />
                                                            </Link>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        val.social_img_name === 'FaXTwitter' ?
                                                            <Link to={val.social_url} target="_blank" rel="noreferrer noopener" >
                                                                <FaXTwitter className="icon-20" />
                                                            </Link>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        val.social_img_name === 'FaWhatsapp' ?
                                                            <Link to={val.social_url} target="_blank" rel="noreferrer noopener" >
                                                                <FaWhatsapp className="icon-20" />
                                                            </Link>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        val.social_img_name === 'FaPinterest' ?
                                                            <Link to={val.social_url} target="_blank" rel="noreferrer noopener" >
                                                                <FaPinterest className="icon-20" />
                                                            </Link>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        val.social_img_name === 'FaBlogger' ?
                                                            <Link to={val.social_url} target="_blank" rel="noreferrer noopener" >
                                                                <FaBlogger className="icon-20" />
                                                            </Link>
                                                            :
                                                            null
                                                    }
                                                </li>
                                            )
                                        })
                                        :
                                        null
                                }
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
            <ScrollToTop smooth className='animated fadeInRight'
                viewBox="0 0 24 24"
                svgPath="m12 6.879-7.061 7.06 2.122 2.122L12 11.121l4.939 4.94 2.122-2.122z"
            />
        </footer>
    )
} 