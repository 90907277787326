import React, { useState, useEffect, useMedia } from "react";
import global from "../../global-variables";
import Header from "../layout/header";
import { Link, useLocation } from "react-router-dom";
import $ from "jquery";
import WOW from "wowjs";
import { HashLink } from "react-router-hash-link";
import Metatag from '../../hooks/Metatag'
import { Container, Row, Col } from "reactstrap";
import Breadcrumb from 'react-bootstrap/Breadcrumb';


export default function Academics() {
  const [show, setShow] = useState(false);
  const [index, setIndex] = useState(0);
  const pathname = useLocation();

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -134;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  }

  useEffect(() => {
    // Go to Top OR Scroll to top
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation (comment behavior if u want to animate)
    });
  }, [pathname]);

  useEffect(() => {
    // add class for home header and remove inner class
    $(".fixed-top").addClass("bg-header");

    // initiate the wow effect
    new WOW.WOW({
      live: false,
    }).init();


  }, []);


  return (
    <>
      <Header main_className="bg-header"></Header>
      <Metatag title='Master’s Degree in Healthcare Informatics | Courses on Healthcare Informatics | M. Tech in Healthcare informatics'
        description='KCDH at IIT Bombay offers Academic Programs in Digital Health, Dual Degree Courses in Healthcare Informatics, M. Tech in Healthcare Informatics and PhD in Healthcare Informatics '
        keywords='Academic Programs in Healthcare Informatics, Digital Health Academic Programs, Digital Health Academic Programs at IIT Bombay, Dual Degree in Healthcare Informatics, Minor Program in Healthcare Informatics, M. Tech Program in Healthcare Informatics, PhD Program in Healthcare Informatics, Courses on Healthcare informatics'
      />
      {/* Visual Panel */}
      <Container fluid className="bg-visual-inside p-0" >
        <div className="visual-inside-big">
          <Row className="g-md-0">
            <Col md={6} className="order-2 order-md-1">
              <Container className="d-flex align-items-end h-100">
                <div className="d-inline-block m-0 align-self-end col-lg-6 col-xl-6 py-xl-3 p-2 text-white">
                  <h1 className="fw-light text-white m-0 align-self-center wow fadeInUp">
                    Academics
                  </h1>
                </div>
              </Container>
            </Col>
            <div className="col-md-6 order-1 order-md-2">
              <img src={global.variables.strDomainUrl + "images/visual-academics.jpg"} className="img-fluid" alt="" title="" />
            </div>
          </Row>
        </div>
      </Container>


      {/* Container */}
      <Container className="my-5">
        <Row className="row-cols-1 row-cols-md-2 row-cols-lg-4 g-3 g-md-4 pb-3 justify-content-center academics">
          <Col>
            <HashLink to="#Ph_D_Program" scroll={el => scrollWithOffset(el)}>
              <div className="bg-light p-3 p-xl-4 rounded-3 h-100 position-relative text-center overflow-hidden">
                <img src={global.variables.strDomainUrl + "images/pic-academics4.jpg"} alt="" title="" className="img-fluid rounded-3 wow fadeIn" />
                <h6 className="px-xl-3 pt-3 m-0 text-center text-dark wow fadeInUp">Ph.D. Program <br />- Digital Health</h6>
              </div>
            </HashLink>
          </Col>
          <Col>
            <HashLink to="#ms_by_research" scroll={el => scrollWithOffset(el)}>
              <div className="bg-light p-3 p-xl-4 rounded-3 h-100 position-relative text-center overflow-hidden">
                <img src={global.variables.strDomainUrl + "images/pic-academics2.jpg"} alt="" title="" className="img-fluid rounded-3 wow fadeIn" />
                <h6 className="px-xl-3 pt-3 m-0 text-center text-dark wow fadeInUp">
                M.S. by Research
                  <br />- Healthcare Informatics
                </h6>
              </div>
            </HashLink>
          </Col>
          <Col>
            <HashLink to="#IDDDP" scroll={el => scrollWithOffset(el)}>
              <div className="bg-light p-3 p-xl-4 rounded-3 h-100 position-relative text-center overflow-hidden">
                <img src={global.variables.strDomainUrl + "images/pic-academics1.jpg"} alt="" title="" className="img-fluid rounded-3 wow fadeIn" />
                <h6 className="px-xl-3 pt-3 m-0 text-center text-dark wow fadeInUp">
                  Interdisciplinary Dual Degree (IDDDP) with Master’s
                  <br />- Healthcare Informatics
                </h6>
              </div>
            </HashLink>
          </Col>
          <Col>
            <HashLink to="#Minor_Program" scroll={el => scrollWithOffset(el)}>
              <div className="bg-light p-3 p-xl-4 rounded-3 h-100 position-relative text-center overflow-hidden">
                <img src={global.variables.strDomainUrl + "images/pic-academics3.jpg"} alt="" title="" className="img-fluid rounded-3 wow fadeIn" />
                <h6 className="px-xl-3 pt-3 m-0 text-center text-dark wow fadeInUp">
                  Minor Program
                  <br />- Healthcare Informatics
                </h6>
              </div>
            </HashLink>
          </Col>
        </Row>


        {/* <HashLink name="Ph.D. Program" id="Ph_D_Program" class="anchor-focus"></HashLink> */}

        <div class="container my-md-5 mb-5" id="Ph_D_Program">
          <div class="row" >
            <div class="col-md-3" >
              <h2 class="text-primary wow fadeInUp">Ph.D. Program</h2>
            </div>
            <div class="col-md-9 ps-md-5">
              <p class="wow fadeInUp">Ph.D. Program in Digital Health is a program offered by IIT Bombay for students interested in pursuing research and a career in Digital Healthcare. This program provides an opportunity for students to fine-tune their informatics and data analytics skills as well as gain a firm grounding in the area of healthcare and its related domains. It gives students an opportunity to learn about biomedical ethics, to apply domain skills such as AI/ML in the hospital system as well as at the community level for public health informatics and in a broader sense for global health. The applied and interdisciplinary nature of this program are its unique features. </p>
              <p class="wow fadeInUp">The Ph.D. program encourages the association of two research supervisors, preferably from two different academic entities (viz. departments or centres within IIT Bombay), to supervise the research of each Ph. D. candidate enrolled in KCDH. Students are guided by faculty members from various departments across the institute - Bioscience and Bioengineering, Computer Science, Mathematics, Electrical Engineering and Humanities. In addition, there is an opportunity to gain exposure to real-life problems by interactions with top clinicians and public health specialists from various hospitals.</p>
              <Link to="/ph-d-program" class="btn btn-warning rounded-pill px-4 text-uppercase fw-bold btn-sm py-2 mt-auto wow fadeInUp">Read More</Link>
            </div>
          </div>
        </div>

        <div class="container my-5">
          <hr class="px-5" />
        </div>
        <div class="container my-md-5 mb-5" id="ms_by_research">
          <div class="row">
            <div class="col-md-3">
              <h2 class="text-primary wow fadeInUp">
              M.S. by Research
              </h2>
            </div>
            <div class="col-md-9 ps-md-5">
              <p class="wow fadeInUp">
                <b>M.S. by Research in Healthcare Informatics:</b> The M.S. by Research program in "Healthcare Informatics" presents a distinctive opportunity for students fueled by a passion for research and development (R&D) within the realm of Informatics and data analytics. It offers a platform to cultivate both technical proficiency and a comprehensive understanding of healthcare and its associated domains.
              </p>
              <p class="list">
                Here’s why KCDH is the right choice for you:
                <ul>
                  <li>Successful track record of running Minors, IDDDP & Ph.D. Courses in Healthcare Informatics and Digital Health from 2021 and 2022 respectively. </li>
                  <li>Over 110 students in a short span of 2 years. </li>
                  <li>More than 54 courses offered to IIT Bombay students. </li>
                  <li>Over 900 students taking Digital Health courses in the Academic year 2023-24. </li>
                  <li>Collaboration with over 65 associated IIT Bombay faculty members. </li>
                  <li>Strong partnerships with over 25 government & private organizations. </li>
                  <li>A growing core KCDH faculty. </li>
                </ul>
              </p>
              <Link to="/ms-by-research-healthcare-informatics" class="btn btn-warning rounded-pill px-4 text-uppercase fw-bold btn-sm py-2 mt-auto wow fadeInUp">Read More</Link>
            </div>
          </div>
        </div>
        <div class="container my-5">
          <hr class="px-5" />
        </div>
        <div class="container my-md-5 mb-5" id="IDDDP">
          <div class="row">
            <div class="col-md-3">
              <h2 class="text-primary wow fadeInUp">
                Interdisciplinary Dual Degree (IDDDP) with Master's
              </h2>
            </div>
            <div class="col-md-9 ps-md-5">
              <p class="wow fadeInUp">
                Interdisciplinary Dual Degree Program(IDDDP) in Healthcare Informatics is a unique year long program offered by IIT Bombay. Through this program undergraduate students pursuing a B.Tech. / B.S. / DD (B.Tech. / B.S. + M.Tech. / M.S.) degree at IIT Bombay
                can pursue PG-level courses and a year-long project in the area of Healthcare Informatics to earn a masters' degree in "Healthcare Informatics" along with their B.Tech. / B.S. degree in a dual degree mode.
              </p>
              <p class="wow fadeInUp">
                The applied and interdisciplinary nature of this program are its unique features. This program provides an opportunity for students to fine tune their informatics and data analytics skills as well as gain a firm grounding in the
                area of healthcare and its related domains. It gives students an opportunity to learn about biomedical ethics, to apply domain skills such as AI/ML in the hospital system as well as at the community level for public health
                informatics and at a broader sense for global health.
              </p>
              <p class="wow fadeInUp">
                Students are taught by faculty members from various departments across the institute – Bioscience and Bio engineering, Computer Science, Mathematics, Electrical Engineering and Humanities. In addition, there is opportunity
                to gain exposure to real life problems by interactions with top clinicians and public health specialists from various hospitals.
              </p>
              <Link to="/interdisciplinary-dual-degree-program" class="btn btn-warning rounded-pill px-4 text-uppercase fw-bold btn-sm py-2 mt-auto wow fadeInUp">Read More</Link>
            </div>
          </div>
        </div>
        <div class="container my-5">
          <hr class="px-5" />
        </div>
        <div class="container my-md-5 mb-5" id="Minor_Program">
          <div class="row">
            <div class="col-md-3">
              <h2 class="text-primary wow fadeInUp">
                Minor Program
              </h2>
            </div>
            <div class="col-md-9 ps-md-5">
              <p class="wow fadeInUp">
                Minor Program in Healthcare Informatics is a program offered by IIT Bombay for under-graduate students from any department or school of the institute in IITB (e.g., Electrical Engineering, School of Management, Industrial Design Centre, etc.). Students
                earn a minor degree in Healthcare informatics from KCDH, by earning 30 credits(equivalent to five full-semester courses, each of 6 credits).
              </p>
              <p class="wow fadeInUp">
                This program provides an opportunity for students to get introduced to specialized courses offered by the faculty members associated with KCDH.
              </p>
              <p class="wow fadeInUp">
                A research project of 6 credits can be taken in lieu of a full semester course of 6 credits towards the completion of 30 credits to earn the MINOR degree.
              </p>
              <Link to="/minor-program-healthcare-informatics" class="btn btn-warning rounded-pill px-4 text-uppercase fw-bold btn-sm py-2 mt-auto wow fadeInUp">Read More</Link>
            </div>
          </div>
        </div>

      </Container>

      {/* Breadcrumb */}
      <Breadcrumb className="breadcrumb-panel mt-auto">
        <Breadcrumb.Item href={global.variables.strDomainUrl}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Academics</Breadcrumb.Item>
      </Breadcrumb>
    </>
  );
}