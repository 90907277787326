import React, { useState, useEffect } from "react";
import global from "../../global-variables";
import Header from "../layout/header";
import { Link,useNavigate } from "react-router-dom";
import $ from "jquery";
import WOW from "wowjs";
import { FaLink, FaImage, FaRegFilePdf, FaVideo, FaEllipsisH } from "react-icons/fa";
import { GetNewsTypeDistinctYearList } from "../../api/news-type-distinct-year-functions";
import { GetNewsGalleryList } from "../../api/news-gallery-functions";
import Metatag from '../../hooks/Metatag'
import { GetNewsList } from "../../api/news-functions";

import { Container, Row, Col } from "reactstrap";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import FsLightbox from "fslightbox-react";
import Form from 'react-bootstrap/Form';
import globalVariables from "../../global-variables";


export default function Home() {
  const [toggler, setToggler] = useState(true);
  const [videos, setvideosToggler] = useState(false);
  const [index, setIndex] = useState(0);
  const navigate = useNavigate();
  const [news, setNewsList] = useState(null);
   
  const [newstypeyear, setNewsTypeYearList] = useState([]);
  const [news_gallery_toggler, setNewsGalleryToggler] = useState(false);
  const [news_gallery, setNewsGallery] = useState([]);
  const [news_gallery_titel, setNewsGalleryTitel] = useState([]);

  const [gallery_key, setGalleryKey] = useState("");
  const [lightbox_video, setLightBoxVideo] = useState([]);

    // success function when the list is retrived
    const OnGetNewsSuccess = (p_response) => 
    {

      // set the list for looping
      setNewsList(p_response);
}

const OnGetNewsTypeYearSuccess = (p_response) => {
  

  // set the list for looping
  setNewsTypeYearList(p_response);

  setTimeout(() => {
      // get the current news dropdown list
      let _ddlYear = document.getElementById("ddlNewsYear");


      // bind the on change event to the dropdown list
      _ddlYear.onchange = (e) => {

          //call the function to get the news
          // make the object for getting the news list
          let _objNews = new Object();
          _objNews._news_type_id = global.variables.strNewsTypeForNews_id;
          _objNews._news_status = 1;
          _objNews._selected_year = e.target.value;

          // call the function to get the news list
          GetNewsList(_objNews, OnGetNewsSuccess,navigate);

      };

      // call the onchange event for loading the news for the first time
      // Create a new 'change' event
      var event = new Event('change');

      // Dispatch it.
      _ddlYear.dispatchEvent(event);
  }, 100);
}

    // success function when news gallery is retrived
    const OnGetNewsGallerySuccess = (p_response) => {

      let _arrGallery = [];

      let _arrGalleryTitel = [];

      // loop and make the array for binding the gallery
      for (let i = 0; i < p_response.length; i++) {
          _arrGallery.push(global.variables.strApiUrl + "/news-gallery-image/" + p_response[i].gallery_pic);
          _arrGalleryTitel.push(p_response[i].gallery_title);
      }
      // set the array in the constant
      setNewsGallery(_arrGallery);

      setGalleryKey(Date.now());

      setNewsGalleryTitel(_arrGalleryTitel);

      // using time out for the value to get saved in state 
      setTimeout(() => {
          // trigger the link click to display the news gallery popup
          document.getElementById("lnk_trigger_news_gallery_popup_home").click();
      }, 200);

  }

      // function to invoke photo gallery
      const InvokePhotoGallery = (e, p_news_id) => {

        e.preventDefault();

        // Make an object for getting news gallery
        let _objNewsGallery = new Object();
        _objNewsGallery.gallery_status = 1;
        _objNewsGallery.gallery_news_id = p_news_id;

        // call the function to get the news gallery
        GetNewsGalleryList(_objNewsGallery, OnGetNewsGallerySuccess,navigate);

    };

    // function to invoke video popup
    const InvokeVideoPopup = (e, p_news_video_url) => {

        e.preventDefault();

        let _arrVideos = [];
        _arrVideos.push(p_news_video_url);

        // set the path to the constant for displaying
        setLightBoxVideo(_arrVideos);

        // trigger the link click to display the popup
        document.getElementById("lnk_trigger_video_popup_home").click();

    };


  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {
    // add class for home header and remove inner class
    $(".fixed-top").addClass("bg-header");

    // initiate the wow effect
    new WOW.WOW({
      live: false,
    }).init();

  }, [news]);



    // things to be done when the page is loaded
    useEffect(() => {

      //make the object for getting the news type year list
      let _objNewsTypeYear = new Object();
      //news type condition come here as general or event
      _objNewsTypeYear._news_type_id = global.variables.strNewsTypeGeneral_id;
      // call the function to get the news type year list
      GetNewsTypeDistinctYearList(_objNewsTypeYear, OnGetNewsTypeYearSuccess,navigate)

  }, []);


  return (
    <>
      <Header main_className="bg-header"></Header>

      <Metatag title='KCDH News and Events'
                description='News and Events at KCDH, IIT Bombay'
                keywords="KCDH News, KCDH Events, News Articles of Digital Health Programs, Digital Health Events, Digital Health Industry News"
                />

      {/* {/ News Visual Panel /} */}
      <Container fluid className="bg-visual-inside p-0" >
        <div className="visual-inside-big">
          <Row className="g-md-0">
            <Col md={6} className="order-2 order-md-1">
              <Container className="d-flex align-items-end h-100">
                <div className="d-inline-block m-0 d-flex align-items-center align-self-end py-lg-3 p-2">
                  <h1 className="fw-light text-white d-inline-block m-0 align-self-center wow fadeInUp">
                    News & Events -
                  </h1>
                  <div className="ms-md-3 ms-2 wow fadeInUp">
                  {
                      newstypeyear.length > 0 ?
                          <Form.Select aria-label="Default select example" className="shadow-none" id="ddlNewsYear">
                              {
                                  newstypeyear.map((val) => {
                                      return (

                                          <option value={val.news_year} key={val.news_year}>{val.news_year}</option>
                                      )
                                  })
                              }
                          </Form.Select>
                          :
                          <Form.Select aria-label="Default select example" className="shadow-none" id="ddlNewsYear">
                              <option value={new Date().getFullYear()}>{new Date().getFullYear()}</option>
                          </Form.Select>
                            }
                  </div>
                          
                </div>
              </Container>
            </Col>
            <div className="col-md-6 order-1 order-md-2">
              <img src={global.variables.strDomainUrl + "images/visual-news-events.jpg"} className="img-fluid" alt="" title="" />
            </div>
          </Row>
        </div>
      </Container>

      {/* {/ News Container /} */}

      {
                news != null ?

                    news.length > 0 ?
                    
                  <Container className="mb-5 news-details ">
                    <Row className="row-cols-1 row-cols-md-1 row-cols-lg-1 row-cols-xl-1 g-4 g-lg-5 g-xl-4 mt-4 grid">
                  
                              {
                                news.map((val,index) => {
                                  return(
                                    <>
                                    {
                                      (++index) % 2 != 0 ?
                                        <Col>
                                          <Container className="border p-md-4 p-2 rounded-3">
                                            <Row className="row">
                                              <Col md={4} lg={3}>
                                                {
                                                  val.news_images != null &&  val.news_images != ""? 
                                                  // <Link to= {global.variables.strDomainUrl + "news-details/" + val.news_pagename} className="nav-link">
                                                    <img src={global.variables.strApiUrl + "/news-main-image" + "/" + val.news_images} alt={val.news_headline} title={val.news_headline} className="img-fluid rounded-3 mb-md-0 mb-3 border wow fadeInUp" />
                                                  // </Link>
                                                  :
                                                  //default image comee here
                                                  null
                                                }
                                                
                                              </Col>
                                              
                                              <Col md={8} lg={9} className="d-flex align-items-start flex-column">
                                                <h4 className="text-primary wow fadeInUp">
                                                  {/* {/ head line /} */}
                                                  {val.news_headline}
                                                </h4>
                                                <p className="wow fadeInUp">
                                                Date:

                                                { 
                                                
                                                  val.news_date != val.news_to_date ? 
                                                  <>

                                                      {
                                                          val.from_mon != val.to_mon ? 
                                                          <>
                                                                  {` ${val.from_day}-${val.from_mon}-${val.from_year} to ${val.to_day}-${val.to_mon}-${val.to_year}`}
                                                                  
                                                          </>
                                                          :
                                                          <>
                                                                {` ${val.from_day}-${val.from_mon}-${val.from_year} to ${val.to_day}-${val.to_mon}-${val.to_year}`}
                                                          </>
                                                          
                                                      }
                                                          
                                                  </>
                                                      
                                                  :
                                                  // when from and to date same 
                                                  <>
                                                      {
                                                            //when from and to date's month is same
                                                          val.from_mon != val.to_mon ? 
                                                            <>
                                                              {` ${val.from_day}-${val.from_mon}-${val.from_year} to ${val.to_day}-${val.to_mon}-${val.to_year}`}
                                                            </>
                                                          :
                                                            <>
                                                              {` ${val.from_day}-${val.from_mon}-${val.from_year}`}
                                                            </>
                                                      }
                                                  </>
                                                  
                                                  
                                              }


                                                    
                                                    {val.news_caption != null && val.news_caption != "" && val.news_caption != "null"?<><br/> Venue: {val.news_caption}</> : null}
                                                    {val.news_sourse != null && val.news_sourse != "" && val.news_sourse != "null"?<><br/> Source: {val.news_sourse}</>  : null} <br/></p>
                                                <p className="wow fadeInUp"> 

                                                  {/* {/ Details /} */}
                                                  {
                                                    val.news_details != null && val.news_details != "null" && val.news_details != "" ?
                                                    <div className="mb-3 wow fadeInUp projects"><span dangerouslySetInnerHTML={{ __html: val.news_details }}></span></div>
                                                    : null
                                                  }
                                                </p>

                              
                                                <div className="d-flex gap-2 mt-2">
                                                  {/* {/ link /} */}
                                                  {
                                                      val.news_link != null && val.news_link != "null" && val.news_link != "" ?
                                                      <Link to={val.news_link} target="_blank" className="btn btn-warning rounded-pill Faicon px-3 py-1 ms-auto mt-auto wow fadeInUp"><FaLink /></Link>
                                                      :
                                                      null
                                                  }

                                                  {/* {/ Photo Gallery /} */}
                                                  {
                                                      parseInt(val.news_gallery_count) > 0 ?
                                                          <a className="btn btn-warning rounded-pill Faicon px-3 py-1 ms-auto mt-auto wow fadeInUp" onClick={event => InvokePhotoGallery(event, val.news_id)}><FaImage /></a>
                                                          :
                                                          null
                                                  }
                                                  
                                                  {/* {/ Document /} */}
                                                  {
                                                      val.news_document != null && val.news_document != "null" && val.news_document != "" ?
                                                          <Link to={global.variables.strApiUrl + "/news-documents/" + val.news_document} target="_blank" className="btn btn-warning rounded-pill Faicon px-3 py-1 ms-auto mt-auto wow fadeInUp"><FaRegFilePdf /></Link>
                                                          :
                                                          null
                                                  }

                                                  {/* {/ Video /} */}
                                                  {
                                                        val.news_video_link != null && val.news_video_link != "null" && val.news_video_link != "" ?
                                                            <a className="btn btn-warning rounded-pill Faicon px-3 py-1 ms-auto mt-auto wow fadeInUp" onClick={event => InvokeVideoPopup(event, val.news_video_link)}>    <FaVideo /></a>
                                                            :
                                                            null
                                                    }
                                                
                                                  {/* <Link to={global.variables.strDomainUrl + "news-details/" + val.news_pagename} className="btn btn-warning rounded-pill Faicon px-3 py-1 ms-auto mt-auto wow fadeInUp">
                                                    <FaEllipsisH />
                                                  </Link> */}
                                                </div>
                              
                                              </Col>
                                            </Row>
                                          </Container>
                                        </Col>
                                      :
                                      <Col>
                                      <Container style={{"background-color": "#ebebeb"}} className="border p-md-4 p-2 rounded-3">
                                        <Row className="row">
                                          <Col md={4} lg={3}>
                                            {
                                              val.news_images != null &&  val.news_images != ""? 
                                              // <Link to= {global.variables.strDomainUrl + "news-details/" + val.news_pagename} className="nav-link">
                                                <img src={global.variables.strApiUrl + "/news-main-image" + "/" + val.news_images} alt={val.news_headline} title={val.news_headline} className="img-fluid rounded-3 mb-md-0 mb-3 border wow fadeInUp" />
                                              // </Link>
                                              :
                                              //default image comee here
                                              null
                                            }
                                            
                                          </Col>
                                          
                                          <Col md={8} lg={9} style={{"color": "#505050"}} className="d-flex align-items-start flex-column">
                                            <h4 className="text-primary wow fadeInUp">
                                              {/* {/ head line /} */}
                                              {val.news_headline}
                                            </h4>
                                            <p className="wow fadeInUp">
                                            Date:

                                            { 
                                            
                                              val.news_date != val.news_to_date ? 
                                              <>

                                                  {
                                                      val.from_mon != val.to_mon ? 
                                                      <>
                                                              {` ${val.from_day}-${val.from_mon}-${val.from_year} to ${val.to_day}-${val.to_mon}-${val.to_year}`}
                                                              
                                                      </>
                                                      :
                                                      <>
                                                            {` ${val.from_day}-${val.from_mon}-${val.from_year} to ${val.to_day}-${val.to_mon}-${val.to_year}`}
                                                      </>
                                                      
                                                  }
                                                      
                                              </>
                                                  
                                              :
                                              // when from and to date same 
                                              <>
                                                  {
                                                        //when from and to date's month is same
                                                      val.from_mon != val.to_mon ? 
                                                        <>
                                                          {` ${val.from_day}-${val.from_mon}-${val.from_year} to ${val.to_day}-${val.to_mon}-${val.to_year}`}
                                                        </>
                                                      :
                                                        <>
                                                          {` ${val.from_day}-${val.from_mon}-${val.from_year}`}
                                                        </>
                                                  }
                                              </>
                                              
                                              
                                          }


                                                
                                                {val.news_caption != null && val.news_caption != "" && val.news_caption != "null"?<><br/> Venue: {val.news_caption}</> : null}
                                                {val.news_sourse != null && val.news_sourse != "" && val.news_sourse != "null"?<><br/> Source: {val.news_sourse}</>  : null} <br/></p>
                                            <p className="wow fadeInUp"> 

                                              {/* {/ Details /} */}
                                              {
                                                val.news_details != null && val.news_details != "null" && val.news_details != "" ?
                                                <div className="mb-3 wow fadeInUp projects"><span dangerouslySetInnerHTML={{ __html: val.news_details }}></span></div>
                                                : null
                                              }
                                            </p>

                          
                                            <div className="d-flex gap-2 mt-2">
                                              {/* {/ link /} */}
                                              {
                                                  val.news_link != null && val.news_link != "null" && val.news_link != "" ?
                                                  <Link to={val.news_link} target="_blank" className="btn btn-warning rounded-pill Faicon px-3 py-1 ms-auto mt-auto wow fadeInUp"><FaLink /></Link>
                                                  :
                                                  null
                                              }

                                              {/* {/ Photo Gallery /} */}
                                              {
                                                  parseInt(val.news_gallery_count) > 0 ?
                                                      <a className="btn btn-warning rounded-pill Faicon px-3 py-1 ms-auto mt-auto wow fadeInUp" onClick={event => InvokePhotoGallery(event, val.news_id)}><FaImage /></a>
                                                      :
                                                      null
                                              }
                                              
                                              {/* {/ Document /} */}
                                              {
                                                  val.news_document != null && val.news_document != "null" && val.news_document != "" ?
                                                      <Link to={global.variables.strApiUrl + "/news-documents/" + val.news_document} target="_blank" className="btn btn-warning rounded-pill Faicon px-3 py-1 ms-auto mt-auto wow fadeInUp"><FaRegFilePdf /></Link>
                                                      :
                                                      null
                                              }

                                              {/* {/ Video /} */}
                                              {
                                                    val.news_video_link != null && val.news_video_link != "null" && val.news_video_link != "" ?
                                                        <a className="btn btn-warning rounded-pill Faicon px-3 py-1 ms-auto mt-auto wow fadeInUp" onClick={event => InvokeVideoPopup(event, val.news_video_link)}>    <FaVideo /></a>
                                                        :
                                                        null
                                                }
                                            
                                              {/* <Link to={global.variables.strDomainUrl + "news-details/" + val.news_pagename} className="btn btn-warning rounded-pill Faicon px-3 py-1 ms-auto mt-auto wow fadeInUp">
                                                <FaEllipsisH />
                                              </Link> */}
                                            </div>
                          
                                          </Col>
                                        </Row>
                                      </Container>
                                      </Col>
                                  }
                                      </>
                                  
                                    )
                                })
                              }

                    </Row>
                  </Container>
                :
                  <Container className="mb-5 news-details">
                      <Row className="row-cols-1 row-cols-md-1 row-cols-lg-1 row-cols-xl-1 g-4 g-lg-5 g-xl-4 mt-4 grid">
                          <span className="text-center">No Records Found</span>
                      </Row>
                  </Container>
          :
              <Container className="mb-5">
                  <Row className="row-cols-1 row-cols-md-1 row-cols-lg-1 row-cols-xl-1 g-4 g-lg-5 g-xl-4 mt-4 grid">
                      <span className="text-center">Loading...</span>
                  </Row>
              </Container>
      }

      {/* {/ Video popup /}
        {/ this link will open the video popup which will be hidden because the button from the grid is causing issue /} */}
        <a onClick={(e) => {
            e.preventDefault();
            setvideosToggler(!videos);
        }}
            className="d-none"
            id="lnk_trigger_video_popup_home"></a>

        <FsLightbox
            toggler={videos}
            sources={lightbox_video}
        />

      {/* {/ News Gallery popup /}
      {/ this link will open the news gallery popup which will be hidden because the button from the grid is causing issue /} */}
      <a onClick={(e) => {
          e.preventDefault();
          setNewsGalleryToggler(!news_gallery_toggler);
      }}
          className="d-none"
          id="lnk_trigger_news_gallery_popup_home"></a>

      <FsLightbox
          toggler={news_gallery_toggler}
          sources={news_gallery}
          key={gallery_key}
      />

      {/* {/ Breadcrumb /} */}
      <Breadcrumb className="breadcrumb-panel mt-auto">
        <Breadcrumb.Item href={globalVariables.variables.strDomainUrl}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active>News & Events</Breadcrumb.Item>
      </Breadcrumb>

    </>
  );
}