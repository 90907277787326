import React, { useState, useEffect } from "react";
import global from "../../global-variables";
import Header from "../layout/header";
import { Link } from "react-router-dom";
import $ from "jquery";
import WOW from "wowjs";

import { Container, Row, Col } from "reactstrap";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import KCDHPartnersNav from "../layout/kcdhpartners-nav";
import Metatag from "../../hooks/Metatag";
import globalVariables from "../../global-variables";


export default function KCDHPartners() {
  const [show, setShow] = useState(false);

  useEffect(() => {
    // add class for home header and remove inner class
    $(".fixed-top").addClass("bg-header");

    // initiate the wow effect
    new WOW.WOW({
      live: false,
    }).init();


  }, []);


  return (
    <>
      <Header main_className="bg-header"></Header>

      <Metatag title='KCDH Partners | KCDH Internship Partners'
               description='KCDH Partners and Internship Partners for Digital Health'
               keywords="KCDH Partners, KCDH Internship Partners, KCDH Digital Health Partners"
               />
      {/* Visual Panel */}
      <Container fluid className="bg-visual-inside p-0" >
        <div className="visual-inside-big">
          <Row className="g-md-0">
            <Col md={6} className="order-2 order-md-1">
              <Container className="d-flex align-items-end h-100">
                <div className="d-inline-block m-0 align-self-end col-lg-6 col-xl-6 py-xl-3 p-2 text-white">
                  <h1 className="fw-light text-white m-0 align-self-center wow fadeInUp">
                    KCDH Partners
                  </h1>
                </div>
              </Container>
            </Col>
            <div className="col-md-6 order-1 order-md-2">
              <img src={global.variables.strDomainUrl + "images/visual-partnership.jpg"} className="img-fluid" alt="" title="" />
            </div>
          </Row>
        </div>
      </Container>


      {/* Container */}
      <Container className="my-5 position-relative">
        <Row>
          <Col md={4} lg={3} className="mb-3">
            <KCDHPartnersNav submenutitle="All KCDH Partners" kcdhpartners_className="active"></KCDHPartnersNav>
          </Col>
          <Col md={8} lg={9} className="ps-md-4">
            <div className="rounded-3 border p-4">
              <h2 className="text-primary">All KCDH Partners</h2>
              <Row className="row-cols-1 g-3">
                {/* 10bedICU  */}
                <div class="col py-3 border-bottom">
                  <div class="row text-start">
                    <div class="col-md-4 col-lg-3">
                      <img src={global.variables.strDomainUrl + "images/logo-10bedicu.png"} alt="10bedICU" title="10bedICU" class="img-fluid border rounded-3 mb-3 wow fadeInUp" />
                    </div>
                    <div class="col-md-8 col-lg-9 p-lg-0 ps-lg-3 ps-xxl-0">
                      <h5 class="text-dark m-0 wow fadeInUp">
                        10bedICU <Link to="https://10bedicu.org/" target="_blank" rel="noreferrer"><small>(www.10bedicu.org)</small></Link>
                      </h5>
                      <p>The 10BedICU project was started in Mar 2021 in the wake of the Covid-19 delta wave spike in India, in order to create critical care ICU infrastructure in rural and smaller government hospitals. Since then they have created over 200 10BedICUs in government hospitals in 9 states of the country. This is a PPP private-public-partnership, where the 10BedICU group finances the capital expenditure of the project by providing the ICU medical equipment, CARE software system, the TeleICU technology infrastructure, clinical and systems training and community participation support. </p>
                      <p><b>Domain:</b> Joint research on re-development of large language models driven clinical assistant </p>
                    </div>
                  </div>
                </div>
                {/* 10bedICU  */}
                {/* Access Health */}
                <div class="col py-3 border-bottom">
                  <div class="row text-start">
                    <div class="col-md-4 col-lg-3">
                      <img src={global.variables.strDomainUrl + "images/logo-accessh.png"} alt="Access Health" title="Access Health" class="img-fluid border rounded-3 mb-3 wow fadeInUp" />
                    </div>
                    <div class="col-md-8 col-lg-9 p-lg-0 ps-lg-3 ps-xxl-0">
                      <h5 class="text-dark m-0 wow fadeInUp">
                        Access Health International <Link to="https://accessh.org/" target="_blank" rel="noreferrer"><small>(www.accessh.org)</small></Link>
                      </h5>
                      <p>ACCESS Health designs and implements health initiatives that tangibly improve health outcomes and the quality of care across the globe. It is a think tank, advisory group, and implementation partner for 20+ International projects, 100+global partners and 125+ publications.</p>
                      <p><b> Domain:</b> Internships in development of AI-based assistant tool to convert doctor speech</p>
                    </div>
                  </div>
                </div>
                {/* Access Health */}
                {/* All India Institute of Medical Sciences */}
                <div class="col py-3 border-bottom">
                  <div class="row text-start">
                    <div class="col-md-4 col-lg-3">
                      <img src={global.variables.strDomainUrl + "images/logo-aiims.png"} alt="All India Institute of Medical Sciences" title="All India Institute of Medical Sciences" class="img-fluid border rounded-3 mb-3 wow fadeInUp" />
                    </div>
                    <div class="col-md-8 col-lg-9 p-lg-0 ps-lg-3 ps-xxl-0">
                      <h5 class="text-dark m-0 wow fadeInUp">
                        All India Institute of Medical Sciences <Link to="https://www.aiims.edu/" target="_blank" rel="noreferrer"><small>(www.aiims.edu)</small></Link>
                      </h5>
                      <p>
                        All-India Institute of Medical Sciences (AIIMS) was established as an institution of national importance for teaching, research and patient-care. In the field of medical research AIIMS is the lead, having  over 600 research publications yearly.
                      </p>
                      <p>
                        <b>Domain:</b> Joint research on Semi automatic segmentation and classification of CT/MR images: through ischemic stroke and sarcopenia as case studies
                      </p>
                    </div>
                  </div>
                </div>
                {/* All India Institute of Medical Sciences */}
                {/* Bajaj Finserv Health */}
                <div class="col py-3 border-bottom">
                  <div class="row text-start">
                    <div class="col-md-4 col-lg-3">
                      <img src={global.variables.strDomainUrl + "images/bajaj-health.png"} alt="Bajaj Finserv Health" title="Bajaj Finserv Health" class="img-fluid border rounded-3 mb-3 wow fadeInUp" />
                    </div>
                    <div class="col-md-8 col-lg-9 p-lg-0 ps-lg-3 ps-xxl-0">
                      <h5 class="text-dark m-0 wow fadeInUp">
                        Bajaj Finserv Health <Link to="https://www.bajajfinservhealth.in/"
                          target="_blank" rel="noreferrer"><small>(www.bajajfinservhealth.in)</small></Link>
                      </h5>
                      <p>Bajaj Finserv Health is a health-tech company from Bajaj Finserv Group, providing access to preventive, personalized & affordable healthcare for all.</p>
                      <p>
                        <b>Domain:</b> Internships Public Health data/ Health Insurance Analytics
                      </p>
                    </div>
                  </div>
                </div>
                {/* Bajaj Finserv Health */}
                {/* Bill and Melinda Gates Foundation */}
                <div class="col py-3 border-bottom">
                  <div class="row text-start">
                    <div class="col-md-4 col-lg-3">
                      <img src={global.variables.strDomainUrl + "images/logo-bmgf.png"} alt="Bill and Melinda Gates Foundation" title="Bill and Melinda Gates Foundation" class="img-fluid border rounded-3 mb-3 wow fadeInUp" />
                    </div>
                    <div class="col-md-8 col-lg-9 p-lg-0 ps-lg-3 ps-xxl-0">
                      <h5 class="text-dark m-0 wow fadeInUp">
                        Bill and Melinda Gates Foundation <Link to="https://www.gatesfoundation.org/"
                          target="_blank" rel="noreferrer"><small>(www.gatesfoundation.org)</small></Link>
                      </h5>
                      <p>Guided by the belief that every life has equal value, the Bill & Melinda Gates Foundation works to help all people lead healthy, productive lives. Based in Seattle, Washington, the foundation is led by CEO Mark Suzman, under the direction of co-chairs Bill Gates and Melinda French Gates and the board of trustees.</p>
                      <p><b>Domain:</b> Established the National Disease Modelling Consortium More details at: <a href="https://www.ndmconsortium.com/" target="_blank" rel="nooperner">www.ndmconsortium.com</a>  </p>
                    </div>
                  </div>
                </div>
                {/* Bill and Melinda Gates Foundation */}
                {/* Bordeaux Neurocampus */}
                <div class="col py-3 border-bottom">
                  <div class="row text-start">
                    <div class="col-md-4 col-lg-3">
                      <img src={global.variables.strDomainUrl + "images/bordeaux-neurocampus.png"} alt="Bordeaux Neurocampus" title="Bordeaux Neurocampus" class="img-fluid border rounded-3 mb-3 wow fadeInUp" />
                    </div>
                    <div class="col-md-8 col-lg-9 p-lg-0 ps-lg-3 ps-xxl-0">
                      <h5 class="text-dark m-0 wow fadeInUp">
                        Bordeaux Neurocampus <Link to="https://www.bordeaux-neurocampus.fr/en/" target="_blank" rel="noreferrer"><small>(www.bordeaux-neurocampus.fr/en)</small></Link>
                      </h5>
                      <p>Bordeaux Neurocampus is a prominent neuroscience research and training center based in Bordeaux, France. It brings together various neuroscience laboratories, research institutes, and educational facilities under a single umbrella to promote collaborative research and advance the understanding of the brain and nervous system. The center focuses on cutting-edge neuroscience research, encompassing diverse areas such as cellular and molecular neuroscience, systems neuroscience, cognitive neuroscience, and computational neuroscience. Bordeaux Neurocampus is a driving force in advancing neuroscience research and contributing to the global scientific community's understanding of the brain.</p>
                      <p>
                        <b>Domain:</b> Joint research on bringing ML solution to preclinical research
                      </p>
                    </div>
                  </div>
                </div>
                {/* Bordeaux Neurocampus */}
                {/* CitiusTech */}
                <div class="col py-3 border-bottom">
                  <div class="row text-start">
                    <div class="col-md-4 col-lg-3">
                      <img src={global.variables.strDomainUrl + "images/logo-citiustech.png"} alt="CitiusTech" title="CitiusTech" class="img-fluid border rounded-3 mb-3 wow fadeInUp" />
                    </div>
                    <div class="col-md-8 col-lg-9 p-lg-0 ps-lg-3 ps-xxl-0">
                      <h5 class="text-dark m-0 wow fadeInUp">
                        CitiusTech <Link to="https://www.citiustech.com/"
                          target="_blank" rel="noreferrer"><small>(www.citiustech.com)</small></Link>
                      </h5>
                      <p>CitiusTech is a global leader in healthcare technology and one of the Unicorns in India. CitiusTech today has over 6,500 healthcare technology professionals and over USD 325 Mn in revenues.</p>
                      <p><b>Domain:</b> Internships in the healthcare domain</p>
                    </div>
                  </div>
                </div>
                {/* CitiusTech */}
                {/* ICICI Lombard */}
                <div class="col py-3 border-bottom">
                  <div class="row text-start">
                    <div class="col-md-4 col-lg-3">
                      <img src={global.variables.strDomainUrl + "images/logo-icici-lombard.png"} alt="ICICI Lombard" title="ICICI Lombard" class="img-fluid border rounded-3 mb-3 wow fadeInUp" />
                    </div>
                    <div class="col-md-8 col-lg-9 p-lg-0 ps-lg-3 ps-xxl-0">
                      <h5 class="text-dark m-0 wow fadeInUp">
                        ICICI Lombard <Link to="https://www.icicilombard.com/"
                          target="_blank" rel="noreferrer"><small>(www.icicilombard.com)</small></Link>
                      </h5>
                      <p> ICICI Lombard GIC Ltd. is one of the leading private sector general insurance companies in India. The company issued over 26.2 million policies and settled over 1.8 million claims as of March 31, 2020. </p>
                      <p> <b>Domain:</b> Internship on predicting Health Insurance cost and predict disease / hospitalization using health data </p>
                    </div>
                  </div>
                </div>
                {/* ICICI Lombard */}
                {/* IBM Research */}
                <div class="col py-3 border-bottom">
                  <div class="row text-start">
                    <div class="col-md-4 col-lg-3">
                      <img src={global.variables.strDomainUrl + "images/logo-ibm.png"} alt="IBM Research" title="IBM Research" class="img-fluid border rounded-3 mb-3 wow fadeInUp" />
                    </div>
                    <div class="col-md-8 col-lg-9 p-lg-0 ps-lg-3 ps-xxl-0">
                      <h5 class="text-dark m-0 wow fadeInUp">
                        IBM Research <Link to="https://research.ibm.com"
                          target="_blank" rel="noreferrer"> <small>(www.research.ibm.com)</small></Link>
                      </h5>
                      <p>IBM Research is a global organization, dedicated to advancing cutting-edge technologies and conducting scientific research. It has been at the forefront of using artificial intelligence (AI) to revolutionize healthcare </p>
                      <p>
                        <b>Domain:</b>  Joint research on medical domain specific large language modeling
                      </p>
                    </div>
                  </div>
                </div>
                {/* IBM Research */}
                {/* IHAT */}
                <div class="col py-3 border-bottom">
                  <div class="row text-start">
                    <div class="col-md-4 col-lg-3">
                      <img src={global.variables.strDomainUrl + "images/logo-ihat.png"} alt="IHAT" title="IHAT" class="img-fluid border rounded-3 mb-3 wow fadeInUp" />
                    </div>
                    <div class="col-md-8 col-lg-9 p-lg-0 ps-lg-3 ps-xxl-0">
                      <h5 class="text-dark m-0 wow fadeInUp">
                        IHAT <Link to="https://www.ihat.in/"
                          target="_blank" rel="noreferrer"> <small>(www.ihat.in)</small></Link>
                      </h5>
                      <p>India Health Action Trust (IHAT) works towards reducing inequities by developing comprehensive and sustainable programs to improve population health. IHAT works closely with the Government to achieve its public health goals in areas of prevention and control of HIV and Tuberculosis, in achieving significant improvements in Reproductive, Maternal, Neonatal and Child Health, improved Nutrition among mothers and children and strengthening health systems.</p>
                      <p><b>Domain:</b> Epidemiology and Digital Health System, Opportunity to work with organizations with large public health systems.</p>
                    </div>
                  </div>
                </div>
                {/* IHAT */}
                {/* Intelehealth */}
                <div class="col py-3 border-bottom">
                  <div class="row text-start">
                    <div class="col-md-4 col-lg-3">
                      <img src={global.variables.strDomainUrl + "images/logo-intelehealth.png"} alt="Intelehealth " title="Intelehealth " class="img-fluid border rounded-3 mb-3 wow fadeInUp" />
                    </div>
                    <div class="col-md-8 col-lg-9 p-lg-0 ps-lg-3 ps-xxl-0">
                      <h5 class="text-dark m-0 wow fadeInUp">
                        Intelehealth  <Link to="https://intelehealth.org/"
                          target="_blank" rel="noreferrer"> <small>(www.intelehealth.org)</small></Link>
                      </h5>
                      <p>Intelehealth is a non-profit delivering high-quality healthcare where there is no doctor via telemedicine. They have developed a open-source technology platform that's driven by an innovative digital health assistant to connect patients and frontline health workers at the last mile with doctors, diagnostics & medications. Intelehealth is on track to provide healthcare for 10 million women over the next three years in partnership with Ministries of Health in India & Kyrgyzstan as well as organizations like UNICEF, Jhpiego, MSF, and many more. Intelehealth has been recognized by the World Economic Forum and Niti Aayog India as an impactful Digital Public Good.</p>
                      <p> <b>Domain:</b> Internships in large language models</p>
                    </div>
                  </div>
                </div>
                {/* Intelehealth */}
                {/* International Innovation Corps */}
                <div class="col py-3 border-bottom">
                  <div class="row text-start">
                    <div class="col-md-4 col-lg-3">
                      <img src={global.variables.strDomainUrl + "images/logo-international-innovation.png"} alt="International Innovation Corps" title="International Innovation Corps" class="img-fluid border rounded-3 mb-3 wow fadeInUp" />
                    </div>
                    <div class="col-md-8 col-lg-9 p-lg-0 ps-lg-3 ps-xxl-0">
                      <h5 class="text-dark m-0 wow fadeInUp">
                        International Innovation Corps
                        <Link to="http://www.iic.uchicago.edu/" target="_blank" rel="noreferrer"> <small>(www.iic.uchicago.edu)</small></Link>
                      </h5>
                      <p>The International Innovation Corps (IIC) is a social impact fellowship program based at the University of Chicago’s Harris School of Public Policy. IIC identifies and mentors zealous young professionals to partner with the public and social sector in solving complex development challenges with a pragmatic and data-driven approach. The IIC is a year-long structured fellowship and currently operates out of India and the United States.</p>
                      <p> <b>Domain:</b> Collaboration on training programmes</p>
                    </div>
                  </div>
                </div>
                {/* International Innovation Corps */}
                {/* Johns Hopkins University */}
                <div class="col py-3 border-bottom">
                  <div class="row text-start">
                    <div class="col-md-4 col-lg-3">
                      <img src={global.variables.strDomainUrl + "images/logo-jhu.png"} alt="Johns Hopkins University" title="Johns Hopkins University" class="img-fluid border rounded-3 mb-3 wow fadeInUp" />
                    </div>
                    <div class="col-md-8 col-lg-9 p-lg-0 ps-lg-3 ps-xxl-0">
                      <h5 class="text-dark m-0 wow fadeInUp">
                        Johns Hopkins University <Link to="https://www.jhu.edu/"
                          target="_blank" rel="noreferrer"><small>(www.jhu.edu)</small></Link>
                      </h5>
                      <p>Johns Hopkins University is a prestigious institution located in Baltimore, Maryland, renowned for its significant contributions to healthcare and medicine. Founded in 1876, the university has consistently been at the forefront of medical research, education, and patient care, earning its reputation as a world leader in the field of healthcare. Johns Hopkins' research efforts encompass a broad range of medical disciplines, from infectious diseases to genetics, from public health to neuroscience. Its legacy of innovation, research excellence, and patient-centric approach has helped shape the landscape of modern healthcare, influencing medical practices, policies, and advancements worldwide.</p>
                      <p>
                        <b>Domain:</b> Joint research in AI/ML and data modeling in the healthcare domain
                      </p>
                    </div>
                  </div>
                </div>
                {/* Johns Hopkins University */}
                {/* KEM Hospital and SGS Medical College */}
                <div class="col py-3 border-bottom">
                  <div class="row text-start">
                    <div class="col-md-4 col-lg-3">
                      <img src={global.variables.strDomainUrl + "images/logo-kem-sgs.png"} alt="KEM Hospital and SGS Medical College" title="KEM Hospital and SGS Medical College" class="img-fluid border rounded-3 mb-3 wow fadeInUp" />
                    </div>
                    <div class="col-md-8 col-lg-9 p-lg-0 ps-lg-3 ps-xxl-0">
                      <h5 class="text-dark m-0 wow fadeInUp">
                        KEM Hospital and SGS Medical College <Link to="https://www.kem.edu/" target="_blank" rel="noreferrer"><small>(www.kem.edu)</small></Link>
                      </h5>
                      <p>Founded in 1926, the Seth Gordhandas Sunderdas Medical College (GSMC) and the King Edward Memorial (KEM) Hospital are amongst the foremost teaching and medical care providing institutions in India, rendering yeomen service (virtually free of cost) mostly to the underprivileged sections of the society. The medical college provides training to about 2000 students in undergraduate, postgraduate, masters and PhD courses in various allied specialities. </p>
                      <p>
                        <b>Domain:</b> Joint research on NMR-AI based hybrid approach for modelling the progression of type-2 diabetes mellitus
                      </p>
                    </div>
                  </div>
                </div>
                {/* KEM Hospital and SGS Medical College */}
                {/* Kokilaben Dhirubhai Ambani hospital and medical research institute */}
                <div class="col py-3 border-bottom">
                  <div class="row text-start">
                    <div class="col-md-4 col-lg-3">
                      <img src={global.variables.strDomainUrl + "images/logo-kokilaben.png"} alt="Kokilaben Dhirubhai Ambani hospital and medical research institute" title="Kokilaben Dhirubhai Ambani hospital and medical research institute" class="img-fluid border rounded-3 mb-3 wow fadeInUp" />
                    </div>
                    <div class="col-md-8 col-lg-9 p-lg-0 ps-lg-3 ps-xxl-0">
                      <h5 class="text-dark m-0 wow fadeInUp">
                        Kokilaben Dhirubhai Ambani Hospital and Medical Research Institute  <Link to="https://www.kokilabenhospital.com/" target="_blank" rel="noreferrer"><small>(www.kokilabenhospital.com)</small></Link>
                      </h5>
                      <p>Kokilaben Dhirubhai Ambani Hospital and medical research institute, is the social initiative of the Reliance Group and the no. 1 hospital in Western India for 5 years in a row. It is designed to raise India's global standing as a healthcare destination, with emphasis on excellence in clinical services, diagnostic facilities and research activities. Its are the only hospital in Mumbai to function with a full-time specialist system, that ensures the availability and access to the best medical talent around the clock. The 750-bed hospital has over 103 full-time doctors, 520 nurses and about 200 paramedics, and growing.</p>
                      <p>
                        <b>Domain:</b> Joint research on Machine Learning-based Models and web-based tools to predict Covid 19  &  Electronic Alert System for acute kidney injury
                      </p>
                    </div>
                  </div>
                </div>
                {/* Kokilaben Dhirubhai Ambani hospital and medical research institute */}
                {/* Maharashtra University of Health Sciences */}
                <div class="col py-3 border-bottom">
                  <div class="row text-start">
                    <div class="col-md-4 col-lg-3">
                      <img src={global.variables.strDomainUrl + "images/logo-muhs.png"} alt="MUHS" title="MUHS" class="img-fluid border rounded-3 mb-3 wow fadeInUp" />
                    </div>
                    <div class="col-md-8 col-lg-9 p-lg-0 ps-lg-3 ps-xxl-0">
                      <h5 class="text-dark m-0 wow fadeInUp">
                        Maharashtra University of Health Sciences (MUHS) <Link to="https://www.muhs.ac.in/" target="_blank" rel="noreferrer"><small>(www.muhs.ac.in)</small></Link>
                      </h5>
                      <p>
                        The university was established on 3 June 1998 by the state Government of Maharashtra. All colleges and institutions imparting education in health science in the state of Maharashtra have been affiliated to this new university under Section 6(3) of the Act. MUHS offers courses and programs in various health sciences fields, including medicine, dentistry, nursing, Ayurveda, homeopathy, physiotherapy, occupational therapy, and more. It  is responsible for affiliating and overseeing numerous medical and healthcare colleges across the state of Maharashtra. It encourages research activities in the health sciences and plays a crucial role in setting curriculum standards and ensuring the quality of medical education in the state.
                      </p>
                      <p>
                        <b>Domain:</b> Mutual  collaboration on  research, publications, workshops & Internship Opportunities
                      </p>
                    </div>
                  </div>
                </div>
                {/* Maharashtra University of Health Sciences */}
                {/* <Max Healthcare */}
                <div class="col py-3 border-bottom">
                  <div class="row text-start">
                    <div class="col-md-4 col-lg-3">
                      <img src={global.variables.strDomainUrl + "images/logo-maxhealthcare.png"} alt="Max Healthcare" title="Max Healthcare" class="img-fluid border rounded-3 mb-3 wow fadeInUp" />
                    </div>
                    <div class="col-md-8 col-lg-9 p-lg-0 ps-lg-3 ps-xxl-0">
                      <h5 class="text-dark m-0 wow fadeInUp">
                        Max Healthcare <Link to="https://www.maxhealthcare.in/"
                          target="_blank" rel="noreferrer"><small>(www.maxhealthcare.in)</small></Link>
                      </h5>
                      <p>
                        Max Healthcare  is a prominent healthcare organization in India, renowned for its exceptional medical services and patient care. The organization is actively involved in clinical and translational research across various medical disciplines.
                      </p>
                      <p>
                        <b>Domain:</b> Joint research on AI/Ml in healthcare
                      </p>
                    </div>
                  </div>
                </div>
                {/* <Max Healthcare */}
                {/* Narayana Health */}
                <div class="col py-3 border-bottom">
                  <div class="row text-start">
                    <div class="col-md-4 col-lg-3">
                      <img src={global.variables.strDomainUrl + "images/logo-narayana-health.png"} alt="Narayana Health" title="Narayana Health" class="img-fluid border rounded-3 mb-3 wow fadeInUp" />
                    </div>
                    <div class="col-md-8 col-lg-9 p-lg-0 ps-lg-3 ps-xxl-0">
                      <h5 class="text-dark m-0 wow fadeInUp">
                        Narayana Health <Link to="https://www.narayanahealth.org/"
                          target="_blank" rel="noreferrer"><small>(www.narayanahealth.org)</small></Link>
                      </h5>
                      <p>
                        Narayana Health Group is India’s leading healthcare provider and one of the largest hospital groups in the country delivering high-quality, affordable healthcare services to the broader population.
                      </p>
                      <p>
                        <b>Domain:</b> Joint research on development of a complete OCR framework for digitization of historical medical records
                      </p>
                    </div>
                  </div>
                </div>
                {/* Narayana Health */}
                {/* NIIT */}
                <div class="col py-3 border-bottom">
                  <div class="row text-start">
                    <div class="col-md-4 col-lg-3">
                      <img src={global.variables.strDomainUrl + "images/logo-niit.png"} alt="NIIT" title="NIIT" class="img-fluid border rounded-3 mb-3 wow fadeInUp" />
                    </div>
                    <div class="col-md-8 col-lg-9 p-lg-0 ps-lg-3 ps-xxl-0">
                      <h5 class="text-dark m-0 wow fadeInUp">
                        NIIT <Link to="https://www.niit.com/india/" target="_blank" rel="noreferrer"><small>(www.niit.com/india)</small></Link>
                      </h5>
                      <p>NIIT is a leading Skills and Talent Development Corporation that is building a manpower pool for global industry requirements. NIIT today ranks among the world's leading training companies owing to its vast and comprehensive array of talent development programs. With a footprint in over 30 countries, NIIT offers training and development solutions to Individuals, Enterprises and Institutions.</p>
                      <p> <b>Domain:</b> Collaboration on training programmes</p>
                    </div>
                  </div>
                </div>
                {/* NIIT */}
                {/* Oraicle Biosciences Limited */}
                <div class="col py-3 border-bottom">
                  <div class="row text-start">
                    <div class="col-md-4 col-lg-3">
                      <img src={global.variables.strDomainUrl + "images/logo-oraiclebio.png"} alt="Oraicle Biosciences Limited" title="Oraicle Biosciences Limited" class="img-fluid border rounded-3 mb-3 wow fadeInUp" />
                    </div>
                    <div class="col-md-8 col-lg-9 p-lg-0 ps-lg-3 ps-xxl-0">
                      <h5 class="text-dark m-0 wow fadeInUp">
                        Oraicle Biosciences Limited <Link to="https://oraiclebio.com/"
                          target="_blank" rel="noreferrer"><small>(www.oraiclebio.com)</small></Link>
                      </h5>
                      <p>Oraicle Biosciences was established with a mission to combat the disease and mortality caused by oral cancers. . Oraicle Biosciences aims to save lives and families by focusing on evidence-based de-addiction methods and innovative diagnostic solutions. They recognize that the global burden of oral cancer is significant  are determined to prioritize oral health and make a positive impact worldwide through a multi-disciplinary approach.</p>
                      <p><b>Domain:</b> Joint research on machine learning model for oral precancerous lesions</p>
                    </div>
                  </div>
                </div>
                {/* Oraicle Biosciences Limited */}
                {/* Osmania Medical College */}
                <div class="col py-3 border-bottom">
                  <div class="row text-start">
                    <div class="col-md-4 col-lg-3">
                      <img src={global.variables.strDomainUrl + "images/logo-osmania.png"} alt="Osmania Medical College" title="Osmania Medical College" class="img-fluid border rounded-3 mb-3 wow fadeInUp" />
                    </div>
                    <div class="col-md-8 col-lg-9 p-lg-0 ps-lg-3 ps-xxl-0">
                      <h5 class="text-dark m-0 wow fadeInUp">
                        Osmania Medical College <Link to="https://osmaniamedicalcollege.org/"
                          target="_blank" rel="noreferrer"><small>(www.osmaniamedicalcollege.org)</small></Link>
                      </h5>
                      <p>Osmania Medical College, formerly known as The Hyderabad Medical School, was founded in 1846 by the 5th Nizam of Hyderabad. The college is affiliated with the Kaloji Narayana Rao University of Health Sciences, and the Osmania General Hospital and focuses on Medical Education, Research and healthcare for the people. </p>
                      <p>
                        <b>Domain:</b> JOint research on Discovery of biomarkers for the prediabetic stage using untargeted metabolomics and AI/ML guided data analysis
                      </p>
                    </div>
                  </div>
                </div>
                {/* Osmania Medical College */}
                {/* Qure*/}
                <div class="col py-3 border-bottom">
                  <div class="row text-start">
                    <div class="col-md-4 col-lg-3">
                      <img src={global.variables.strDomainUrl + "images/logo-qure.png"} alt="Qure" title="Qure" class="img-fluid border rounded-3 mb-3 wow fadeInUp" />
                    </div>
                    <div class="col-md-8 col-lg-9 p-lg-0 ps-lg-3 ps-xxl-0">
                      <h5 class="text-dark m-0 wow fadeInUp">
                        Qure.ai <Link to="https://qure.ai"
                          target="_blank" rel="noreferrer"><small>(www.qure.ai)</small></Link>
                      </h5>
                      <p>Qure.ai is a breakthrough Artificial Intelligence (AI) solution provider that is disrupting the radiology 'status quo' by enhancing imaging accuracy and improving health outcomes with the assistance of machine-supported tools. Qure.ai taps deep learning technology to provide automated interpretation of radiology exams like X-rays, CTs and Ultrasounds scans for time and resource-strapped medical imaging professionals-enabling faster diagnosis and speed to treatment. Overall, Qure.ai is helping to make healthcare more accessible and affordable to patients worldwide. </p>
                      <p><b>Domain:</b> Internships to the students in deep learning Machine language </p>
                    </div>
                  </div>
                </div>
                {/* Qure*/}
                {/* Sankara Nethralaya */}
                <div class="col py-3 border-bottom">
                  <div class="row text-start">
                    <div class="col-md-4 col-lg-3">
                      <img src={global.variables.strDomainUrl + "images/logo-sankara-nethralaya.png"} alt="Sankara Nethralaya" title="Sankara Nethralaya" class="img-fluid border rounded-3 mb-3 wow fadeInUp" />
                    </div>
                    <div class="col-md-8 col-lg-9 p-lg-0 ps-lg-3 ps-xxl-0">
                      <h5 class="text-dark m-0 wow fadeInUp">
                        Sankara Nethralaya <Link to="https://www.sankaranethralaya.org/" target="_blank" rel="noreferrer"><small>(www.sankaranethralaya.org)</small></Link>
                      </h5>
                      <p>Sankara Nethralaya, a not-for-profit charitable eye hospital, was founded in 1978 to provide world-class tertiary eye care in Chennai India. It has grown into a super speciality institution for ophthalmic care and has gained international excellence and is acclaimed for its quality care and compassion. Today, the Sankara Nethralaya family has over 1,600 individuals with the vision to propagate the Nethralaya philosophy; on average, 1200 patients and 200 surgeries are performed every day.</p>
                      <p>
                        <b>Domain:</b> Predictive model for Childhood Congenital Glaucoma
                      </p>
                    </div>
                  </div>
                </div>
                {/* Sankara Nethralaya */}
                {/* Tata Memorial Hospital */}
                <div class="col py-3 border-bottom">
                  <div class="row text-start">
                    <div class="col-md-4 col-lg-3">
                      <img src={global.variables.strDomainUrl + "images/logo-tata-memorial.png"} alt="Tata Memorial Hospital" title="Tata Memorial Hospital" class="img-fluid border rounded-3 mb-3 wow fadeInUp" />
                    </div>
                    <div class="col-md-8 col-lg-9 p-lg-0 ps-lg-3 ps-xxl-0">
                      <h5 class="text-dark m-0 wow fadeInUp">
                        Tata Memorial Hospital <small>
                          <Link to="https://tmc.gov.in/"
                            target="_blank" rel="noreferrer">(www.tmc.gov.in)</Link>
                        </small>
                      </h5>
                      <p>Tata Memorial Hospital located in Mumbai, India is a specialist cancer treatment and research centre. It is closely associated with the Advanced Centre for Treatment, Research and Education in Cancer. Tata Memorial Centre is amongst the oldest and largest cancer centres in the world, with over 75 years of exceptional patient care, high-quality training and innovative cancer research. Over the years, it has grown in size and stature, maintaining its pre-eminent position at the forefront of national and global cancer control efforts.</p>
                      <p>
                        <b>Domain:</b> Joint research on  Data Science Algorithms for Radiation Reduction in Computed Tomography, AI-based prognostic approach for advanced-stage Hodgkin Lymphoma, Machine learning-based approach to identify proteomic networks and biomarkers driving Triple Negative Breast Cancer
                      </p>
                    </div>
                  </div>
                </div>
                {/* Tata Memorial Hospital */}
                {/* The INCLEN Trust International */}
                <div class="col py-3 border-bottom">
                  <div class="row text-start">
                    <div class="col-md-4 col-lg-3">
                      <img src={global.variables.strDomainUrl + "images/logo-inclen.png"} alt="The INCLEN Trust International" title="The INCLEN Trust International" class="img-fluid border rounded-3 mb-3 wow fadeInUp" />
                    </div>
                    <div class="col-md-8 col-lg-9 p-lg-0 ps-lg-3 ps-xxl-0">
                      <h5 class="text-dark m-0 wow fadeInUp">
                        The INCLEN Trust International <small>
                          <Link to="http://inclentrust.org/inclen/"
                            target="_blank" rel="noreferrer">(www.inclentrust.org/inclen/)</Link>
                        </small>
                      </h5>
                      <p><b>The INCLEN Trust International</b> is a not for profit research organization conducting collaborative, multi-disciplinary studies on high priority global health issues. INCLEN have Clinical Epidemiology Units (CEU) and Clinical Epidemiology Research Training Centre (CERTC’s) as core functional units, located in 89 academic institutions in 34 countries. </p>
                      <p>
                        <b>Domain:</b> Joint research on Harmonized One-health Trans-Species Transmission of Antibiotics Resistance and levers for action using System Dynamics Modelling (HOTSTAR-SDM)
                      </p>
                    </div>
                  </div>
                </div>
                {/* The INCLEN Trust International */}
                {/* Thought Works */}
                <div class="col py-3 border-bottom">
                  <div class="row text-start">
                    <div class="col-md-4 col-lg-3">
                      <img src={global.variables.strDomainUrl + "images/thoughtworks.png"} alt="Thought Works" title="Thought Works" class="img-fluid border rounded-3 mb-3 wow fadeInUp" />
                    </div>
                    <div class="col-md-8 col-lg-9 p-lg-0 ps-lg-3 ps-xxl-0">
                      <h5 class="text-dark m-0 wow fadeInUp">
                        Thoughtworks <small>
                          <Link to="https://www.thoughtworks.com/en-in"
                            target="_blank" rel="noreferrer">(www.thoughtworks.com/en-in)</Link>
                        </small>
                      </h5>
                      <p>Thoughtworks is a leading global technology consultancy that integrates strategy, design and software engineering to enable enterprises and technology disruptors across the globe to thrive as modern digital businesses. Thoughtworks operate in 17 countries and has over 10,000+ employees. </p>
                      <p><b>Domain:</b> Internships in AI-ML in Medical Optical Character Recognition</p>
                    </div>
                  </div>
                </div>
                {/* Thought Works */}
                {/* UNICEF */}
                <div class="col py-3 border-bottom">
                  <div class="row text-start">
                    <div class="col-md-4 col-lg-3">
                      <img src={global.variables.strDomainUrl + "images/logo-unicef.png"} alt="UNICEF" title="UNICEF" class="img-fluid border rounded-3 mb-3 wow fadeInUp" />
                    </div>
                    <div class="col-md-8 col-lg-9 p-lg-0 ps-lg-3 ps-xxl-0">
                      <h5 class="text-dark m-0 wow fadeInUp">
                        UNICEF <small>
                          <Link to="https://www.unicef.org/india/"
                            target="_blank" rel="noreferrer">(www.www.unicef.org/india/)</Link>
                        </small>
                      </h5>
                      <p>UNICEF is fully committed to working with the Government of India to ensure that each child born in this vast and complex country gets the best start in life, thrives and develops to his or her full potential. UNICEF has been working in India since 1949 advocating for the rights of children and young people, and currently has a presence in 16 states.</p>
                      <p><b>Domain:</b> HMIS Analyzer: Visualization Dashboard for Health data</p>
                    </div>
                  </div>
                </div>
                {/* UNICEF */}
                {/* Vision Research Foundation */}
                <div class="col py-3 border-bottom">
                  <div class="row text-start">
                    <div class="col-md-4 col-lg-3">
                      <img src={global.variables.strDomainUrl + "images/logo-visionresearchfoundation.png"} alt="Vision Research Foundation" title="Vision Research Foundation" class="img-fluid border rounded-3 mb-3 wow fadeInUp" />
                    </div>
                    <div class="col-md-8 col-lg-9 p-lg-0 ps-lg-3 ps-xxl-0">
                      <h5 class="text-dark m-0 wow fadeInUp">
                        Vision Research Foundation <small>
                          <Link to="https://www.visionresearchfoundation.org/"
                            target="_blank" rel="noreferrer">(www.visionresearchfoundation.org)</Link>
                        </small>
                      </h5>
                      <p> Vision Research Foundation (VRF) is a renowned ophthalmic research center, established to meet the demand for India-centric research in ophthalmology. Its contributions to the field have led to recognition as a Scientific Institution by the Department of Scientific & Industrial Research, Government of India. </p>
                      <p>
                        <b>Domain:</b> Joint research on designing predictive model for childhood congenital glaucoma using clinical and genetic data
                      </p>
                    </div>
                  </div>
                </div>
                {/* Vision Research Foundation */}
                {/* Wadhwani AI */}
                <div class="col py-3 border-bottom">
                  <div class="row text-start">
                    <div class="col-md-4 col-lg-3">
                      <img src={global.variables.strDomainUrl + "images/logo-wadhwani.png"} alt="Wadhwani AI" title="Wadhwani AI" class="img-fluid border rounded-3 mb-3 wow fadeInUp" />
                    </div>
                    <div class="col-md-8 col-lg-9 p-lg-0 ps-lg-3 ps-xxl-0">
                      <h5 class="text-dark m-0 wow fadeInUp">
                        Wadhwani AI <small>
                          <Link to="https://www.wadhwaniai.org"
                            target="_blank" rel="noreferrer">(www.wadhwaniai.org)</Link>
                        </small>
                      </h5>
                      <p>Wadhwani AI, also known as the Wadhwani Institute for Artificial Intelligence, is an Indian organization dedicated to advancing AI research and development.  In healthcare, Wadhwani AI is actively developing AI solutions for medical imaging, disease prediction, preventive healthcare, and healthcare management systems. Wadhwani AI aims to revolutionize healthcare and contribute significantly to the advancement of the healthcare industry.</p>
                      <p><b>Domain:</b> Joint research on development of  gut microbiome informatics Tool for non communicable diseases</p>
                    </div>
                  </div>
                </div>
                {/* Wadhwani AI */}
                <p class="small">
                  * All brand names/logos used are trademarks or registered trademarks of
                  their respective companies and are hereby acknowledged.
                </p>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>


      {/* Breadcrumb */}
      <Breadcrumb className="breadcrumb-panel mt-auto">
        <Breadcrumb.Item href={globalVariables.variables.strDomainUrl}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Partnerships</Breadcrumb.Item>
        <Breadcrumb.Item active>Overview</Breadcrumb.Item>
      </Breadcrumb>
    </>
  );
}