import React, { useState, useEffect } from "react";
import global from "../../global-variables";
import Header from "../layout/header";
import { Link } from "react-router-dom";
import { FaFacebookF, FaTwitter, FaYoutube, FaLinkedinIn, FaInstagram } from "react-icons/fa";
import $ from "jquery";
import WOW from "wowjs";

import { Container, Row, Col } from "reactstrap";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Modal from 'react-bootstrap/Modal';
import Metatag from "../../hooks/Metatag";
import { SpeakersPanelists } from "./speakers-panelists";

export default function KCDHinauguration() {
  const [show, setShow] = useState(false);
  const [getspeakerid, setCourseid] = useState('');
  const [index, setIndex] = useState(0);
  const handleClose = () => setShow(false);
  const handleShow = (e, speaker_id) => {
    e.preventDefault();
    setShow(true);
    //get Course id
    setCourseid(speaker_id);
  }

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {
    // add class for home header and remove inner class
    $(".fixed-top").addClass("bg-header");

    // initiate the wow effect
    new WOW.WOW({
      live: false,
    }).init();


  }, []);


  return (
    <>
      <Header main_className="bg-header"></Header>
      <Metatag title='The Koita Centre for Digital Health – KCDH at IIT Bombay'
        description='Koita Centre for Digital Health - KCDH at IIT Bombay is focused on driving academic programs, research, and industry collaborations in Digital Health and Healthcare Informatics'
        keywords='The Koita Centre for Digital Health, KCDH, Institutes offering courses in Digital Health,  Institutes offering courses in Healthcare Informatics, Healthcare Programs at IIT Bombay'
      />
      {/* Visual Panel */}
      <Container fluid className="bg-visual-inside p-0" >
        <div className="visual-inside-big">
          <Row className="g-md-0">
            <Col md={6} className="order-2 order-md-1">
              <Container className="d-flex align-items-end h-100">
                <div className="d-inline-block m-0 align-self-end col-lg-6 col-xl-6 py-xl-3 p-2 text-white">
                  <h1 className="fw-light text-white m-0 align-self-center wow fadeInUp">
                    KCDH Inauguration
                  </h1>
                </div>
              </Container>
            </Col>
            <div className="col-md-6 order-1 order-md-2">
              <img src={global.variables.strDomainUrl + "images/kcdh-inauguration/kcdh-inauguration.jpg"} className="img-fluid" alt="" title="" />
            </div>
          </Row>
        </div>
      </Container>

      <Container className="mt-2 mb-5">
        <div className="text-end col-12 mb-2">
          <Link className="btn btn-warning rounded-pill px-4 fw-bold btn-sm py-2 mt-auto" to={global.variables.strDomainUrl + "news-events"}>Back</Link>
        </div>
        <div className="border p-md-4 p-2 rounded-3">

          <div>
            <h3 className="text-primary fw-bold wow fadeInUp">
              Catalysing the Digital Health Revolution in India
            </h3>
            <p className="wow fadeInUp"><b>Date:</b> Monday, April 17, 2023 <br /> <b>Venue:</b> Prof.
              B. Nag
              Auditorium, VMCC, IIT Bombay, Powai, Mumbai - 400076, India</p>
            <p className="wow fadeInUp"><b>Register at:</b> <Link to="https://forms.gle/jckE6qB5HHJXkeAFA" target="_blank">https://forms.gle/jckE6qB5HHJXkeAFA</Link>
            </p>
          </div>
          <hr />
          <div>
            <h4 className="text-primary wow fadeInUp">
              Introduction
            </h4>
            <p>Digital Health promises to play a central role in democratization of healthcare
              services in a country like India. It is a constantly evolving field, with new
              challenges emerging constantly. At, KCDH, we strive to play a vital role to converge
              health and technology in anholistic manner, thereby advancing the vision of ‘Health
              for All’. On April 17th, 2023, the KCDH will hold its inaugural event and a
              symposium titled "Catalyzing the Digital Health Revolution in India." We bring
              together healthcare professionals, thought leaders, innovators and entrepreneurs,
              and others who are driving change in the industry from various regions and
              specialties through this symposium.</p>

            <p>We aim to provide opportunities for networking and building collaborations amongst
              different stakeholders in the digital health ecosystem. The event is an excellent
              opportunity to stay current on the latest developments in digital health, network
              with industry leaders, and learn about new opportunities and best practices in the
              field. We cordially invite you to the KCDH Inauguration on April 17th, 2023 at IIT
              Bombay in order to foster a vibrant and supportive community capable of propelling
              India's digital health revolution forward.</p>


            <p>
              <strong>We will distribute a compendium of the digital health organisations. Please
                fill in the details of your organisation at </strong>
              <Link to="https://forms.gle/UnfRLDxsU9DPaZCK7"
                target="_blank">https://forms.gle/UnfRLDxsU9DPaZCK7</Link>
            </p>
          </div>
          <hr />
          <div>
            <h4 className="text-primary wow fadeInUp">
              Key Highlights of Symposium
            </h4>
            <div className="list">
              <ul className="m-0">
                <li>Keynote Speeches</li>
                <li>Panel Discussions</li>
                <li>Technical Colloquia</li>
                <li>Start-up Conclave</li>
                <li>Student Work Showcase</li>
                <li>Release of Compendium of Digital Health Organizations</li>
                <li>Networking Opportunities</li>
              </ul>
            </div>
          </div>
          <hr />
          <div>
            <h4 className="text-primary wow fadeInUp">
              Agenda
            </h4>
            <img src={global.variables.strDomainUrl + "images/kcdh-inauguration/kcdh-Inauguration-agenda-v6-final-1.png"} alt="" title=""
              className="img-fluid mb-md-0 mb-3 wow fadeIn" />
          </div>
          <hr />
          <div>
            <div className="row row-cols-1 row-cols-md-3 row-cols-lg-4 justify-content-center g-3 g-xl-5 speaker">
              {/* Rajesh Gokhale */}
              <div className="col text-center">
                <h4 className="text-primary wow fadeInUp">
                  Chief Guest
                </h4>
                <Link onClick={(e) => handleShow(e, "Rajesh_Gokhale")}>
                  <div className="row align-items-center">
                    <div>
                      <img src={global.variables.strDomainUrl + "images/kcdh-inauguration/rajesh-gokhale.jpg"} alt="Rajesh S. Gokhale"
                        title="Rajesh S. Gokhale" className="img-fluid rounded-3 mb-3 wow fadeInUp" />
                      <div>
                        <h5 className="text-dark m-0 wow fadeInUp">
                          Rajesh S. Gokhale
                        </h5>
                        <p className="wow fadeInUp">
                          <b>Secretary, Department of Biotechnology<br /> Ministry
                            of Science & Technology, GoI </b>
                        </p>
                      </div>
                    </div>

                  </div>
                </Link>
              </div>

              {/* Lt Gen Dr. Madhuri Kanitkar */}
              <div className="col text-center">
                <h4 className="text-primary wow fadeInUp">
                  Distinguished Guest
                </h4>
                <Link onClick={(e) => handleShow(e, "Madhuri_Kanitkar")}>
                  <div className="row align-items-center">
                    <div>
                      <img src={global.variables.strDomainUrl + "images/kcdh-inauguration/madhuri-kanitkar1.jpg"} alt="Lt Gen Dr. Madhuri Kanitkar" title="Lt Gen Dr. Madhuri Kanitkar" className="img-fluid rounded-3 mb-3 wow fadeInUp" />
                      <div>
                        <h5 className="text-dark m-0 wow fadeInUp">
                          Lt Gen Dr. Madhuri Kanitkar
                        </h5>
                        <p className="wow fadeInUp">
                          <b>Vice-Chancellor<br /> Maharashtra University of Health Sciences</b>
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <hr />
          <div className="container speaker">
            <div className="row">
              <div className="row g-2">
                <h4 className="text-primary wow fadeInUp">
                  Speakers & Panelists
                </h4>
                <div className="row row-cols-1 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 g-3 g-xl-4">
                  {/* Aparna Gupta */}
                  <div className="col text-center">
                    <Link onClick={(e) => handleShow(e, "Aparna_Gupta")}>
                      <div className="row align-items-center">
                        <div>
                          <img src={global.variables.strDomainUrl + "images/kcdh-inauguration/aparna-gupta.jpg"} alt="Aparna Gupta"
                            title="Aparna Gupta" className="img-fluid rounded-3 mb-3 wow fadeInUp" />
                          <div>
                            <h5 className="text-dark m-0 wow fadeInUp">
                              Aparna Gupta
                            </h5>
                            <p className="wow fadeInUp">
                              <b>Executive Director<br />
                                Microsoft</b>
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  {/* C S Pramesh  */}
                  <div className="col text-center">
                    <Link onClick={(e) => handleShow(e, "CS_Pramesh")}>
                      <div className="row align-items-center">
                        <div>
                          <img src={global.variables.strDomainUrl + "images/kcdh-inauguration/cs-pramesh.jpg"} alt="C S Pramesh"
                            title="C S Pramesh" className="img-fluid rounded-3 mb-3 wow fadeInUp" />
                          <div>
                            <h5 className="text-dark m-0 wow fadeInUp">
                              Dr. C S Pramesh
                            </h5>
                            <p className="wow fadeInUp">
                              <b>Director<br />Tata Memorial Hospital
                              </b>
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  {/* Dr. Devi Prasad Shetty  */}
                  <div className="col text-center">
                    <Link onClick={(e) => handleShow(e, "Devi_Shetty")}>
                      <div className="row align-items-center">
                        <div>
                          <img src={global.variables.strDomainUrl + "images/kcdh-inauguration/dr-shetty.jpg"} alt="Dr. Devi Prasad Shetty"
                            title="Dr. Devi Prasad Shetty"
                            className="img-fluid rounded-3 mb-3 wow fadeInUp" />
                          <div>
                            <h5 className="text-dark m-0 wow fadeInUp">
                              Dr. Devi Prasad Shetty
                            </h5>
                            <p className="wow fadeInUp">
                              <b>Chairman<br />Narayana Health
                              </b>
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  {/* Dr. Naranamangalam Raghunathan Jagannathan */}
                  <div className="col text-center">
                    <Link onClick={(e) => handleShow(e, "N_Jagannathan")}>
                      <div className="row align-items-center">
                        <div>
                          <img src={global.variables.strDomainUrl + "images/kcdh-inauguration/nr-jagannathan.jpg"} alt="Prof. N R Jagannathan"
                            title="Prof. N R Jagannathan" className="img-fluid rounded-3 mb-3 wow fadeInUp" />
                          <div>
                            <h5 className="text-dark m-0 wow fadeInUp">
                              Prof. N R Jagannathan
                            </h5>
                            <p className="wow fadeInUp">
                              <b>Visiting Professor<br />KCDH IIT
                                Bombay </b>
                            </p>
                          </div>
                        </div>

                      </div>
                    </Link>
                  </div>

                  {/* Dr. Sanjay Arora  */}
                  <div className="col text-center">
                    <Link onClick={(e) => handleShow(e, "Sanjay_Arora")}>
                      <div className="row align-items-center">
                        <div>
                          <img src={global.variables.strDomainUrl + "images/kcdh-inauguration/sanjay-arora.jpg"} alt="Dr. Sanjay Arora"
                            title="Dr. Sanjay Arora" className="img-fluid rounded-3 mb-3 wow fadeInUp" />
                          <div>
                            <h5 className="text-dark m-0 wow fadeInUp">
                              Dr. Sanjay Arora
                            </h5>
                            <p className="wow fadeInUp">
                              <b>Group Medical Director <br /> Dr. Lal PathLabs </b>
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  {/* Dr. Manish Gupta   */}
                  <div className="col text-center">
                    <Link onClick={(e) => handleShow(e, "Manish_Gupta")}>
                      <div className="row align-items-center">
                        <div>
                          <img src={global.variables.strDomainUrl + "images/kcdh-inauguration/manish-gupta.jpg"} alt="Dr. Manish Gupta" title="Dr. Manish Gupta" className="img-fluid rounded-3 mb-3 wow fadeInUp" />
                          <div>
                            <h5 className="text-dark m-0 wow fadeInUp">
                              Manish Gupta
                            </h5>
                            <p className="wow fadeInUp">
                              <b>Director, Google Research<br />India</b>
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  {/* Dr. Marcus Ranney   */}
                  <div className="col text-center">
                    <Link onClick={(e) => handleShow(e, "Marcus_Ranney")}>
                      <div className="row align-items-center">
                        <div>
                          <img src={global.variables.strDomainUrl + "images/kcdh-inauguration/marcus-ranney.jpg"} alt="Dr. Marcus Ranney" title="Dr. Marcus Ranney" className="img-fluid rounded-3 mb-3 wow fadeInUp" />
                          <div>
                            <h5 className="text-dark m-0 wow fadeInUp">
                              Dr. Marcus Ranney
                            </h5>
                            <p className="wow fadeInUp">
                              <b>Founder & CEO<br />Humanedge</b>
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  {/* Narendra Ahuja  */}
                  <div className="col text-center">
                    <Link onClick={(e) => handleShow(e, "Narendra_Ahuja")}>
                      <div className="row align-items-center">
                        <div>
                          <img src={global.variables.strDomainUrl + "images/kcdh-inauguration/narendra-ahuja.jpg"} alt="Narendra Ahuja" title="Narendra Ahuja" className="img-fluid rounded-3 mb-3 wow fadeInUp" />
                          <div>
                            <h5 className="text-dark m-0 wow fadeInUp">
                              Dr. Narendra Ahuja
                            </h5>
                            <p className="wow fadeInUp">
                              <b>Professor, University of Illinois<br /> USA</b>
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  {/* Neeraj Sinha */}
                  <div className="col text-center">
                    <Link onClick={(e) => handleShow(e, "Neeraj_Sinha")}>
                      <div className="row align-items-center">
                        <div>
                          <img src={global.variables.strDomainUrl + "images/kcdh-inauguration/neeraj-sinha.jpg"} alt="Neeraj Sinha" title="Neeraj Sinha" className="img-fluid rounded-3 mb-3 wow fadeInUp" />
                          <div>
                            <h5 className="text-dark m-0 wow fadeInUp">
                              Prof. Neeraj Sinha
                            </h5>
                            <p className="wow fadeInUp">
                              <b>Professor<br />CBMR Lucknow</b>
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  {/* Nim Arinaminpathy */}
                  <div className="col text-center">
                    <Link onClick={(e) => handleShow(e, "Nimalan_Arinaminpathy")}>
                      <div className="row align-items-center">
                        <div>
                          <img src={global.variables.strDomainUrl + "images/kcdh-inauguration/nimalan-arinaminpathy.jpg"} alt="Nim Arinaminpathy" title="Nim Arinaminpathy" className="img-fluid rounded-3 mb-3 wow fadeInUp" />
                          <div>
                            <h5 className="text-dark m-0 wow fadeInUp">
                              Prof. Nimalan Arinaminpathy (Nim)
                            </h5>
                            <p className="wow fadeInUp">
                              <b>Faculty of Medicine, School of Public Health Imperial College London</b>
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  {/* Dr S. Subramanian */}
                  <div className="col text-center">
                    <Link onClick={(e) => handleShow(e, "S_Subramanian")}>
                      <div className="row align-items-center">
                        <div>
                          <img src={global.variables.strDomainUrl + "images/kcdh-inauguration/s-subramanium.jpg"} alt="Dr. S. Subramanian" title="Dr. S. Subramanian" className="img-fluid rounded-3 mb-3 wow fadeInUp" />
                          <div>
                            <h5 className="text-dark m-0 wow fadeInUp">
                              Dr. S. Subramanian
                            </h5>
                            <p className="wow fadeInUp">
                              <b>Sr. Consultant<br /> ICMR-Vector Control  Research Centre</b>
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  {/* Dr. Sujoy Kar */}
                  <div className="col text-center">
                    <Link onClick={(e) => handleShow(e, "Sujoy_Kar")}>
                      <div className="row align-items-center">
                        <div>
                          <img src={global.variables.strDomainUrl + "images/kcdh-inauguration/sujoy-kar.jpg"} alt="Dr. Sujoy Kar" title="Dr. Sujoy Kar" className="img-fluid rounded-3 mb-3 wow fadeInUp" />
                          <div>
                            <h5 className="text-dark m-0 wow fadeInUp">
                              Dr. Sujoy Kar
                            </h5>
                            <p className="wow fadeInUp">
                              <b>Chief Medical Information Officer and
                                Vice President<br />Apollo Hospitals</b>
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  {/* Surajit Nundy */}
                  <div className="col text-center">
                    <Link onClick={(e) => handleShow(e, "Surajit_Nundy")}>
                      <div className="row align-items-center">
                        <div>
                          <img src={global.variables.strDomainUrl + "images/kcdh-inauguration/surajit-nundy.jpg"} alt="Surajit Nundy" title="Surajit Nundy" className="img-fluid rounded-3 mb-3 wow fadeInUp" />
                          <div>
                            <h5 className="text-dark m-0 wow fadeInUp">
                              Dr. Surajit Nundy
                            </h5>
                            <p className="wow fadeInUp">
                              <b>Chief Executive Officer<br /> Raxa Health</b>
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  {/* Dr. Vidur Mahajan */}
                  <div className="col text-center">
                    <Link onClick={(e) => handleShow(e, "Vidur_Mahajan")}>
                      <div className="row align-items-center">
                        <div>
                          <img src={global.variables.strDomainUrl + "images/kcdh-inauguration/vidur-mahajan.jpg"} alt="Dr. Vidur Mahajan" title="Dr. Vidur Mahajan" className="img-fluid rounded-3 mb-3 wow fadeInUp" />
                          <div>
                            <h5 className="text-dark m-0 wow fadeInUp">
                              Dr. Vidur Mahajan
                            </h5>
                            <p className="wow fadeInUp">
                              <b>CEO<br />CARPL.ai</b>
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  {/* Vishnu Vardhan */}
                  <div className="col text-center">
                    <Link onClick={(e) => handleShow(e, "Vishnu_Vardhan")}>
                      <div className="row align-items-center">
                        <div>
                          <img src={global.variables.strDomainUrl + "images/kcdh-inauguration/vishnu.jpg"} alt="Vishnu Vardhan"
                            title="Vishnu Vardhan" className="img-fluid rounded-3 mb-3 wow fadeInUp" />
                          <div>
                            <h5 className="text-dark m-0 wow fadeInUp">
                              Dr. Vishnu Vardhan
                            </h5>
                            <p className="wow fadeInUp">
                              <b>Founder & CEO<br />Vizzy Inc</b>
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  {/* Dr. Vivek Tiwari */}
                  <div className="col text-center">
                    <Link onClick={(e) => handleShow(e, "Vivek_Tiwari")}>
                      <div className="row align-items-center">
                        <div>
                          <img src={global.variables.strDomainUrl + "images/kcdh-inauguration/vivek-tiwari.jpg"} alt="Dr. Vivek Tiwari" title="Dr. Vivek Tiwari" className="img-fluid rounded-3 mb-3 wow fadeInUp" />
                          <div>
                            <h5 className="text-dark m-0 wow fadeInUp">
                              Prof. Vivek Tiwari
                            </h5>
                            <p className="wow fadeInUp">
                              <b>Professor<br />IISER Berhampur</b>
                            </p>
                          </div>
                        </div>

                      </div>
                    </Link>
                  </div>

                  {/* Dr. Rimjhim Agrawal */}
                  <div className="col text-center">
                    <Link onClick={(e) => handleShow(e, "Rimjhim_Agrawal")}>
                      <div className="row align-items-center">
                        <div>
                          <img src={global.variables.strDomainUrl + "images/kcdh-inauguration/rimjhim.jpg"} alt="Dr. Rimjhim Agrawal" title="Dr. Rimjhim Agrawal" className="img-fluid rounded-3 mb-3 wow fadeInUp" />
                          <div>
                            <h5 className="text-dark m-0 wow fadeInUp">
                              Dr. Rimjhim Agrawal
                            </h5>
                            <p className="wow fadeInUp">
                              <b>Co-founder & CTO<br />Brainsight Tech Pvt. Ltd </b>
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  {/* Dr. Manishaa Soin */}
                  <div className="col text-center">
                    <Link onClick={(e) => handleShow(e, "Manishaa_Soin")}>
                      <div className="row align-items-center">
                        <div>
                          <img src={global.variables.strDomainUrl + "images/kcdh-inauguration/manishaa-soin.jpg"} alt="Dr. Manishaa Soin" title="Dr. Manishaa Soin" className="img-fluid rounded-3 mb-3 wow fadeInUp" />
                          <div>
                            <h5 className="text-dark m-0 wow fadeInUp">
                              Dr. Manishaa Soin
                            </h5>
                            <p className="wow fadeInUp">
                              <b>Chief Executive Officer<br /> CanceRX
                              </b>
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  {/* Prof. Anant B Patel  */}
                  <div className="col text-center">
                    <Link onClick={(e) => handleShow(e, "Anant_Patel")}>
                      <div className="row align-items-center">
                        <div>
                          <img src={global.variables.strDomainUrl + "images/kcdh-inauguration/anant_patel.jpg"} alt="Prof. Anant B. Patel"
                            title="Prof. Anant B. Patel" className="img-fluid rounded-3 mb-3 wow fadeInUp" />
                          <div>
                            <h5 className="text-dark m-0 wow fadeInUp">
                              Prof. Anant B. Patel
                            </h5>
                            <p className="wow fadeInUp">
                              <b>Professor<br /> CCMB Hyderabad
                              </b>
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  {/* Sasank Chilamkurthy */}
                  <div className="col text-center">
                    <Link onClick={(e) => handleShow(e, "Sasank_Chilamkurthy")}>
                      <div className="row align-items-center">
                        <div>
                          <img src={global.variables.strDomainUrl + "images/kcdh-inauguration/sasank.jpg"} alt="Sasank Chilamkurthy" title="Sasank Chilamkurthy" className="img-fluid rounded-3 mb-3 wow fadeInUp" />
                          <div>
                            <h5 className="text-dark m-0 wow fadeInUp">
                              Sasank Chilamkurthy
                            </h5>
                            <p className="wow fadeInUp">
                              <b>Chief Technology Officer<br /> Qure.AI </b>
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  {/* Dr. Swapnil Rane */}
                  <div className="col text-center">
                    <Link onClick={(e) => handleShow(e, "Swapnil_Rane")}>
                      <div className="row align-items-center">
                        <div>
                          <img src={global.variables.strDomainUrl + "images/kcdh-inauguration/swapnil-rane.jpg"} alt="Dr. Swapnil Rane" title="Dr. Swapnil Rane" className="img-fluid rounded-3 mb-3 wow fadeInUp" />
                          <div>
                            <h5 className="text-dark m-0 wow fadeInUp">
                              Dr. Swapnil Rane
                            </h5>
                            <p className="wow fadeInUp">
                              <b>Professor<br />Tata Memorial Centre</b>
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>

                </div>
              </div>
              {/* All Speakers and Panelists  */}
            </div>
          </div>
          <hr />
          <div className="list">
            <h4 className="text-primary wow fadeInUp">
              Links
            </h4>
            <ul>
              <li>Live Webcast Link:<br />
                <Link to="https://www.youtube.com/c/IITBombayOfficialChannel"
                  target="_blank">https://www.youtube.com/c/IITBombayOfficialChannel</Link>
              </li>
              <li>Panel Discussion on Challenges and Directions in bringing AI to the Clinics:
                KCDH Inauguration<br />
                <Link to="https://events.teams.microsoft.com/event/89c89189-b35a-438f-897d-142708b9d170@403ee5f4-55b3-45cd-8ae2-824be887a075"
                  target="_blank">https://events.teams.microsoft.com/event/89c89189-b35a-438f-897d-142708b9d170@403ee5f4-55b3-45cd-8ae2-824be887a075</Link>
              </li>
              <li>Disease Modelling Colloquium: KCDH Inauguration<br />
                <Link to="https://events.teams.microsoft.com/event/a0c4b01e-a6b4-4f25-9f86-44b5767e8dd9@403ee5f4-55b3-45cd-8ae2-824be887a075"
                  target="_blank">https://events.teams.microsoft.com/event/a0c4b01e-a6b4-4f25-9f86-44b5767e8dd9@403ee5f4-55b3-45cd-8ae2-824be887a075</Link>
              </li>
              <li>NMR in Healthcare<br />
                <Link to="https://events.teams.microsoft.com/event/6e68c7f5-75ac-4dd2-b69b-f4c39704fb49@403ee5f4-55b3-45cd-8ae2-824be887a075"
                  target="_blank">https://events.teams.microsoft.com/event/6e68c7f5-75ac-4dd2-b69b-f4c39704fb49@403ee5f4-55b3-45cd-8ae2-824be887a075</Link>
              </li>
            </ul>
          </div>
          <hr />

          <div>
            <h4 className="text-primary wow fadeInUp">
              Venue
            </h4>
            <div className="row">
              <div className="col-lg-7 col-md-6">
                <p>The venue for the in-person event of Catalysing the Digital Health Revolution in India will be the <Link to="https://www.iitb.ac.in/en/about-iit-bombay/victor-menezes-convention-centre-vmcc" target="_blank">Victor Menezes Convention Centre, IIT Bombay</Link>.
                </p>

                <strong>Getting to IIT Bombay: </strong>

                <p><b>Flights:</b> International flights land at Terminal 2, Chatrapati Shivaji
                  International
                  Airport (Sahar Airport), which is about 10 kms from IIT Bombay. The Domestic
                  Airport
                  is at Terminal 1A and Terminal 1B (Santa Cruz), which is about 15 kms. Call
                  taxis,
                  pre-paid taxis and autorickshaws are available at the airports. </p>
                <p><b>Trains:</b> There is a terminus at Kurla where some Central as well as
                  Western Railway
                  Trains terminate. There is no convenient bus route from Kurla terminus to
                  IITB. An
                  autorickshaw is the best option. However it would be more economical to take
                  a
                  suburban train from Kurla to Kanjur Marg and then take an auto from Kanjur
                  Marg to
                  IITB Campus.</p>
                <p>
                  <strong>Campus Map:</strong> A map of the IIT Bombay campus is available <strong>
                    <Link to="https://www.iitb.ac.in/en/about-iit-bombay/campus-map"
                      target="_blank">here.</Link>
                  </strong><br /> (The venue is VMCC, located in Sector B4 of this map, with
                  code number 85).
                </p>
              </div>
              <div className="col-lg-5 col-md-6">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.4315578892783!2d72.91520721490201!3d19.132577687055473!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c7f5c5583633%3A0x892b212b62b3b237!2sVictor%20Menezes%20Convention%20Centre!5e0!3m2!1sen!2sin!4v1681208490337!5m2!1sen!2sin"
                  width="100%" height="400" className="border-0" allowfullscreen="" loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </div>
          </div>
          <div>
            <h4 className="text-primary wow fadeInUp">Contact us</h4>
            <p>Email: <Link to="mailto:office.kcdh@iitb.ac.in">office.kcdh@iitb.ac.in</Link></p>
          </div>
          <div className="list">
            <h4 className="text-primary wow fadeInUp">
              Downloads
            </h4>
            <ul>
              <li>KCDH slide deck:</li>
              <li>Research project:</li>
              <li>KCDH video: <Link to="https://youtu.be/6TTOE99JOEk"
                target="_blank">https://youtu.be/6TTOE99JOEk</Link></li>
            </ul>
          </div>
          <div>
            <h4 className="text-primary wow fadeInUp">
              Follow us
            </h4>
            <ul className="social_media" >
              <li className="linkedin wow animated">
                <Link to="https://www.linkedin.com/company/koita-centre-for-digital-health/?viewAsMember=true"
                  target="_blank" rel="noopener">
                  <FaLinkedinIn className="icon-20" />
                </Link>
              </li>
              <li className="instagram wow animated">
                <Link to="https://www.instagram.com/kcdh_iitb/" target="_blank" rel="noopener">
                  <FaInstagram className="icon-20" />
                </Link>
              </li>
              <li className="facebook wow animated">
                <Link to="https://www.facebook.com/kcdh.iitb" target="_blank" rel="noopener">
                  <FaFacebookF className="icon-20" />
                </Link>
              </li>
              <li className="youtube wow animated">
                <Link to="https://www.youtube.com/channel/UCLjcVs6-1holqMB_8N8VEbA/featured" target="_blank"
                  rel="noopener">
                  <FaYoutube className="icon-20" />
                </Link>
              </li>
              <li className="twitter wow animated">
                <Link to="https://twitter.com/KCDH_IITB" target="_blank" rel="noopener">
                  <FaTwitter className="icon-20" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </Container>

      {/* Breadcrumb */}
      <Breadcrumb className="breadcrumb-panel mt-auto">
        <Breadcrumb.Item href={global.variables.strDomainUrl}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active>About Us</Breadcrumb.Item>
      </Breadcrumb>

      {/* Speakers and Panelist Details */}
      <Modal show={show} size="xl" centered onHide={handleClose} dialogClassName="p-2 popup-students">
        {
          SpeakersPanelists.map((val) => {
            return (
              <div>
                {
                  val.speaker_id == getspeakerid ?
                    <>
                      <div key={val.speaker_id}>
                        <Modal.Header closeButton className="border-0 align-items-start">
                        </Modal.Header>
                        <Modal.Body className="pt-0" style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
                          <span dangerouslySetInnerHTML={{ __html: val.speaker_info }}></span>
                        </Modal.Body>
                      </div>
                    </> : null
                }
              </div>
            )
          })
        }
      </Modal >
      {/* Speakers and Panelist Details */}
    </>
  );
}