import React from "react";
import { Routes, Route } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import { websiteRoutes } from "./routes";
import WebsiteLayout from "./pages/layout/"
function App() {

  // const helmetContext = {};

  return (
    <React.Fragment>
      {/* <HelmetProvider context={helmetContext}> */}

      <Routes>       

        {websiteRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
                <WebsiteLayout>{route.component}</WebsiteLayout>}
            key={idx}
            exact={true}
          />
        ))}

      </Routes>
      {/* </HelmetProvider> */}
    </React.Fragment>
  );
}

export default App;
