import React, { useState, useEffect } from "react";
import global from "../../global-variables";
import Header from "../layout/header";
import { Link } from "react-router-dom";
import $ from "jquery";
import WOW from "wowjs";

import { Container, Row, Col } from "reactstrap";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import KCDHPartnersNav from "../layout/kcdhpartners-nav";
import Metatag from "../../hooks/Metatag";
import globalVariables from "../../global-variables";


export default function KCDHPartners() {
  const [show, setShow] = useState(false);

  useEffect(() => {
    // add class for home header and remove inner class
    $(".fixed-top").addClass("bg-header");

    // initiate the wow effect
    new WOW.WOW({
      live: false,
    }).init();


  }, []);


  return (
    <>
      <Header main_className="bg-header"></Header>

      <Metatag title='National Intitative'
               keywords="National Intitative"
               description='National Intitative'/>
      {/* Visual Panel */}
      <Container fluid className="bg-visual-inside p-0" >
        <div className="visual-inside-big">
          <Row className="g-md-0">
            <Col md={6} className="order-2 order-md-1">
              <Container className="d-flex align-items-end h-100">
                <div className="d-inline-block m-0 align-self-end col-lg-6 col-xl-6 py-xl-3 p-2 text-white">
                  <h1 className="fw-light text-white m-0 align-self-center wow fadeInUp">
                    National Intitative
                  </h1>
                </div>
              </Container>
            </Col>
            <div className="col-md-6 order-1 order-md-2">
              <img src={global.variables.strDomainUrl + "images/visual-partnership.jpg"} className="img-fluid" alt="" title="" />
            </div>
          </Row>
        </div>
      </Container>


      {/* Container */}
      <Container className="my-5">
        <Row className="row-cols-1 row-cols-lg-1 g-4 g-lg-5">
          <Col>
            <div className="border rounded-3 px-md-5 p-3 pt-md-5 h-100">
              <h2 className="text-primary mb-3">National Disease Modelling Consortium (NDMC)</h2>
              <div className="col-md-12 mb-4 m-auto">The <Link to="https://www.ndmconsortium.com/aboutus/" target="_blank" rel="noreferrer" className="text-decoration-underline text-primary">National Disease Modelling Consortium (NDMC)</Link> is a unique national effort to model various diseases. Established in 2022, NDMC is anchored in the Indian Institute of Technology, Bombay, and represents a group of modellers from across the country. NDMC aims to generate consolidated estimates of disease occurrence and the overall effect of vaccination in the population.
                Several KCDH associated faculty are part of this National Disease Modelling Consortium. KCDH PIC <Link to="https://www.cse.iitb.ac.in/~ganesh/" target="_blank" rel="noreferrer" className="text-decoration-underline text-primary">Prof Ganesh Ramakrishnan</Link> is a co-PI on this consortium.</div>
            </div>
          </Col>

          <Col>
            <div className="border rounded-3 px-md-5 p-3 pt-md-5 h-100">
              <h2 className="text-primary mb-3">Maharashtra University of Health Sciences (MUHS)</h2>
              <div className="col-md-12 mb-4 m-auto">
                <p>IIT Bombay and Maharashtra University of Health Sciences (MUHS) have embarked on an innovative and pioneering collaboration, aiming to promote interaction and establish a robust partnership in the pursuit of advancing healthcare and technology. Together, they are set to enhance capacity building in the domain of Digital Health, leveraging strategic collaborations, research, and innovation to create a dynamic ecosystem that seamlessly integrates medical science and engineering.</p>
                <p>With a shared commitment to a brighter and healthier future, IIT Bombay and MUHS are gearing up to roll out joint courses, internships, and student exchange programs specially tailored to meet the distinctive needs of both engineering and medical students. This strategic move is designed to bridge the gap between these diverse disciplines, providing students with comprehensive insights and expertise. The ultimate goal is to foster a new wave of innovation at the intersection of healthcare and technology.</p>
                <p>At the heart of this partnership lies an unwavering dedication to nurture talent, inspire creativity, and drive transformative advancements in the realm of Digital Health. Together, IIT Bombay and MUHS are at the forefront of leading the way in shaping the future of healthcare, technology, and overall human well-being.</p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>


      {/* Breadcrumb */}
      <Breadcrumb className="breadcrumb-panel mt-auto">
        <Breadcrumb.Item href={globalVariables.variables.strDomainUrl}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Partnerships</Breadcrumb.Item>
        <Breadcrumb.Item active>Overview</Breadcrumb.Item>
      </Breadcrumb>
    </>
  );
}