import React, { useState, useEffect } from "react";
import global from "../../global-variables";
import Header from "../layout/header";
import { Link } from "react-router-dom";
import $ from "jquery";
import WOW from "wowjs";

import { Container, Row, Col } from "reactstrap";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import globalVariables from "../../global-variables";
import Metatag from "../../hooks/Metatag";


export default function OpportunitiesDigitalHealthcare() {
  const [show, setShow] = useState(false);
  const [getcourseid, setCourseid] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = (e, course_id) => {
    e.preventDefault();
    setShow(true);
    //get Course id
    setCourseid(course_id);
  }

  useEffect(() => {
    // add class for home header and remove inner class
    $(".fixed-top").addClass("bg-header");

    // initiate the wow effect
    new WOW.WOW({
      live: false,
    }).init();

  }, []);

  useEffect(()=>{

    window.initializeFancyboxMedia();
    
  },[])
  
  return (
    <>
      <Header main_className="bg-header"></Header>

      <Metatag title='Opportunities in Digital Healthcare'
               description='KCDH and IIT Bombay offers Career Opportunities in Digital Healthcare and Informatics'
               keywords="Opportunities in Digital Healthcare, Career Opportunities in Digital Healthcare and Informatics, Corporate Opportunities in Digital Healthcare and Informatics, Academics Opportunities in Digital Healthcare, Research Opportunities in Digital Healthcare"
               />

      {/* Visual Panel */}
      <Container fluid className="bg-visual-inside p-0" >
        <div className="visual-inside-big">
          <Row className="g-md-0">
            <Col md={6} className="order-2 order-md-1">
              <Container className="d-flex align-items-end h-100">
                <div className="d-inline-block m-0 align-self-end col-lg-6 col-xl-6 py-xl-3 p-2 text-white">
                  <h1 className="fw-light text-white m-0 align-self-center wow fadeInUp">
                    Opportunities in Digital Healthcare
                  </h1>
                </div>
              </Container>
            </Col>
            <div className="col-md-6 order-1 order-md-2">
              <img src={global.variables.strDomainUrl + "images/visual-students-corner.jpg"} className="img-fluid" alt="" title="" />
            </div>
          </Row>
        </div>
      </Container>


      {/* Container */}
      <Container className="my-5">
        <Row>
          <Col md={3}>
            <h2 className="text-primary wow fadeInUp">
              Overview
            </h2>
          </Col>
          <Col md={9} className="ps-md-5 list">
            <p>
              The worldwide focus on enhancing Digital Health and informatics has resulted in massive investments and innovation in the field at an unprecedented scale. The market is expected to reach over $120 Bn by 2025 from $40 Bn in 2016 - a CAGR of 14%.  The growth
              rate will be highest in India / APAC region - rising awareness about usage of technology in healthcare and a simultaneous surge in the IT industry.
            </p>
            <b>Some of the key drivers of Digital Health are:</b>
            <ul className="m-0">
              <li>Increase in usage of clinical systems by healthcare organizations</li>
              <li>Increase in government spending in developing and upgrading healthcare infrastructure</li>
              <li>Increase in internet connectivity across the country</li>
              <li>Massive growth in mobile phones</li>
              <li>Advancements in data management, analytics, and AI/ML technologies</li>
            </ul>
          </Col>
        </Row>
      </Container>
      <Container>
        <hr className="px-5" />
      </Container>
      <Container className="my-5">
        <Row>
          <Col md={3}>
            <h2 className="text-primary wow fadeInUp">
              Career Opportunities in Digital Health
            </h2>
          </Col>
          <Col mkd={9} className="ps-md-5 list">
            <p>Digital Health offers a range of career opportunities across corporates, start-ups / entrepreneurship and academics / research.</p>
            <ul>
              <li><b>Corporate Opportunities</b><br />Digital Health offers significant global career opportunities across a range of organizations:
                <ul className="pt-1 mb-2">
                  <li>Healthcare software companies, medical devices companies</li>
                  <li>Life Sciences / Pharma cos.</li>
                  <li>Clinical Research labs</li>
                  <li>Large Hospitals, Specialty care</li>
                  <li>Health insurance organizations</li>
                  <li>Public Health organizations</li>
                </ul>
              </li>
              <li>
                <p><b>Start-ups / Entrepreneurship</b><br />Digital Health offers significant start-ups/entrepreneurship opportunities.  Digital Health is one of the most exciting areas for start-ups worldwide. In India too, there is a significant
                  rise in VC / Private equity investments in healthcare technology over the past 5 years.</p>
              </li>
              <li><b>Academics / Research</b><br />Digital Health is one of the most exciting areas for academic work and research.  Many leading universities such as Harvard, JHU, Brown, Stanford, etc have programs in Digital Health and Informatics
                leading to opportunities in academics and research at top facilities across the world
              </li>
            </ul>
            <p className="m-0">KCDH provides students with the necessary skills and experience to be able to have a very unique profile that will enable them to avail the opportunities across any of the three streams based on their interests.</p>
          </Col>
        </Row>
      </Container>
      <Container>
        <hr className="px-5" />
      </Container>
      <Container className="my-5">
        <Row>
          <Col md={3}>
            <h2 className="text-primary wow fadeInUp">
              Hear from our alums
            </h2>
          </Col>
          <Col md={9} className="ps-md-5 list">
            <Link to="https://www.youtube.com/watch?v=7Ub5RY_3d_U" className="fancybox-media"><img src={global.variables.strDomainUrl + "images/alum-speak-video.jpg"} alt="Hear from our alums" title="Hear from our alums" className="img-flid rounded-3" /></Link>
          </Col>
        </Row>
      </Container>


      {/* Breadcrumb */}
      <Breadcrumb className="breadcrumb-panel mt-auto px-3">
        <Breadcrumb.Item to={globalVariables.variables.strDomainUrl}>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Students' Corner</Breadcrumb.Item>
        <Breadcrumb.Item active>Opportunities in Digital Healthcare</Breadcrumb.Item>
      </Breadcrumb>

    </>
  );
}