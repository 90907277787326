import React, { useState, useEffect, useRef } from 'react';
import global from "../../global-variables";
import { Link, useLocation } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import TrackingCode from '../../hooks/TrackingCode';
import WOW from 'wowjs';
import { Container, Row, Col } from 'reactstrap';
import axios from 'axios';
import globalVariables from '../../global-variables';
import { HashLink } from "react-router-hash-link";
import $ from "jquery";

export const scrollToTop = () => {
    window.scrollTo({
    top: 0,
    behavior: 'smooth' // Optional smooth scrolling
    });
};

export default function Header(props) {

    // const scrollWithOffset = (el) => {
    //     const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    //     const yOffset = -134;
    //     window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    // }


    //navbar active 
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    //navbar active 

    const ref = useRef(null);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [fullscreen, setFullscreen] = useState(true);
    // const pathname = useLocation();

    const scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -134;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    }

    useEffect(() => {
        // Go to Top OR Scroll to top
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation (comment behavior if u want to animate)
        });
    }, [pathname]);

    useEffect(() => {
        // initiate the wow effect
        new WOW.WOW({
            live: false
        }).init();

        $(document).ready(function () {
            if ($(window).width() >= 1198) { $(".dropdown").hover(function () { $('.dropdown-menu', this).not('.in .dropdown-menu').stop(true, true).slideDown(); $(this).toggleClass('open'); $('.offcanvas-overlay').eq(0).addClass('activeoverlay'); $('.dropdown-menu').addClass('openmenu'); }, function () { $('.dropdown-menu', this).not('.in .dropdown-menu').stop(true, true).slideUp("400"); $(this).toggleClass('open'); $('.offcanvas-overlay').eq(0).removeClass('activeoverlay'); }); }
            $('div#offcanvas-overlay').on('click', function () {
                $('html').removeClass('open-menu');
            });
        });
    }, []);

    const [peopleCategory, setPeopleCategoryList] = useState([]);

    useEffect(() => {
        let _obj = new Object();
        _obj.pcategory_status = 1;
        setTimeout(() => {

            axios.post(global.variables.strApiUrl + "/peoplecategoryApi/getcategoryHavingPeopleList", _obj)
                .then((response_list) => {
                    // pass the result in the success function of the respective page
                    setPeopleCategoryList(response_list.data[0]);
                });
        }, 300);

    }, [])

    //serach  function
    function url_generator(url) {
        var url_generated = "";
        // For local host
        if (window.location.href.indexOf('localhost') != -1) {
            url_generated = url;
        }

        //    // For my machine Server
        else if (window.location.href.indexOf('192.168.2.202') != -1) {
            // url_generated = "/a/amnsinternationalschool" + url;
            url_generated = url;
        }

        // For Live server
        else {
            url_generated = url;
        }
        // alert(url_generated);
        return url_generated;
    }

    useEffect(() => {

        $("#btnsearch").on("click", function (e) {

            $("#searcherr").html("");
            if ($("#txtSearch").val() == "") {
                $("#searcherr").html("Please Enter Search String");
                return;
            }
            else {
                window.location.href = (global.variables.strDomainUrl + "search-result/" + $("#txtSearch").val());
            }
        });


        $('#txtSearch').keypress(function (e) {
            var key = e.which;
            if (key == 13)  // the enter key code
            {
                if ($("#txtSearch").val() == "") {
                    $("#searcherr").html("Please Enter Search String");
                    return;
                }
                else {
                    <Link to=""></Link>
                    window.location.href = url_generator(global.variables.strDomainUrl + "search-result/" + $("#txtSearch").val());
                }
                return false;
            }
        });

        $('#focusOnInput').on('click', function () {
            setTimeout(() => {
                ref.current.focus();

            }, 500);
        })

    })


    let conversion_code = "";

    if (props.conversion_code != undefined) {
        conversion_code = 1;
    }
    else {
        conversion_code = 2;
    }



    return (
        <>

            <Navbar className={props.main_class + " fixed-top"} collapseonselect expand="xl">
                <Container className='d-block'>
                    <Row className="align-items-center justify-content-between d-flex">
                        <Col xs={6} md={6} lg={6} xl={4} className='pe-0'>
                            <div className='logo'>
                                <Link to="/" onClick={scrollToTop} className="position-relative overflow-hidden">
                                    <img src={global.variables.strDomainUrl + "images/logo.png"} className="img-fluid my-2 my-md-3 animated fadeInUp" alt="The Koita Centre for Digital Health - KCDH set up at IIT Bombay" title="The Koita Centre for Digital Health - KCDH set up at IIT Bombay" width={245} height={80} loading='lazy' />
                                </Link>
                            </div>
                        </Col>
                        <Col xs={6} md={6} lg={6} xl={8} className='ps-xl-5'>
                            <div className="d-flex justify-content-end align-items-center mb-xl-2 pb-xl-1">
                                <div className="me-3">
                                    <Link className="bg-transparent nav-link icon-search animated fadeInUp" id='focusOnInput' onClick={handleShow}><FiSearch /></Link>
                                </div>
                                <div>
                                    <ul className="navbar-nav fw-normal top-nav m-0">
                                        <li className="nav-item d-xl-block d-none animated fadeInUp"><Link to="/news-events" onClick={scrollToTop} className="bg-transparent nav-link">News & Events</Link></li>
                                        <li className="nav-item d-xl-block d-none animated fadeInUp"><Link to="/careers" onClick={scrollToTop} className="bg-transparent nav-link">Careers</Link></li>
                                        <li className="nav-item d-xl-block d-none animated fadeInUp"><HashLink to="#contact" className="bg-transparent nav-link" scroll={el => scrollWithOffset(el)}>Contact Us</HashLink></li>
                                    </ul>
                                </div>
                                <Navbar.Toggle className="offcanvas-toggle shadow-none px-1 animated fadeInUp" aria-controls={'offcanvas-menu'}>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </Navbar.Toggle>
                            </div>
                            <Navbar.Offcanvas id={'offcanvas-menu'} aria-labelledby={'offcanvas-menu'} placement="end" responsive="xl" className="px-md-0 px-3">
                                <Offcanvas.Header closeButton>
                                    <Offcanvas.Title>
                                    </Offcanvas.Title>
                                </Offcanvas.Header>
                                <ul className="navbar-nav mt-xl-auto justify-content-between top-bt-nav">
                                    <li className={splitLocation[1] === "aboutus" ? "active nav-item animated fadeInUp" : "nav-item animated fadeInUp"}><Link to="/aboutus" onClick={scrollToTop} className="nav-link">About Us</Link></li>
                                    <li className={splitLocation[1] === "people" ? "active" : ""}> 
                                        <NavDropdown title="People" id="basic-nav-dropdown" className="animated fadeInUp" renderMenuOnMount={true}>
                                            <ul className='px-0 py-xl-2 bg-white rounded-3'>
                                                {
                                                    peopleCategory.map((val_people) => {
                                                        return (
                                                            <NavDropdown.Item as="li"><Link to={globalVariables.variables.strDomainUrl + 'people/' + val_people.pcategory_pagename} onClick={scrollToTop} id='ClickByRef'>{val_people.pcategory_name}</Link></NavDropdown.Item>
                                                        )
                                                    })
                                                }
                                                {/* <NavDropdown.Item as="li"><Link to="">KCDH Team</Link></NavDropdown.Item>
                                            <NavDropdown.Item as="li"><Link to="/advisory-board">Advisory Board</Link></NavDropdown.Item>
                                            <NavDropdown.Item as="li"><Link to="">Students</Link></NavDropdown.Item> */}
                                            </ul>
                                        </NavDropdown>
                                    </li>
                                    <li className={splitLocation[1] === "academics" ? "active nav-item animated fadeInUp" : "nav-item animated fadeInUp"}><Link to="/academics" onClick={scrollToTop} className="nav-link">Academics</Link></li>
                                    <li className={splitLocation[1] === "research" ? "active" : "" || splitLocation[1] === "publications"? "active" : ""}>
                                        <NavDropdown title="Research" id="basic-nav-dropdown" className="animated fadeInUp" renderMenuOnMount={true}>
                                            <ul className='px-0 py-xl-2 bg-white rounded-3'>
                                                <NavDropdown.Item as="li"><Link to="/research" onClick={scrollToTop}>Overview</Link></NavDropdown.Item>
                                                <NavDropdown.Item as="li"><Link to="/research/kcdh-projects" onClick={scrollToTop}>KCDH Projects</Link></NavDropdown.Item>
                                                <NavDropdown.Item as="li"><Link to="/publications" onClick={scrollToTop}>Publications</Link></NavDropdown.Item>
                                            </ul>
                                        </NavDropdown>
                                    </li>
                                    <li className={splitLocation[1] === "partnerships" ? "active" : "" || splitLocation[1] === "kcdhpartners" ? "active" : "" || splitLocation[1] === "national-intitative" ? "active" : ""}>
                                        <NavDropdown title="Partnerships" id="basic-nav-dropdown" className="animated fadeInUp" renderMenuOnMount={true}>
                                            <ul className='px-0 py-xl-2 bg-white rounded-3'>
                                                <NavDropdown.Item as="li"><Link to="/partnerships" onClick={scrollToTop}>Overview</Link></NavDropdown.Item>
                                                <NavDropdown.Item as="li"><Link to="/kcdhpartners" onClick={scrollToTop}>KCDH Partners</Link></NavDropdown.Item>
                                                <NavDropdown.Item as="li"><Link to="/national-intitative" onClick={scrollToTop}>National Intitative</Link></NavDropdown.Item>
                                            </ul>
                                        </NavDropdown>
                                    </li>
                                    <li className={splitLocation[1] === "ph-d-program" ? "active" : "" || splitLocation[1] === "interdisciplinary-dual-degree-program" ? "active" : "" || splitLocation[1] === "minor-program-healthcare-informatics" ? "active" : "" || splitLocation[1] === "opportunities-digital-healthcare" ? "active" : "" || splitLocation[1] === "kcdh-internships" ? "active" : ""}>
                                        <NavDropdown title="Students' Corner" id="basic-nav-dropdown" className="animated fadeInUp" renderMenuOnMount={true}>
                                            <ul className='px-0 py-xl-2 bg-white rounded-3'>
                                                <NavDropdown.Item as="li"><Link to="/ph-d-program" onClick={scrollToTop}>Ph.D. Program</Link></NavDropdown.Item>
                                                <NavDropdown.Item as="li"><Link to="/interdisciplinary-dual-degree-program" onClick={scrollToTop}>Interdisciplinary Dual Degree Program</Link></NavDropdown.Item>
                                                <NavDropdown.Item as="li"><Link to="/minor-program-healthcare-informatics" onClick={scrollToTop}>Minor Program</Link></NavDropdown.Item>
                                                <NavDropdown.Item as="li"><Link to="/ms-by-research-healthcare-informatics" onClick={scrollToTop}>M.S. by Research</Link></NavDropdown.Item>
                                                <NavDropdown.Item as="li"><Link to="/opportunities-digital-healthcare" onClick={scrollToTop}>Opportunities in Digital Healthcare</Link></NavDropdown.Item>
                                                <NavDropdown.Item as="li"><Link to="/kcdh-internships" onClick={scrollToTop}>KCDH Internships</Link></NavDropdown.Item>
                                            </ul>
                                        </NavDropdown>
                                    </li>
                                    <li className="nav-item animated fadeInUp d-xl-none"><Link to="/news-events" onClick={scrollToTop} className="nav-link">News & Events</Link></li>
                                    <li className="nav-item animated fadeInUp d-xl-none"><Link to="/careers" className="nav-link" onClick={scrollToTop}>Careers</Link></li>
                                    <li className="nav-item animated fadeInUp d-xl-none"><HashLink to="#contact" className="nav-link" scroll={el => scrollWithOffset(el)}>Contact Us</HashLink></li>
                                </ul>
                            </Navbar.Offcanvas>
                        </Col>
                    </Row>
                </Container>

                <Modal show={show} fullscreen={fullscreen} onHide={handleClose} className="bg-search-panel">
                    <Modal.Header closeButton className="border-0 p-md-5 p-4 h5">
                        <Modal.Title></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="d-flex justify-content-center">
                        <Container>
                            <div className="col-md-8 mx-auto">
                                <InputGroup className="mb-3 border rounded bg-white">
                                    <Form.Control
                                        className="border-0 form-control-lg shadow-none"
                                        placeholder="Search"
                                        aria-label="Search"
                                        aria-describedby=""
                                        required=""
                                        id='txtSearch'
                                        ref={ref}
                                    />
                                    <span id="searcherr" class="text-danger me-2" style={{ 'margin-top': ' 10px', 'margin-right': '5px' }}></span>
                                    <Button variant="outline-dark border-0" id="btnsearch">
                                        <FiSearch />
                                    </Button>
                                </InputGroup>
                            </div>
                        </Container>
                    </Modal.Body>
                </Modal>
            </Navbar>
            <TrackingCode conversion_code={conversion_code} />
        </>
    )
} 