import React, { useState, useEffect } from "react";
import global from "../../global-variables";
import Header from "../layout/header";
import { Link } from "react-router-dom";
import $ from "jquery";
import WOW from "wowjs";
import { FaLink, FaImage, FaRegFilePdf, FaVideo, FaChevronRight } from "react-icons/fa";

import { Container, Row, Col } from "reactstrap";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Metatag from "../../hooks/Metatag";


export default function AboutUs() {
  const [show, setShow] = useState(false);
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {
    // add class for home header and remove inner class
    $(".fixed-top").addClass("bg-header");

    // initiate the wow effect
    new WOW.WOW({
      live: false,
    }).init();


  }, []);


  return (
    <>
      <Header main_className="bg-header"></Header>
      <Metatag title='The Koita Centre for Digital Health – KCDH at IIT Bombay'
               description='Koita Centre for Digital Health - KCDH at IIT Bombay is focused on driving academic programs, research, and industry collaborations in Digital Health and Healthcare Informatics'
               keywords='The Koita Centre for Digital Health, KCDH, Institutes offering courses in Digital Health,  Institutes offering courses in Healthcare Informatics, Healthcare Programs at IIT Bombay'
               />
      {/* Visual Panel */}
      <Container fluid className="bg-visual-inside p-0" >
        <div className="visual-inside-big">
          <Row className="g-md-0">
            <Col md={6} className="order-2 order-md-1">
              <Container className="d-flex align-items-end h-100">
                <div className="d-inline-block m-0 align-self-end col-lg-6 col-xl-6 py-xl-3 p-2 text-white">
                  <h1 className="fw-light text-white m-0 align-self-center wow fadeInUp">
                    About KCDH
                  </h1>
                </div>
              </Container>
            </Col>
            <div className="col-md-6 order-1 order-md-2">
              <img src={global.variables.strDomainUrl + "images/visual-aboutus.jpg"} className="img-fluid" alt="" title="" />
            </div>
          </Row>
        </div>
      </Container>

      {/* Container */}
      <Container className="my-5">
        <Row>
          <Col md={7} lg={8}>
            <p>Koita Centre for Digital Health (KCDH) at IIT Bombay is the first of its kind in India, focused on driving academic programs, research, and industry collaborations in Digital Health.</p>
            <p>KCDH addresses the need for engineering, clinical professionals and entrepreneurs in Digital health and creates research and employment opportunities in this domain. IIT Bombay partners with leading hospital systems, healthcare research, and academic organizations which have strong capabilities in different branches of medicine and healthcare.</p>
            <p className="m-0">KCDH was established with a generous contribution received from its alumni Rekha and Rizwan Koita, under the aegis of the Koita Foundation (<Link to="https://www.koitafoundation.org/" target="_blank"><u>www.koitafoundation.org</u></Link>). The centre was approved by the Board of Governors of IIT Bombay on 4th June 2021.</p>
            <div className="list my-4">
              <Row className="g-0 d-flex">
                <Col xl={2} md={3} className="col-xl-2 col-lg-3 col-md-3">
                  <div className="bg-light rounded-start-3 text-center h-100 d-flex justify-content-center align-items-center p-3">
                    <h5 className="text-dark m-0 d-flex align-self-center wow fadeInUp">KCDH Vision</h5>
                  </div>
                </Col>
                <Col xl={10} md={9} className="p-3 border rounded-end-3">
                  <p className="mb-0">To become a globally renowned centre in Digital Health and Health Informatics. The centre will drive research, entrepreneurship and employment in Digital Health to transform healthcare in India, partnering closely with clinical professionals and healthcare organizations.</p>
                </Col>
              </Row>
            </div>

            <div>
              <div className="bg-light rounded-top-3 text-center h-100 d-flex justify-content-center align-items-center p-3">
                <h5 className="text-dark m-0 d-flex align-self-center text-center wow fadeInUp">Koita Centre for Digital Health (IIT Bombay)</h5>
              </div>

              <div className="border rounded-bottom-3">
                <div className="col-xxl-9 col-xl-9 col-lg-10 mx-auto about-video pb-4 px-xl-3 px-4 pt-4">
                  <div className="ratio ratio-16x9">
                    <iframe src="https://www.youtube.com/embed/6TTOE99JOEk" title="YouTube video player" className="rounded-3 border-0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col md={5} lg={4} className="ps-md-5 mt-md-0 mt-4">
            <div className="bg-light rounded-3 h-100 px-2">
              <div className="quote-sm py-5 px-4 h-100">
                <p className="mt-md-5 mt-2">"Today, we are launching a mission that has the potential of bringing a revolutionary change in India’s health facilities"</p>
                <p className="m-0">
                  <b>Prime Minister Shri Narendra Modi</b><br /> On Ayushman Bharat Digital Mission,<br />September 27, 2021
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Container className="mb-5">
        <Row>
          <Col md={7} lg={8} className="col-md-7 col-lg-8">
            <h2 className="text-primary mb-4 wow fadeInUp">IIT Bombay's Unique Position in Digital Health</h2>
            <p>
              Digital Health and Informatics needs cross functional expertise – computer science, bioinformatics, statistics, AI/ machine learning, medical science. IIT Bombay, as India’s #1 academic institution, has deep technical and research expertise, which is well
              suited to build excellence in Digital Health along with Digital Health Partners (DHP) and to establish a globally renowned centre.
            </p>
            <p>
              In addition, IIT Bombay and its DHP partners have a unique opportunity to take leadership in Digital Health relevant to India. Our country has unique healthcare problems/ disease patterns and healthcare delivery challenges. This need is even more important
              in the face of the COVID-19 pandemic facing India today.
            </p>
            <p>
              KCDH creates a cadre of professionals who can leverage these opportunities and contribute to the field. IIT B attracts some of the smartest minds across the countries and this centre is giveing them an opportunity to be able to apply technical expertise
              in areas like AI/ML to real world problems creating a win-win situation across the ecosystem.
            </p>
            <p className="m-0">
              Digital Health offers significant job opportunities and is one of the fastest areas of job growth. Job opportunities in Digital Health exist within many sectors including healthcare device companies, healthcare software companies, hospital systems, health
              insurance, pharma companies, consulting and research organizations. Digital Health offers significant startup / entrepreneurship opportunities.
            </p>
          </Col>
          <div className="col-md-5 col-lg-4 ps-md-5 mt-md-0 mt-4">
            <div className="bg-light rounded-3">
              <img src={global.variables.strDomainUrl + "images/pic-iitbombay.jpg"} alt="IIT Bombay" title="IIT Bombay" className="img-fluid rounded-3 w-100" />
                <ul className="m-0 list-group p-md-4 px-2 py-4">
                  <li className="list-group-item bg-transparent border-0 border-bottom border-white">Consistently ranked <b>#1 institute</b> in India</li>
                  <li className="list-group-item bg-transparent border-0 border-bottom border-white">Selected as <b>"Institute of Eminence"</b> by <b>HRD Ministry, India</b></li>
                  <li className="list-group-item bg-transparent border-0 border-bottom border-white">The <b>top academic entity</b> filing India patents</li>
                  <li className="list-group-item bg-transparent border-0"><b>Strong Entrepreneurship Ecosystem</b></li>
                </ul>
            </div>

          </div>
        </Row>
      </Container>

      {/* Breadcrumb */}
      <Breadcrumb className="breadcrumb-panel mt-auto">
        <Breadcrumb.Item href={global.variables.strDomainUrl}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active>About Us</Breadcrumb.Item>
      </Breadcrumb>
    </>
  );
}