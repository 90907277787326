import React, { useState, useEffect } from "react";
import global from "../../global-variables";
import Header from "../layout/header";
import { Link } from "react-router-dom";
import $ from "jquery";
import WOW from "wowjs";

import { Container, Row, Col } from "reactstrap";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import KCDHPartnersNav from "../layout/kcdhpartners-nav";
import Metatag from '../../hooks/Metatag'
import globalVariables from "../../global-variables";

export default function KCDHPartners() {
  const [show, setShow] = useState(false);

  useEffect(() => {
    // add class for home header and remove inner class
    $(".fixed-top").addClass("bg-header");

    // initiate the wow effect
    new WOW.WOW({
      live: false,
    }).init();


  }, []);


  return (
    <>
      <Header main_className="bg-header"></Header>

      <Metatag title='KCDH Partners | KCDH Internship Partners'
               description='KCDH Partners and Internship Partners for Digital Health'
               keywords="KCDH Partners, KCDH Internship Partners, KCDH Digital Health Partners"
               />
      {/* Visual Panel */}
      <Container fluid className="bg-visual-inside p-0" >
        <div className="visual-inside-big">
          <Row className="g-md-0">
            <Col md={6} className="order-2 order-md-1">
              <Container className="d-flex align-items-end h-100">
                <div className="d-inline-block m-0 align-self-end col-lg-6 col-xl-6 py-xl-3 p-2 text-white">
                  <h1 className="fw-light text-white m-0 align-self-center wow fadeInUp">
                    KCDH Partners
                  </h1>
                </div>
              </Container>
            </Col>
            <div className="col-md-6 order-1 order-md-2">
              <img src={global.variables.strDomainUrl + "images/visual-partnership.jpg"} className="img-fluid" alt="" title="" />
            </div>
          </Row>
        </div>
      </Container>


      {/* Container */}
      <Container className="my-5 position-relative">
        <Row>
          <Col md={4} lg={3} className="mb-3">
            <KCDHPartnersNav submenutitle="Academic Institutions" academic_className="active"></KCDHPartnersNav>
          </Col>
          <Col md={8} lg={9} className="ps-md-4">
            <div className="rounded-3 border p-4">
              <h2 className="text-primary">Academic Institutions</h2>
              <Row className="row-cols-1 g-3">
                {/* Bajaj Finserv Health */}
                <div class="col py-3 border-bottom">
                  <div class="row text-start">
                    <div class="col-md-4 col-lg-3">
                      <img src={global.variables.strDomainUrl + "images/bajaj-health.png"} alt="Bajaj Finserv Health" title="Bajaj Finserv Health" class="img-fluid border rounded-3 mb-3 wow fadeInUp" />
                    </div>
                    <div class="col-md-8 col-lg-9 p-lg-0 ps-lg-3 ps-xxl-0">
                      <h5 class="text-dark m-0 wow fadeInUp">
                        Bajaj Finserv Health <Link to="https://www.bajajfinservhealth.in/"
                          target="_blank" rel="noreferrer"><small>(www.bajajfinservhealth.in)</small></Link>
                      </h5>
                      <p>Bajaj Finserv Health is a health-tech company from Bajaj Finserv Group, providing access to preventive, personalized & affordable healthcare for all.</p>
                      <p>
                        <b>Domain:</b> Internships Public Health data/ Health Insurance Analytics
                      </p>
                    </div>
                  </div>
                </div>
                {/* Bajaj Finserv Health */}
                {/* CitiusTech */}
                <div class="col py-3 border-bottom">
                  <div class="row text-start">
                    <div class="col-md-4 col-lg-3">
                      <img src={global.variables.strDomainUrl + "images/logo-citiustech.png"} alt="CitiusTech" title="CitiusTech" class="img-fluid border rounded-3 mb-3 wow fadeInUp" />
                    </div>
                    <div class="col-md-8 col-lg-9 p-lg-0 ps-lg-3 ps-xxl-0">
                      <h5 class="text-dark m-0 wow fadeInUp">
                        CitiusTech <Link to="https://www.citiustech.com/"
                          target="_blank" rel="noreferrer"><small>(www.citiustech.com)</small></Link>
                      </h5>
                      <p>CitiusTech is a global leader in healthcare technology and one of the Unicorns in India. CitiusTech today has over 6,500 healthcare technology professionals and over USD 325 Mn in revenues.</p>
                      <p><b>Domain:</b> Internships in the healthcare domain</p>
                    </div>
                  </div>
                </div>
                {/* CitiusTech */}
                {/* IBM Research */}
                <div class="col py-3 border-bottom">
                  <div class="row text-start">
                    <div class="col-md-4 col-lg-3">
                      <img src={global.variables.strDomainUrl + "images/logo-ibm.png"} alt="IBM Research" title="IBM Research" class="img-fluid border rounded-3 mb-3 wow fadeInUp" />
                    </div>
                    <div class="col-md-8 col-lg-9 p-lg-0 ps-lg-3 ps-xxl-0">
                      <h5 class="text-dark m-0 wow fadeInUp">
                        IBM Research <Link to="https://research.ibm.com"
                          target="_blank" rel="noreferrer"> <small>(www.research.ibm.com)</small></Link>
                      </h5>
                      <p>IBM Research is a global organization, dedicated to advancing cutting-edge technologies and conducting scientific research. It has been at the forefront of using artificial intelligence (AI) to revolutionize healthcare </p>
                      <p>
                        <b>Domain:</b>  Joint research on medical domain specific large language modeling
                      </p>
                    </div>
                  </div>
                </div>
                {/* IBM Research */}
                {/* ICICI Lombard */}
                <div class="col py-3 border-bottom">
                  <div class="row text-start">
                    <div class="col-md-4 col-lg-3">
                      <img src={global.variables.strDomainUrl + "images/logo-icici-lombard.png"} alt="ICICI Lombard" title="ICICI Lombard" class="img-fluid border rounded-3 mb-3 wow fadeInUp" />
                    </div>
                    <div class="col-md-8 col-lg-9 p-lg-0 ps-lg-3 ps-xxl-0">
                      <h5 class="text-dark m-0 wow fadeInUp">
                        ICICI Lombard <Link to="https://www.icicilombard.com/"
                          target="_blank" rel="noreferrer"><small>(www.icicilombard.com)</small></Link>
                      </h5>
                      <p> ICICI Lombard GIC Ltd. is one of the leading private sector general insurance companies in India. The company issued over 26.2 million policies and settled over 1.8 million claims as of March 31, 2020. </p>
                      <p> <b>Domain:</b> Internship on predicting Health Insurance cost and predict disease / hospitalization using health data </p>
                    </div>
                  </div>
                </div>
                {/* ICICI Lombard */}
                {/* Intelehealth */}
                <div class="col py-3 border-bottom">
                  <div class="row text-start">
                    <div class="col-md-4 col-lg-3">
                      <img src={global.variables.strDomainUrl + "images/logo-intelehealth.png"} alt="Intelehealth " title="Intelehealth " class="img-fluid border rounded-3 mb-3 wow fadeInUp" />
                    </div>
                    <div class="col-md-8 col-lg-9 p-lg-0 ps-lg-3 ps-xxl-0">
                      <h5 class="text-dark m-0 wow fadeInUp">
                        Intelehealth  <Link to="https://intelehealth.org/"
                          target="_blank" rel="noreferrer"> <small>(www.intelehealth.org)</small></Link>
                      </h5>
                      <p>Intelehealth is a non-profit delivering high-quality healthcare where there is no doctor via telemedicine. They have developed a open-source technology platform that's driven by an innovative digital health assistant to connect patients and frontline health workers at the last mile with doctors, diagnostics & medications. Intelehealth is on track to provide healthcare for 10 million women over the next three years in partnership with Ministries of Health in India & Kyrgyzstan as well as organizations like UNICEF, Jhpiego, MSF, and many more. Intelehealth has been recognized by the World Economic Forum and Niti Aayog India as an impactful Digital Public Good.</p>
                      <p> <b>Domain:</b> Internships in large language models</p>
                    </div>
                  </div>
                </div>
                {/* Intelehealth */}
                
                
                {/* NIIT */}
                <div class="col py-3 border-bottom">
                  <div class="row text-start">
                    <div class="col-md-4 col-lg-3">
                      <img src={global.variables.strDomainUrl + "images/logo-niit.png"} alt="NIIT" title="NIIT" class="img-fluid border rounded-3 mb-3 wow fadeInUp" />
                    </div>
                    <div class="col-md-8 col-lg-9 p-lg-0 ps-lg-3 ps-xxl-0">
                      <h5 class="text-dark m-0 wow fadeInUp">
                        NIIT <Link to="https://www.niit.com/india/" target="_blank" rel="noreferrer"><small>(www.niit.com/india)</small></Link>
                      </h5>
                      <p>NIIT is a leading Skills and Talent Development Corporation that is building a manpower pool for global industry requirements. NIIT today ranks among the world's leading training companies owing to its vast and comprehensive array of talent development programs. With a footprint in over 30 countries, NIIT offers training and development solutions to Individuals, Enterprises and Institutions.</p>
                      <p> <b>Domain:</b> Collaboration on training programmes</p>
                    </div>
                  </div>
                </div>
                {/* NIIT */}
                {/* Oraicle Biosciences Limited */}
                <div class="col py-3 border-bottom">
                  <div class="row text-start">
                    <div class="col-md-4 col-lg-3">
                      <img src={global.variables.strDomainUrl + "images/logo-oraiclebio.png"} alt="Oraicle Biosciences Limited" title="Oraicle Biosciences Limited" class="img-fluid border rounded-3 mb-3 wow fadeInUp" />
                    </div>
                    <div class="col-md-8 col-lg-9 p-lg-0 ps-lg-3 ps-xxl-0">
                      <h5 class="text-dark m-0 wow fadeInUp">
                        Oraicle Biosciences Limited <Link to="https://oraiclebio.com/"
                          target="_blank" rel="noreferrer"><small>(www.oraiclebio.com)</small></Link>
                      </h5>
                      <p>Oraicle Biosciences was established with a mission to combat the disease and mortality caused by oral cancers. . Oraicle Biosciences aims to save lives and families by focusing on evidence-based de-addiction methods and innovative diagnostic solutions. They recognize that the global burden of oral cancer is significant  are determined to prioritize oral health and make a positive impact worldwide through a multi-disciplinary approach.</p>
                      <p><b>Domain:</b> Joint research on machine learning model for oral precancerous lesions</p>
                    </div>
                  </div>
                </div>
                {/* Oraicle Biosciences Limited */}
                
                
                {/* Qure*/}
                <div class="col py-3 border-bottom">
                  <div class="row text-start">
                    <div class="col-md-4 col-lg-3">
                      <img src={global.variables.strDomainUrl + "images/logo-qure.png"} alt="Qure" title="Qure" class="img-fluid border rounded-3 mb-3 wow fadeInUp" />
                    </div>
                    <div class="col-md-8 col-lg-9 p-lg-0 ps-lg-3 ps-xxl-0">
                      <h5 class="text-dark m-0 wow fadeInUp">
                        Qure.ai <Link to="https://qure.ai"
                          target="_blank" rel="noreferrer"><small>(www.qure.ai)</small></Link>
                      </h5>
                      <p>Qure.ai is a breakthrough Artificial Intelligence (AI) solution provider that is disrupting the radiology 'status quo' by enhancing imaging accuracy and improving health outcomes with the assistance of machine-supported tools. Qure.ai taps deep learning technology to provide automated interpretation of radiology exams like X-rays, CTs and Ultrasounds scans for time and resource-strapped medical imaging professionals-enabling faster diagnosis and speed to treatment. Overall, Qure.ai is helping to make healthcare more accessible and affordable to patients worldwide. </p>
                      <p><b>Domain:</b> Internships to the students in deep learning Machine language </p>
                    </div>
                  </div>
                </div>
                {/* Qure*/}
                
                
                
                
                {/* Thought Works */}
                <div class="col py-3 border-bottom">
                  <div class="row text-start">
                    <div class="col-md-4 col-lg-3">
                      <img src={global.variables.strDomainUrl + "images/thoughtworks.png"} alt="Thought Works" title="Thought Works" class="img-fluid border rounded-3 mb-3 wow fadeInUp" />
                    </div>
                    <div class="col-md-8 col-lg-9 p-lg-0 ps-lg-3 ps-xxl-0">
                      <h5 class="text-dark m-0 wow fadeInUp">
                        Thoughtworks <small>
                          <Link to="https://www.thoughtworks.com/en-in"
                            target="_blank" rel="noreferrer">(www.thoughtworks.com/en-in)</Link>
                        </small>
                      </h5>
                      <p>Thoughtworks is a leading global technology consultancy that integrates strategy, design and software engineering to enable enterprises and technology disruptors across the globe to thrive as modern digital businesses. Thoughtworks operate in 17 countries and has over 10,000+ employees. </p>
                      <p><b>Domain:</b> Internships in AI-ML in Medical Optical Character Recognition</p>
                    </div>
                  </div>
                </div>
                {/* Thought Works */}
                <p class="small">
                  * All brand names/logos used are trademarks or registered trademarks of
                  their respective companies and are hereby acknowledged.
                </p>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>


      {/* Breadcrumb */}
      <Breadcrumb className="breadcrumb-panel mt-auto">
        <Breadcrumb.Item href={globalVariables.variables.strDomainUrl}>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Partnerships</Breadcrumb.Item>
        <Breadcrumb.Item active>Overview</Breadcrumb.Item>
      </Breadcrumb>
    </>
  );
}