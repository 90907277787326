import axios from 'axios';
import React, { useEffect,useState } from 'react'
import { useParams,Link, useNavigate } from 'react-router-dom'
import { GetCareerList } from '../../api/career-functions';
import { Container, Row, Col } from "reactstrap";
import Header from "../layout/header";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import globalVariables from '../../global-variables'
import $ from "jquery";
import WOW from "wowjs";
import Metatag from '../../hooks/Metatag';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { HiOutlineMenuAlt2 } from "react-icons/hi";

 function CareerDetails() {
 
  const navigate = useNavigate();

   let { career_pagename} = useParams();
    const  qs_career_pagename = career_pagename;
    const [careerList,setCareerList] = useState([]);
    const [categoryTypeList,setCategoryTypeList] = useState([]);
    const [getPerticularData,setPerticularData] = useState([])
    //  // success function when the list is retrived
    const OnGetCareerSuccess = (p_response) => {
    //   // set the list for looping
      setCareerList(p_response);
    }
  
    useEffect(() => {
      // add class for home header and remove inner class
      $(".fixed-top").addClass("bg-header");
  
      // initiate the wow effect
      new WOW.WOW({
        live: false,
      }).init();
  
  
    }, []);
    // things to be done when the page is loaded
    useEffect(() => {
      //make the object for getting the active career list
        let _obj = new Object();
        _obj.career_status = 1;
      // call the function to get the career
      GetCareerList(_obj,OnGetCareerSuccess,navigate);
  
    }, []);

    useEffect(()=>{

      let _obj =new Object()
      _obj.ctype_status = 1
      axios.post(globalVariables.variables.strApiUrl + "/careerApi/getcareerTypeList",_obj)
      .then((response_ctype)=>{
        if(response_ctype.data === 'error')
        {
          navigate('/error-page')
        }
        else
        {
        setCategoryTypeList(response_ctype.data[0])
        }
      })
    },[])
    
    useEffect(()=>{
      
      let _obj = new Object();
        _obj.career_status = 1;
        _obj.career_pagename = qs_career_pagename
      axios.post(globalVariables.variables.strApiUrl + "/careerApi/getCareerList",_obj)
      .then((response_perticular)=>{
        if(response_perticular.data === 'error')
        {
          navigate('/error-page')
        }
        else
        {
          setPerticularData(response_perticular.data[0])
        }
      })  
    },[qs_career_pagename])

     //variable to close left navbar on link click
    const [expanded, setExpanded] = useState(false);

    
  return (
    <>
      <Header main_className="bg-header"></Header>
      {/* {
        getPerticularData != null ? 
          <Metatag title={getPerticularData[0]?.career_pagetitle} 
            keywords={getPerticularData[0]?.career_meta_keywords} 
            description={getPerticularData[0]?.career_meta_description}/>
        :
        null

      } */}
      <Metatag title='Careers at KCDH'
              description='Careers and Jobs at Koita Centre for Digital Health'
              keywords='Careers at KCDH, Careers at Koita Centre for Digital Health, Faculty Positions at KCDH, Faculty Positions Koita Centre for Digital, Recruitment for Institute Post-Doctoral Fellow position at KCDH, Recruitment for Institute Post-Doctoral Fellow position at Koita Centre for Digital Health'
              />

      {/* career */}
      <Container fluid className="bg-visual-inside p-0" >
        <div className="visual-inside-big">
          <Row className="g-md-0">
            <Col md={6} className="order-2 order-md-1">
              <Container className="d-flex align-items-end h-100">
                <div className="d-inline-block m-0 align-self-end col-lg-6 col-xl-6 py-xl-3 p-2 text-white">
                  <h1 className="fw-light text-white m-0 align-self-center wow fadeInUp">
                    We are Hiring!
                  </h1>
                  <p className="mb-lg-0">KCDH (IITB) invites applications for Faculty Positions and Post Doc
                    Positions. Applications for adjunct faculty / short-term visits are also welcome.
                  </p>
                </div>
              </Container>
            </Col>
            <div className="col-md-6 order-1 order-md-2">
              <img src={globalVariables.variables.strDomainUrl + "images/visual-careers.jpg"} className="img-fluid" alt="" title="" />
            </div>
          </Row>
        </div>
      </Container>

      <Container className="my-5 careers" >
        <Row>
        <Col md={4} lg={3} className="mb-3">
          <Navbar expanded={expanded} collapseonselect expand="md" className='d-block p-0'>
            <Navbar.Toggle  onClick={() => setExpanded(expanded ? false : "expanded")} className="offcanvas-toggle shadow-none w-100 animated fadeInUp" aria-controls={'offcanvas-menu'}>
                <Link className="btn btn-outline-secondary rounded-pill px-4 py-2 w-100 text-start d-flex justify-content-between align-items-center d-md-none">{getPerticularData[0]?.career_name}<HiOutlineMenuAlt2 /></Link>
            </Navbar.Toggle>
              <Navbar.Offcanvas id={'offcanvasTop'} aria-labelledby={'offcanvasTop'} placement="start" responsive="md" className="p-md-0 p-2 d-block">
                <Offcanvas.Header closeButton onClick={() => setExpanded(false)}>
                    <Offcanvas.Title>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <ul className='left-navbar bg-white py-md-2 py-3 px-2 w-100 mb-3'>
                  <div className="nav flex-column me-3 mb-5 pb-5" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                    <Link to={globalVariables.variables.strDomainUrl + 'careers'} className="nav-link ">Why KCDH, IIT-B?</Link>
                    {
                      categoryTypeList.map((val_ctype)=>{
                        return(
                          <>
                          {
                             // career_count is grater than 0 than bind career_type title
                            val_ctype.career_count > 0  ? 
                            <>
                            <button className="nav-link" data-bs-toggle="" data-bs-target="" type="button" role="tab" aria-controls="" aria-selected="false" disabled> {val_ctype.ctype_name}</button>
                            {
                              careerList.map((val_career)=>{

                                return(
                                  <>
                                  {
                                    
                                    //check ctype id and career ctype id
                                    val_ctype.ctype_id === val_career.career_ctype_id ? 

                                      //check if qs_career_pagename value is equal to val_career.career_pagename then add class active
                                        qs_career_pagename === val_career.career_pagename ? 
                                        <Link onClick={() => setExpanded(false)} to={globalVariables.variables.strDomainUrl + "careers/" + val_career.career_pagename} id={"lnk_" + val_career.career_pagename} className="nav-link nav-sublink Faicon14 active " >{val_career.career_name}</Link>:

                                        <Link onClick={() => setExpanded(false)} to={globalVariables.variables.strDomainUrl + "careers/" + val_career.career_pagename} id={"lnk_" + val_career.career_pagename} className="nav-link nav-sublink Faicon14 " >{val_career.career_name}</Link>
                                      
                                    : null
                                  }
                                  
                                  </>
                                  
                                )

                              })
                            }
                            </>
                            :
                            null
                          }
                            
                          
                          </>
                          )
                        })
                    }
                    
                  </div>

                  <Link to="https://docs.google.com/forms/d/e/1FAIpQLScxzg5kSooRj7alAuWutXhoDvWY5yM_sVxRfNQl5n0Gr4HuOQ/viewform" target="_blank">
                    <div className="bg-light p-4 rounded-3 mt-5">
                      <div className="icon-circle-110 bg-primary d-flex align-items-center justify-content-center m-auto mb-3 icon-mt-n80 border border-white border-5 wow fadeInUp">
                        <img src={globalVariables.variables.strDomainUrl + "images/icon-interest.png"} alt="" title="" className="img-fluid px-3 wow fadeInUp" />
                      </div>
                      <h5 className="text-dark text-center">
                        Submit Interest
                      </h5>
                    </div>
                  </Link>
                </ul>
            </Navbar.Offcanvas>
          </Navbar>
        </Col>
      
          <Col  md={8} lg={9} className="ps-md-4" >
          <div className="tab-content border rounded-3 p-4" id="v-pills-tabContent">
              {
                getPerticularData.map((val)=>{
                  return(
                      <div
                        className="tab-pane fade show active" id="Why-KCDH-IIT-B-tab" role="tabpanel" aria-labelledby="Why-KCDH-IIT-B" tabindex="0"
                      >
                                  <div id="collapseOne" className=" show active collapse d-lg-block" data-bs-parent="#v-pills-tabContent">
                                    <div className="accordion-body">
                                      <h6 class="fw-bold text-dark mb-3">{val.career_name}</h6>
                                      {
                                        val.career_location != null && val.career_location !=''?
                                        <p ><b class="text-dark">Job Location:</b> {val.career_location}</p>
                                        :
                                        null
                                      }
                                      {
                                        val.career_desc != null && val.career_desc != ''?
                                        <span className='projects list-group list-group-flush' dangerouslySetInnerHTML={{__html : val.career_desc}}></span>
                                        :
                                        null
                                      }
                                      {
                                        val.career_apply_url != null && val.career_apply_url !=''?
                                        <Link to={val.career_apply_url} target="_blank" className="btn btn-warning rounded-pill px-4 text-uppercase fw-bold btn-sm py-2 mb-4 d-inline-block">
                                          Apply Here
                                        </Link>
                                        :
                                        null
                                      }
                                    </div>
                                  </div>
                      </div>
                  )
                })
              }

          </div>
          </Col>
        </Row>
      </Container>
      
      {/* Breadcrumb */}
      <Breadcrumb className="breadcrumb-panel mt-auto">
        <Breadcrumb.Item href={globalVariables.variables.strDomainUrl}>Home</Breadcrumb.Item>
        <Breadcrumb.Item href={globalVariables.variables.strDomainUrl +'careers'}>Careers</Breadcrumb.Item>
        <Breadcrumb.Item active>{getPerticularData[0]?.career_name}</Breadcrumb.Item>
      </Breadcrumb>
    
    </>
  )
}
export default CareerDetails;