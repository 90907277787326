import React, { useState, useEffect } from "react";
import global from "../../global-variables";
import Header from "../layout/header";
import { Link } from "react-router-dom";
import $ from "jquery";
import WOW from "wowjs";

import { Container, Row, Col } from "reactstrap";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import KCDHPartnersNav from "../layout/kcdhpartners-nav";
import Metatag from "../../hooks/Metatag";
import globalVariables from "../../global-variables";


export default function GovernmentOrganizations() {
  const [show, setShow] = useState(false);

  useEffect(() => {
    // add class for home header and remove inner class
    $(".fixed-top").addClass("bg-header");

    // initiate the wow effect
    new WOW.WOW({
      live: false,
    }).init();


  }, []);


  return (
    <>
      <Header main_className="bg-header"></Header>

      <Metatag title='KCDH Partners | KCDH Internship Partners'
               description='KCDH Partners and Internship Partners for Digital Health'
               keywords="KCDH Partners, KCDH Internship Partners, KCDH Digital Health Partners"
               />
      {/* Visual Panel */}
      <Container fluid className="bg-visual-inside p-0" >
        <div className="visual-inside-big">
          <Row className="g-md-0">
            <Col md={6} className="order-2 order-md-1">
              <Container className="d-flex align-items-end h-100">
                <div className="d-inline-block m-0 align-self-end col-lg-6 col-xl-6 py-xl-3 p-2 text-white">
                  <h1 className="fw-light text-white m-0 align-self-center wow fadeInUp">
                    KCDH Partners
                  </h1>
                </div>
              </Container>
            </Col>
            <div className="col-md-6 order-1 order-md-2">
              <img src={global.variables.strDomainUrl + "images/visual-partnership.jpg"} className="img-fluid" alt="" title="" />
            </div>
          </Row>
        </div>
      </Container>


      {/* Container */}
      <Container className="my-5 position-relative">
        <Row>
          <Col md={4} lg={3} className="mb-3">
            <KCDHPartnersNav submenutitle="NGOs, NPOs & Government Organizations" ngos_className="active"></KCDHPartnersNav>
          </Col>
          <Col md={8} lg={9} className="ps-md-4">
            <div className="rounded-3 border p-4">
              <h2 className="text-primary">NGOs, NPOs & Government Organizations</h2>
              <Row className="row-cols-1 g-3">
                {/* 10bedICU  */}
                <div class="col py-3 border-bottom">
                  <div class="row text-start">
                    <div class="col-md-4 col-lg-3">
                      <img src={global.variables.strDomainUrl + "images/logo-10bedicu.png"} alt="10bedICU" title="10bedICU" class="img-fluid border rounded-3 mb-3 wow fadeInUp" />
                    </div>
                    <div class="col-md-8 col-lg-9 p-lg-0 ps-lg-3 ps-xxl-0">
                      <h5 class="text-dark m-0 wow fadeInUp">
                        10bedICU <Link to="https://10bedicu.org/" target="_blank" rel="noreferrer"><small>(www.10bedicu.org)</small></Link>
                      </h5>
                      <p>The 10BedICU project was started in Mar 2021 in the wake of the Covid-19 delta wave spike in India, in order to create critical care ICU infrastructure in rural and smaller government hospitals. Since then they have created over 200 10BedICUs in government hospitals in 9 states of the country. This is a PPP private-public-partnership, where the 10BedICU group finances the capital expenditure of the project by providing the ICU medical equipment, CARE software system, the TeleICU technology infrastructure, clinical and systems training and community participation support. </p>
                      <p><b>Domain:</b> Joint research on re-development of large language models driven clinical assistant </p>
                    </div>
                  </div>
                </div>
                {/* 10bedICU  */}
                {/* Access Health */}
                <div class="col py-3 border-bottom">
                  <div class="row text-start">
                    <div class="col-md-4 col-lg-3">
                      <img src={global.variables.strDomainUrl + "images/logo-accessh.png"} alt="Access Health" title="Access Health" class="img-fluid border rounded-3 mb-3 wow fadeInUp" />
                    </div>
                    <div class="col-md-8 col-lg-9 p-lg-0 ps-lg-3 ps-xxl-0">
                      <h5 class="text-dark m-0 wow fadeInUp">
                        Access Health International <Link to="https://accessh.org/" target="_blank" rel="noreferrer"><small>(www.accessh.org)</small></Link>
                      </h5>
                      <p>ACCESS Health designs and implements health initiatives that tangibly improve health outcomes and the quality of care across the globe. It is a think tank, advisory group, and implementation partner for 20+ International projects, 100+global partners and 125+ publications.</p>
                      <p><b> Domain:</b> Internships in development of AI-based assistant tool to convert doctor speech</p>
                    </div>
                  </div>
                </div>
                {/* Access Health */}
                {/* Bill and Melinda Gates Foundation */}
                <div class="col py-3 border-bottom">
                  <div class="row text-start">
                    <div class="col-md-4 col-lg-3">
                      <img src={global.variables.strDomainUrl + "images/logo-bmgf.png"} alt="Bill and Melinda Gates Foundation" title="Bill and Melinda Gates Foundation" class="img-fluid border rounded-3 mb-3 wow fadeInUp" />
                    </div>
                    <div class="col-md-8 col-lg-9 p-lg-0 ps-lg-3 ps-xxl-0">
                      <h5 class="text-dark m-0 wow fadeInUp">
                        Bill and Melinda Gates Foundation <Link to="https://www.gatesfoundation.org/"
                          target="_blank" rel="noreferrer"><small>(www.gatesfoundation.org)</small></Link>
                      </h5>
                      <p>Guided by the belief that every life has equal value, the Bill & Melinda Gates Foundation works to help all people lead healthy, productive lives. Based in Seattle, Washington, the foundation is led by CEO Mark Suzman, under the direction of co-chairs Bill Gates and Melinda French Gates and the board of trustees.</p>
                      <p><b>Domain:</b> Established the National Disease Modelling Consortium More details at: <a href="https://www.ndmconsortium.com/" target="_blank" rel="nooperner">www.ndmconsortium.com</a>  </p>
                    </div>
                  </div>
                </div>
                {/* Bill and Melinda Gates Foundation */}
                {/* IHAT */}
                <div class="col py-3 border-bottom">
                  <div class="row text-start">
                    <div class="col-md-4 col-lg-3">
                      <img src={global.variables.strDomainUrl + "images/logo-ihat.png"} alt="IHAT" title="IHAT" class="img-fluid border rounded-3 mb-3 wow fadeInUp" />
                    </div>
                    <div class="col-md-8 col-lg-9 p-lg-0 ps-lg-3 ps-xxl-0">
                      <h5 class="text-dark m-0 wow fadeInUp">
                        IHAT <Link to="https://www.ihat.in/"
                          target="_blank" rel="noreferrer"> <small>(www.ihat.in)</small></Link>
                      </h5>
                      <p>India Health Action Trust (IHAT) works towards reducing inequities by developing comprehensive and sustainable programs to improve population health. IHAT works closely with the Government to achieve its public health goals in areas of prevention and control of HIV and Tuberculosis, in achieving significant improvements in Reproductive, Maternal, Neonatal and Child Health, improved Nutrition among mothers and children and strengthening health systems.</p>
                      <p><b>Domain:</b> Epidemiology and Digital Health System, Opportunity to work with organizations with large public health systems.</p>
                    </div>
                  </div>
                </div>
                {/* IHAT */}
                {/* International Innovation Corps */}
                <div class="col py-3 border-bottom">
                  <div class="row text-start">
                    <div class="col-md-4 col-lg-3">
                      <img src={global.variables.strDomainUrl + "images/logo-international-innovation.png"} alt="International Innovation Corps" title="International Innovation Corps" class="img-fluid border rounded-3 mb-3 wow fadeInUp" />
                    </div>
                    <div class="col-md-8 col-lg-9 p-lg-0 ps-lg-3 ps-xxl-0">
                      <h5 class="text-dark m-0 wow fadeInUp">
                        International Innovation Corps
                        <Link to="http://www.iic.uchicago.edu/" target="_blank" rel="noreferrer"> <small>(www.iic.uchicago.edu)</small></Link>
                      </h5>
                      <p>The International Innovation Corps (IIC) is a social impact fellowship program based at the University of Chicago’s Harris School of Public Policy. IIC identifies and mentors zealous young professionals to partner with the public and social sector in solving complex development challenges with a pragmatic and data-driven approach. The IIC is a year-long structured fellowship and currently operates out of India and the United States.</p>
                      <p> <b>Domain:</b> Collaboration on training programmes</p>
                    </div>
                  </div>
                </div>
                {/* International Innovation Corps */}
                {/* The INCLEN Trust International */}
                <div class="col py-3 border-bottom">
                  <div class="row text-start">
                    <div class="col-md-4 col-lg-3">
                      <img src={global.variables.strDomainUrl + "images/logo-inclen.png"} alt="The INCLEN Trust International" title="The INCLEN Trust International" class="img-fluid border rounded-3 mb-3 wow fadeInUp" />
                    </div>
                    <div class="col-md-8 col-lg-9 p-lg-0 ps-lg-3 ps-xxl-0">
                      <h5 class="text-dark m-0 wow fadeInUp">
                        The INCLEN Trust International <small>
                          <Link to="http://inclentrust.org/inclen/"
                            target="_blank" rel="noreferrer">(www.inclentrust.org/inclen/)</Link>
                        </small>
                      </h5>
                      <p><b>The INCLEN Trust International</b> is a not for profit research organization conducting collaborative, multi-disciplinary studies on high priority global health issues. INCLEN have Clinical Epidemiology Units (CEU) and Clinical Epidemiology Research Training Centre (CERTC’s) as core functional units, located in 89 academic institutions in 34 countries. </p>
                      <p>
                        <b>Domain:</b> Joint research on Harmonized One-health Trans-Species Transmission of Antibiotics Resistance and levers for action using System Dynamics Modelling (HOTSTAR-SDM)
                      </p>
                    </div>
                  </div>
                </div>
                {/* The INCLEN Trust International */}
                
                
                {/* UNICEF */}
                <div class="col py-3 border-bottom">
                  <div class="row text-start">
                    <div class="col-md-4 col-lg-3">
                      <img src={global.variables.strDomainUrl + "images/logo-unicef.png"} alt="UNICEF" title="UNICEF" class="img-fluid border rounded-3 mb-3 wow fadeInUp" />
                    </div>
                    <div class="col-md-8 col-lg-9 p-lg-0 ps-lg-3 ps-xxl-0">
                      <h5 class="text-dark m-0 wow fadeInUp">
                        UNICEF <small>
                          <Link to="https://www.unicef.org/india/"
                            target="_blank" rel="noreferrer">(www.www.unicef.org/india/)</Link>
                        </small>
                      </h5>
                      <p>UNICEF is fully committed to working with the Government of India to ensure that each child born in this vast and complex country gets the best start in life, thrives and develops to his or her full potential. UNICEF has been working in India since 1949 advocating for the rights of children and young people, and currently has a presence in 16 states.</p>
                      <p><b>Domain:</b> HMIS Analyzer: Visualization Dashboard for Health data</p>
                    </div>
                  </div>
                </div>
                {/* UNICEF */}
                
                {/* Wadhwani AI */}
                <div class="col py-3 border-bottom">
                  <div class="row text-start">
                    <div class="col-md-4 col-lg-3">
                      <img src={global.variables.strDomainUrl + "images/logo-wadhwani.png"} alt="Wadhwani AI" title="Wadhwani AI" class="img-fluid border rounded-3 mb-3 wow fadeInUp" />
                    </div>
                    <div class="col-md-8 col-lg-9 p-lg-0 ps-lg-3 ps-xxl-0">
                      <h5 class="text-dark m-0 wow fadeInUp">
                        Wadhwani AI <small>
                          <Link to="https://www.wadhwaniai.org"
                            target="_blank" rel="noreferrer">(www.wadhwaniai.org)</Link>
                        </small>
                      </h5>
                      <p>Wadhwani AI, also known as the Wadhwani Institute for Artificial Intelligence, is an Indian organization dedicated to advancing AI research and development.  In healthcare, Wadhwani AI is actively developing AI solutions for medical imaging, disease prediction, preventive healthcare, and healthcare management systems. Wadhwani AI aims to revolutionize healthcare and contribute significantly to the advancement of the healthcare industry.</p>
                      <p><b>Domain:</b> Joint research on development of  gut microbiome informatics Tool for non communicable diseases</p>
                    </div>
                  </div>
                </div>
                {/* Wadhwani AI */}
                <p class="small">
                  * All brand names/logos used are trademarks or registered trademarks of
                  their respective companies and are hereby acknowledged.
                </p>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>


      {/* Breadcrumb */}
      <Breadcrumb className="breadcrumb-panel mt-auto">
        <Breadcrumb.Item href={globalVariables.variables.strDomainUrl}>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Partnerships</Breadcrumb.Item>
        <Breadcrumb.Item active>Overview</Breadcrumb.Item>
      </Breadcrumb>
    </>
  );
}