import React, { useState, useEffect } from "react";
import global from "../../global-variables";
import Header from "../layout/header";
import { Link } from "react-router-dom";
import $ from "jquery";
import WOW from "wowjs";

import { Container, Row, Col } from "reactstrap";
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import globalVariables from "../../global-variables";
import Metatag from '../../hooks/Metatag'

export default function Internships(props) {
  const submenutitle = props.submenutitle;

  const [show, setShow] = useState(false);
  const [getcourseid, setCourseid] = useState('');
  const handleClose = () => setShow(false);

  useEffect(() => {
    // add class for home header and remove inner class
    $(".fixed-top").addClass("bg-header");

    // initiate the wow effect
    new WOW.WOW({
      live: false,
    }).init();

  }, []);

  return (
    <>
      <Header main_className="bg-header"></Header>

      <Metatag title='KCDH Internships | Summer Internships | In-Semester Projects'
               description='Summer Internships and In-Semester Projects for Digital Health Academic Programs by KCDH'
               keywords="KCDH Internships, Digital Health Summer Internships, Digital Health In-Semester Projects, Internships for Digital Health Programs, Internships for Digital Health Students"
               />

      {/* Visual Panel */}
      <Container fluid className="bg-visual-inside p-0" >
        <div className="visual-inside-big">
          <Row className="g-md-0">
            <Col md={6} className="order-2 order-md-1">
              <Container className="d-flex align-items-end h-100">
                <div className="d-inline-block m-0 align-self-end col-lg-6 col-xl-6 py-xl-3 p-2 text-white">
                  <h1 className="fw-light text-white m-0 align-self-center wow fadeInUp">
                    KCDH Internships
                  </h1>
                </div>
              </Container>
            </Col>
            <div className="col-md-6 order-1 order-md-2">
              <img src={global.variables.strDomainUrl + "images/visual-students-corner.jpg"} className="img-fluid" alt="" title="" />
            </div>
          </Row>
        </div>
      </Container>


      {/* Container */}
      <Container className="my-5 position-relative">
        <Row>
          <Col md={4} lg={3} className="mb-3">
            <Navbar collapseonselect expand="xl" className='d-block p-0'>
              <Navbar.Toggle className="offcanvas-toggle shadow-none w-100 animated fadeInUp" aria-controls={'offcanvas-menu'}>
                <Link className="btn btn-outline-secondary rounded-pill px-4 py-2 w-100 text-start d-flex justify-content-between align-items-center d-md-none">Internship Opportunities <HiOutlineMenuAlt2 /></Link>
              </Navbar.Toggle>
              <Navbar.Offcanvas id={'offcanvasTop'} aria-labelledby={'offcanvasTop'} placement="start" responsive="xl" className="p-md-0 p-2 d-block">
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title>
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <ul className='left-navbar bg-white rounded-5 py-md-0 py-3 px-2 w-100'>
                  <li><Link to="/kcdh-internships" className="active">Internship Opportunities</Link></li>
                  <li><Link to="/kcdhpartners">Internship Partners</Link></li>
                </ul>
              </Navbar.Offcanvas>
            </Navbar>
          </Col>
          <Col md={8} lg={9} className="ps-md-4">
            <div className="rounded-3 border p-4">
              <h2 className="text-primary">Internship Opportunities</h2>

              <p class="text-start">The Healthcare Informatics curriculum at Koita Centre for Digital
                health at IITB prepares students for careers in a specialized range of professions in
                healthcare. KCDH provides a whole spectrum of internship opportunities in different
                fields like Healthcare software companies, Medical device companies, Life Sciences /
                Pharma cos., Health insurance organizations etc. The Internship will help students
                expand their understanding of a health-related environment and gain practical experience
                in applying theory and method while working with a health informatics team. This
                internship is an opportunity for a proactive individual looking for a productive and
                meaningful learning experience on a fast-paced and closely collaborative team.</p>
              <p class="mb-4">
                For more details about available projects: - Reach out to us at <img src={global.variables.strDomainUrl + "images/email.png"} class="img-fluid" width="276" height="18" alt="" title="" />
              </p>
              <h5 class="text-dark wow fadeInUp text-start">Summer Internships</h5>
              <p class="mb-4 text-start">KCDH provides summer internships in the top most Healthcare
                Informatics companies, which typically takes place in the month of May, run for eight to
                twelve weeks long and can be full or part-time. The summer internships are paid
                internships and these short-term experiences provide a real insight into what it’s
                actually like working in a particular job or healthcare informatics field. Bachelor’s
                students interested in the field of healthcare informatics from any branch and any
                academic year at IITB can apply.</p>
              <h5 class="text-dark wow fadeInUp text-start">In-Semester Projects</h5>
              <p class="mb-4 text-start">KCDH also provides In-Semester Projects which take place during
                the semester (typically from Jan to April or July to Nov). Students give a few hours
                along with their semester. The in-semester projects can be paid/unpaid depending on the
                project and time required. Bachelor’s students interested in the field of healthcare
                informatics from any branch and any academic year can apply. </p>
            </div>
          </Col>
        </Row>
      </Container>


      {/* Breadcrumb */}
      <Breadcrumb className="breadcrumb-panel mt-auto px-3">
        <Breadcrumb.Item to={globalVariables.variables.strDomainUrl}>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Students' Corner</Breadcrumb.Item>
        <Breadcrumb.Item active>KCDH Internships</Breadcrumb.Item>
      </Breadcrumb>
    </>
  );
}