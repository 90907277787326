import React, { useState, useEffect } from "react";
import global from "../../global-variables";
import Header from "../layout/header";
import { Link ,useNavigate} from "react-router-dom";
import $ from "jquery";
import WOW from "wowjs";
import { GetResearchCategoryList } from "../../api/research-category-functions";
import { Container, Row, Col } from "reactstrap";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import globalVariables from "../../global-variables";
import Metatag from "../../hooks/Metatag";


export default function Research() {
  
  const navigate = useNavigate();
  const [getrcategoryList,setRcategoryList] = useState(null);
  

  const OnGetResearchCategorySuccess = (p_response) => 
    {

      // set the list for looping
      setRcategoryList(p_response);
}
  // things to be done when the page is loaded
  useEffect(() => {

    // make the object for getting the research category list
    let _obj = new Object();
    _obj.rcategory_status = 1;

    // call the function to get the research category
    GetResearchCategoryList(_obj, OnGetResearchCategorySuccess,navigate);

}, []);
  useEffect(() => {
    // add class for home header and remove inner class
    $(".fixed-top").addClass("bg-header");

  }, []);

  useEffect(()=>{
     // initiate the wow effect
     new WOW.WOW({
      live: false,
    }).init();
  },[getrcategoryList])

  return (
    <>
      <Header main_className="bg-header"></Header>
      {/* MetaTag */}
      
        <Metatag title='Research Projects in Digital Healthcare at KCDH'
                 description='The research activities at KCDH can be classified into Healthcare Applications, Healthcare Data Management, Healthcare Analytics and AI/ML, Consumer Health & Tele-medicine, Computational Biology & Bioinformatics, Population Health & Public Health Policy'
                 keywords="Research Projects at KCDH, Automated feature selection for biomarker discovery from big biological data (IDDDP), Addressing Class Imbalance in Medical Image Data: Finding Needle in a Haystack (IDDDP), Deep active learning for medical image classification (IDDDP), Semi supervised learning in Medical image data (IDDDP), Disparities in access to and utilization of healthcare services in India (IDDDP), Advancing causal inference methods in health economics and health services research (IDDDP), Implementation of an alert system for early diagnosis of Acute Kidney Injury (IDDDP)"
                 />
        

        {/* Visual Panel */}
        <Container fluid className="bg-visual-inside p-0" >
          <div className="visual-inside-big">
            <Row className="g-md-0">
              <Col md={6} className="order-2 order-md-1">
                <Container className="d-flex align-items-end h-100">
                  <div className="d-inline-block m-0 align-self-end col-lg-6 col-xl-6 py-xl-3 p-2 text-white">
                    <h1 className="fw-light text-white m-0 align-self-center wow fadeInUp">
                      Overview
                    </h1>
                  </div>
                </Container>
              </Col>
              <div className="col-md-6 order-1 order-md-2">
                <img src={global.variables.strDomainUrl + "images/visual-research2.jpg"} className="img-fluid" alt="" title="" />
              </div>
            </Row>
          </div>
        </Container>

        {
          getrcategoryList != null ?
            getrcategoryList.length > 0 ?

              <Container className="my-5">
                <div className="m-auto text-center mb-4">
                  <h4 className="text-primary wow fadeInUp">The research activities and interests of the centre can be broadly classified into the following areas:</h4>
                </div>
                <Row
                  className="row-cols-1 row-cols-md-2 row-cols-lg-3 g-3 g-xl-4 g-xxl-4 pb-3">
                  {
                    getrcategoryList.map((val)=>{
                      return(
                        <Col>
                          <div className="bg-light p-4 rounded-3 h-100 position-relative overflow-hidden list d-flex flex-column justify-content-center">
                            
                            <img src={global.variables.strApiUrl + "/researchcategory-image/" + val.rcategory_image} width="380" height="175" alt={val.rcategory_name} title={val.rcategory_name} className="img-fluid rounded-3 border border-white border-4 wow fadeIn" />
                            
                            <h6 className="px-md-3 pt-3 mb-1 text-dark wow fadeInUp">
                              {val.rcategory_name}
                            </h6>
                            {
                              val.rcategory_description != null && val.rcategory_description != ''?
                              <p className="mb-3 px-md-3 wow fadeInUp"><span className="wow fadeInUp" dangerouslySetInnerHTML={{ __html: val.rcategory_description }}></span></p>
                              :
                              null
                            }
                            <div className="mt-auto">
                              <Link to={globalVariables.variables.strDomainUrl + "research/" + val.rcategory_pagename}
                                className="btn btn-warning rounded-pill px-4 fw-bold btn-sm py-2 mt-2 mx-1">View
                                Projects</Link>
                            </div>
                           
                          </div>
                        </Col>
                      )

                    })
                  }
                </Row>
              </Container>
            :
              <Container className="my-5 h-100">
                <Row>
                    <span className="text-center">No Records Found</span>
                </Row>
            </Container>
          :
          <Container className="my-5 h-100">
            <Row>
                <span className="text-center">Loading...</span>
            </Row>
        </Container>
      }

      {/* Breadcrumb */}
      <Breadcrumb className="breadcrumb-panel mt-auto">
        <Breadcrumb.Item href={globalVariables.variables.strDomainUrl}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Research</Breadcrumb.Item>
        <Breadcrumb.Item active>Overview</Breadcrumb.Item>
      </Breadcrumb>
    </>
  );
}