import React, { useState, useEffect } from "react";
import global from "../../global-variables";
import Header from "../layout/header";
import { Link, useParams, useNavigate } from "react-router-dom";
import $ from "jquery";
import WOW from "wowjs";
import { Container, Row, Col } from "reactstrap";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Modal from 'react-bootstrap/Modal';

import axios from "axios";
import globalVariables from "../../global-variables";
import MappedCategoryNav from "../layout/mappedCategory-nav";
import Metatag from "../../hooks/Metatag";

export default function MappedResearch() {

  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [getResearchid, setResearchid] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = (e, p_res_id) => {
    e.preventDefault();
    setShow(true);
    //get faculty id
    setResearchid(p_res_id);
  }

  let {rcategory_pagename} = useParams()
  const qs_rcategory_pagename = rcategory_pagename;

  const [getresearchTypeList,setResearchTypeList] = useState(null)
  

  // things to be done when the page is loaded
  useEffect(() => {

    // make the object for getting the research type list
    let _obj = new Object();
    _obj.rtype_status = 1;
    axios.post(globalVariables.variables.strApiUrl + '/researchApi/researchTypeList',_obj)
      .then((response)=>{
        if(response.data === 'error')
        {
          navigate('/error-page')
        }
        else
        {
        setResearchTypeList(response.data[0])
        }
      })

  }, []);

  const [researchCategory,setResearchCategoryList] = useState(null)
    useEffect(()=>{
       let obj = new Object()
       obj.rcategory_status = 1
       
       axios.post(globalVariables.variables.strApiUrl + "/rcategoryApi/getrcategoryList",obj)
       .then((response)=>{
        if(response.data === 'error')
        {
          navigate('/error-page')
        }
        else
        {
            setResearchCategoryList(response.data[0])
        }
       })
    },[])

  const [mappedresearchList,setMappedResearchList]= useState([]);
  // things to be done when the page is loaded
  useEffect(() => {

    // make the object for getting the research type list
    let _obj = new Object();
    _obj.rtype_status = 1;
    _obj.rcategory_pagename = qs_rcategory_pagename
    axios.post(globalVariables.variables.strApiUrl + '/researchApi/getmappedResearch',_obj)
      .then((response)=>{
        if(response.data === 'error')
        {
          navigate('/error-page')
        }
        else
        {
        setMappedResearchList(response.data[0])
        }
      })

  }, [qs_rcategory_pagename]);


    const [perticularResearchCategoryList,setPerticularResearchCategoryList] = useState()
  useEffect(() => {

    // make the object for getting the perticular research category list
    let _obj = new Object();
    _obj.rcategory_status = 1;
    _obj.rcategory_pagename = qs_rcategory_pagename
    axios.post(globalVariables.variables.strApiUrl + '/rcategoryApi/getrcategoryList',_obj)
      .then((response)=>{
        if(response.data === 'error')
        {
          navigate('/error-page')
        }
        else
        {
        setPerticularResearchCategoryList(response.data[0])
        }
      })

  }, [qs_rcategory_pagename]);
  


  useEffect(() => {
    // add class for home header and remove inner class
    $(".fixed-top").addClass("bg-header");

    // initiate the wow effect
    new WOW.WOW({
      live: false,
    }).init();


  }, [mappedresearchList]);


  return (
    <>
      <Header main_className="bg-header"></Header>

      {/* MetaTag */}
      {/* {
        perticularResearchCategoryList != undefined ?
          <Metatag title={perticularResearchCategoryList[0]?.rcategory_pagetitle} keywords={perticularResearchCategoryList[0]?.rcategory_meta_keywords} description={perticularResearchCategoryList[0]?.rcategory_meta_description}/>
        :
        null
      } */}
      <Metatag title='Digital Health Research Projects | KCDH Research Projects'
               description='Students Research Projects and Sponsored Research Projects in Digital Health by Koita Centre for Digital Health'
               keywords='Digital Health Research Projects, KCDH Research Projects, Students Research Projects in Digital Health, Sponsored Research Projects in Digital Health, Research Projects by Koita Centre for Digital Health, Research Projects by KCDH Faculty, Research Projects by KCDH Partners'
               />
      {/* Visual Panel */}
      <Container fluid className="bg-visual-inside p-0" >
        <div className="visual-inside-big">
          <Row className="g-md-0">
            <Col md={6} className="order-2 order-md-1">
              <Container className="d-flex align-items-end h-100">
                <div className="d-inline-block m-0 align-self-end col-lg-6 col-xl-6 py-xl-3 p-2 text-white">
                  <h1 className="fw-light text-white m-0 align-self-center wow fadeInUp">
                    KCDH Projects
                  </h1>
                </div>
              </Container>
            </Col>
            <div className="col-md-6 order-1 order-md-2">
              <img src={global.variables.strDomainUrl + "images/visual-research2.jpg"} className="img-fluid" alt="" title="" />
            </div>
          </Row>
        </div>
      </Container>

      {/* Container */}
      
            <Container className="my-5 position-relative">
              <Row>
                <Col md={4} lg={3} className="mb-3">
                  {
                    perticularResearchCategoryList != undefined ?
                    <MappedCategoryNav researchCategory={researchCategory} 
                    qs_rcategory_pagename={qs_rcategory_pagename}  
                    submenutitle={perticularResearchCategoryList[0]?.rcategory_name} 
                    getresearchTypeList={getresearchTypeList}></MappedCategoryNav>
                    :
                    null
                  }
                </Col>
                <Col md={8} lg={9} className="ps-md-4">
                  {
                    researchCategory != null ?
                      researchCategory.length > 0 ?
                        <Row className="row-cols-1 g-3 px-2 px-md-0">

                          {
                            // mappedresearchList.length > 0 ?
                            mappedresearchList.map((val_mapped_research)=>{
                                return(
                                  <>
                                    {
                                        <Col className="border p-4 rounded-3 h-100 my-3">
                                          <div>
                                            <h5 className="text-dark wow fadeInUp">
                                              {val_mapped_research.res_title}
                                            </h5>
                                            <ul className="list-group list-group-flush px-0 mb-3 projects">

                                            {
                                              val_mapped_research.res_kcdh_faculty != null && val_mapped_research.res_kcdh_faculty !=''?
                                              <li className="list-group-item border-0 p-0 wow fadeInUp">
                                                <b>KCDH Faculty:</b> <span dangerouslySetInnerHTML={{ __html: val_mapped_research.res_kcdh_faculty }}></span></li>
                                              :
                                              null
                                            }
                                            {
                                              val_mapped_research.res_kcdh_partner != null && val_mapped_research.res_kcdh_partner !=''?
                                              <li className="list-group-item border-0 p-0 wow fadeInUp">
                                                <b>KCDH Partner:</b> <span dangerouslySetInnerHTML={{ __html: val_mapped_research.res_kcdh_partner }}></span></li>
                                              :
                                              null
                                            }
                                            {
                                              val_mapped_research.res_supervisor != null && val_mapped_research.res_supervisor !=''?
                                              <li className="list-group-item border-0 p-0 wow fadeInUp">
                                                <b>Supervisor:</b> <span dangerouslySetInnerHTML={{ __html: val_mapped_research.res_supervisor }}></span></li>
                                              :
                                              null
                                            }
                                            {
                                              val_mapped_research.res_co_supervisor != null && val_mapped_research.res_co_supervisor !=''?
                                              <li className="list-group-item border-0 p-0 wow fadeInUp">
                                                <b>Co-supervisor:</b> <span dangerouslySetInnerHTML={{ __html: val_mapped_research.res_co_supervisor }}></span></li>
                                              :
                                              null
                                            }
                                            
                                            </ul>
                                            <Link onClick={(e) => handleShow(e,val_mapped_research.res_id)}
                                              className="btn btn-warning rounded-pill px-4 text-uppercase fw-bold btn-sm py-2 mt-auto mt-2 research-projects">
                                              Read
                                              More
                                            </Link>
                                          </div>
                                        </Col>
                                      
                                    }
                                  </>
                                )
                              })
                            // :
                            //   <Col className="border p-4 rounded-3 h-100 my-3 text-center">
                            //     <span className="text-center"><b>No records found</b></span>
                            //   </Col>
                          }
                        </Row>
                      :
                      <Col className="border p-4 rounded-3 h-100 my-3 text-center">
                        <span className="text-center"><b>No records found</b></span>
                      </Col>
                    :
                      <Col className="border p-4 rounded-3 h-100 my-3 text-center">
                        <span className="text-center">Loading...</span>
                      </Col>
                  }
                </Col>
              </Row>
            </Container>

      {/* Breadcrumb */}
      <Breadcrumb className="breadcrumb-panel mt-auto">
        <Breadcrumb.Item href={globalVariables.variables.strDomainUrl}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Research</Breadcrumb.Item>
        <Breadcrumb.Item active>KCDH Projects</Breadcrumb.Item>
      </Breadcrumb>


      {/* PROJECT DETAILS */}
      <Modal show={show} size="xl" scrollable centered onHide={handleClose} dialogClassName="p-2 popup">
        <Modal.Header closeButton className="border-0 align-items-start">
        </Modal.Header>
        <Modal.Body>
          {
            mappedresearchList.map((val) => {
              return (

                <>
                  {
                    val.res_id == getResearchid ?
                      <>
                        <Row className='row-cols-1 p-md-4 p-2' key={val.res_id}>
                          <Col>
                            <h5 className="text-dark">{val.res_title}</h5>
                            <ul className="list-group list-group-flush px-0 mb-3 projects">

                            {
                              val.res_kcdh_faculty != "" && val.res_kcdh_faculty != null ?
                              <li className="list-group-item border-0 p-0 wow fadeInUp">
                                  <b>KCDH Faculty:</b> <span dangerouslySetInnerHTML={{ __html: val.res_kcdh_faculty }}></span>
                                </li> :
                                null
                            }
                            {
                              val.res_kcdh_partner != "" && val.res_kcdh_partner != null ?
                              <li className="list-group-item border-0 p-0 wow fadeInUp">
                                <b>KCDH Partner:</b> <span dangerouslySetInnerHTML={{ __html: val.res_kcdh_partner }}></span>
                                </li> :
                              null
                            }

                            {
                              val.res_supervisor != "" && val.res_supervisor != null ?
                              <li className="list-group-item border-0 p-0 wow fadeInUp">
                                  <b>Supervisor:</b> <span dangerouslySetInnerHTML={{ __html: val.res_supervisor }}></span></li> :
                                null
                            }
                            {
                              val.res_co_supervisor != "" && val.res_co_supervisor != null ?
                              <li className="list-group-item border-0 p-0 wow fadeInUp">
                                <b>Co-supervisor:</b> <span dangerouslySetInnerHTML={{ __html: val.res_co_supervisor }}></span></li> :
                              null
                            }
                            </ul>
                            {
                              val.res_description != "" && val.res_description != null ?
                              <p className="list"><span dangerouslySetInnerHTML={{ __html: val.res_description }}></span></p>
                              :
                              null
                            }
                            {/* {
                              val.project_type != "" && val.project_type != null ?
                                <b className="text-dark">Project Type: {val.project_type}</b> :
                                null
                            } */}
                          </Col>
                        </Row>
                      </> : null
                  }

                </>

              )
            })

          }
        </Modal.Body>
      </Modal>
      {/* PROJECT DETAILS */}
    </>
  );
}