import React, { useState, useEffect,useRef} from "react";
import global from "../../global-variables";
import Header from "../layout/header";
import { Link, useParams , useNavigate } from "react-router-dom";
import $ from "jquery";
import WOW from "wowjs";
import { FaLinkedinIn } from "react-icons/fa";
import axios from "axios";
import { Container, Row, Col } from "reactstrap";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Modal from 'react-bootstrap/Modal';
import globalVariables from "../../global-variables";
import Metatag from '../../hooks/Metatag'
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { HiOutlineMenuAlt2 } from "react-icons/hi";



export default function People() {

  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [getPeopleid, setPeopleid] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = (e, people_id) => {
    e.preventDefault();
    setShow(true);
    //get faculty id
    setPeopleid(people_id);
  }

  //getting pagename value from url
  let {pcategory_pagename,} = useParams();
  const qs_pcategory_pagename = pcategory_pagename;

  
  //getting perticular people category list
  const [getpeopleCategory, setPeopleCategoryList] = useState(null);
  
  //saving people category id value to get subcategory list
  const [getPcategoryId,setpcategoryId] = useState();

  //getting perticular people subcategory list
  const [getPeopleSubCategoryList,setPeopleSubCategoryList] = useState([])
  
 //saving people sub category id value to get subcategory list
  const [getPsCategoryId,setPsCategoryId] = useState();

  //save people list data
  const [getPeopleList,setPeopleList] = useState([]);

  const [getMappedDeptList, setMappedDeptList] = useState(null);


    //People category list
    useEffect(()=>{
        let _obj = new Object();
        _obj.pcategory_status = 1;
        _obj.pcategory_pagename = qs_pcategory_pagename

          
          axios.post(global.variables.strApiUrl + "/peoplecategoryApi/getpeopleCategoryList",_obj)
          .then((response_perticular_pCategory) => {
            if(response_perticular_pCategory.data === 'error')
            {
              navigate('/error-page')
            }
            else
            {
              if(response_perticular_pCategory.data[0].length > 0){

                // pass the result in the success function of the respective page
                setPeopleCategoryList(response_perticular_pCategory.data[0]);
                setpcategoryId(response_perticular_pCategory.data[0][0]?.pcategory_id);
              }
              else{
                window.location = globalVariables.variables.strDomainUrl  ;
              }
            }

          }); 
        
        
    },[qs_pcategory_pagename])

    //People sub category list
    useEffect(()=>{
      let _obj = new Object();
      // _obj.ps_category_status = 1;
      _obj.ps_pcategory_id = getPcategoryId
      axios.post(global.variables.strApiUrl + "/peoplesubcategoryApi/getsubcategoryHavingActivePeopleList",_obj)
      .then((response_perticular_pSubCategory) => {
        if(response_perticular_pSubCategory.data === 'error')
        {
          navigate('/error-page')
        }
        else
        {
          if(response_perticular_pSubCategory.data[0].length > 0 && response_perticular_pSubCategory.data[0][0].ps_pcategory_id === getPcategoryId)
          {
            // pass the result in the success function of the respective page
            setPeopleSubCategoryList(response_perticular_pSubCategory.data[0]);
            setPsCategoryId(response_perticular_pSubCategory.data[0][0]?.ps_category_id)

          }
            if(response_perticular_pSubCategory.data[0][0]?.ps_category_show_dept === 1)
            {
              // call the function to get the mapped department list 

              let obj = new Object();
              obj.ps_category_id = response_perticular_pSubCategory.data[0][0].ps_category_id

              axios.post(globalVariables.variables.strApiUrl + "/getmapPeopledepartmentApi/getMappedPeopleDepartment",obj)
              .then((response_mappedPeopleDepartment)=>{
                
                setMappedDeptList(response_mappedPeopleDepartment.data[0])
                
              })
            }
          }
      });
    },[getPcategoryId])



    useEffect(()=>{
      let _objPeople = new Object();
      _objPeople.people_status = 1
      _objPeople.people_ps_category_id = getPsCategoryId
      // setTimeout(() => {
        if(getPsCategoryId != null && getPsCategoryId != '')
        {
          axios.post(globalVariables.variables.strApiUrl + "/peopleApi/getpeopleList",_objPeople)
          .then((response_peopleList)=>{
            if(response_peopleList.data === 'error')
            {
              navigate('/error-page')
            }
            else
            {
              if(response_peopleList.data[0].length > 0 && response_peopleList.data[0][0].people_ps_category_id ===  getPsCategoryId)
              {
                // setTimeout(() => {
                  
                  setPeopleList(response_peopleList.data[0])
                // }, 200);
              }
            }
            })

        }
        
      // }, 300);
    },[getPsCategoryId])

    //conditon to click by dafault on left nav
    const linkListRef = useRef(null);
    
    useEffect(() => {
      // Access the first link element when the component mounts
      if(linkListRef.current)
      {
          
          if(linkListRef.current?.querySelector('.nav-link.active') != null)
          {

            const firstLink = linkListRef.current?.querySelector('.nav-link.active');
          
                
                if(getpeopleCategory.psubcategory_count != 1)
                {
                  firstLink.click()

                }
          }
  
      }

    },[getPeopleSubCategoryList]);

    useEffect(() => {
      // add class for home header and remove inner class
      $(".fixed-top").addClass("bg-header");
  
      // initiate the wow effect
      new WOW.WOW({
        live: false,
      }).init();
  
  
    }, [getPeopleList]);

  return (
    <>
      <Header  main_className="bg-header"></Header>
      {
        getpeopleCategory != null ?
          <Metatag title={getpeopleCategory[0]?.pcategory_pagetitle} keywords={getpeopleCategory[0]?.pcategory_meta_keywords} description={getpeopleCategory[0]?.pcategory_meta_description}/>
        :
        null
      }
      
      {
        getpeopleCategory != null ?
          getpeopleCategory.length > 0 ?
            getpeopleCategory.map((val_peoplecategory)=>{
              return(
              <>
              <Container fluid className="bg-visual-inside p-0" >
                    <div className="visual-inside-big">
                      <Row className="g-md-0">
                          <Col md={6} className="order-2 order-md-1">
                            <Container className="d-flex align-items-end h-100">
                        {
                              <>
                                {
                                  qs_pcategory_pagename === val_peoplecategory.pcategory_pagename ?
                                  
                                    <div className="d-inline-block m-0 align-self-end col-lg-6 col-xl-6 py-xl-3 p-2 text-white">
                                      <h1 className="fw-light text-white m-0 align-self-center wow fadeInUp">
                                        {val_peoplecategory.pcategory_name}
                                      </h1>
                                    </div>
                                  :
                                  null
                                }
                              </>
                          }
                            </Container>
                          </Col>
                            
                        <div className="col-md-6 order-1 order-md-2">
                          <img src={global.variables.strDomainUrl + "images/visual-people.jpg"} className="img-fluid" alt="" title="" />
                        </div>
                      </Row>
                    </div>
                  </Container>
                {/* Container */}
                            {
                              val_peoplecategory.psubcategory_count > 1 ?
                              <Container className="my-5">
                                <Row>
                                    <Col md={4} lg={3} className="mb-3">
                                      <Navbar collapseonselect expand="md" className='d-block p-0'>
                                        <Navbar.Toggle className="offcanvas-toggle shadow-none w-100 animated fadeInUp" aria-controls={'offcanvas-menu'}>
                                            <Link className="btn btn-outline-secondary rounded-pill px-4 py-2 w-100 text-start d-flex justify-content-between align-items-center d-md-none">
                                              {getPeopleSubCategoryList[0]?.ps_category_name}
                                              <HiOutlineMenuAlt2 /></Link>
                                        </Navbar.Toggle>
                                        <Navbar.Offcanvas id={'offcanvasTop'} aria-labelledby={'offcanvasTop'} placement="start" responsive="md" className="p-md-0 p-2 d-block">
                                            <Offcanvas.Header closeButton>
                                                <Offcanvas.Title>
                                                </Offcanvas.Title>
                                            </Offcanvas.Header>
                                            <ul ref={linkListRef}  className='left-navbar bg-white py-md-2 py-3 px-2 w-100 mb-3'>
                                              {
                                                getPeopleSubCategoryList.map((val_peopleSubCategory,index)=>{
                                                  return(<>
                                                        {
                                                          val_peopleSubCategory.ps_category_show_dept != 1   ?
                                                            
                                                                index === 0 ?
                                                                <li><Link to={globalVariables.variables.strDomainUrl + 'people/'+ qs_pcategory_pagename +'/' + val_peopleSubCategory.ps_category_pagename} className="nav-link active">{val_peopleSubCategory.ps_category_name}</Link></li>
                                                                :
                                                                <li><Link to={globalVariables.variables.strDomainUrl + 'people/'+ qs_pcategory_pagename +'/' + val_peopleSubCategory.ps_category_pagename} className="nav-link " >{val_peopleSubCategory.ps_category_name}</Link></li>
                              
                                                            
                                                          : 
                                                          <>
                                                                
                                                                  
                                                                <div className="nav flex-column me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                                                  {
                                                                    getMappedDeptList != null && getMappedDeptList.length > 0 ?
                                                                    <>
                                                                    {
                                                                      index === 0 ?
                                                                        <li><Link 
                                                                        to={globalVariables.variables.strDomainUrl + 'people/'+ qs_pcategory_pagename +'/' + val_peopleSubCategory.ps_category_pagename}
                                                                        className="nav-link active">{val_peopleSubCategory.ps_category_name}</Link></li>
                                                                      :
                                                                        <li><Link 
                                                                        to={globalVariables.variables.strDomainUrl + 'people/'+ qs_pcategory_pagename +'/' + val_peopleSubCategory.ps_category_pagename}
                                                                        className="nav-link ">{val_peopleSubCategory.ps_category_name}</Link></li>


                                                                    }
                                                                      {
                                                                        getMappedDeptList?.map((val_dept,index)=>{
                                                                          return(
                                                                            getPsCategoryId === val_peopleSubCategory.ps_category_id  ?
                                                                              // index === 0 ?
                                                                              <Link Link to={globalVariables.variables.strDomainUrl + 'people/'+ qs_pcategory_pagename +'/' + val_peopleSubCategory.ps_category_pagename + '/' + val_dept.department_pagename} className="nav-link nav-sublink Faicon14 " >{val_dept.department_name}</Link>
                                                                              // :
                                                                              // <Link Link to={globalVariables.variables.strDomainUrl + 'people/'+ qs_pcategory_pagename +'/' + val_peopleSubCategory.ps_category_pagename + '/' + val_dept.department_pagename} className="nav-link nav-sublink Faicon14" ><FaChevronRight /> {val_dept.department_name}</Link>
                                                                            :
                                                                              null
                                                                          )
                                                                        })
                                                                      }
                                                                    </>
                                                                    :
                                                                    <>
                                                                      {
                                                                        index === 0 ?
                                                                        <Link 
                                                                        to={globalVariables.variables.strDomainUrl + 'people/'+ qs_pcategory_pagename +'/' + val_peopleSubCategory.ps_category_pagename}
                                                                        className="nav-link active">{val_peopleSubCategory.ps_category_name}</Link>
                                                                        :
                                                                        <Link 
                                                                        to={globalVariables.variables.strDomainUrl + 'people/'+ qs_pcategory_pagename +'/' + val_peopleSubCategory.ps_category_pagename}
                                                                        className="nav-link ">{val_peopleSubCategory.ps_category_name}</Link>
                                                                      }
                                                                      {/* {
                                                                        getMappedDeptList?.map((val_dept,index)=>{
                                                                          return(
                                                                            getPsCategoryId === val_peopleSubCategory.ps_category_id  ?
                                                                              index === 0 ?
                                                                              <Link Link to={globalVariables.variables.strDomainUrl + 'people/'+ qs_pcategory_pagename +'/' + val_peopleSubCategory.ps_category_pagename + '/' + val_dept.department_pagename} className="nav-link nav-sublink Faicon14 active" ><FaChevronRight /> {val_dept.department_name}</Link>
                                                                              :
                                                                              <Link Link to={globalVariables.variables.strDomainUrl + 'people/'+ qs_pcategory_pagename +'/' + val_peopleSubCategory.ps_category_pagename + '/' + val_dept.department_pagename} className="nav-link nav-sublink Faicon14" ><FaChevronRight /> {val_dept.department_name}</Link>
                                                                            :
                                                                              null
                                                                          )
                                                                        })
                                                                      } */}
                                                                    </>
                                                                    }
                                                                    
                                                                </div>
                                                                
                                                              
                                                              </>
                                                        }
                                                  </>)
                                                })
                                              }
                                              </ul>
                                        </Navbar.Offcanvas>
                                      </Navbar>
                                    </Col>
                                    <Col md={8} lg={9} className="ps-md-4">
                                      <div className="tab-content border px-4 rounded-3" >
                                        <div className="tab-pane fade show active  overflow-hidden" id="FullTimeKCDHFacultytab" role="tabpanel" aria-labelledby="FullTimeKCDHFaculty" tabindex="0">
                                          <div id="collapseOne" className=" show active collapse d-lg-block" >
                                            <Row className=" row-cols-1 g-4 py-4 people">
                                      {
                                        // getPeopleList != null ?
                                          // getPeopleList.length > 0 ?
                                            getPeopleList.map((val_people)=>{
                                              return(
                                                <>
                                                    {
                                                      // val_people.people_ps_category_id === getPsCategoryId ?
                                                        <Col>
                                                          <Link onClick={(e) => handleShow(e, val_people.people_id)}>
                                                            <Row className="align-items-start">
                                                              <Col md={4} lg={2}>
                                                                {val_people.people_pic != null && val_people.people_pic != ''?
                                                                <img src={globalVariables.variables.strApiUrl + '/people/' + val_people.people_pic } alt={val_people?.people_salutation !=null && val_people?.people_salutation != '' ? `${val_people?.people_salutation} ${val_people?.people_name}` : `${val_people?.people_name}`} title={ val_people?.people_salutation !=null && val_people?.people_salutation != '' ? `${val_people?.people_salutation} ${val_people?.people_name}`: `${val_people?.people_name}`} className="img-fluid rounded-3 mb-md-0 mb-3 wow fadeInUp" />
                                                                :
                                                                <img src={global.variables.strDomainUrl + "images/people/people-default.jpg"} alt={val_people?.people_salutation !=null && val_people?.people_salutation != '' ? `${val_people?.people_salutation} ${val_people?.people_name}` : `${val_people?.people_name}`} title={ val_people?.people_salutation !=null && val_people?.people_salutation != '' ? `${val_people?.people_salutation} ${val_people?.people_name}`: `${val_people?.people_name}`} className="img-fluid rounded-3 mb-md-0 mb-3 wow fadeInUp" />
                                                                
                                                              }
                                                              </Col>
                                                              <Col md={8} lg={10}>
                                                                
                                                                  {
                                                                    val_people.people_salutation != null && val_people.people_salutation != ''?
                                                                    <h5 className="text-dark m-0 wow fadeInUp">
                                                                      {`${val_people.people_salutation} ${val_people.people_name}`}
                                                                    </h5>
                                                                    :
                                                                    <h5 className="text-dark m-0 wow fadeInUp">
                                                                      {val_people.people_name}
                                                                    </h5>
                                                                  }
                                                                
                                                                {
                                                                  val_people.people_designation != null && val_people.people_designation != ''?
                                                                    <p className="wow fadeInUp">
                                                                      <b>{val_people.people_designation}</b>
                                                                    </p>
                                                                  :
                                                                  null
                                                                }
                                                                {/* Introduction  */}
                                                                {
                                                                  val_people.people_research_inst != null && val_people.people_research_inst != ''?
                                                                  <p><span dangerouslySetInnerHTML={{ __html: val_people.people_research_inst }}></span></p>
                                                                  :
                                                                  null
                                                                }
                                                              </Col>
                                                            </Row>
                                                          </Link>
                                                        </Col>
                                                      // :
                                                      // null
                                                    }
                                                </>
                                              )
                                            })
                                        //   :
                                        //   <Container className="my-5">
                                        //   <Row>
                                        //     <span className="text-center"><b>No records found</b></span>
                                        //   </Row>
                                        // </Container> 
                                        // :
                                        // <Container className="my-5">
                                        //     <Row>
                                        //       <span className="text-center">Loading...</span>
                                        //     </Row>
                                        //   </Container>
                                         
                                      }
                                            </Row>
                                          </div>
                                        </div>
                                      </div>
                                    </Col>
                                </Row>
                              </Container>
                              :
                              <Container className="my-5 careers">
                                <div className="border p-4 rounded-3 overflow-hidden">
                                  <Row className="row-cols-1 g-4 people">
                                  
                                    {
                                      // getPeopleList != null ?
                                        // getPeopleList.length > 0 ?
                                          getPeopleList.map((val_people)=>{
                                            return(
                                              <>
                                              {
                                                // val_people.people_ps_category_id === getPsCategoryId ?
                                                  <Col>
                                                    <Row>
                                                      <Col md={4} lg={2}>
                                                      {
                                                      val_people.people_pic != null && val_people.people_pic != ''?
                                                        <img src={globalVariables.variables.strApiUrl + '/people/' + val_people.people_pic } alt={val_people?.people_salutation !=null && val_people?.people_salutation != '' ? `${val_people?.people_salutation} ${val_people?.people_name}` : `${val_people?.people_name}`} title={ val_people?.people_salutation !=null && val_people?.people_salutation != '' ? `${val_people?.people_salutation} ${val_people?.people_name}`: `${val_people?.people_name}`} className="img-fluid rounded-3 mb-md-0 mb-3 wow fadeInUp" />
                                                        :
                                                        <img src={global.variables.strDomainUrl + "images/people/people-default.jpg"} alt={val_people?.people_salutation !=null && val_people?.people_salutation != '' ? `${val_people?.people_salutation} ${val_people?.people_name}` : `${val_people?.people_name}`} title={ val_people?.people_salutation !=null && val_people?.people_salutation != '' ? `${val_people?.people_salutation} ${val_people?.people_name}`: `${val_people?.people_name}`} className="img-fluid rounded-3 mb-md-0 mb-3 wow fadeInUp" />
                                                        }
                                                      </Col>
                                                      <Col md={8} lg={10}>
                                                        {
                                                          val_people.people_salutation != null && val_people.people_salutation != ''?
                                                          <h5 className="text-dark m-0 wow fadeInUp">
                                                            {`${val_people.people_salutation} ${val_people.people_name}`}
                                                          </h5>
                                                          :
                                                          <h5 className="text-dark m-0 wow fadeInUp">
                                                            {val_people.people_name}
                                                          </h5>
                                                          }
                                                          {
                                                            val_people.people_designation != null && val_people.people_designation != ''?
                                                            <p className="wow fadeInUp">
                                                                <b>{val_people.people_designation}</b>
                                                              </p>
                                                            :
                                                            null
                                                          }
                                                          {/* Introduction  */}
                                                          {
                                                            val_people.people_research_inst != null && val_people.people_research_inst != ''?
                                                            <p><span dangerouslySetInnerHTML={{ __html: val_people.people_research_inst }}></span></p>
                                                            :
                                                            null
                                                          }
                                                        
                                                        <div>
                                                        {
                                                        // linkedin link
                                                        val_people.people_linkedin_url != "" && val_people.people_linkedin_url != null ?
                                                        <p className="d-inline-block mx-2"><Link to={val_people.people_linkedin_url} target={'_blank'} className="btn btn-secondary rounded-circle falinked"><FaLinkedinIn /></Link> </p> :
                                                          null
                                                        }
                                                        {
                                                          val_people.people_profile_brief != null && val_people.people_profile_brief != '' ?
                                                          <Link onClick={(e) => handleShow(e, val_people.people_id)} target="_blank" class="btn btn-warning rounded-pill px-4 text-uppercase fw-bold btn-sm py-2 d-inline-block team wow fadeInUp">
                                                            Read More
                                                          </Link>
                                                          : 
                                                          null
                                                        }
                                                        </div>
                                                      </Col>
                                                    </Row>
                                                  </Col>
                                                // : 
                                                // null
                                              }
                                              </>
                                            )
                                          })
                                      //   :
                                      //   <Col className="text-center">
                                      //     <span ><b>No records found</b></span>
                                      //   </Col>
                                      // :
                                      // <Col className="text-center">
                                      //     <span>Loading...</span>
                                      //   </Col>
                                      
                                    }
                                    
                                  </Row>
                        
                                </div>
                              </Container>
                            }
                        </>

            )
            })
          :
          <>
                <Container className="my-5">
                  <Row>
                    <span className="text-center"><b>No records found</b></span>
                  </Row>
                </Container>
              
          </>
        :
        <>
          <Container fluid className="bg-visual-inside p-0" >
              <div className="visual-inside-big">
                <Row className="g-md-0">
                  <Col md={6} className="order-2 order-md-1">
                    <Container className="d-flex align-items-end h-100">
                      <div className="d-inline-block m-0 align-self-end col-lg-6 col-xl-6 py-xl-3 p-2 text-white">
                        <h1 className="fw-light text-white m-0 align-self-center wow fadeInUp">
                          
                        </h1>
                      </div>
                    </Container>
                  </Col>
                  <div className="col-md-6 order-1 order-md-2">
                    <img src={global.variables.strDomainUrl + "images/visual-people.jpg"} className="img-fluid" alt="" title="" />
                  </div>
                </Row>
              </div>
                </Container>
            <Container className="my-5 careers">
              <Row>
                <span className="text-center">Loading...</span>
              </Row>
            </Container>
        </>

        
      }

      

      {/* Breadcrumb */}
      <Breadcrumb className="breadcrumb-panel mt-auto">
        <Breadcrumb.Item href={globalVariables.variables.strDomainUrl}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active>People</Breadcrumb.Item>
        <Breadcrumb.Item active>{
          getpeopleCategory != null ?
          getpeopleCategory[0]?.pcategory_name
          : 
          null
        }</Breadcrumb.Item>
      </Breadcrumb>


      {/* TEAM DETAILS */}
      <Modal show={show} size="xl" scrollable centered onHide={handleClose} dialogClassName="p-2 popup">
        <Modal.Header closeButton className="border-0 align-items-start">
        </Modal.Header>
        <Modal.Body>
          {
            getpeopleCategory != null ?
            getpeopleCategory.map((val_pcategory)=>{
              return(
                <>
                  {
                      getPeopleList.map((val) => {
                        return (
          
                          <>
                            {
                              val.people_id === getPeopleid ?
                                <>
                                  <Row className='row-cols-1 p-md-4 p-2' key={val.people_id}>
                                    <Col md={4} lg={2} className="mb-3 mb-md-0">
                                    {
                                        val.people_pic != null && val.people_pic != ''?
                                        <img src={global.variables.strApiUrl + '/people/' + val.people_pic} className="img-fluid rounded-3"alt={val?.people_salutation !=null && val?.people_salutation != '' ? `${val?.people_salutation} ${val?.people_name}` : `${val?.people_name}`} title={ val?.people_salutation !=null && val?.people_salutation != '' ? `${val?.people_salutation} ${val?.people_name}`: `${val?.people_name}`}/>
                                        :
                                        <img src={global.variables.strDomainUrl + "images/people/people-default.jpg"} className="img-fluid rounded-3" alt={val?.people_salutation !=null && val?.people_salutation != '' ? `${val?.people_salutation} ${val?.people_name}` : `${val?.people_name}`} title={ val?.people_salutation !=null && val?.people_salutation != '' ? `${val?.people_salutation} ${val?.people_name}`: `${val?.people_name}`} />
                                    }
                                    </Col>
                                    <Col md={8} lg={10} className="list">
                                    {
                                        val.people_salutation != null && val.people_salutation != ''?
                                        <h3 className="text-primary lh-sm">{`${val.people_salutation} ${val.people_name}`}</h3>
                                        :
                                        <h3 className="text-primary lh-sm">{val.people_name}</h3>
                                      }
                                      {
                                        val.people_designation != null && val.people_designation != ''?
                                        <p className='fw-bold text-dark'><span dangerouslySetInnerHTML={{ __html: val.people_designation }}></span></p>
                                        :
                                        null
          
                                      }
                                      {/* Description  */}
                                      {
                                        val.people_profile_brief != null && val.people_profile_brief != ''?
                                        <p><span dangerouslySetInnerHTML={{ __html: val.people_profile_brief }}></span></p>
                                        :
                                        null
                                      }

                                      {
                                        //linkedin link
                                        val.people_linkedin_url != null && val.people_linkedin_url != ""  ?
                                          <p className="d-inline-block mx-2"><Link to={val.people_linkedin_url} target={'_blank'} className="btn btn-secondary rounded-circle falinked"><FaLinkedinIn /></Link> </p> :
                                          null
                                      }
                                      
                                      {
                                        //browser link
                                        val.people_url != null && val.people_url != ""  ?
                                          <p className="d-inline-block"><Link to={val.people_url} target={'_blank'} className="btn btn-warning rounded-pill px-4 text-uppercase fw-bold btn-sm py-2">Read More</Link> </p> :
                                          null
                                      }
                                    </Col>
                                  </Row>
                                </> : null
                            }
          
                          </>
          
                        )
                      })
                  }
                </>
              )
            })
            :
            null
          }
        </Modal.Body>
      </Modal >
      {/* TEAM DETAILS */}
    </>
  );
}