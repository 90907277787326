import React, { useState, useEffect } from "react";
import global from "../../global-variables";
import Header from "../layout/header";
import { Link } from "react-router-dom";
import $ from "jquery";
import WOW from "wowjs";

import { Container, Row, Col } from "reactstrap";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Modal from 'react-bootstrap/Modal';
import { Course } from './course-popup.js';
import Slider from 'react-slick';
import globalVariables from "../../global-variables";
import Metatag from '../../hooks/Metatag'

export default function MinorProgram() {
  const [show, setShow] = useState(false);
  const [getcourseid, setCourseid] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = (e, course_id) => {
    e.preventDefault();
    setShow(true);
    //get Course id
    setCourseid(course_id);
  }

  useEffect(() => {
    // add class for home header and remove inner class
    $(".fixed-top").addClass("bg-header");

    // initiate the wow effect
    new WOW.WOW({
      live: false,
    }).init();

  }, []);

  var settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  useEffect(() => {

    window.initializeFancyboxMedia();
  }, [])

  return (
    <>
      <Header main_className="bg-header"></Header>

      <Metatag title='Minor Degree in Healthcare Informatics | Minor Program in Digital Health'
               description='KCDH and IIT Bombay offers Minor Degree Program in Digital Health and Healthcare Informatics'
               keywords="Minor Degree in Healthcare Informatics, Minor Degree in Digital Health, IIT Bombay Minor Degree Program in Digital Health, Minor Degree Program in Digital Health at IIT Bombay, Minor Degree Program Overview, Minor Degree Program Eligibility, Minor Degree Program Curriculum Structure"
               />
      {/* Visual Panel */}
      <Container fluid className="bg-visual-inside p-0" >
        <div className="visual-inside-big">
          <Row className="g-md-0">
            <Col md={6} className="order-2 order-md-1">
              <Container className="d-flex align-items-end h-100">
                <div className="d-inline-block m-0 align-self-end col-lg-6 col-xl-6 py-xl-3 p-2 text-white">
                  <h1 className="fw-light text-white m-0 align-self-center wow fadeInUp">
                    Minor Program
                  </h1>
                </div>
              </Container>
            </Col>
            <div className="col-md-6 order-1 order-md-2">
              <img src={global.variables.strDomainUrl + "images/visual-students-corner.jpg"} className="img-fluid" alt="" title="" />
            </div>
          </Row>
        </div>
      </Container>


      {/* Container */}
      <Container className="my-5">
        <Row>
          <Col md={3}>
            <h2 className="text-primary wow fadeInUp">
              Program Overview
            </h2>
          </Col>
          <Col md={9} className="ps-md-5">
            <p className="m-0"> Under-Graduate students from any department or school of the institute (e.g. Electrical Engineering, School of Management, Industrial Design Centre, etc.) can earn a minor degree in Healthcare informatics from KCDH, by earning 30 credits (equivalent to five full-semester courses, each of 6 credits)</p>
          </Col>
        </Row>
      </Container>
      <Container>
        <hr className="px-5" />
      </Container>
      <div className="container my-5">
        <div className="row">
          <div className="col-md-3">
            <h2 className="text-primary wow fadeInUp">
              Curriculum Structure
            </h2>
          </div>
          <div className="col-md-9 ps-md-5 list">
            <ul className="m-0">
              <li>In total 30 credits (equivalent to five full-semester courses, each of 6 credits) are to be
                completed.
              </li>
              <li>The course DH 302: Introduction to Public Health Informatics (6 credit) is mandatory.</li>
            </ul>
            <div className="rounded-3 border overflow-hidden mb-4 mt-3">
              <div className="table-responsive">
                <table className="table table-bordered table-striped m-0">
                  <thead className="table-primary">
                    <tr>
                      <th style={{ width: "120px" }}>Course Code</th>
                      <th style={{ width: "350px" }}>Course Name</th>
                      <th style={{ width: "75px" }}>Credits</th>
                      <th style={{ width: "auto" }}>Department/Center</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="border-bottom-0">
                      <td>DH 302</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "DH302")}>Introduction to
                          Public Health Informatics</Link>
                      </td>
                      <td>6</td>
                      <td>KCDH</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <ul>
              <li className="mb-3">
                Three more PG level elective courses are to be completed out of the three groups
              </li>
            </ul>
            <b>Group 1:</b> Healthcare Standards, Clinical Applications & Healthcare Foundation
            <div className="rounded-3 border overflow-hidden mb-4 mt-2">
              <div className="table-responsive">
                <table className="table table-bordered table-striped m-0">
                  <thead className="table-primary">
                    <tr>
                      <th style={{ width: "120px" }}>Course Code</th>
                      <th style={{ width: "350px" }}>Course Name</th>
                      <th style={{ width: "75px" }}>Credits</th>
                      <th style={{ width: "auto" }}>Department/Center</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>BB 603</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "BB603")}>Physiology for
                          Engineers</Link>
                      </td>
                      <td>6</td>
                      <td>Biosciences and Bioengineering</td>
                    </tr>
                    <tr className="border-bottom-0">
                      <td>BB 607</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "BB607")}>Proteomics: Principles and Techniques</Link>
                      </td>
                      <td>6</td>
                      <td>Biosciences and Bioengineering</td>
                    </tr> 
                    <tr>
                      <td>BB 619</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "BB619")}>Mathematics for
                          Biologists</Link>
                      </td>
                      <td>6</td>
                      <td>Biosciences and Bioengineering</td>
                    </tr>
                    <tr className="border-bottom-0">
                      <td>BB 624</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "BB624")}>Microfluidics:
                          Physics and Applications</Link>
                      </td>
                      <td>6</td>
                      <td>Biosciences and Bioengineering</td>
                    </tr>
                    <tr>
                      <td>BB 626</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "BB626")}>Modeling
                          Biological Systems and Processes</Link>
                      </td>
                      <td>6</td>
                      <td>Biosciences and Bioengineering</td>
                    </tr>
                    <tr>
                      <td>BB 627</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "BB627")}>Medical Imaging
                          Methods</Link>
                      </td>
                      <td>3</td>
                      <td>Biosciences and Bioengineering</td>
                    </tr>
                    <tr>
                      <td>BB 633 </td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "BB633")}>
                          Movement Neuroscience
                        </Link>
                      </td>
                      <td>6</td>
                      <td>Biosciences and Bioengineering </td>
                    </tr>
                    <tr>
                      <td>BB 640</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "BB640")}>Biologics</Link>
                      </td>
                      <td>3</td>
                      <td>Biosciences and Bioengineering</td>
                    </tr>
                    <tr className="border-bottom-0">
                      <td>BB 645</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "BB645")}>Drug Discovery and Development</Link>
                      </td>
                      <td>3</td>
                      <td>Biosciences and Bioengineering</td>
                    </tr>
                    <tr className="border-bottom-0">
                      <td>BB 656</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "BB656")}>Introduction to Mechanobiology</Link>
                      </td>
                      <td>3</td>
                      <td>Biosciences and Bioengineering</td>
                    </tr>

                    <tr>
                      <td>BB 663</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "BB663")}>Medical Imaging Physics</Link>
                      </td>
                      <td>3</td>
                      <td>Biosciences and Bioengineering</td>
                    </tr>

                    <tr>
                      <td>CL 662</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "CL662")}>Introduction to Computational Biology</Link>
                      </td>
                      <td>6</td>
                      <td>Chemical Engineering</td>
                    </tr>

                    <tr className="border-bottom-0">
                      <td>DH 301</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "DH301")}>Basic Epidemiology</Link>
                      </td>
                      <td>6</td>
                      <td>Koita Centre for Digital Health</td>
                    </tr>
                    <tr className="border-bottom-0">
                      <td>DH 803</td>
                      <td><Link onClick={(e) => handleShow(e, "DH803")}>Wearable Health
                        Technologies</Link></td>
                      <td>6</td>
                      <td>Koita Centre for Digital Health</td>
                    </tr>
                    <tr>
                      <td>DH 804</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "DH804")}>Magnetic Resonance Imaging: From Physics to Physiology</Link>
                      </td>
                      <td> </td>
                      <td> </td>
                    </tr>
                    <tr>
                      <td>DH 805</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "DH805")}>X-ray based Imaging in Healthcare</Link>
                      </td>
                      <td> </td>
                      <td> </td>
                    </tr>
                    <tr className="border-bottom-0">
                      <td>ME 724</td>
                      <td><Link onClick={(e) => handleShow(e, "ME724")}>Essentials of
                        Turbulence</Link></td>
                      <td>6</td>
                      <td>Mechanical Engineering</td>
                    </tr>
                    <tr className="border-bottom-0">
                      <td>ME 780</td>
                      <td><Link onClick={(e) => handleShow(e, "ME780")}>Biofluid
                        Mechanics</Link></td>
                      <td>6</td>
                      <td>Mechanical Engineering</td>
                    </tr>
                    <tr className="border-bottom-0">
                      <td>TD 617</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "TD617")}>Healthtech
                          Innovation and Design</Link>
                      </td>
                      <td>6</td>
                      <td>Centre for Technology Alternatives for Rural Areas (CTARA)</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <b> Group 2:</b> Healthcare Informatics & Analytics
            <div className="rounded-3 border overflow-hidden mb-4 mt-2">
              <div className="table-responsive">
                <table className="table table-bordered table-striped m-0">
                  <thead className="table-primary">
                    <tr>
                      <th style={{ width: "120px" }}>Course Code</th>
                      <th style={{ width: "350px" }}>Course Name</th>
                      <th style={{ width: "75px" }}>Credits</th>
                      <th style={{ width: "auto" }}>Department/Center</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>CS 419</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "CS419")}>Introduction to
                          Machine Learning</Link>
                      </td>
                      <td>6</td>
                      <td>Computer Science & Engineering</td>
                    </tr>
                    <tr className="border-bottom-0">
                      <td>CS 631</td>
                      <td><Link onClick={(e) => handleShow(e, "CS631")}>Implementation Techniques for Relational Database Systems </Link></td>
                      <td>6</td>
                      <td>Computer Science & Engineering</td>
                    </tr>
                    <tr className="border-bottom-0">
                      <td>CS 663</td>
                      <td><Link onClick={(e) => handleShow(e, "CS663")}>Digital Image
                        Processing</Link></td>
                      <td>6</td>
                      <td>Computer Science & Engineering</td>
                    </tr>
                    <tr className="border-bottom-0">
                      <td>CS 725</td>
                      <td><Link onClick={(e) => handleShow(e, "CS725")}>Foundations of
                        Machine Learning</Link></td>
                      <td>6</td>
                      <td>Computer Science & Engineering</td>
                    </tr>
                    <tr className="border-bottom-0">
                      <td>CS 726</td>
                      <td><Link onClick={(e) => handleShow(e, "CS726")}>Advanced
                        Machine Learning</Link></td>
                      <td>6</td>
                      <td>Computer Science & Engineering</td>
                    </tr>
                    <tr>
                      <td>CS 736</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "CS736")}>Medical Image
                          Computing</Link>
                      </td>
                      <td>6</td>
                      <td>Computer Science & Engineering</td>
                    </tr>
                    <tr>
                      <td>CS 754</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "CS754")}>Advanced Image
                          Processing</Link>
                      </td>
                      <td>6</td>
                      <td>Computer Science & Engineering</td>
                    </tr>
                    <tr className="border-bottom-0">
                      <td>CS 769</td>
                      <td><Link onClick={(e) => handleShow(e, "CS769")}>Optimization in
                        Machine Learning</Link></td>
                      <td>6</td>
                      <td>Computer Science & Engineering</td>
                    </tr>
                    <tr>
                      <td>DH 306</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "DH306")}>Healthcare Performance
                          Metrics</Link>
                      </td>
                      <td>6</td>
                      <td>Koita Centre for Digital Health</td>
                    </tr>
                    <tr className="border-bottom-0">
                      <td>DH 308</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "DH308")}>Clinical Data Management</Link>
                      </td>
                      <td>6</td>
                      <td>Koita Centre for Digital Health</td>
                    </tr> 
                    <tr>
                      <td>DH 602</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "DH602")}>Machine Learning and Statistical Methods in Healthcare</Link>
                      </td>
                      <td> </td>
                      <td> </td>
                    </tr>
                    <tr>
                      <td>DH 607</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "DH607")}>Introduction to Computational Multi-omics</Link>
                      </td>
                      <td>6</td>
                      <td> </td>
                    </tr>
                    <tr>
                      <td>DH 801</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "DH801")}>Biostatistics
                          in Healthcare</Link>
                      </td>
                      <td>6</td>
                      <td>Koita Centre for Digital Health</td>
                    </tr>
                    <tr>
                      <td>DS 303</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "DS303")}>
                          Introduction to Machine Learning
                        </Link>
                      </td>
                      <td>6</td>
                      <td>Centre for Machine Intelligence and Data Science</td>
                    </tr>
                    <tr>
                      <td>EE 610</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "EE610")}>Image
                          Processing</Link>
                      </td>
                      <td>6</td>
                      <td>Electrical Engineering</td>
                    </tr>
                    <tr>
                      <td>EE 769</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "EE769")}>Introduction to
                          Machine Learning</Link>
                      </td>
                      <td>6</td>
                      <td>Electrical Engineering</td>
                    </tr>
                    <tr>
                      <td>GNR 652</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "GNR652")}>
                          Machine Learning for Remote Sensing 1
                        </Link>
                      </td>
                      <td>6</td>
                      <td>Centre of Studies in Resources Engineering</td>
                    </tr>
                    <tr className="border-bottom-0">
                      <td>IE 501</td>
                      <td><Link onClick={(e) => handleShow(e, "IE501")}>Optimization Models</Link></td>
                      <td>6</td>
                      <td>Industrial Engineering and Operations Research</td>
                    </tr>
                    <tr>
                      <td>IE 615</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "IE615")}>Data Analytics
                          in Operations Research</Link>
                      </td>
                      <td>6</td>
                      <td>Industrial Engineering and Operational Research </td>
                    </tr>
                    <tr className="border-bottom-0">
                      <td>IE 643</td>
                      <td><Link onClick={(e) => handleShow(e, "IE643")}>Deep Learning Theory & Practice</Link></td>
                      <td>6</td>
                      <td>Industrial Engineering and Operations Research</td>
                    </tr>
                    <tr className="border-bottom-0">
                      <td>ME 781</td>
                      <td><Link onClick={(e) => handleShow(e, "ME781")}>Statistical
                        Machine Learning and Data Mining</Link></td>
                      <td>6</td>
                      <td>Mechanical Engineering</td>
                    </tr>
                    <tr>
                      <td>SI 541</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "SI541")}>Statistical
                          Epidemiology</Link>
                      </td>
                      <td>6</td>
                      <td>Mathematics </td>
                    </tr>
                    <tr>
                      <td>SI 422</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "SI422")}>Regression
                          Analysis</Link>
                      </td>
                      <td>8</td>
                      <td>Mathematics </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <b> Group 3:</b> Health Systems &amp; Policy &amp; Ethics.
            <div className="rounded-3 border overflow-hidden  mb-4 mt-2">
              <div className="table-responsive">
                <table className="table table-bordered table-striped m-0">
                  <thead className="table-primary">
                    <tr>
                      <th style={{ width: "120px" }}>Course Code</th>
                      <th style={{ width: "350px" }}>Course Name</th>
                      <th style={{ width: "75px" }}>Credits</th>
                      <th style={{ width: "auto" }}>Department/Center</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="border-bottom-0">
                      <td>DH 304</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "DH304")}>Economics of Health Care</Link>
                      </td>
                      <td>6</td>
                      <td>Koita Centre for Digital Health</td>
                    </tr>
                    <tr className="border-bottom-0">
                      <td>DH 802</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "DH802")}>Service
                          Operations and Quality Management in Healthcare</Link>
                      </td>
                      <td>6</td>
                      <td>Koita Centre for Digital Health</td>
                    </tr>
                    <tr className="border-bottom-0">
                      <td>DH 899</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "DH899")}>Communication
                          Skills</Link>
                      </td>
                      <td>6</td>
                      <td>Koita Centre for Digital Health</td>
                    </tr>
                    <tr className="border-bottom-0">
                      <td>ES 899/CM 899</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "ES899")}>Communication
                          Skills</Link>
                      </td>
                      <td>6</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>HS 633</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "HS633")}>Econometrics of
                          Programme Evaluation</Link>
                      </td>
                      <td>6</td>
                      <td>Humanities and Social Science</td>
                    </tr>
                    <tr className="border-bottom-0">
                      <td>HS 638</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "HS638")}>Financial
                          Econometrics</Link>
                      </td>
                      <td>6</td>
                      <td>Humanities and Social Sciences</td>
                    </tr>
                    <tr className="border-bottom-0">
                      <td>HS 426</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "HS426")}>Theory and
                          Policy of Managerial Finance</Link>
                      </td>
                      <td>6</td>
                      <td>Humanities and Social Sciences</td>
                    </tr>
                    <tr>
                      <td>IE 709</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "IE709")}>IEOR for Health
                          Care</Link>
                      </td>
                      <td>8</td>
                      <td>Industrial Engineering and Operational Research</td>
                    </tr>
                    <tr>
                      <td>PS 619</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "PS619")}>Health Policy:
                          An Introduction</Link>
                      </td>
                      <td>6</td>
                      <td>Ashank Desai Centre for Policy Studies</td>
                    </tr>
                    <tr className="border-bottom-0">
                      <td>SOM 633</td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "SOM633")}>Quality
                          Management</Link>
                      </td>
                      <td>3</td>
                      <td>SJM School of Management</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <p><b>Group 4:</b> R&D Project </p>
            <div className="rounded-3 border overflow-hidden mb-4 mt-2">
              <div className="table-responsive">
                <table className="table table-bordered table-striped m-0">
                  <thead className="table-primary">
                    <tr>
                      <th style={{ width: "120px" }}>Course Code</th>
                      <th style={{ width: "350px" }}>Course Name</th>
                      <th style={{ width: "75px" }}>Credits</th>
                      <th style={{ width: "auto" }}>Department/Center</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="border-bottom-0">
                      <td>DH 307 </td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "DH307")}>R&D Project </Link>
                      </td>
                      <td>6</td>
                      <td>Koita Centre for Digital Health</td>
                    </tr>
                    <tr className="border-bottom-0">
                      <td>DH 604 </td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "DH604")}>R&D Project - I  </Link>
                      </td>
                      <td></td>
                      <td>  </td>
                    </tr>
                    <tr className="border-bottom-0">
                      <td>DH 605 </td>
                      <td>
                        <Link onClick={(e) => handleShow(e, "DH605")}>R&D Project - II  </Link>
                      </td>
                      <td></td>
                      <td>  </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <ul>
              <li>
                Every student must take credit courses from at least two of the groups. The courses under
                these groups includes specialized
                courses offered by the faculty members associated with KCDH and approved by the suitable
                academic
                committee of KCDH.
              </li>
              <li>
                An RnD project of 6 credits can be taken in lieu of a full semester course of 6 credits
                towards the completion of 30 credits
                to earn the MINOR degree. However, students have to identify and get the consent of a
                faculty
                member associated with KCDH to guide him/her for the RnD project.
              </li>
            </ul>
            Reference: Page 36 of the <Link to="https://www.iitb.ac.in/newacadhome/ugrulebook.pdf" target="_blank"><u>UG Rule book</u> </Link>
          </div>
        </div >
      </div >
      <div className="container">
        <hr className="px-5" />
      </div>
     
      <Container className="my-5">
        <Row>
          <Col md={3}>
            <h2 className="text-primary wow fadeInUp">
              Course Offered in Autumn 2024 - 25
            </h2>
          </Col>
          <Col md={9} className="ps-md-5 list">
            <div className="rounded-3 border border-bottom position-relative overflow-hidden mb-3">
              <div className="table-responsive">
                <table className="table table-bordered table-striped m-0">
                  <thead className="table-primary">
                    <tr>
                      <th style={{ width: "120px" }}>Course Code</th>
                      <th style={{ width: "auto" }}>Course Name</th>
                      <th style={{ width: "350px" }}>Instructor</th> 
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>DH 307</td>
                      <td><Link onClick={(e) => handleShow(e, "DH307")}> R & D Project </Link></td>
                      <td>Prof. Ganesh Ramakrishnan</td> 
                    </tr> 
                    <tr>
                      <td>DH 301</td>
                      <td><Link onClick={(e) => handleShow(e, "DH301")}>Basic Epidemiology </Link></td>
                      <td>Prof. Ganesh Ramakrishnan and <br></br> Dr. Kalyani Addya and Dr. Sandip Mandal</td> 
                    </tr> 
                    <tr>
                      <td>DH 803</td>
                      <td><Link onClick={(e) => handleShow(e, "DH803")}>Wearable Health Technologies</Link></td>
                      <td>Dr. Nirmal Punjabi</td> 
                    </tr>
                    <tr>
                      <td>DH 604</td>
                      <td><Link onClick={(e) => handleShow(e, "DH604")}>R&D Project - I </Link></td>
                      <td>Prof. Ganesh Ramakrishnan</td> 
                    </tr>
                    <tr>
                      <td>DH 607</td>
                      <td><Link onClick={(e) => handleShow(e, "DH607")}>Introduction to Computational Multi-omics  </Link></td>
                      <td>Prof. Saket Choudhary </td> 
                    </tr>
                    <tr>
                      <td>DH 804</td>
                      <td><Link onClick={(e) => handleShow(e, "DH804")}>Magnetic Resonance Imaging: From Physics to Physiology (Second Half) </Link></td>
                      <td>Prof. Ashutosh Kumar </td> 
                    </tr>
                    <tr>
                      <td>CL 662 </td>
                      <td><Link onClick={(e) => handleShow(e, "CL662")}>Introduction to Computational Biology</Link></td>
                      <td>Prof. Pramod Wangikar </td> 
                    </tr>
                    <tr>
                      <td>BB 633</td>
                      <td><Link onClick={(e) => handleShow(e, "BB633")}>Movement Neuroscience</Link></td>
                      <td>Prof. Neeta Kanekar</td> 
                    </tr>
                    <tr>
                      <td>BB 607 </td>
                      <td><Link onClick={(e) => handleShow(e, "BB607")}>Proteomics: Principles and Techniques </Link></td>
                      <td>Prof. Sanjeeva Srivastava </td> 
                    </tr>
                    <tr>
                      <td>IE 643</td>
                      <td><Link onClick={(e) => handleShow(e, "IE643")}>Deep Learning: Theory and Practice</Link></td>
                      <td>Prof. Balamurugan </td> 
                    </tr>
                    <tr>
                      <td>EE 769</td>
                      <td><Link onClick={(e) => handleShow(e, "EE769")}>Introduction to Machine Learning</Link></td>
                      <td>Prof. Amit Sethi </td> 
                    </tr>
                    <tr>
                      <td>CS 663</td>
                      <td><Link onClick={(e) => handleShow(e, "CS663")}>Digital Image Processing </Link></td>
                      <td>Prof. Ajit Rajwade </td> 
                    </tr>
                    <tr>
                      <td>Si 515 </td>
                      <td>Multivariate Analysis</td>
                      <td>Prof. Siuli Mukhopadhyay  </td> 
                    </tr>
                    <tr>
                      <td>BB 525</td>
                      <td>Biological Thermodynamics and Kinetic</td>
                      <td>Prof. Ranjith Padinhateeri</td> 
                    </tr> 
                  </tbody>
                </table>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      {/* Breadcrumb */}
      <Breadcrumb className="breadcrumb-panel mt-auto">
        <Breadcrumb.Item to="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item>Students' Corner</Breadcrumb.Item>
        <Breadcrumb.Item active>Minor Program</Breadcrumb.Item>
      </Breadcrumb>


      {/* TEAM DETAILS */}
      <Modal show={show} size="xl" scrollable centered onHide={handleClose} dialogClassName="p-2 popup-students">
        {
          Course.map((val) => {
            return (

              <>
                {
                  val.course_id == getcourseid ?
                    <>
                      <div key={val.course_id}>
                        <Modal.Header closeButton className="border-0 align-items-start">
                          <h3 className="bg-primary text-white py-2 px-md-4 px-2 m-0 w-100">{val.course_title}</h3>
                        </Modal.Header>
                        <Modal.Body className="py-0" style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
                          <span dangerouslySetInnerHTML={{ __html: val.course_info }}></span>
                        </Modal.Body>
                      </div>
                    </> : null
                }

              </>

            )
          })

        }
      </Modal >
      {/* TEAM DETAILS */}
    </>
  );
}