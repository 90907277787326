const Course = [
    {
        course_id: 'BB603',
        course_title: "BB 603 - Physiology for Engineers",
        course_info: "<div class='px-md-4 px-2 list'> " +
            "<b class='text-dark d-block'>Course content</b> " +
            "<ul class='m-0'> " +
            "<li>Introduction to the overall organization of the human body. </li> " +
            "<li> " +
            "Basic and engineering aspects of the following: " +
            "<ul> " +
            "<li>The master and vegetative tissues, organs systems, nervous system, musculo-skeletal system, endocrine system and body metabolism, gastrointestinal system, respiratory system and spirometers, cardiovascular system, blood and body fluids, reproductive system, blood and body fluids, reproductive system, hepato-biliary system, kidney and excretory system</li> " +
            "<li>Basic physiologic principles</li> " +
            "<li>Metabolism</li> " +
            "<li>Immunity and allergy</li> " +
            "<li>Physiology museum hematology experiments</li> " +
            "</ul> " +
            "</li> " +
            "</ul> " +

            "<b class='text-dark'>References</b> " +
            "<ul> " +
            "<li>W.F. Ganong, Medical physiology, 12 Marusen Asian end. Singapore, 1985.</li> " +
            "<li>C.C. Chatterjee, Human physiology, Med. Allied Agency, Calcutta, 1985.</li> " +
            "<li>S. Wright, Applied Physiology, Oxford, 1984.</li> " +
            "<li>J. H. Brown (ed.) Engineering principles in physiology Acad. Press, New York.</li> " +
            "</ul> " +

            "<div class='rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3 col-lg-9'> " +
            "<table class='table table-striped bg-white m-0'> " +
            "<tr> " +
            "<td class='fw-bold' style='width:300px'>Total credits</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>6 credits - Lecture</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Type</td> " +
            "<td>:</td> " +
            "<td></td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Duration</td> " +
            "<td>:</td> " +
            "<td>Autumn 2022</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Name(s) of other Academic units to whom the course may be relevant</td> " +
            "<td>:</td> " +
            "<td>N/A</td> " +
            "</tr> " +
            "</table> " +
            "</div> " +
            "</div> ",
    },

    {
        course_id: 'BB607',
        course_title: "BB 607 - Proteomics: Principles and Techniques",
        course_info: "<div class='px-md-4 px-2 list'> " +
            "<b class='text-dark d-block pt-2'>Course content</b> " +
            "<ul> " +
            "<li>An introduction to proteomics: Basics of protein structure and function, An overview of systems biology, Evolution from protein chemistry to proteomics </li> " +
            "<li>Abundance-based proteomics: Sample preparation and pre-fractionation steps, Gel-based proteomics - two-dimensional gel electrophoresis (2-DE), two-dimensional fluorescence difference in-gel electrophoresis (DIGE), Staining techniques </li> " +
            "<li>Central role of mass spectrometry: ionization sources, mass analyzers, different types of mass spectrometers; Quantitative proteomics - stable isotope labelling by amino acids in cell culture (SILAC), isotope-coded affinity tag (ICAT), isobaric tagging for relative and absolute quantitation (iTRAQ)  </li> " +
            "<li>Functional proteomics: Recombinational cloning, Interact omics techniques to study protein-protein interactions, yeast two-hybrid, immunoprecipitation, protein microarrays, Nucleic Acid Programmable Protein Array (NAPPA) </li> " +
            "<li>Label-free nanotechnologies in proteomics, Surface Plasmon Resonance (SPR); Modificomics: understanding post-translational modifications; Structural proteomics; Bioinformatics in proteomics; Challenges and future prospects of proteomics research </li> " +
            "<li>Proteomics course will cover several basic concepts of various technologies but emphasis will be more on software's and hands-on sessions/ data analysis.   </li> " +
            "</ul> " +

            "<b class='text-dark'>References</b> " +
            "<ul> " +
            "<li>Introduction to Proteomics: Tools for the New Biology, D.C. Liebler, Humana Press, 2002. </li> " +
            "<li>Principles of Proteomics, R.M. Twyman, Bios Scientific Pub., 2004. </li> " +
            "<li>Proteomics for Biological Discovery, T.D. Veenstra, J.R. Yates III, John-Wiley & Sons, Hoboken, New Jersey, USA; 2006. </li> " +
            "<li>Protein Biochemistry and Proteomics (The Experimenter Series), R. Hubert, Academic Press, 2006.  </li> " +
            "<li>Proteomics in Practice: A Guide to Successful Experimental Design, R. Westermeier, T. Naven, H-R. Hopker, Wiley-VCH, 2008.  </li> " +
            "<li>Proteomics: A Cold Spring Harbor Laboratory Course Manual, A.J. Link and J. LaBaer, Cold Spring Harbor Laboratory Press, 2009.  </li> " +
            "<li>Selected papers from scientific journals. </li> " +
            "</ul> " +

            "<div class='rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3 col-lg-9'> " +
            "<table class='table table-striped bg-white m-0'> " +
            "<tr> " +
            "<td class='fw-bold' width='120px'>Total credits</td> " +
            "<td width='20px'>:</td> " +
            "<td>6 credits – Lecture and Tutorial </td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Type</td> " +
            "<td>:</td> " +
            "<td>Department Elective </td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Duration</td> " +
            "<td>:</td> " +
            "<td>Full Semester</td> " +
            "</tr> " +
            "</table> " +
            "</div> " +
            "</div> ",
    },

    {
        course_id: 'BB619',
        course_title: "BB 619 - Mathematics for Biologists",
        course_info: "<div class='px-md-4 px-2 list'> " +
            "<b class='text-dark d-block pt-2'>Course content</b> " +
            "<ul> " +
            "<li>Elementary calculus : (12 hours)</li> " +
            "<li>Functions : Idea of functions using examples from Biology. Concentration of proteins as a function of time. Periodic functions.</li> " +
            "<li>Differentiation: Introduce change in concentration, change in length etc as derivatives. Slope and derivative. Derivatives of simple functions. Pressure, entropy, force etc as derivatives of free energy .Techniques of differentiation.</li> " +
            "<li>Plotting functions: Maximum and minimum points.</li> " +
            "<li>Sketching simple functions : Exponential, Gaussian, Logarithm etc.</li> " +
            "<li>Techniques of Integration: Integration as area under a curve. Integrating simple expressions. Use of Integration techniques in biology.</li> " +
            "<li>Descriptive statistics and Data display (6 Hours)</li> " +
            "<li>Measurement scales. Continuous and discrete data</li> " +
            "<li>Summarizing data set: Histograms, Pie Charts, otherways of representing data Mean, variance, standard deviation Errors, fitting a function to experimental data, linear and non-linear fits. </li> " +
            "<li>Vectors, Co-ordinate systems: (3 hours)</li> " +
            "<li>Scalars and vectors. Spherical polar coordinates, Cylindrical coordinates. Use of these coordinate systems to study 3-dimensional configuration of proteins</li> " +
            "<li>Differential Equations : (8 hours)</li> " +
            "<li>Ordinary/partial differential equations: Rate equations, Diffusion, Solving differential equations, Applications in biology. Calculus of growth and decay processes.</li> " +
            "<li>Probability: (8 hours)</li> " +
            "<li>Relevance of stochasticity in biology. </li> " +
            "<li>Thermal fluctuations, Mutations, Bacterial motion.</li> " +
            "<li>Introduction to probability, Probability distributions, Moments Binomial distribution , Gaussian distribution ,Poisson distribution Examples from biology (Luria-Delbruck fluctuation test, Knudson 302222s two-hit hypothesis)</li> " +
            "<li>Conditional probability Master equations. Modeling gene expression, polymerization of actin/microtubules </li> " +
            "<li>Fourier series, Fourier transformation, (3 hours)Discuss the use of Fourier transformation in X-ray crystallography and in optics</li> " +
            "</ul> " +
            "<b class='text-dark'>References</b> " +
            "<ul> " +
            "<li>Mathematics for Biological Scientists, M. Aitken, B. Broadhursts, S. Haldky, Garland Science(2009) </li> " +
            "<li>Introduction to Mathematics for Life Scientists, E. Batschelet, Springer Verlag, 3rd edition (2003) </li> " +
            "<li>Physical Biology of the Cell, R Phillips, J Kondev, J. Theriot, Garland Science (2009)</li> " +
            "<li>Calculus for Life Sciences, R. De Sapio, W. H. Freeman and Co. (1976)</li> " +
            "<li>Random Walks in Biology, H. C. Berg, Princeton university press (1993)</li> " +
            "</ul> " +
            "<div class='rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3 col-md-9'> " +
            "<table class='table table-striped bg-white m-0'> " +
            "<tr> " +
            "<td class='fw-bold' style='width:350px'>Pre-requisite</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>N/A</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Total credits</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>6 credits - Lecture</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Type</td> " +
            "<td>:</td> " +
            "<td></td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Duration</td> " +
            "<td>:</td> " +
            "<td>Autumn 2022</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Name(s) of other Academic units to whom the course may be relevant</td> " +
            "<td>:</td> " +
            "<td>N/A</td> " +
            "</tr> " +
            "</table> " +
            "</div> " +
            "</div> ",
    },

    {
        course_id: 'BB624',
        course_title: "BB 624 - Microfluidics: physics and applications",
        course_info: "<div class='px-md-4 px-2 list'> " +
            "<b class='text-dark d-block pt-2'>Course content</b> " +
            "<ul> " +
            "<li>Length scales and forces in microsystems, dimensionless numbers (Reynolds number, Peclet number, etc.), laminar and turbulent flows; diffusion, mixing and separation; electric fields in microfluidics; fabrication of microfluidic devices; flow control using pumps and valves; droplet generation and manipulation; DNA and protein analysis; engineering cellular microenvironments; paper-based microfluidics and its applications in diagnostics. </li> " +
            "</ul> " +

            "<b class='text-dark'>References</b> " +
            "<ul> " +
            "<li>Introduction to Microfluidics, Patrick Tabeling (Oxford University Press), reprint edition, 2010. </li> " +
            "<li>Microfluidics for Biotechnology, Jean Berthier and Pascal Silberzan (Artech House), 2nd edition, 2009. </li> " +
            "<li>Microfluidics for Biological Applications, Edited by Wei-Cheng Tian and Erin Finehout, (Springer), 1st edition, 2008. </li> " +
            "<li>Biological Applications of Microfluidics, edited by Frank A. Gomez, Wiley-Interscience, 2008 </li> " +
            "</ul> " +

            "<div class='rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3 col-md-9'> " +
            "<table class='table table-striped bg-white m-0'> " +
            "<tr> " +
            "<td class='fw-bold' style='width:350px'>Pre-requisite</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>NA </td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Total credits</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>6</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Type</td> " +
            "<td>:</td> " +
            "<td>Theory</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Duration</td> " +
            "<td>:</td> " +
            "<td></td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Name(s) of other Academic units to whom the course may be relevant</td> " +
            "<td>:</td> " +
            "<td>N/A</td> " +
            "</tr> " +
            "</table> " +
            "</div> " +
            "</div>",
    },

    {
        course_id: 'BB626',
        course_title: "BB 626 - Modeling Biological Systems and Processes",
        course_info: "<div class='px-md-4 px-2 list'> " +
            "<b class='text-dark d-block pt-2'>Course content</b> " +
            "<ul> " +
            "<li>Examples of simple models to understand different types of biological processes such as Lotka-Voltera model, simple epidemic model, reaction-diffusion models, drift-diffusion models.</li> " +
            "<li>Introductions to stochastic processes in biology, Introduction to Fokker-Planck and Langevin equations.</li> " +
            "<li>Introduction to computer simulations. </li> " +
            "<li>Introductions to different types of simulations-Molecular Dynamics, Monte Carlo and Langevin dynamics simulations. </li> " +
            "<li>Simple examples of application of direct Monte Carlo simulation and Gillespie algorithm to a number of simple stochastic biological systems.</li> " +
            "</ul> " +

            "<b class='text-dark'>References</b> " +
            "<ul> " +
            "<li>Physical Biology of the Cell, R Phillips, J Kondev, J. Theriot, Garland Science, 2009.</li> " +
            "<li>A Course in Mathematical Biology: Quantitative Modeling with Mathematical and Computational Methods, Gerda de Vries, Thomas Hillen, Mark Lewis, Birgitt Schõnfisch, Johannes Muller, SIAM, 2006. </li> " +
            "<li>Dynamic Models in Biology, Stephen P. Ellner, John Guckenheimer, Princeton University Press, 2006.</li> " +
            "</ul> " +

            "<div class='rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3 col-md-9'> " +
            "<table class='table table-striped bg-white m-0'> " +
            "<tr> " +
            "<td class='fw-bold' style='width:120px'>Total credits</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>6 credits - Lecture</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Type</td> " +
            "<td>:</td> " +
            "<td>Department elective</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Duration</td> " +
            "<td>:</td> " +
            "<td>Full Semester</td> " +
            "</tr> " +
            "</table> " +
            "</div> " +
            "</div>",
    },

    {
        course_id: 'BB627',
        course_title: "BB 627 - Medical Imaging Methods",
        course_info: "<div class='px-md-4 px-2 list'> " +
            "<b class='text-dark d-block pt-2'>Course content</b> " +
            "<ul> " +
            "<li>Introduction to imaging</li> " +
            "<li> Imaging modalities " +
            "<ul> " +
            "<li>X-ray</li> " +
            "<li>Ray tracing</li> " +
            "<li>Diffraction imaging</li> " +
            "<li>Near Infrared imaging</li> " +
            "<li>General theory on Imaging</li> " +
            "</ul> " +
            "</li> " +
            "</ul> " +

            "<div class='rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3 col-md-9'> " +
            "<table class='table table-striped bg-white m-0'> " +
            "<tr> " +
            "<td class='fw-bold' width='120px'>Total credits</td> " +
            "<td width='20px'>:</td> " +
            "<td>3 credits - Lecture</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Type</td> " +
            "<td>:</td> " +
            "<td></td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Duration</td> " +
            "<td>:</td> " +
            "<td>3 lectures, Second half of semester</td> " +
            "</tr> " +
            "</table> " +
            "</div> " +
            "</div>",
    },

    {
        course_id: 'BB633',
        course_title: "BB 633 - Movement Neuroscience",
        course_info: "<div class='px-md-4 px-2 list'> " +
            "<b class='text-dark d-block pt-2'>Course content</b> " +
            "<p> Overview of the cellular components of the nervous system, neural circuits, organization of human nervous system, and neural signaling Sensory contributions to movement control Somatosensory system Visual system Vestibular system Central contributions to movement control Lower motor neuron circuits Upper motor neuron control of the brainstem and spinal cord Modulation of movement by basal ganglia and cerebellum Relevant electrodiagnostic and therapeutic applications that have been developed based on the understanding of movement neurophysiology. Role of rehabilitation technology. </p> " +

            "<b class='text-dark'>References</b> " +
            "<ul> " +
            "<li>Dale Purves 302205 [et al.], Neuroscience, 5th Edition, Sinauer Associates, Inc., 2, ISBN: 978-0-87893-695-3</li> " +
            "<li>Eric Kandel, James Schwartz, Principles of Neural Sciences, 5th Edition, McGraw-Hill Education/Medical, 2, ISBN-13: 978-0071390118</li> " +
            "<li>John Hall, Guyton and Hall Textbook of Medical Physiology, 13th Edition, Elsevier, 2, ISBN: 978-1455770052</li> " +
            "<li>Richard Schmidt, Timothy Lee, Motor Control and Learning 302226 A Behavioral Emphasis, 5th Edition, Human Kinetics, 2011, ISBN-13: 978-0736079617</li> " +
            "<li>Anne Shumway-Cook, Marjorie Woollacott, Motor Control: Translating Research Into Clinical Practice, 4th Edition, Lippincott Williams and Wilkins, 2011, ISBN-13: 978-1608310180 </li> " +
            "</ul> " +

            "<div class='rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3 col-md-9'> " +
            "<table class='table table-striped bg-white m-0'> " +
            "<tr> " +
            "<td class='fw-bold' width='120px'>Total credits</td> " +
            "<td width='20px'>:</td> " +
            "<td>3 credits - Lecture </td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Type</td> " +
            "<td>:</td> " +
            "<td>  </td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Duration</td> " +
            "<td>:</td> " +
            "<td> First half of semester  </td> " +
            "</tr> " +
            "</table> " +
            "</div> " +
            "</div>",
    },

    {
        course_id: 'BB640',
        course_title: "BB 640 - Biologics",
        course_info: "<div class='px-md-4 px-2 list'> " +
            "<b class='text-dark d-block pt-2'>Course content</b> " +
            "<ul> " +
            "<li>Introduction to Biosimilars, Development and its role in Therapeutics, Cell Line Development and Upstream Bioprocessing, cell culture methods, clone selection and optimization, Bioreactors, Scale-up optimization Critical Quality Attributes for Biologics and Bio-similars, Glycosylation, De-amidation, Charge Variant analysis, Analytical Methods to measure Glycosylation, De-amidation, Charge Variant (AUC, CE, Mass spectrometry) </li> " +
            "<li>Biophysical and analytical Characterization of Biologics products, Primary, secondary and tertiary structural analysis by various Biophysics Methods, Protein aggregation principle and analysis by DLS and SE-HPLC, Thermodynamic stability by DSC, ITC Downstream Purification for Biologics, Regulatory Approach for Biosimilars, Globalization of Biosimilar</li> " +
            "</ul> " +
            "<b class='text-dark'>References</b> " +
            "<ul> " +
            "<li>Introduction to Biologic and Biosimilar Product Development and Analysis, Nagel, Karen M. Springer, 2018</li> " +
            "<li>Biosimilars: Regulatory, Clinical, and Biopharmaceutical Development Editors: Gutka, Hiten J., Yang, Harry, Kakar, Shefali (Eds.) Springer, 2018</li> " +
            "</ul> " +
            "<div class='rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3 col-md-9'> " +
            "<table class='table table-striped bg-white m-0'> " +
            "<tr> " +
            "<td class='fw-bold' style='width:350px'>Pre-requisite</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>N/A</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Total credits</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>3 credits</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Type</td> " +
            "<td>:</td> " +
            "<td></td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Duration</td> " +
            "<td>:</td> " +
            "<td>Spring 2023</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Name(s) of other Academic units to whom the course may be relevant</td> " +
            "<td>:</td> " +
            "<td>N/A</td> " +
            "</tr> " +
            "</table> " +
            "</div> " +
            "</div>",
    },

    {
        course_id: 'BB645',
        course_title: "BB 645 - Drug Discovery and Development",
        course_info: "<div class='px-md-4 px-2 list'> " +
            "<b class='text-dark d-block pt-2'>Course content</b> " +
            "<ul> " +
            "<li>Identification of target or drug leads associated with a particular disease by a number of different techniques including combinations of molecular modeling, combinatorial libraries and high-throughput screening (HTS); </li> " +
            "<li>Conceptualizing the automation of the HTS process and the importance of bioinformatics and data processing in identification of lead compounds; </li> " +
            "<li>Rational drug design, based on understanding the three-dimensional structures and physicochemical properties of drugs and receptors; </li> " +
            "<li>Modelling drug/ receptor interactions with the emphasis on molecular mechanisms, molecular dynamics simulations and homology modelling; Conformational sampling, macromolecular folding, structural bioinformatics, receptor-based and ligand-based design and docking methods, in silico screening of libraries, semi-empirical and ab-initio methods, QSAR methods, molecular diversity, design of combinatorial libraries of drug-like molecules, macromolecular and chemical databases.</li> " +
            "<li>Identification of relevant groups on a molecule that interact with a receptor and are responsible for biological activity; Understanding structure activity relationship; Structure modification to increase potency and therapeutic index; Concept of quantitative drug design using Quantitative structure–activity relationship models (QSAR models) based on the fact that the biological properties of a compound are a function of its physicochemical parameters such as solubility, lipophilicity, electronic effects, ionization, stereochemistry, etc.; </li> " +
            "<li>Bioanalytical assay development in support of in vitro and in vivo studies (LC/MS/MS, GC/MS and ELISA). </li> " +
            "<li>Principles of drug absorption, drug metabolism and distribution - intestinal absorption, metabolic stability, drug-drug interactions, plasma protein binding assays, metabolite profile studies, </li> " +
            "<li>Principles of toxicology, Experimental design for preclinical and clinical PK/PD/TK studies, Selection of animal model; Regulatory guidelines for preclinical PK/ PD/TK studies; Scope of GLP, SOP for conduct of clinical & non clinical testing, control on animal house, report preparation and documentation Integration of non-clinical and preclinical data to aid design of clinical studies. </li> " +
            "<li>Requirements of GMP implementation, Documentation of GMP practices, CoA,</li> " +
            "<li>Regulatory certification of GMP, Quality control and Quality assurance, concept and philosophy of TQM, ICH and ISO 9000; ICH guidelines for Manufacturing,</li> " +
            "<li>Understanding Impurity Qualification Data, Stability Studies. Objectives of Phase I, II, III and IV clinical studies, Clinical study design, enrollment, sites and documentation, Clinical safety studies: Adverse events and adverse drug reactions, Clinical PK, pharmacology, drug-drug interaction studies, Statistical analysis and documentation.</li> " +
            "<li>Global Regulatory Affairs and different steps involved, Regulatory Objectives, Regulatory Agencies; FDA guidelines on IND and NDA submissions, Studies required for IND and NDA submissions for oncology, HIV, cardiovascular indications, On-label vs. off-label drug use GCP and Requirements of GCP Compliance, Ethical issues and Compliance to current ethical guidelines, Ethical Committees and their set up, Animal Ethical issues and compliance.</li> " +
            "</ul> " +

            "<b class='text-dark'>References</b> " +
            "<ul> " +
            "<li>Krogsgaard-Larsen et al. Textbook of Drug Design and Discovery. 4th Edition. CRC Press.</li> " +
            "<li>Kuhse, H. (2010). Bioethics: Anthology. Malden, MA: Blackwell. </li> " +
            "<li>Nally, J. D. (2006) GMP for Pharmaceuticals. 6th edition. CRC Press </li> " +
            "<li>Brody, T. (2016) Clinical Trials: Study Design, Endpoints and Biomarkers, Drug Safety, and FDA and ICH Guidelines. Academic Press.</li> " +
            "</ul> " +

            "<div class='rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3 col-md-9'> " +
            "<table class='table table-striped bg-white m-0'> " +
            "<tr> " +
            "<td class='fw-bold' style='width:350px'>Pre-requisite</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>N/A</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Total credits</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>3</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Type</td> " +
            "<td>:</td> " +
            "<td></td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Duration</td> " +
            "<td>:</td> " +
            "<td> First half of semester</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Name(s) of other Academic units to whom the course may be relevant</td> " +
            "<td>:</td> " +
            "<td>N/A</td> " +
            "</tr> " +
            "</table> " +
            "</div> " +
            "</div>",
    },

    {
        course_id: 'BB656',
        course_title: "BB 656 - Introduction to Mechanobiology",
        course_info: "<div class='px-md-4 px-2 list'> " +
            "<b class='text-dark d-block pt-2'>Course content</b> " +
            "<ul> " +
            "<li>Introduction to Mechanobiology</li> " +
            "<li>Alterations of Tissue/Cell </li> " +
            "<li>Properties in Diseases Extracellular Matrix (ECM) Composition </li> " +
            "<li>Basics of Stress and Strain </li> " +
            "<li>Rheological Characterization of Biopolymers </li> " +
            "<li>Physical Properties of Collagen Gels</li> " +
            "<li>Mechanosensitivity of Fibronectin</li> " +
            "<li>Composition of Focal Adhesions </li> " +
            "<li>Force Sensitivity of Focal adhesions</li> " +
            "<li>Effect of ECM properties on Cell Adhesion, Cell Spreading and Cell Migration</li> " +
            "</ul> " +

            "<b class='text-dark'>References</b> " +
            "<ul> " +
            "<li>Since this course will be research-oriented, there is no official textbook other than original research papers and reviews identified by the instructor.</li> " +
            "<li>Students will be responsible for downloading and printing PDFs.</li> " +
            "<li>B. Alberts, D. Bray, J. Levis, M. Raff, K. Roberts & J. D. Watson: Molecular Biology of the Cell; 5th Ed, Garland Science</li> " +
            "<li>Fung, Y. C.: Biomechanics: Mechanical Properties of Living Tissues. 2nd Ed., Springer.</li> " +
            "<li>R. Kamm and M. K. Mofrad. Cytoskeletal Mechanics: Models and Measurements. Cambridge University Press.</li> " +
            "</ul> " +

            "<div class='rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3 col-md-9'> " +
            "<table class='table table-striped bg-white m-0'> " +
            "<tr> " +
            "<td class='fw-bold' style='width:350px'>Pre-requisite</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>N/A</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Total credits</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>6 credits - Lecture</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Type</td> " +
            "<td>:</td> " +
            "<td>Core Course</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Duration</td> " +
            "<td>:</td> " +
            "<td>Full Semester</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Name(s) of other Academic units to whom the course may be relevant</td> " +
            "<td>:</td> " +
            "<td>N/A</td> " +
            "</tr> " +
            "</table> " +
            "</div> " +
            "</div>",
    },

    {
        course_id: 'BB663',
        course_title: "BB 663 - Medical Imaging Physics",
        course_info: "<div class='px-md-4 px-2 list'> " +
            "<b class='text-dark d-block pt-2'>Course content</b> " +
            "<ul> " +
            "<li>Planar X-rays: electromagnetic radiation, generation and characteristics of x-rays, x-ray tubes, interaction of x-rays with tissues.</li> " +
            "<li>Magnetic resonance imaging (MRI): concept of spin and nuclear magnetic resonance, spin decay through interaction with tissues, use of different magnets in MRI systems, one or two simple imaging sequences. </li> " +
            "<li>Ultrasound: characteristics of sound waves, piezoelectricity and generation of ultrasound, interaction of ultrasound with tissues, Doppler effect and its uses.</li> " +
            "</ul> " +

            "<b class='text-dark'>References</b> " +
            "<ul> " +
            "<li>Introduction to Medical Imaging: Physics, Engineering and Clinical Applications, Nadine Barrie Smith and Andrew Webb (Cambridge University Press, 1st edition, 2011).</li> " +
            "<li>Medical Imaging Physics, William R. Hendee and E. Russell Ritenour (Wiley-Liss, 4th edition, 2002)</li> " +
            "</ul> " +

            "<div class='rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3 col-md-9'> " +
            "<table class='table table-striped bg-white m-0'> " +
            "<tr> " +
            "<td class='fw-bold' width='120px'>Total credits</td> " +
            "<td width='20px'>:</td> " +
            "<td>3 credits - Lecture</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Type</td> " +
            "<td>:</td> " +
            "<td></td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Duration</td> " +
            "<td>:</td> " +
            "<td>First half of semester</td> " +
            "</tr> " +
            "</table> " +
            "</div> " +
            "</div>",
    },

    {
        course_id: 'CL662',
        course_title: "CL 662 - Introduction to Computational Biology",
        course_info: "<div class='px-md-4 px-2 list'> " +
            "<b class='text-dark d-block pt-2'>Course description</b> " +
            "<p>Flood of data on genomics, transcriptomics, proteomics, phenomics, and xxx-omics has led to the emergence of a new interdisciplinary field where organization, analysis, and processing of this data has to be done efficiently. The biological databases are of many different kinds and more and more are emerging with the advent of the technology in the respective field. Although the different databases are related to each other, the link is not always clear. The challenge lies in understanding the link between these databases to glean new biological information. Another avenue is to apply systems science approaches to understand biology better. The course introduces students to biological databases, inter-and intra-database relationships, various algorithms, verbal models, quantitative models, etc. The course would cater to both biologists as well as non-biologists.</p> " +

            "<b class='text-dark'>References</b> " +
            "<ul> " +
            "<li>Bioinformatics: A Practical Guide to the Analysis of Genes and Proteins. A.D. Baxevanis and B.F.F .Ouellette. Wiley- Interscience (1998).</li> " +
            "<li>Introduction to Computational Biology: Maps, Sequences, Genomes. M. S. Waterman. Chapman & Hall (1995)</li> " +
            "<li>Biological Sequence Analysis: Probabilistic Models of proteins and Nucleic Acids, R. Durbin, S. Eddy, Akrogh, G. Mitchison, Cambridge University Press (1990).</li> " +
            "<li>Bioinformatics Basics: Applications in Biological Science and Medicine, H. H. Rashidi and L. K. Buchler, CRC Press (2001)</li> " +
            "</ul> " +

            "<div class='rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3 col-md-9'> " +
            "<table class='table table-striped bg-white m-0'> " +
            "<tr> " +
            "<td class='fw-bold' width='120px'>Total credits</td> " +
            "<td width='20px'>:</td> " +
            "<td>6 credits - Lecture and practical</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Type</td> " +
            "<td>:</td> " +
            "<td>Department elective</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Duration</td> " +
            "<td>:</td> " +
            "<td>Full Semester</td> " +
            "</tr> " +
            "</table> " +
            "</div> " +
            "</div>",
    },

    {
        course_id: 'DH301',
        course_title: "DH 301: Basic Epidemiology",
        course_info: "<div class='px-md-4 px-2 list'> " +
            "<b class='text-dark d-block pt-2'>Course content</b> " +
            "<ul> " +
            "<li>Definition and objectives of epidemiology: Principles and methods of epidemiology to investigate disease distribution. Using epidemiologic methods to study the cause, extent and prevention of various infectious and non-infectious diseases.</li> " +
            "<li>Dynamics of disease transmission: modes of transmission, attack rate, disease outbreak investigation</li> " +
            "<li>Disease surveillance and measures of morbidity and mortality: Active and passive surveillance, emergency warning systems, stages of a disease, incidence and prevalence of disease, mortality rates, case fatality</li> " +
            "<li>Assessing screening tests: Validity of tests, Tests with binary and continuous outcomes, sequential testing, sensitivity and specificity measures</li> " +
            "<li>Different types of study design, including randomized trials, case-control and cohort studies, risk estimation and causal inferences.</li> " +
            "<li>Epidemiology and the development of policy.</li> " +
            "</ul> " +
            "<b class='text-dark'>References</b> " +
            "<ul> " +
            "<li>Gordis, Leon. Epidemiology. Fifth edition. Philadelphia, PA: Elsevier Saunders, 2014.</li> " +
            "<li>Rosner, B. Fundamentals of biostatistics. Belmont, CA: Thomson-Brooks/Cole, 2006.</li> " +
            "</ul> " +

            "<div class='rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3 col-md-9'> " +
            "<table class='table table-striped bg-white m-0'> " +
            "<tr> " +
            "<td class='fw-bold' style='width:300px'>Pre-requisite</td> " +
            "<td style='width: 20px;'>:</td> " +
            "<td>None</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Total credits</td> " +
            "<td>:</td> " +
            "<td>6 credits - Lecture and Tutorial</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Type</td> " +
            "<td>:</td> " +
            "<td>Department elective</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Duration</td> " +
            "<td>:</td> " +
            "<td>Full Semester - To be offered normally in 1<sup>st</sup> semester</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='border-0 fw-bold'>Name(s) of other Academic units to whom the course may be relevant</td> " +
            "<td class='border-0'>:</td> " +
            "<td class='border-0'>BSBE, CHE</td> " +
            "</tr> " +
            "</table> " +
            "</div> " +
            "</div>",
    },

    {
        course_id: 'DH607',
        course_title: "DH 607: Introduction to Computational Multi-omics",
        course_info: "<div class='px-md-4 px-2 list'> " +
            "<b class='text-dark d-block pt-2'>Course content</b> " +
            "<ul> " +
            "<li>Brief introduction to biology </li> " + 
            "<li>Brief history of bioinformatics </li> " + 
            "<li>Aligning Genomes </li> " + 
            "<li>Aligning transcriptomes (RNA-seq and quantification) </li> " + 
            "<li>RNA-seq differential expression statistical modelling </li> " + 
            "<li>Classification of gene expression data </li> " + 
            "<li>Deep learning application in transcriptomics </li> " + 
            "<li>Traditional dimensionality reduction for gene expression data </li> " + 
            "<li>Recent developments in dimensionality reduction tools and their applications in multiomics </li> " + 
            "<li>Statistical models for identifying transcription factor binding (MCMC, Gibbs sampling, ChIP-seq, ATAC-seq) </li> " + 
            "<li>Hidden markov models and applications in multiomics </li> " + 
            "<li>Markov chain and HMMs for gene finding </li> " + 
            "<li>Introduction to genome wide association studies </li> " + 
            "<li>Introduction to Cancer Genomics </li> " + 
            "<li>Characterizing cancer mutations </li> " + 
            "<li>Introduction  to single-cell multomics </li> " + 
            "<li>Statistical models for modeling CRISPR screens </li> " + 
            "<li>Introduction to CRISPR and CRISPR screens</li> " + 
            "<li>Recent advancements in statistical methods and deep learning applications in multiomics for human diseases </li> " + 
            "</ul> " +

            "<p>Each of the above major topic will have a tutorial session that will go over the implementation or application of existing statistical package on a real-world dataset  </p>"+
            "<b class='text-dark'>References</b> " +

            "<ul> " +
            "<li> Deonier, Richard C., Michael S. Waterman, and Simon Tavar303251. Computational Genome Analysis. Springer New York, 2005. </li> " + 
            "<li> Waterman, Michael S. Introduction to computational biology: maps, sequences and genomes. Chapman and Hall/CRC, 2018. </li>"+
            "<li> Compeau P, Pevzner P. Bioinformatics algorithms: an active learning approach., 3rd Edition. 2 Aug. </li>"+
            "<li> Holmes SH, Huber W. Modern statistics for modern biology. Cambridge university press; 2018. </li>"+
            "<li> Irizarry RA, Love MI. Data analysis for the life sciences with R. Chapman and Hall/CRC; 2016 Oct 4. </li>"+
            "<li> Brown TA. Genomes 5. CRC Press; 2023 Apr 28. </li>"+
            "<li> Recent papers on single-cell and bulk multiomics </li>"+
            "</ul> " +

            "<div class='rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3 col-md-9'> " +
            "<table class='table table-striped bg-white m-0'> " +
            "<tr> " +
            "<td class='fw-bold' style='width:300px'>Pre-requisite</td> " +
            "<td style='width: 20px;'>:</td> " +
            "<td> BB101 (for UGs); No prerequisites for PGs but prior exposure to basic biology is strongly recommended.</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Total credits</td> " +
            "<td>:</td> " +
            "<td> 6 credits </td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Type</td> " +
            "<td>:</td> " +
            "<td> 2 Lectures + 1 Tutorial </td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Duration</td> " +
            "<td>:</td> " +
            "<td>Full Semester</td> " +
            "</tr> " + 
            "</table> " +
            "</div> " +
            "</div>",
    },

    {
        course_id: 'DH803',
        course_title: "DH 803 - Wearable Health Technologies",
        course_info: "<div class='px-md-4 px-2 list'> " +
            "<b class='text-dark d-block pt-2'>Course content</b> " +
            "<ul> " +
            "<li>Background and Need for wearable monitoring. Need/Scope for wearable monitoring. Basics of common biomedical signals, basic parameters in physiological monitoring Source of data for wearable device: Electronic signals, Physical movements, Sweat, Tear, Sound, Breath, Basic sensors/transducers</li> " +
            "<li>Wearable Devices for Healthcare Types of Wearable Medical Devices Rehabilitation and Clinic or Ward based wearable devices. Point of care Devices or Home Health Combination of Monitoring and Therapeutics. Type of measurement: Absolute measurement and Relative measurement. Non-invasive monitoring and subcutaneous monitoring. Differentiation between commercial monitoring (such as Fitbit, Apple Watch, etc.,) versus medical-grade monitoring.</li> " +
            "<li>Technologies in Wearable Health Internet of Things, Cloud computing and Telemedicine systems. Artificial Intelligence & Machine Learning in Wearable Technology. Framework for integration with digital networks</li> " +
            "<li>Data Validation and Management in Wearables Devices. Potential interference factors that affect the reliability of wearable sensors. Data standards for interoperability Privacy, Security & Ethics</li> " +
            "<li>Applications: Current and Futuristic Healthcare monitoring embedded in clothing and wearable accessories. Virtual Reality and Augmented Reality in Wearable Healthcare devices; Health Gamification. Non-conventional Applications: Sports medicine, Space Health, Mental Health, Oral Health, Defence</li> " +
            "</ul> " +

            "<b class='text-dark'>References</b> " +
            "<ul> " +
            "<li>Eugenijus Kaniusas, Biomedical Signals and Sensors I, Linking Physiological Phenomena and Biosignals, Springer, </li> " +
            "<li>Edward Sazonov and Michael R. Neuman, Wearable Sensors: Fundamentals, Implementation and Applications, Elsevier Science, 2020.</li> " +
            "<li>Alan Godfrey and Stan Stuart, Digital Health Exploring Use and Integration of Wearables, Elsevier Science, 2021.</li> " +
            "<li>Manuel Cardona, Vijender Kumar Solanki, and Cecilia E. Garca Cena, Internet of Medical Things Paradigm of Wearable Devices, Taylor & Francis, 2021.</li> " +
            "<li>Peer-reviewed literature on various topics.</li> " +
            "<li>White papers from various wearable health device companies</li> " +
            "</ul> " +

            "<div class='rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3 col-md-9'> " +
            "<table class='table table-striped bg-white m-0'> " +
            "<tr> " +
            "<td class='fw-bold' style='width:350px'>Pre-requisite</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>N/A</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Total credits</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>6 credits - Lecture</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Type</td> " +
            "<td>:</td> " +
            "<td></td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Duration</td> " +
            "<td>:</td> " +
            "<td>Full Semester</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Name(s) of other Academic units to whom the course may be relevant</td> " +
            "<td>:</td> " +
            "<td>N/A</td> " +
            "</tr> " +
            "</table> " +
            "</div> " +
            "</div>",
    },

    {
        course_id: 'DH804',
        course_title: "DH 804 - Magnetic Resonance Imaging: From Physics to Physiology",
        course_info: `<div class="px-md-4 px-2 list">
                    <b class="text-dark d-block pt-2">Course content</b>
                    <ul>
                    <li>Basic<br>
                    1. An Overview of Magnetic Resonance Imaging, Electricity and Magnetism <br>
                    2. Nuclear Magnetism, Equilibrium-Saturation </li>
                    <li>The Magnetic Resonance Image <br>
                    3.Radiofrequency Pulse Sequences, Magnetic Resonance Imaging Tissue Parameters <br> 
                    4.Manipulating Magnetic Resonance Image Contrast, Fourier Transforms in Magnetic Resonance Imaging</li>
                    <li>The Imaging System <br> 
                    5. Magnetic Resonance Imaging Hardware, <br> 
                    6. Primary Magnetic Resonance Imaging Magnets,</li>
                    <li>Image Formation <br> 
                    7. Digital Imaging, A Walk Through the Spatial Frequency Domain <br> 
                    8. Magnetic Resonance Images</li>
                    <li>Pulse Sequences <br> 
                    9. Spin Echo Imaging, Chemical Shift and Magnetization Transfer <br> 
                    10. Steady State Gradient Echo Imaging, Echo Planar Imaging</li>
                    <li>Applications <br> 
                    11. Nuclear Magnetic Resonance Spectroscopy, Partially Parallel Magnetic Resonance Imaging <br> 
                    12. Magnetic Resonance Angiography, Perfusion Imaging  and safety consideration.</li>
                    </ul>

                    <b class="text-dark d-block pt-2">References</b>
                    <ul>
                    <li>"Magnetic Resonance Imaging: Physical and Biological Principles" by Stewart C. Bushong. ISBN:9780323073547 </li>
                    <li>Clinical Magnetic Resonance Imaging" by Edelman, Hesselink and Zlatkin. Publisher ‏ : ‎ Saunders; 3rd edition (16 November 2005), ISBN-10 ‏ : ‎ 0721603068</li>
                    <li>Magnetic Resonance Imaging – Physical Principles and Sequence Design" by Haacke, Brown, ISBN:9780471720850</li>
                    <li>Spin Dynamics: Basics of Nuclear Magnetic Resonance" by Malcolm H. Levitt. ISBN: 978-0-470-51117-6 </li>
                    </ul>

                    <div class="rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3 col-md-9">
                    <table class="table table-striped bg-white m-0"> 
                    <tr> 
                    <td class="fw-bold" style="width:350px">Prerequisite</td>
                    <td style="width:20px">:</td>
                    <td>Nothing but preferred that BB 663 and BB 627 have attended</td>
                    </tr>
                    <tr> 
                    <td class="fw-bold" style="width:350px">Total credits</td>
                    <td style="width:20px">:</td>
                    <td>3 credits - Lecture</td>
                    </tr>
                    <tr> 
                    <td class="fw-bold" style="width:350px">Type</td>
                    <td style="width:20px">:</td>
                    <td>Department Elective, Institute Elective</td>
                    </tr>
                    <tr> 
                    <td class="fw-bold" style="width:350px">Duration</td>
                    <td style="width:20px">:</td>
                    <td>Half  Semester - To be offered normally in 2nd semester</td>
                    </tr>
                    <tr> 
                    <td class="fw-bold" style="width:350px">
                    Name(s) of other Academic units to whom the course may be relevant
                    </td>
                    <td style="width:20px">:</td>
                    <td>None</td>
                    </tr>
                </table>
        </div> 
        </div>`
    },

    {
        course_id: 'DH805',
        course_title: "DH 805 - X-ray based Imaging in Healthcare",
        course_info: `<div class="px-md-4 px-2 list">
            <b class="text-dark d-block pt-2">Course content</b>
            <ul>
            <li>X ray production (X ray tubes, design considerations, interaction of Electrons with matter).</li>
            <li>X Ray based imaging of human body (Interaction of X Rays with matter, analog films, digital screens)</li>
            <li>Application of X-ray Imaging in Medicine </li>
            <li>AI in X-ray Radiography</li>
            <li>Principles of Computed Tomography (CT Scan) </li>
            <li>Image reconstruction and Post Processing in C </li>
            <li>Advanced Applications in CT </li>
            <li>AI solutions in CT</li>
            </ul>

            <b class="text-dark d-block pt-2">References</b>
            <ul>
            <li>Principles of Biomedical Instrumentation and Measurement by Richard Aston Merrill Publishing Company, 1990.</li>
            <li>Technical Fundamentals of Radiology and CT Guillermo Avendano Cervantes IOP Publishing, Bristol, UK, 2016 </li>
            </ul>

            <div class="rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3 col-md-9">
                <table class="table table-striped bg-white m-0"> 
                    <tr> 
                    <td class="fw-bold" style="width:350px">Prerequisite</td>
                    <td style="width:20px">:</td>
                    <td>None</td>
                    </tr>
                    <tr> 
                    <td class="fw-bold">Total credits</td>
                    <td>:</td>
                    <td>3 credits - Lecture</td>
                    </tr>
                    <tr> 
                    <td class="fw-bold">Type</td>
                    <td>:</td>
                    <td>Department Elective, Institute Elective</td>
                    </tr>
                    <tr> 
                    <td class="fw-bold">Duration</td>
                    <td>:</td>
                    <td>Half  Semester - To be offered normally in 1std semester</td>
                    </tr>
                    <tr> 
                    <td class="fw-bold">Name(s) of other Academic units to whom the course may be relevant</td>
                    <td>:</td>
                    <td>None</td>
                    </tr>
                </table>
        </div> 
        </div>`
    },

    {
        course_id: 'ME724',
        course_title: "ME 724 - Essentials of Turbulence",
        course_info: "<div class='px-md-4 px-2 list'> " +
            "<b class='text-dark d-block pt-2'>Course content</b> " +
            "<ul> " +
            "<li>Fundamental Considerations</li> " +
            "<li>The nature of turbulence</li> " +
            "<li>Fundamentals of Turbulence (Equations of fluid motion and Statistical Description of turbulent flows)</li> " +
            "<li>Scales of turbulent motion</li> " +
            "<li>Modelling and Simulation: Direct Numerical Simulation (DNS)</li> " +
            "<li>Modelling and Simulation: Large eddy simulation (LES)</li> " +
            "<li>Turbulent flow applications</li> " +
            "</ul> " +

            "<b class='text-dark'>References</b> " +
            "<ul> " +
            "<li>Turbulent Flows, Stephen B. Pope.</li> " +
            "<li>A First Course in Turbulence, Tennekes and Lumley</li> " +
            "<li>Turbulence: An introduction for scientists and engineers, P.A. Davidson</li> " +
            "</ul> " +

            "<div class='rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3 col-md-9'> " +
            "<table class='table table-striped bg-white m-0'> " +
            "<tr> " +
            "<td class='fw-bold' style='width:300px'>Pre-requisite</td> " +
            "<td style='width:20px'>:</td> " +
            "<td> " +
            "<ul class='m-0'> " +
            "<li>Undergraduate Fluid Mechanics (Compulsory)</li> " +
            "<li>Engineering Mathematics (Compulsory)</li> " +
            "<li>Ability to program, perform data analysis (Compulsory)</li> " +
            "<li>Advanced Fluid Mechanics (Not compulsory, but desirable)</li> " +
            "<li>Familiarity with probability theory, tensor algebra and constitutive equations (Not compulsory, but desirable) </li> " +
            "</ul> " +
            "</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Total credits</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>6</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Type</td> " +
            "<td>:</td> " +
            "<td>Theory</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Duration</td> " +
            "<td>:</td> " +
            "<td></td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Name(s) of other Academic units to whom the course may be relevant</td> " +
            "<td>:</td> " +
            "<td>N/A</td> " +
            "</tr> " +
            "</table> " +
            "</div> " +
            "</div>",
    },

    {
        course_id: 'ME780',
        course_title: "ME 780: Introduction to Biofluid Mechanics",
        course_info: "<div class='px-md-4 px-2 list'> " +
            "<b class='text-dark d-block pt-2'>Course content</b> " +
            "<ul> " +
            "<li>Structure and function of biological cells, Types of tissues, Hierarchical structure of tissues, Human circulatory system, Lung physiology, Skeletal physiology.</li> " +
            "<li>Continuum kinematics, Constitutive relationships for fluid & solid stress, Navier Stokes Equation, Generalized Hooke’s law, Potential flow theory, Vortex dynamics Swimming and flying: S callop theorem.</li> " +
            "<li>Resistive force theory, Motion of flagellum, Swimming of flexible sheet, small amplitude, swimming of fish, Steady flight, Soaring of birds, Clap and fling mechanism for insect flight.</li> " +
            "<li>Blood flow in human body - Blood rheology, Flow through arteries and veins, Wave propagation in arterial circulation, Flow separation in bifurcations, Pulsatile flow, Cardiac cycle, Heart motion, Heart valve function, Disease conditions, Microcirculation physiology,</li> " +
            "<li>Pressure and velocity distribution in microcirculation beds, Hematocrit/Fahraeus Lindquist Effect/Fahraeus Effect, Interactions between cells and vessel walls.</li> " +
            "<li>Mass transport in microcirculation Flow in lungs and joints – Pressure volume relationship for air flow in lungs, Oxygen/CO2 diffusion, Oxygen/CO 2 transport in blood.</li> " +
            "<li>Synovial fluid flow Poroelasticity of tissues - Mechanical modeling of biological tissues, Stress Strain pore pressure relationship, Fluid content stress pore pressure relationship.</li> " +
            "<li>Darcy’s law, Matrix material and fluid incompressibility constraints, Undrained elastic coefficients, Basic equations for poroelasticity, Compression of a fluid filled anisotropic disc, Poroelastic modeling of tissues, Modeling array of cilia</li> " +
            "</ul> " +

            "<b class='text-dark'>References</b> " +
            "<ul> " +
            "<li>P.K. Kundu, D. R. Dowling and I. M. Cohen. “Fluid Mechanics”. Elsevier Science, USA, 2011</li> " +
            "<li>D. Rubenstein, W. Yin, M. D. Frame. “Biofluid Mechanics: An Introduction to Fluid Mechanics, Microcirculation”. Academic Press, USA, 2015 </li> " +
            "<li>J.N. Mazumdar, “Biofluid mechanics”, World Scientific, Singapore, 1992 </li> " +
            "<li>M.J. Lighthill, “Mathematical Biofluid dynamics”, Society for Industrial & Applied Mathematics, USA, 1975 </li> " +
            "<li>W. Shyy, Y. Lian, J. Tang, D. Viieru, H. Liu, “Aerodynamics of Low Reynolds Number Flyers”, Cambridge University Press, New York, USA 2007 </li> " +
            "<li>S.C. Cowin, S.B. Doty, “Tissue Mechanics”, Springer Science and Business Media, 2007 </li> " +
            "<li>Y.C. Fung, “Biodynamics: Circulation”, Springer-Verlag, New York, USA, 1984</li> " +
            "</ul> " +

            "<div class='rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3 col-md-9'> " +
            "<table class='table table-striped bg-white m-0'> " +
            "<tr> " +
            "<td class='fw-bold' style='width:350px'>Pre-requisite</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>N/A</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Total credits</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>6</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Type</td> " +
            "<td>:</td> " +
            "<td></td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Duration</td> " +
            "<td>:</td> " +
            "<td></td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Name(s) of other Academic units to whom the course may be relevant</td> " +
            "<td>:</td> " +
            "<td>N/A</td> " +
            "</tr> " +
            "</table> " +
            "</div> " +
            "</div>",
    },

    {
        course_id: 'TD617',
        course_title: "TD 617 - Healthtech Innovation and Design",
        course_info: "<div class='px-md-4 px-2 list'> " +
            "<b class='text-dark d-block pt-2'>Course content</b> " +
            "<ul> " +
            "<li>Lectures will introduce the healthcare scenario in India with focus on the disease status, government policies, both at a national level, as well as specifically in the rural sector. It will also cover the fundamentals of need analysis, innovation, technology, design, development and deployment of med-tech devices. Marketability aspects will be introduced through case studies.</li> " +
            "<li>Tutorials: The tutorials will supplement the lecture series. In groups of 3 or 4, the students will practice need assessment techniques and define problem statements relevant to the med-tech industry. Students will be exposed to PHCs and community health departments and their ongoing preventive and therapeutic healthcare efforts. In further sessions, students will be taught techniques for project planning and production of a working unit with performance assessment. Where feasible, students will be encouraged to build actual prototypes using digital prototyping infrastructure available with the Instructor, as well as elsewhere on campus.</li> " +
            "</ul> " +
            "<b class='text-dark'>References</b> " +
            "<ul> " +
            "<li>Biodesign: The process of innovating medical technologies Zenios et al. (Eds.) Cambridge University Press, 20092. The Medical Device R&D Handbook. Kucklick, T., 2nd Ed., CRC Press, 23. India's Healthcare Industry: Innovation in Delivery, Financing, and Manufacturing. Burns, L. R. Cambridge University Press, 2014.4. Discussion Papers and Policy Notes from the World Bank</li> " +
            "</ul> " +
            "<div class='rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3 col-md-9'> " +
            "<table class='table table-striped bg-white m-0'> " +
            "<tr> " +
            "<td class='fw-bold' style='width:350px'>Pre-requisite</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>N/A</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Total credits</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>6</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Type</td> " +
            "<td>:</td> " +
            "<td>Theory</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Duration</td> " +
            "<td>:</td> " +
            "<td></td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Name(s) of other Academic units to whom the course may be relevant</td> " +
            "<td>:</td> " +
            "<td>N/A</td> " +
            "</tr> " +
            "</table> " +
            "</div> " +
            "</div>",
    },

    {
        course_id: 'CS419',
        course_title: "CS 419 - Introducing to Machine Learning",
        course_info: "<div class='px-md-4 px-2 list'> " +
            "<b class='text-dark d-block pt-2'>Course content</b> " +
            "<ul> " +
            "<li>This course will provide a broad overview of Machine Learning with a stress on applications. </li> " +
            "<li>Supervised learning: Decision trees, Nearest neighbor classifiers, Generative classifiers like naive Bayes, Support vector Machines</li> " +
            "<li>Unsupervised learning: K-Means clustering, Hierarchical clustering, EM, Itemset mining</li> " +
            "<li>Applications: image recognition, speech recognition, text and web data retrieval, bio-informatics, commercial data mining.</li> " +
            "</ul> " +

            "<b class='text-dark'>References</b> " +
            "<ul> " +
            "<li>Tom Mitchell, Machine Learning. McGraw-Hill, 1997. 302240302240302240</li> " +
            "<li>Pattern recognition and machine learning by Christopher Bishop, SPringer Verlag 2006 302240302240302240</li> " +
            "<li>Selected papers</li> " +
            "</ul> " +

            "<div class='rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3 col-md-9'> " +
            "<table class='table table-striped bg-white m-0'> " +
            "<tr> " +
            "<td class='fw-bold' style='width:350px'>Pre-requisite</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>N/A</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Total credits</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>6 credits - Lecture</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Type</td> " +
            "<td>:</td> " +
            "<td>Core Course</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Duration</td> " +
            "<td>:</td> " +
            "<td>Full Semester</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Name(s) of other Academic units to whom the course may be relevant</td> " +
            "<td>:</td> " +
            "<td>N/A</td> " +
            "</tr> " +
            "</table> " +
            "</div> " +
            "</div>",
    },

    {
        course_id: 'CS631',
        course_title: "CS 631 - Implementation Techniques for Relational Database Systems",
        course_info: "<div class='px-md-4 px-2 list'> " +
            "<b class='text-dark d-block pt-2'>Course content</b> " +
            "<ul> " +
            "<li>Physical storage and indexing structures, Query processing algorithms, Query optimization, Transaction processing and serializability, Concurrency Control, Recovery.</li> " +
            "<li>Big data management: transaction and query processing on parallel and distributed databases including issues of availability, replication, consistency, concurrency control, and recovery. Emerging database trends.</li> " +
            "</ul> " +

            "<b class='text-dark'>References</b> " +
            "<ul> " +
            "<li>Abraham Silberschatz, Henry F. Korth and S. Sudarshan, Database System Concepts 6th Ed, McGraw Hill, 2010</li> " +
            "<li>Transaction Processing, Concepts and Techniques, J. Gray and A. Reuter, Morgan Kauffman, 1994. </li> " +
            "<li>Research papers from leading conferences</li> " +
            "</ul> " +

            "<div class='rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3 col-md-9'> " +
            "<table class='table table-striped bg-white m-0'> " +
            "<tr> " +
            "<td class='fw-bold' style='width:350px'>Pre-requisite</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>Must have done a basic course in databases either as part of curriculum or online, which covers relational databases, SQL, functional dependencies and normalization at a minimum, and read up on other material on your own.</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Total credits</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>6</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Type</td> " +
            "<td>:</td> " +
            "<td></td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Duration</td> " +
            "<td>:</td> " +
            "<td>Autumn 2022</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Name(s) of other Academic units to whom the course may be relevant</td> " +
            "<td>:</td> " +
            "<td>N/A</td> " +
            "</tr> " +
            "</table> " +
            "</div> " +
            "</div>",
    },

    {
        course_id: 'CS663',
        course_title: "CS 663 - Fundamentals of Digital Image Processing",
        course_info: "<div class='px-md-4 px-2 list'> " +
            "<b class='text-dark d-block pt-2'>Course content</b> " +
            "<p>Image enhancement: histogram equalization and specification, contrast modification, neighborhood filtering, image smoothing and image sharpening. Frequency domain processing: Sampling theorem, Fourier transforms and their properties, applications in image filtering Edge detection Principal components analysis: applications in face recognition [eigenfaces], and denoising (later) Image restoration: denoising, deblurring Image segmentation: region-based methods, template matching, Hough transform, Mean shift, active contours (snakes) Color models, filtering of color images Image compression: JPEG, wavelet representation for images Tomography, radon Transform, projection theorem, image reconstruction from back-projections Statistics of natural images (time permitting), Morphological image processing (time permitting), Sparse representations and non-local similarity (time permitting), introduction to compressive sensing (time permitting)</p> " +

            "<div class='rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3 col-md-9'> " +
            "<table class='table table-striped bg-white m-0'> " +
            "<tr> " +
            "<td class='fw-bold'>Total credits</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>6 - Lecture and Tutorial</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Type</td> " +
            "<td>:</td> " +
            "<td>Department Elective</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Duration</td> " +
            "<td>:</td> " +
            "<td>Full Semester</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Name(s) of other Academic units to whom the course may be relevant</td> " +
            "<td>:</td> " +
            "<td></td> " +
            "</tr> " +
            "</table> " +
            "</div> " +
            "</div>",
    },

    {
        course_id: 'CS725',
        course_title: "CS 725 - Foundations of Machine Learning",
        course_info: "<div class='px-md-4 px-2 list'> " +
            "<b class='text-dark d-block pt-2'>Course content</b> " +
            "<ul> " +
            "<li> Supervised learning: decision trees, nearest neighbor classifiers, generative classifiers like naive Bayes, linear discriminant analysis, loss regularization framework for classification, Support vector Machines Regression methods: least-square regression, kernel regression, regression trees Unsupervised learning: k-means, hierarchical, EM, non-negative matrix factorization, rate distortion theory. </li> " +
            "</ul> " +

            "<b class='text-dark'>References</b> " +
            "<ul>  " +
            "<li> Hastie, Tibshirani, Friedman The elements of Statistical Learning Springer Verlag </li> " +
            "<li> Pattern recognition and machine learning by Christopher Bishop.</li> " +
            "<li> Selected papers. </li> " +
            "</ul> " +

            "<div class='rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3 col-md-9'> " +
            "<table class='table table-striped bg-white m-0'> " +
            "<tr> " +
            "<td class='fw-bold' style='width:300px'>Pre-requisite</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>Remedial co-requisite: Mathematical foundations (Separately proposed by Prof. Saketh Nath) Recommended parallel courses: CS709 (Convex optimization) </td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Total credits</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>6</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Type</td> " +
            "<td>:</td> " +
            "<td>Theory</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Duration</td> " +
            "<td>:</td> " +
            "<td></td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Name(s) of other Academic units to whom the course may be relevant</td> " +
            "<td>:</td> " +
            "<td>N/A</td> " +
            "</tr> " +
            "</table> " +
            "</div> " +
            "</div>",
    },

    {
        course_id: 'CS726',
        course_title: "CS 726 - Advanced Machine Learning",
        course_info: "<div class='px-md-4 px-2 list'> " +
            "<b class='text-dark d-block pt-2'>Course content</b> " +
            "<ul> " +
            "<li>Modelling, generation, and prediction of multiple inter-dependent variables.</li> " +
            "<li>The topics covered will span probabilistic graphical models (directed and undirected), </li> " +
            "<li>Inference methods like junction trees.</li> " +
            "<li>Belief propagation, and other approximate methods, </li> " +
            "<li>MCMC sampling methods like Gibbs and Langevin, </li> " +
            "<li>Generative models like variational auto-encoders,</li> " +
            "<li>GANs, Deep Gaussian processes, </li> " +
            "<li>Neural architectures for structured predictions,</li> " +
            "<li>Neural density estimation methods, causality, and other recent topics in machine learning.</li> " +
            "</ul> " +

            "<b class='text-dark'>References</b> " +
            "<ul> " +
            "<li>Probabilistic Graphical Models: Principles and Techniques, by Daphne Koller and Nir Friedman, MIT Press, 2009</li> " +
            "<li>Deep Learning by Ian Goodfellow, Yoshua Bengio and Aaron Courville, MIT Press, 2016</li> " +
            "</ul> " +

            "<div class='rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3 col-md-9'> " +
            "<table class='table table-striped bg-white m-0'> " +
            "<tr> " +
            "<td class='fw-bold' style='width:300px'>Pre-requisite</td> " +
            "<td style='width:20px'>:</td> " +
            "<td> " +
            "<ul> " +
            "<li>A formal introductory ML course like CS 725 or CS 337 or CS 419 or DS 303 is required. Online ML courses do not qualify as pre-requisites. </li> " +
            "<li>The course assumes basic knowledge of probability, statistics, and linear algebra.</li> " +
            "<li>Chapters 2 and 3 of the Deep-learning book are a good place to refresh the necessary required background. </li> " +
            "<li>Also, the course assumes basic background in machine learning, for example as covered in Chapter 5 of the Deep-learning book and deep learning, for example, as covered in Chapter 6 of the same book. Further, we will assume that students are familiar with CNNs, RNNs, and sequence to sequence learning with attention.</li> " +
            "</ul> " +
            "</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Total credits</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>6</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Type</td> " +
            "<td>:</td> " +
            "<td>Theory</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Duration</td> " +
            "<td>:</td> " +
            "<td></td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Name(s) of other Academic units to whom the course may be relevant</td> " +
            "<td>:</td> " +
            "<td></td> " +
            "</tr> " +
            "</table> " +
            "</div> " +
            "</div>",
    },

    {
        course_id: 'CS736',
        course_title: "CS 736 - Medical Image Computing",
        course_info: "<div class='px-md-4 px-2 list'> " +
            "<b class='text-dark d-block pt-2'>Course Description</b> " +
            "<p>The implementation-based experiments will rely on C/C++or Matlab environments. As part of the implementation-based experiments the students will be introduced to software tools for medical image processing; examples include popular open-source cross-platform software packages for medical image analysis like the Insight Toolkit (<a href='https://itk.org/' target='_blank'><u>www.itk.org</u></a>), Visualization Toolkit (<a href='https://vtk.org/' target='_blank'><u>www.vtk.org</u></a>),etc., or other tools built using these packages. The course will use simulated and clinical medical image datasets available freely through the Interne from universities or research institutions worldwide.</p> " +
            "<b class='text-dark d-block pt-2'>Content</b> " +
            "<ul> " +
            "<li>Introduction to imaging modalities, mathematical imaging models, noise and artefact models, sampling, signal modelling and fitting X ray, computed tomography (CT), positron 302225 emission   tomography (PET), magnetic resonance imaging (MRI) (including diffusion MRI, functional MRI),  ultrasound, microscopy </li> " +
            "<li>Visualization Methods: sectioning, multimodal images, 302225 overlays, rendering surfaces and volumes, using glyphs Application domains: 3D imaging, PET-CT 302225 imaging, diffusion tensor imaging </li> " +
            "<li>Image reconstruction Methods: image models, sampling, problem 302225 formulations, algorithms Application domains: MRI, CT 302225 </li> " +
            "<li>Image denoising Methods: Bayesian estimation, nonlinear 302225 smoothing Application domains: MRI, CT, others 302225</li> " +
            "<li>Image segmentation Methods: clustering, Bayesian estimation, 302225 graph partitioning, classification Application domains: brain, heart, knee, 302225 thorax, abdomen; MRI, CT, ultrasound; cancer imaging</li> " +
            "<li>Anatomical shape analysis Methods: descriptors, learning shape models, 302225 hypothesis testing Application domains: brain, others 302225</li> " +
            "<li>Image registration Methods: similarity, transformation 302225 Applications: anatomical atlas, co- 302225 registration, motion correction</li> " +
            "<li>Content based medical-image retrieval Methods: image descriptors, image similarity 302225 Applications 302225</li> " +
            "</ul> " +

            "<b class='text-dark'>References</b> " +
            "<ul> " +
            "<li>Guide to Medical Image Analysis: Methods and Algorithms Author: Klaus D. Toennies Springer, ISBN 978-1-4471-2751-2</li> " +
            "<li>Mathematics of Medical Imaging Author: Charles L. Epstein Prentice Hall, 2003. ISBN  97801306754843.</li> " +
            "<li>Medical Image Reconstruction: A Conceptual Tutorial Author: Gengsheng L. Zeng Springer, 2010. ISBN 978-3-642-05368-9</li> " +
            "<li>Statistical Models of Shape: Optimisation and Evaluation Authors: Rhodri H. Davies, Carole J. Twining, Chris J. Taylor Springer, 2010. ISBN 978-1-84800-137-4</li> " +
            "<li>Medical Image Registration Authors: Joseph V. Hajnal, Derek L.G. Hill, David Hawkes CRC Press, 2001. ISBN: 0849300649</li> " +
            "<li>Reference Notes - Biomedical Signal and Image Processing, Spring 2007 MIT Open Course Ware: Massachusetts Institute of Technology MIT Course Number: HST.582J Authors: Gari Clifford, John Fisher, Julie Greenberg, William Wells <a href='https://ocw.mit.edu/courses/health-sciences-and-technology/hst-582j-biomedical-signal-and-image-processing-spring-2007/' target='_blank'><u>ocw.mit.edu</u></a></li> " +
            "</ul> " +

            "<div class='rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3 col-md-9'> " +
            "<table class='table table-striped bg-white m-0'> " +
            "<tr> " +
            "<td class='fw-bold' style='width:120px'>Pre-requisite</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>N/A</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold' style='width:120px'>Total credits</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>6 credits - Lecture</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Type</td> " +
            "<td>:</td> " +
            "<td>Department elective</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Duration</td> " +
            "<td>:</td> " +
            "<td>Full Semester</td> " +
            "</tr> " +
            "</table> " +
            "</div> " +
            "</div>",
    },

    {
        course_id: 'CS754',
        course_title: "CS 754 - Advanced Image Processing",
        course_info: "<div class='px-md-4 px-2 list'> " +
            "<b class='text-dark d-block pt-2'>Course content</b>" +
            "<ul>" +
            "<li>Image transforms and statistics of natural images</li>" +
            "<li>Survey of statistical properties of image transform coefficients </li>" +
            "<li>Implications of these statistics for important image processing applications such as denoising, compression, source separation, deblurring and image forensics </li>" +
            "<li>Non-local self-similarity in images</li>" +
            "<li>Dictionary learning and sparse representations in image processing</li>" +
            "<li>Overview of Principal Components Analysis (PCA), Singular Value Decomposition (SVD) and Independent Components Analysis (ICA); PCA, SVD and ICA in the context of image processing</li>" +
            "<li>Sparse PCA</li>" +
            "<li>Concept of overcomplete dictionaries</li>" +
            "<li>Greedy pursuit algorithms: matching pursuit (MP), orthogonal matching pursuit (OMP) and basis pursuit (BP)</li>" +
            "<li>Popular dictionary learning techniques: Method of Optimal Directions (MOD), Unions of Orthonormal Bases, K-SVD, Non-negative sparse coding – along with applications in image compression, denoising, inpainting and deblurring</li>" +
            "<li>Sparsity-seeking algorithms: iterative shrinkage and thresholding (ISTA) (3) Compressed Sensing (CS) </li>" +
            "<li>Concept and need for CS</li>" +
            "<li>Theoretical treatment: concept of coherence, null-space property and restricted isometry property, proof of a key theorem in CS</li>" +
            "<li>Algorithms for CS (covered in part 2) and some key properties of these algorithms</li>" +
            "<li>Applications of CS: Rice Single Pixel Camera and its variants, Video compressed sensing, Color and Hyperspectral CS, Applications in Magnetic Resonance Imaging (MRI), Implications for Computed Tomography</li>" +
            "<li>CS under Forward Model Perturbations: a few key results and their proofs as well as applications</li>" +
            "<li>Designing Forward Models for CS</li>" +
            "<li>Low-rank matrix estimation and Robust Principal Components Analysis: concept and application scenarios in image processing, statement of some key theorems, and proof of one important theorem</li>" +
            "</ul>" +

            "<b class='text-dark'>References</b>" +
            "<ul>" +
            "<li>We will extensively refer to the following textbooks, besides a number of research papers from journals such as IEEE Transactions on Image Processing, IEEE Transactions on Signal Processing, and IEEE Transactions on Pattern Analysis and Machine Intelligence:</li>" +
            "<li>'Natural Image Statistics' by Aapo Hyvarinen, Jarmo Hurri and Patrick Hoyer, Springer Verlag 2009 (<a href='http://www.naturalimagestatistics.net/' target='_blank'>http://www.naturalimagestatistics.net/</a> - freely downloadable online)</li>" +
            "<li>'A Mathematical Introduction to Compressive Sensing' by Simon Foucart and Holger Rauhut, Birkhauser, 2013 (<a href='http://www.springer.com/us/book/9780817649470' target='_blank'>http://www.springer.com/us/book/9780817649470</a>)</li>" +
            "<li>Fung, Y. C.: Biomechanics: Mechanical Properties of Living Tissues. 2nd Ed., Springer.</li>" +
            "<li>R. Kamm and M. K. Mofrad. Cytoskeletal Mechanics: Models and Measurements. Cambridge University Press. </li>" +
            "</ul>" +

            "<div class='rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3 col-md-9'>" +
            "<table class='table table-striped bg-white m-0'>" +
            "<tr>" +
            "<td class='fw-bold' style='width:350px'>Pre-requisite</td>" +
            "<td style='width:20px'>:</td>" +
            "<td>N/A</td>" +
            "</tr>" +
            "<tr>" +
            "<td class='fw-bold'>Total credits</td>" +
            "<td style='width:20px'>:</td>" +
            "<td>6 credits - Lecture</td>" +
            "</tr>" +
            "<tr>" +
            "<td class='fw-bold'>Type</td>" +
            "<td>:</td>" +
            "<td>Core Course</td>" +
            "</tr>" +
            "<tr>" +
            "<td class='fw-bold'>Duration</td>" +
            "<td>:</td>" +
            "<td>Full Semester</td>" +
            "</tr>" +
            "<tr>" +
            "<td class='fw-bold'>Name(s) of other Academic units to whom the course may be relevant</td>" +
            "<td>:</td>" +
            "<td>N/A</td>" +
            "</tr>" +
            "</table>" +
            "</div>" +
            "</div>",
    },

    {
        course_id: 'CS769',
        course_title: "CS 769 - Optimization in Machine Learning",
        course_info: "<div class='px-md-4 px-2 list'> " +
            "<b class='text-dark d-block pt-2'>Course content</b> " +
            "<ul> " +
            "<li>Continuous and Discrete Optimization are two important pillars of machine learning. Continuous optimization typically occurs in learning model parameters while discrete optimization problems appear in inference and auxiliary tasks such as feature selection, data subset selection, model compression etc.</li> " +
            "<li>In the first part of this course, we will cover continuous optimization with applications in machine learning.</li> " +
            "<li>Topics to discuss will include Convexity, Gradient Methods, Proximal algorithms, Stochastic and Online Variants of mentioned methods, Coordinate Descent Methods, Sub gradient Methods, Non-Convex Optimization, Frank Wolfe, Accelerated Methods, Lagrange and Fenchel Duality, Second-Order Methods, Quasi-Newton Methods, Gradient-Free and Zero-Order Optimization.</li> " +
            "<li>We will also cover some advanced topics including non-convex optimization, alternating minimization algorithms and parallel/distributed variants of algorithms. </li> " +
            "<li>We will ground all these algorithms with applications and loss functions in machine learning (starting from logistic regression, linear regression, svms, matrix factorization right up to deep neural networks).</li> " +
            "<li>Summarily, in Continuous Optimization we will cover topics such as Basics of Continuous Optimization, Convexity, Gradient Descent, Projected/Proximal GD, Sub gradient Descent, Accelerated Gradient Descent, Newton & Quasi Newton, Duality: Legrange, Fenchel, Coordinate Descent, Frank Wolfe, Continuous Optimization in Practice, etc.</li> " +
            "<li>The second part of this course will cover the fundamentals of discrete optimization.</li> " +
            "<li>We will start with basic forms of combinatorial optimization (knapsack, s-t cuts/paths, matchings and matroids) and then discuss submodular functions and their applications.</li> " +
            "<li>We will cover algorithms for submodular minimization and maximization under various combinatorial constraints.</li> " +
            "<li>We will also discuss discrete probabilistic models such as Determinantal Point Processes (DPPs). </li> " +
            "<li>We will ground all these algorithmic and theoretical results with real world applications in feature selection, summarization and diversified search, structured prediction, data subset selection and model compression.</li> " +
            "<li>Summarily, in Discrete Optimization we will cover topics such as Linear Cost Problems with Applications in ML, Matroids, Spanning Trees, s-t paths, s-t cuts, Matchings, Covers (Set Covers, Vertex Covers, Edge Covers), Non-Linear Discrete Optimization, Submodular Functions and Applications in Machine Learning, Submodularity and Convexity, Submodular Minimization, Submodular Maximization, Beyond Minimization and Maximization: Other forms of Submodular Optimization, Discrete Optimization in Practice</li> " +
            "</ul> " +

            "<b class='text-dark'>References</b> " +
            "<ul> " +
            "<li>S303251 bastien Bubeck, Convex Optimization: Algorithms and Complexity , In Foundations and Trends in Machine Learning 8(3-4): 231-357 Online copy: <a href='https://arxiv.org/abs/1405.4980' target='_blank'>https://arxiv.org/abs/1405.4980</a> </li> " +
            "<li>Zhang, Aston, et al., Dive into Deep Learning , Chapter 11, Unpublished draft. Retrieved 3 (2019): 319, by Zhang, Lipton, Li and Smola. Online copy: <a href='http://d2l.ai/' target='_blank'>http://d2l.ai/</a></li> " +
            "<li>Bach, Francis. 'Learning with submodular functions: A convex optimization perspective.' Foundations and Trends 302256 in Machine Learning 6.2-3 (2013): 145-373. Online copy: <a href='https://arxiv.org/pdf/1111.6453.pdf' target='_blank'>https://arxiv.org/pdf/1111.6453.pdf</a></li> " +
            "<li>Boyd, Stephen, Stephen P. Boyd, and Lieven Vandenberghe, Convex optimization, Cambridge university press, 2004. Online copy: <a href='https://web.stanford.edu/~boyd/cvxbook/bv_cvxbook.pdf' target='_blank'>https://web.stanford.edu/~boyd/cvxbook/bv_cvxbook.pdf</a></li> " +
            "<li>Yurii Nesterov, 'Introductory lectures on convex optimization.' (2004). Online copy:<a href='https://www.springer.com/gp/book/9781402075537' target='_blank'>https://www.springer.com/gp/book/9781402075537</a></li> " +
            "<li>Schrijver, Alexander. 'A course in combinatorial optimization', CWI, Kruislaan 413 (2003): 1098. Online copy: <a href='https://homepages.cwi.nl/~lex/files/dict.pdf' target='_blank'>https://homepages.cwi.nl/~lex/files/dict.pdf</a></li> " +
            "<li>Schrijver, Alexander. Combinatorial optimization: polyhedra and efficiency. Vol. 24. Springer Science & Business Media, 2003. Online copy:<a href='https://www.springer.com/gp/book/9783540443896' target='_blank'>https://www.springer.com/gp/book/9783540443896</a></li> " +
            "<li>Fujishige, Satoru. Submodular functions and optimization. Elsevier, 2005. Online copy: <a href='https://www.elsevier.com/books/submodular-functions-and-optimization/fujishige/978-0-444-52086-9' target='_blank'>https://www.elsevier.com/books/submodular-functions-and-optimization/fujishige/978-0-444-52086-9</a> " +
            "</li> " +
            "</ul> " +

            "<div class='rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3 col-md-9'> " +
            "<table class='table table-striped bg-white m-0'> " +
            "<tr> " +
            "<td class='fw-bold' style='width:350px'>Pre-requisite</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>N/A</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Total credits</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>6 credits - Lecture</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Type</td> " +
            "<td>:</td> " +
            "<td>Core Course</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Duration</td> " +
            "<td>:</td> " +
            "<td>Full Semester</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Name(s) of other Academic units to whom the course may be relevant</td> " +
            "<td>:</td> " +
            "<td>N/A</td> " +
            "</tr> " +
            "</table> " +
            "</div> " +
            "</div>",
    },

    {
        course_id: 'DH306',
        course_title: "DH 306: Healthcare Performance Metrics",
        course_info: "<div class='px-md-4 px-2 list'> " +
            "<b class='text-dark d-block pt-2'>Course content</b> " +
            "<ul> " +
            "<li>Key performance metrics: KPI taxonomy, selecting indicators, data collection,</li> " +
            "<li>Nonclinical indicators: Length of Stay, Beds, Outpatient service visits, Occupancy rates, Total discharges, etc</li> " +
            "<li>Clinical indicators: Definitions, inpatient quality indicators, prevention quality indicators, patient safety indicators, pediatric indicators</li> " +
            "<li>Benchmarks, sources, safety benchmarks</li> " +
            "<li>Reporting, reporting options, reporting designs</li> " +
            "<li>Statistics: Predictive modeling, indicator adjustments, statistical process control costing</li> " +
            "</ul> " +
            "<b class='text-dark'>References</b> " +
            "<ul> " +
            "<li>Bergeron, B. P. (2017). Performance Management in Healthcare: From Key Performance Indicators to Balanced Scorecard. United Kingdom: Taylor & Francis.</li> " +
            "<li>Langabeer II, J. R. (2018). Performance Improvement in Hospitals and Health Systems: Managing Analytics and Quality in Healthcare, 2nd Edition. United States: Taylor & Francis.</li> " +
            "</ul> " +

            "<div class='rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3 col-md-9'> " +
            "<table class='table table-striped bg-white m-0'> " +
            "<tr> " +
            "<td class='fw-bold' style='width:300px'>Pre-requisite</td> " +
            "<td style='width: 20px;'>:</td> " +
            "<td>None</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Total credits</td> " +
            "<td>:</td> " +
            "<td>6 credits - Lecture and Tutorial</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Type</td> " +
            "<td>:</td> " +
            "<td>Department elective</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Duration</td> " +
            "<td>:</td> " +
            "<td>Full Semester - To be offered normally in 2<sup>nd</sup> semester</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='border-0 fw-bold'>Name(s) of other Academic units to whom the course may be relevant</td> " +
            "<td class='border-0'>:</td> " +
            "<td class='border-0'>BSBE, Math, CSE, CHE</td> " +
            "</tr> " +
            "</table> " +
            "</div> " +
            "</div>",
    },

    {
        course_id: 'DH308',
        course_title: "DH 308: Clinical Data Management",
        course_info: "<div class='px-md-4 px-2 list'> " +
            "<b class='text-dark d-block pt-2'>Course content</b> " +
            "<ul> " +
            "<li>Introduction to Clinical Data Management (CDM), CDM flow,</li> " +
            "<li>Case report form (CRF) design, Function of the CRF, Procedure for CRF design,</li> " +
            "<li>Data capture, Traditional data capture methods, Data from external databases,</li> " +
            "<li>Reading optical images data,</li> " +
            "<li>Factors affecting data capture,</li> " +
            "<li>Remote data entry methods, </li> " +
            "<li>Introduction to database design and build, database validation, Quality assurance, and CDM, Audit, Error Metrics,</li> " +
            "<li>CDM Training, Computer validation,</li> " +
            "<li>Performance measures, status reporting,</li> " +
            "<li>Data Presentation, Clinical research issues for data presentation, types of clinical data</li> " +
            "<li>Hands-on practice with clinical data loading, database creation, quality control of clinical data</li> " +
            "</ul> " +
            "<b class='text-dark'>References</b> " +
            "<ul> " +
            "<li>Clinical Data Management: 2nd Edition by Richard K. Rondel, Sheila A. Varley, Colin F. Webb </li> " +
            "<li>Practical Guide to Clinical Data Management by Susanne Prokscha - Taylor & Francis </li> " +
            "<li>Appropriate research articles for each topic will be suggested in class.</li> " +
            "</ul> " +

            "<div class='rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3'> " +
            "<table class='table table-striped bg-white m-0'> " +
            "<tr> " +
            "<td class='fw-bold' style='width:300px'>Pre-requisite</td> " +
            "<td style='width: 20px;'>:</td> " +
            "<td>None</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Total credits</td> " +
            "<td>:</td> " +
            "<td>6 credits - Lecture</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Type</td> " +
            "<td>:</td> " +
            "<td>Department elective</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Duration</td> " +
            "<td>:</td> " +
            "<td>Full Semester - To be offered normally in 2<sup>nd</sup> semester</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='border-0 fw-bold'>Name(s) of other Academic units to whom the course may be relevant</td> " +
            "<td class='border-0'>:</td> " +
            "<td class='border-0'>BSBE, Math, CSE, CHE</td> " +
            "</tr> " +
            "</table> " +
            "</div> " +
            "</div>",
    },

    {
        course_id: 'DH602',
        course_title: "DH 602 - Machine Learning and Statistical Methods in Healthcare",
        course_info: `<div class="px-md-4 px-2 list">
            <b class="text-dark d-block pt-2">Course content</b>
            <ul>
            <li>Biostatistics </li>
            <li>Medical Data Acquisition and Pre-processing</li>
            <li>Medical Data Efficient Machine Learning</li>
            <li>Feature Extraction</li>
            <li>Whole slide imaging: Breast cancer detection. Image processing for Brain tumor detection,</li>
            <li>Harling long tailed problems in Healthcare</li>
            <li>Sickle Cell Disease Management: A Machine Learning Approach</li>
            <li>Detection of Pulmonary Diseases,</li>
            <li>Longitudinal predictive analysis</li>
            <li>Dialysis patient data</li>
            <li>Federated Learning in Healthcare,</li>
            <li>Large Language models in Healthcare</li>
            <li>Normative aging Model using MRI data</li>
            <li>Optical Character Recognition for data extraction from medical records,</li>
            <li>Multimodal data analysis</li>
            <li>Utilizing Autoencoders in Healthcare,</li>
            <li>Generative Al in Healthcare</li>
            </ul>

            <b class="text-dark d-block pt-2">References</b>
            <ul>
            <li>Singh, B.K., & Sinha, G.R. (2022). Machine Learning in Healthcare: Fundamentals and recent applications of Machine Learning </li>
            <li>Hartzband. D. (2019). Information Technology and Data in Healthcare: Using and Understanding Data </li>
            <li>Natarajan, P Frenzel, J. C., & Smaltz, D. H. (2023). Demystifying Big Data and Machine Learning or Healthcare </li>
            <li>Kumar N, Gupta R, Gupta S. Whole Slide Imaging (WSI) in Pathology Current Perspectives and Future Directions </li>
            <li>decile.erg </li>
            </ul>

            <div class="rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3 col-md-9">
                <table class="table table-striped bg-white m-0"> 
                    <tr> 
                    <td class="fw-bold" style="width:350px">Prerequisite</td>
                    <td style="width:20px">:</td>
                    <td>None</td>
                    </tr>
                    <tr> 
                    <td class="fw-bold">Total credits</td>
                    <td>:</td>
                    <td>6 credits - Lecture</td>
                    </tr>
                    <tr> 
                    <td class="fw-bold">Type</td>
                    <td>:</td>
                    <td>Department Elective, Institute Elective</td>
                    </tr>
                    <tr> 
                    <td class="fw-bold">Duration</td>
                    <td>:</td>
                    <td>Full  Semester </td>
                    </tr>
                    <tr> 
                    <td class="fw-bold">Name(s) of other Academic units to whom the course may be relevant</td>
                    <td>:</td>
                    <td>CS 419 OR CS 726 OR EE 769 OR DS 303 </td>
                    </tr>   
                </table>
        </div> 
        </div>`
    },

    
    {
        course_id: 'DH604',
        course_title: "DH 604 - R&D Project - I",
        course_info: `<div class="px-md-4 px-2 list">
            <b class="text-dark d-block pt-2">Course content</b>
            <ul>
            <li>In <b> DH 604</b>, participants undertake a research project in their chosen topic related to digital health. This course is available to both undergraduate and postgraduate students at IIT Bombay. Students who have completed DH 307 can enroll in DH 604 for advanced credit.</li> 
            <li>The <b> DH 604</b> curriculum integrates theoretical knowledge with hands-on experience. Participants receive comprehensive training in research stages, including problem identification, literature review, hypothesis formulation, experimental design, data analysis, and findings presentation. Regular faculty interactions enhance the learning experience.</li>
            <li>Overall, DH 604 equips students with the skills for independent research, contributing to knowledge advancement and preparing them for careers in academia, industry, and beyond.</li>
            </ul>

            <b class="text-dark d-block pt-2">Procedure</b>
            <ul>
            <li><b> Project Selection:</b> You will receive a list of projects offered by KCDH core and associated faculty members. Topics can be chosen based on mutual interest and compatibility between the supervisor and the student. The project list will be updated periodically.</li>
            <li><b> Faculty Interaction:</b> You are encouraged to reach out to faculty members separately based on your interests. Secure the supervisor's consent by getting a consent form signed.</li>
            <li><b> Supervisor Confirmation:</b> It is essential to find a supervisor during the course adjustment period to secure your spot in the course. If you are unable to find a supervisor in time, you will need to drop the course.</li> 
            </ul> 
        </div>`
    },

    {
        course_id: 'DH605',
        course_title: "DH 605 - R&D Project - II",
        course_info: `<div class="px-md-4 px-2 list">
            <b class="text-dark d-block pt-2">Course content</b>
            <ul>
            <li>In <b> DH 605</b>, participants are required to undertake a research project in their chosen topic. The program is tailored to meet the specific needs and interests of participants, offering a diverse range of topics to explore. Participants can choose from a wide array of interdisciplinary research topics.</li> 
            <li>The R&D course's curriculum is meticulously designed to integrate theoretical knowledge with hands-on experiences, fostering a practical learning environment. Participants undergo comprehensive training in multiple research stages, encompassing problem identification, literature review, hypothesis formulation, experimental design, data analysis, and findings presentation. Regular interactions with faculty members enrich the students' learning journey.</li>
            <li>Overall, this course equips participants with the skills and knowledge necessary to engage in independent and rigorous research, contribute to the advancement of knowledge, and prepare them for further research pursuits or professional careers in academia, industry, or other fields.</li>
            <li><b> DH 605</b> course is designed to serve as a R&D course specially for postgraduates. This course is offered to mainly M.S. by Research students. </li>
            </ul>

            <b class="text-dark d-block pt-2">Procedure</b>
            <ul>
            <li><b> Project Selection:</b> You will receive a list of projects offered by KCDH core and associated faculty members. Topics can be chosen based on mutual interest and compatibility between the supervisor and the student. The project list will be updated periodically.</li>
            <li><b> Faculty Interaction:</b> You are encouraged to reach out to faculty members separately based on your interests. Secure the supervisor's consent by getting a consent form signed.</li>
            <li><b> Supervisor Confirmation:</b> It is essential to find a supervisor during the course adjustment period to secure your spot in the course. If you are unable to find a supervisor in time, you will need to drop the course.</li> 
            </ul> 
        </div>`
    },

    {
        course_id: 'DH801',
        course_title: "DH 801 - Biostatistics in Healthcare",
        course_info: "<div class='px-md-4 px-2 list'> " +
            "<b class='text-dark d-block pt-2'>Course content</b> " +
            "<ul> " +
            "<li>Statistical methods for understanding and interpreting biological and public health data.</li> " +
            "<li>Descriptive Statistics, Screening tests, ROC curves, prevalence and incidence of disease.</li> " +
            "<li>Application of regression to biological and health data sets: graphical data summary,statistical comparison of interventions by hypothesis tests and confidence intervals, correlation,and regression. Case studies.</li> " +
            "<li>Design and Analysis techniques for Epidemiologic Studies: Study designs, confounding and standardization, Meta analysis, Analysis of variance and covariance. crossover designs, clustered data, intraclass correlation, longitudinal analysis, missing data, case studies</li> " +
            "<li>Categorical health data: Contingency tables, Goodness of fit tests, Tests for binomial proportions, estimation of power and sample size, logistic regression, case studies</li> " +
            "<li>Person time health data: Inference for incidence rate data, power and sample size considerations, testing for trend, survival analysis, proportional hazard model, case studies </li> " +
            "<li>Case studies of health big data: techniques to summarize and display big data, descriptive and inferential methodologies</li> " +
            "<li>Clinical trials: statistical design and analysis aspects of clinical trials</li> " +
            "</ul> " +

            "<b class='text-dark'>References</b> " +
            "<ul> " +
            "<li>Rosner, B. Fundamentals of biostatistics. Belmont, CA: Thomson-Brooks/Cole, 2006.</li> " +
            "<li>Wayne W. Daniel, Chad L. Cross, Biostatistics: A Foundation for Analysis in the Health Sciences (11thed.), John Wiley, NewYork, 2018.</li> " +
            "<li>S.C. Chow and J.P. Liu, Design and Analysis of Clinical Trials - Concepts & Methodologies (3rd Edition),John Wiley & Sons, NY, 2013.</li> " +
            "<li>Kutner, M., Nachtsheim, C., Neter, J. and Li, W. Applied Linear Statistical Models, 5th Edition,McGraw-Hill Companies, Boston, 2005.</li> " +
            "</ul> " +

            "<div class='rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3 col-md-9'> " +
            "<table class='table table-striped bg-white m-0'> " +
            "<tr> " +
            "<td class='fw-bold' style='width:350px'>Pre-requisite</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>NA</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Total credits</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>6</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Type</td> " +
            "<td>:</td> " +
            "<td>Theory</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Duration</td> " +
            "<td>:</td> " +
            "<td></td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Name(s) of other Academic units to whom the course may be relevant</td> " +
            "<td>:</td> " +
            "<td>N/A</td> " +
            "</tr> " +
            "</table> " +
            "</div> " +
            "</div>",
    },

    {
        course_id: 'DS303',
        course_title: "DS 303 - Introduction to Machine Learning ",
        course_info: "<div class='px-md-4 px-2 list'> " +
            "<b class='text-dark d-block pt-2'>Course content</b> " +
            "<ul> " +
            "<li>Physical storage and indexing structures, Query processing algorithms, Query optimization, Transaction processing and serializability, Concurrency Control, Recovery</li>  " +
            "<li>Big data management: transaction and query processing on parallel and distributed databases including issues of availability, replication, consistency, concurrency control, and recovery. Emerging database trends</li> " +
            "<li>Introduction to ML</li> " +
            "<li>Idea of supervised, unsupervised, semi-supervised, reinforcement learning</li> " +
            "<li>Linear regression</li> " +
            "<li>Idea of model complexity, generalization, bias-variance trade-off, regularization</li> " +
            "<li>Cross validation, VC dimension</li> " +
            "<li>Supervised classification algorithms: K nearest neighbor, LDA, Decision Tree, SVM and kernel methods, Neural Network, Naive Bayes', Gaussian discriminant analysis, Ensemble methods etc.</li> " +
            "<li>More on probabilistic learning models: Parameter estimation using MLE, MAP, GMM, EM algorithm</li> " +
            "<li>Unsupervised learning: Clustering and kernel density estimation, K-means, DBSCAN, Parzen window technique etc.</li> " +
            "<li>Dimensionality reduction using PCA and kernel PCA</li> " +
            "<li>Intro to reinforcement learning</li> " +
            "<li>Intro to deep learning and convolutional networks, recurrent networks</li> " +
            "<li>Some advanced learning topics if time permits</li> " +
            "</ul> " +

            "<b class='text-dark'>References</b> " +
            "<ul> " +
            "<li>Pattern Recognition and MachineLearning, by Christopher Bishop,Springer 2011</li> " +
            "<li>The Elements of Statistical Learning:Data Mining, Inference, and Prediction,Second Edition, by Trevor Hastie andRobert Tibshirani (Springer Series inStatistics) 2016</li> " +
            "<li>Supplementary material available online,e.g. Dive into Deep Learning by AstonZhang, Zack C. Lipton, Mu Li andAlexander Smola, 2020 (<a href='https://d2l.ai/' target='_blank'>https://d2l.ai</a>)</li> " +
            "</ul> " +

            "<div class='rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3 col-md-9'> " +
            "<table class='table table-striped bg-white m-0'> " +
            "<tr> " +
            "<td class='fw-bold' style='width:120px'>Pre-requisite</td> " +
            "<td style='width:20px'>:</td> " +
            "<td> CS101. Plus math courses like linear algebra, probability, calculus will be helpful</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Total credits</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>6 credits - Lecture  </td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Type</td> " +
            "<td>:</td> " +
            "<td>Theory</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Duration</td> " +
            "<td>:</td> " +
            "<td>Full Semester</td> " +
            "</tr>  " +
            "</table> " +
            "</div> " +
            "</div>",
    },

    {
        course_id: 'EE610',
        course_title: "EE 610 - Image Processing",
        course_info: "<div class='px-md-4 px-2 list'> " +
            "<b class='text-dark d-block pt-2'>Course content</b> " +
            "<ul> " +
            "<li>Image representation, basics of colorimetry, KL transforms, two dimensional transforms, image enhancement, edge detection, histograms. </li> " +
            "<li>Image restoration : sources and models of image degradation, point spread functions (psf), stochastic psf, noise in images. </li> " +
            "<li>Formulation of image restoration problem least square, minimum mean square error (MMSE) and homomorphic filter restoration, linear and non-linear restoration techniques.</li> " +
            "<li>Mathematical morphology, computer tomography.</li> " +
            "</ul> " +

            "<b class='text-dark'>References</b> " +
            "<ul> " +
            "<li>W.K. Pratt: `Digital image processing, Prentice Hall, 1978. </li> " +
            "<li>A. Rosenfold and A.C. Kak: `Digital image processing, Academic Press, 1976. </li> " +
            "<li>A. Rosenfold and A.C. Kak: `Digital image processing, Vols 1 and 2, Prentice Hall, 1986. </li> " +
            "<li>H.C. Andrew and B.R. Hunt, Digital image restoration, Prentice Hall, 1977. </li> " +
            "<li>K.R. Castleman: `Digital image processing, Prentice Hall, 1979. </li> " +
            "<li>A.K. Jain: `Fundamentals of digital image processing, Prentice Hall, 1989</li> " +
            "</ul> " +

            "<div class='rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3 col-md-9'> " +
            "<table class='table table-striped bg-white m-0'> " +
            "<tr> " +
            "<td class='fw-bold' style='width:350px'>Pre-requisite</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>N/A</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Total credits</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>6 credits - Lecture</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Type</td> " +
            "<td>:</td> " +
            "<td>Core Course</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Duration</td> " +
            "<td>:</td> " +
            "<td>Autumn 2022</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Name(s) of other Academic units to whom the course may be relevant</td> " +
            "<td>:</td> " +
            "<td>N/A</td> " +
            "</tr> " +
            "</table> " +
            "</div> " +
            "</div>",
    },

    {
        course_id: 'EE769',
        course_title: "EE 769 - Introduction to Machine Learning",
        course_info: "<div class='px-md-4 px-2 list'> " +
            "<b class='text-dark d-block pt-2'>Course content</b> " +
            "<ul> " +
            "<li>Introduction to machine learning: What is learning, learning objectives, data needed.</li> " +
            "<li>Bayesian inference and learning: Inference, naïve Bayes.</li> " +
            "<li>Basic objective of learning: Assumption of nearness and contiguity in input spaces, accuracy, Bayesian risk and casting of learning as Bayesian inference, Risk matrix, Other cost measures</li> " +
            "<li>Other issues in learning: Generalization and model complexity, Accuracy, Empirical risk and training, validation, and testing, Model complexity, Structural risk, Number of free parameters vs. VC dimension, Bias-variance tradeoff, Curse of dimensionality, Training sample size requirement, Convergence and training time, Memory requirement, Introduction to online/incremental learning Objective functions for classification, regression, and ranking</li> " +
            "<li>Some supervised learning formulations: Linear regression and LMS algorithm, Perceptron and logistic regression, Cybenko’s theorem for nonlinear function estimation, MLP and backpropagation, introduction to momentum and quasi-Newton, L1-norm penalty and sparsity, SVM, support vector regression</li> " +
            "<li>Decision trees Kernelization of linear problems: RBF, increase in dimensionality through simple kernels, kernel definition and Mercer’s theorem, Kernelized SVM and SVR, Other applications of kernelization, matching a kernel to a problem</li> " +
            "<li>Role of randomization and model combination: Committees and random forests, boosting cascade of classifiers Some unsupervised learning machines: Clustering criteria, K-means, Fuzzy C-means, DB-scan, PDF estimation, Parzen window, EM-algorithm for mixture of Gaussians</li> " +
            "<li>Optional topics: Manifold learning, Kernel-PCA, semi-supervised learning, introduction to generative and probabilistic graphical models</li> " +
            "</ul> " +

            "<b class='text- dark'>References</b> " +
            "<ul> " +
            "<li>Textbooks: The lecture will follow standard texts on machine learning technology. For example, the following book provides all the information needed for this course. Whenever we deviate from the main material discussed in these books, soft-copy of lectures notes will be provided.</li> " +
            "<li>Pattern Recognition and Machine Learning, by Christopher Bishop, Springer 2011</li> " +
            "<li>Machine Learning: A Probabilistic Perspective (Adaptive Computation and Machine Learning series), 2012 by Kevin P. Murphy</li> " +
            "<li>The Elements of Statistical Learning: Data Mining, Inference, and Prediction, Second Edition (Springer Series in Statistics) 2016 by Trevor Hastie and Robert Tibshirani</li> " +
            "</ul> " +

            "<div class='rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3 col-md-9'> " +
            "<table class='table table-striped bg-white m-0'> " +
            "<tr> " +
            "<td class='fw-bold' style='width:120px'>Total credits</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>6 credits - Lecture</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Type</td> " +
            "<td>:</td> " +
            "<td>Department Elective</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Duration</td> " +
            "<td>:</td> " +
            "<td>Full Semester</td> " +
            "</tr> " +
            "</table> " +
            "</div> " +
            "</div>",
    },

    {
        course_id: 'GNR652',
        course_title: "GNR 652 - Machine Learning for Remote Sensing 1",
        course_info: "<div class='px-md-4 px-2 list'> " +
            "<b class='text-dark d-block pt-2'>Course content</b> " +
            "<ul> " +
            "<li>Introduction to remote sensing image analysis </li> " +
            "<li>Math refresher - probability, linear algebra, calculus </li> " +
            "<li>Introduction to machine learning - an overview, learning theory </li> " +
            "<li>Supervised learning - regression, effects of regularization, loss function, gradient based parameter optimization</li> " +
            "<li>Supervised learning - classification: basic idea, nearest neighbor based classifier </li> " +
            "<li>Supervised learning - classification: probabilistic classifiers, parameter estimation techniques (maximum likelihood, maximum a posteriori), Gaussian mixture models and expectation maximization </li> " +
            "<li>Supervised learning - classification: Introduction to graphical models (Bayesian networks, Markov random fields, Conditional random fields, Hidden Markov Model) </li> " +
            "<li>Supervised learning - classification: support vector machines (soft and hard margin, idea of kernel functions) </li> " +
            "<li>Supervised learning - neural networks for classification and regression (multi-layer perceptron model), derivation of back-propagation, radial basis function networks </li> " +
            "<li>Unsupervised classification - clustering and density estimation, k-means clustering, graph-cut based clustering, mean-shift clustering and kernel density estimate </li> " +
            "<li>Dimensionality reduction techniques - idea of feature transformation and feature selection, principal component analysis, independent component analysis, neighborhood component analysis, auto-encoder (basic, denoising, sparse)</li> " +
            "<li>Semi-supervised learning - probabilistic methods, transductive SVMs, graph based semi- supervised learning </li> " +
            "<li>Weakly supervised learning - idea of weak supervision, zero-shot, few-shot learning </li> " +
            "<li>Introduction to deep learning Convolutional networks - architectures in detail, CNN for image classification, semantic image segmentation, and object detection </li> " +
            "<li>Recurrent networks - basic RNN, LSTM, GRU </li> " +
            "</ul> " +

            "<b class='text-dark'>References</b> " +
            "<div class='rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3 col-md-9'> " +
            "<table class='table table-striped bg-white m-0'> " +
            "<tr> " +
            "<td class='fw-bold' style='width:200px'>Total credits</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>6 credits - Lecture</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Type</td> " +
            "<td>:</td> " +
            "<td>Theory</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Duration</td> " +
            "<td>:</td> " +
            "<td>Full Semester</td> " +
            "</tr> " +
            "</table> " +
            "</div> " +
            "</div>",
    },

    {
        course_id: 'IE501',
        course_title: "IE 501 - Optimization Models",
        course_info: "<div class='px-md-4 px-2 list'> " +
            "<b class='text-dark d-block pt-2'>Course content</b> " +
            "<ul> " +
            "<li>Modeling of allocation and control problems in industry and social systems. </li> " +
            "<li>Framework and overview of optimization with examples of continuous and discrete optimization, unconstrained and constrained problems. </li> " +
            "<li>Single stage and multistage models.</li> " +
            "<li>Formulations and equivalences. Examples from science, engineering and business.</li> " +
            "<li>Linear programming. Geometry and algebra of the simplex method. </li> " +
            "<li>Duality & sensitivity.Combinatorial optimization problems with emphasis on applications, notion of large feasible spaces and neighborhood solutions, representation of solution space, search tree, search techniques, branch and bound method.</li> " +
            "<li>Examples of mixed-integer programming models. Use of binary variables in constraint modeling. </li> " +
            "<li>Decision problems involving network flows, assignment models, transportation models, multi-stage flows.</li> " +
            "</ul> " +

            "<b class='text-dark'>References</b> " +
            "<ul> " +
            "<li>Wayne L. Winston (2004) Operations Research: Applications and Algorithms, 4th edition, Thomson Learning. SBN: 8131501906 EAN: 9788131501900</li> " +
            "<li>Wayne L. Winston (2003) Introduction to Mathematical Programming: Applications and Algorithms, 4th edition, Duxbury Resource Center. SBN: 0534423574H. </li> " +
            "<li>Paul Williams (1999) Model Building in Mathematical Programming, 4th edition, John Wiley & Sons. SBN: 0471997889 EAN: 9780471997887</li> " +
            "<li>Ashok D. Belegundu, and Tirupathi R. Chandrupatla (1999)Optimization Concepts and Applications in Engineering, Pearson Education India. SBN: 8178086735</li> " +
            "<li>Hamdy. A. Taha (2002) Operations Research: An Introduction, 8th edition, Prentice Hall of India. SBN: 8120330439F. </li> " +
            "<li>Hillier and G. Lieberman (2005) Introduction to Operations Research, 8th edition, McGraw-Hill ISBN-10: 0073211141 ISBN-13: 978-0073211145</li> " +
            "</ul> " +

            "<div class='rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3 col-md-9'> " +
            "<table class='table table-striped bg-white m-0'> " +
            "<tr> " +
            "<td class='fw-bold' style='width:350px'>Pre-requisite</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>N/A</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Total credits</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>6</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Type</td> " +
            "<td>:</td> " +
            "<td></td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Duration</td> " +
            "<td>:</td> " +
            "<td>Autumn 2022</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Name(s) of other Academic units to whom the course may be relevant</td> " +
            "<td>:</td> " +
            "<td>N/A</td> " +
            "</tr> " +
            "</table> " +
            "</div> " +
            "</div>",
    },

    {
        course_id: 'IE615',
        course_title: "IE 615 - Data Analytics in Operations Research",
        course_info: "<div class='px-md-4 px-2 list'> " +
            "<b class='text-dark d-block pt-2'>Course content</b> " +
            "<ul> " +
            "<li>Modeling of allocation and control problems in industry and social systems. </li> " +
            "<li>Framework and overview of optimization with examples of continuous and discrete optimization, unconstrained and constrained problems. </li> " +
            "<li>Single stage and multistage models.</li> " +
            "<li>Formulations and equivalences. Examples from science, engineering and business.</li> " +
            "<li>Linear programming. Geometry and algebra of the simplex method. </li> " +
            "<li>Duality & sensitivity. Combinatorial optimization problems with emphasis on applications, notion of large feasible spaces and neighborhood solutions, representation of solution space, search tree, search techniques, branch and bound method.</li> " +
            "<li>Examples of mixed-integer programming models. Use of binary variables in constraint modeling. </li> " +
            "<li>Decision problems involving network flows, assignment models, transportation models, multi-stage flows.</li> " +
            "</ul> " +

            "<b class='text-dark'>References</b> " +
            "<ul> " +
            "<li>Wayne L. Winston (2004) Operations Research: Applications and Algorithms, 4th edition, Thomson Learning. SBN: 8131501906 EAN: 9788131501900</li> " +
            "<li>Wayne L. Winston (2003) Introduction to Mathematical Programming: Applications and Algorithms, 4th edition, Duxbury Resource Center. SBN: 0534423574H. </li> " +
            "<li>Paul Williams (1999) Model Building in Mathematical Programming, 4th edition, John Wiley & Sons. SBN: 0471997889 EAN: 9780471997887</li> " +
            "<li>Ashok D. Belegundu, and Tirupathi R. Chandrupatla (1999) Optimization Concepts and Applications in Engineering, Pearson Education India. SBN: 8178086735</li> " +
            "<li>Hamdy. A. Taha (2002) Operations Research: An Introduction,8th edition, Prentice Hall of India. SBN: 8120330439F. </li> " +
            "<li>Hillier and G. Lieberman (2005) Introduction to Operations Research, 8th edition, McGraw-Hill ISBN-10: 0073211141 ISBN-13: 978-0073211145</li> " +
            "</ul> " +

            "<div class='rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3 col-md-9'> " +
            "<table class='table table-striped bg-white m-0'> " +
            "<tr> " +
            "<td class='fw-bold' style='width:350px'>Pre-requisite</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>N/A</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Total credits</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>6</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Type</td> " +
            "<td>:</td> " +
            "<td></td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Duration</td> " +
            "<td>:</td> " +
            "<td>Autumn 2022</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Name(s) of other Academic units to whom the course may be relevant</td> " +
            "<td>:</td> " +
            "<td>N/A</td> " +
            "</tr> " +
            "</table> " +
            "</div> " +
            "</div>",
    },

    {
        course_id: 'IE643',
        course_title: "IE 643 - Deep Learning Theory & Practice",
        course_info: "<div class='px-md-4 px-2 list'> " +
            "<b class='text-dark d-block pt-2'>Course content</b> " +
            "<ul> " +
            "<li>The Perceptron, Feed-forward networks and Multi-layer perceptron, Memory based networks like Boltzmann Machines, Hopfield Networks. </li> " +
            "<li>State based networks like Recurrent Neural Networks, Long Short Term Memory Networks. Convolutional Neural Networks, Bidirectional networks, Concept based networks used for transfer learning, Structural Networks for structured prediction, Attention based networks, Auto encoders for dimension reduction and embedding, Generative Adversarial Networks, Deep Gaussian Processes, Deep Bayesian nets, Deep Search Models, Deep Reinforcement Learning, Deep Neural Recommenders. Non-convex Optimization tools for Deep Networks. </li> " +
            "<li>Theoretical tools to describe Convolutional Neural Networks and Recurrent Neural Networks. Learning theory for Deep Neural Networks. </li> " +
            "<li>Several Applications covering operations research, computer vision, natural language processing, multi-media analytics, proof checking</li> " +
            "</ul> " +

            "<b class='text-dark'>References</b> " +
            "<ul> " +
            "<li>Deep Learning by Ian Goodfellow, Yoshua Bengio, and Aaron Courville. MIT Press. 2017.302225 </li> " +
            "<li>Deep Learning with Python by Fran303247ois Chollet. Manning Publication. 2017.10.3.</li> " +
            "<li>Pattern Recognition and Machine Learning by Christopher Bishop. Springer, 2010.302225 </li> " +
            "<li>The Elements of Statistical Learning: Data Mining, Inference, and Prediction (Second Edition), by Trevor Hastie, Robert Tibshirani, Jerome Friedman. Springer, 2017.</li> " +
            "<li>Free e-book at <a href='http://neuralnetworksanddeeplearning.com' target='_blank'>http://neuralnetworksanddeeplearning.com</a> by Michael Nielsen.302225</li> " +
            "<li>Code websites: <a href='https://www.tensorflow.org' target='_blank'>https://www.tensorflow.org</a>, <a href='https://keras.io/' target='_blank'>https://keras.io/</a>, <a href='https://github.com/Theano/Theano' target='_blank'>https://github.com/Theano/Theano</a> </li> " +
            "</ul> " +

            "<div class='rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3 col-md-9'> " +
            "<table class='table table-striped bg-white m-0'> " +
            "<tr> " +
            "<td class='fw-bold' style='width:350px'>Pre-requisite</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>N/A</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Total credits</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>6</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Type</td> " +
            "<td>:</td> " +
            "<td></td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Duration</td> " +
            "<td>:</td> " +
            "<td>Autumn 2022</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Name(s) of other Academic units to whom the course may be relevant</td> " +
            "<td>:</td> " +
            "<td>N/A</td> " +
            "</tr> " +
            "</table> " +
            "</div> " +
            "</div>",
    },

    {
        course_id: 'ME781',
        course_title: "ME 781 - Statistical Machine Learning and Data Mining",
        course_info: "<div class='px-md-4 px-2 list'> " +
            "<b class='text-dark d-block pt-2'>Course content</b> " +
            "<ul> " +
            "<li>Introduction / Foundation(4 lectures - 6 hours)- Essentials of Statistics- What is Data Mining- Data Mining Stages- Methods overview- Applications overview Data Mining Methods(10 Lectures - 15 hours)- Clustering- Classification- Association- Sequence Analysis- Regressions- Decision Trees- Neural Networks- SVM</li> " +
            "<li>Engineering and Manufacturing Applications(6 lectures - 9 hours)- Data Mining for Product Design- Applications in Planning and Scheduling- Data Mining for Process and Quality Control- Application of Data Mining in Maintenance- Applications in Shop Floor Control and Layout- Introduction to Cyber Physical Systems Handling Very Large Data Sets (Big Data) and their Analytics(5 lectures - 7.5 hours)- Definition of Big Data- Structured / semi-structured / unstructured data- Techniques for storing and processing Big Data- Tools for storing and processing Big Data- The Big Data Ecosystem- Sources of Big Data in the Manufacturing Enterprise- Applications of Big Data Analytics in Engineering And Manufacturing</li> " +
            "</ul> " +

            "<b class='text-dark'>References</b> " +
            "<ul> " +
            "<li>Data Mining for Design and Manufacturing Methods and Applications; Ed. Dan Braha; Springer Science +Business Media, B.V., 2001302223 </li> " +
            "<li>The Elements of Statistical Learning: Data Mining, Inference and Prediction; Trevor Hastie, Robert Tibshirani, Jerome Friedman; Springer Series in Statistics, 2009302223 </li> " +
            "<li>Data Mining: Concepts and Techniques; Jiawei Han, Micheline Kamber and Jian Pei; Morgan Kaufman, Predictive Analytics and Data Mining; Vijay Kotu and Bala Deshpande; Morgan Kaufmann Publishers,</li> " +
            "</ul> " +

            "<div class='rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3 col-md-9'> " +
            "<table class='table table-striped bg-white m-0'> " +
            "<tr> " +
            "<td class='fw-bold' style='width:350px'>Pre-requisite</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>N/A</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Total credits</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>6</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Type</td> " +
            "<td>:</td> " +
            "<td>Theory</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Duration</td> " +
            "<td>:</td> " +
            "<td></td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Name(s) of other Academic units to whom the course may be relevant</td> " +
            "<td>:</td> " +
            "<td>N/A</td> " +
            "</tr> " +
            "</table> " +
            "</div> " +
            "</div>",
    },

    {
        course_id: 'SI422',
        course_title: "SI 422 - Regression Analysis",
        course_info: "<div class='px-md-4 px-2 list'> " +
            "<b class='text-dark d-block pt-2'>Course content</b> " +
            "<ul> " +
            "<li>Simple and multiple linear regression models – estimation, tests and confidence regions. Simultaneous testing methods- Bonferroni method etc. </li> " +
            "<li>Analysis of Variance for simple and multiple regression models. Analysis of residuals. Lack of fit tests. Checks (graphical procedures and tests) for model assumptions: Normality, homogeneity of errors, independence, correlation of covariates and errors. Multicollinearity, outliers, leverage and measures of influence. </li> " +
            "<li>Model selection (stepwise, forward and backward, best subset selection) and model validation.</li> " +
            "<li>Discussion of algorithms for model selection. Regression models with indicator variables. Polynomial regression models. Regression models with interaction terms. Transformation of response variables and covariates. </li> " +
            "<li>Variance stabilizing transformations, Box-Cox method. Ridge`s regression. Weighted Regression. </li> " +
            "</ul> " +

            "<b class='text-dark'>References</b> " +
            "<ul> " +
            "<li>Draper, N. and Smith, H. Applied Regression Analysis, 3rd Edition, John Wiley and Sons Series in Probability and Statistics, New York, 1998. </li> " +
            "<li>Montgomery, D., Peck, E., Vining, G. Introduction to Linear Regression Analysis, 5th Edition, John Wiley, New York, 2013</li> " +
            "<li>Sen, A. and Srivastava, M. Regression Analysis Theory, Methods & Applications, 1st Edition, Springer-Verlag Berlin Heidelberg, New York, 1990. </li> " +
            "<li>Kutner, M., Nachtsheim, C., Neter, J. and Li, W. Applied Linear Statistical Models, 5th Edition, McGraw-Hill Companies, Boston, 2005.</li> " +
            "</ul> " +

            "<div class='rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3 col-md-9'> " +
            "<table class='table table-striped bg-white m-0'> " +
            "<tr> " +
            "<td class='fw-bold' style='width:300px'>Pre-requisite</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>SI 427 (Exposure) (For students from other departments, instructor’s permission will be required)</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Total credits</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>8</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Type</td> " +
            "<td>:</td> " +
            "<td></td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Duration</td> " +
            "<td>:</td> " +
            "<td>Spring 2023</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Name(s) of other Academic units to whom the course may be relevant</td> " +
            "<td>:</td> " +
            "<td>N/A</td> " +
            "</tr> " +
            "</table> " +
            "</div> " +
            "</div>",
    },

    {
        course_id: 'SI541',
        course_title: "SI 541 - Statistical Epidemiology",
        course_info: "<div class='px-md-4 px-2 list'> " +
            "<b class='text-dark d-block pt-2'>Course content</b> " +
            "<ul> " +
            "<li>Epidemiologic approach to clinical trials: observational studies, cross-sectional studies, designing a case control study, bias in a case-control study, matching issues, cohort studies, design of a cohort study, biases in a cohort study, comparing case and cohort studies, randomized trials, selection of subjects, crossover trials, issues on sample size, recruitment. Case studies to explore above topics. </li> " +
            "<li>Spatial Epidemiology: Geographical Representation and Mapping, Spatial Interpolation and Smoothing Methods, Estimation and Inference, Spatial Proximity Indices, Disease Clustering, Spatial Regression, Infectious disease modelling. </li> " +
            "<li>Survival Analysis in Epidemiology: Functions of survival time, censoring mechanisms, nonparametric estimators of survival function, Cox’s proportional hazards model, Cases studies using survival analysis methods in health research.</li> " +
            "</ul> " +

            "<b class='text-dark'>References</b> " +
            "<ul> " +
            "<li>Lawson, A. Statistical Methods in Spatial Epidemiology, 2nd Edition, Wiley, New York, 2006.</li> " +
            "<li>Gordis, L. Epidemiology, 5th Edition, Elsevier Saunders, Philadelphia, 2014. </li> " +
            "<li>Kalbfleisch, J. and Prentice, R. The Statistical Analysis of Failure Time Data, 2nd Edition, Wiley, New York, 2002. </li> " +
            "<li>Lee, E. and Wang, J. Statistical methods for survival data analysis, 3rd Edition, John Wiley & Sons., Hoboken, 2003.</li> " +
            "</ul> " +

            "<div class='rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3 col-md-9'> " +
            "<table class='table table-striped bg-white m-0'> " +
            "<tr> " +
            "<td class='fw-bold' style='width:350px'>Pre-requisite</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>N/A</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Total credits</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>6 credits - Lecture</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Maximum Strength</td> " +
            "<td>:</td> " +
            "<td>30</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Type</td> " +
            "<td>:</td> " +
            "<td>Core Course</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Duration</td> " +
            "<td>:</td> " +
            "<td>Autumn 2022</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Name(s) of other Academic units to whom the course may be relevant</td> " +
            "<td>:</td> " +
            "<td>N/A</td> " +
            "</tr> " +
            "</table> " +
            "</div> " +
            "</div>",
    },

    {
        course_id: 'DH304',
        course_title: "DH 304: Economics of Healthcare",
        course_info: "<div class='px-md-4 px-2 list'> " +
            "<b class='text-dark d-block pt-2'>Course content</b> " +
            "<ul> " +
            "<li> " +
            "Introduction and Overview " +
            "<ul class='m-0'> " +
            "<li>What is Health Economics?</li> " +
            "<li>Overview of Indian health care system</li> " +
            "<li>How to measure health?</li> " +
            "</ul> " +
            "</li> " +
            "<li> " +
            "Demand for Health and Demand for Medical Care " +
            "<ul class='m-0'> " +
            "<li>Production of Health</li> " +
            "<li>Education, Income, and Health </li> " +
            "<li>Infant and Child Health</li> " +
            "<li>Demand for Medical Care</li> " +
            "</ul> " +
            "</li> " +
            "<li> " +
            "Health Insurance " +
            "<ul class='m-0'> " +
            "<li>Demand for Health Insurance and moral hazard</li> " +
            "<li>Health insurance markets and adverse selection</li> " +
            "<li>Public Health Insurance Programs</li> " +
            "<li>Health Service Disparities</li> " +
            "</ul> " +
            "</li> " +
            "<li>Market for Physicians</li> " +
            "<li>Economics of Hospitals</li> " +
            "<li>Health Behaviours: substance abuse, smoking, obesity</li> " +
            "<li> " +
            "Burden of Illness " +
            "<ul class='m-0'> " +
            "<li>Communicable diseases</li> " +
            "<li>Non-communicable diseases</li> " +
            "</ul> " +
            "</li> " +
            "<li>Health Policy Evaluation</li> " +
            "</ul> " +
            "<b class='text-dark'>References</b> " +
            "<ul> " +
            "<li>Bhattacharya, Hyde & Tu. Health Economics, First Edition, Palgrave Macmillan, 2014.</li> " +
            "<li>Folland, Goodman & Stano. The Economics of Health and Health Care, Fifth Edition, Prentice-Hall, 2007.</li> " +
            "<li>Glied, S & Smith, PC. The Oxford Handbook of Health Economics, Oxford University Press, 2011.</li> " +
            "<li>Cameron, AC & Trivedi, PK. Micro Econometrics: Methods and Applications. Cambridge University Press, 2005.</li> " +
            "<li>Angrist, JD & Pischke, JS. Mostly harmless econometrics: An empiricist’s companion. Princeton University Press, 2009.</li> " +
            "<li>Appropriate research articles for each topic will be suggested in class.</li> " +
            "</ul> " +

            "<div class='rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3 col-md-9'> " +
            "<table class='table table-striped bg-white m-0'> " +
            "<tr> " +
            "<td class='fw-bold' style='width:300px'>Pre-requisite</td> " +
            "<td style='width: 20px;'>:</td> " +
            "<td>None</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Total credits</td> " +
            "<td>:</td> " +
            "<td>6 credits - Lecture</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Type</td> " +
            "<td>:</td> " +
            "<td>Department elective</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Duration</td> " +
            "<td>:</td> " +
            "<td>Full Semester - To be offered normally in 2<sup>nd</sup> semester</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='border-0 fw-bold'>Name(s) of other Academic units to whom the course may be relevant</td> " +
            "<td class='border-0'>:</td> " +
            "<td class='border-0'>HSS, SOM</td> " +
            "</tr> " +
            "</table> " +
            "</div> " +
            "</div>",
    },

    {
        course_id: 'DH802',
        course_title: "DH 802 - Service Operations and Quality Management in Healthcare",
        course_info: "<div class='px-md-4 px-2 list'> " +
            "<b class='text-dark d-block pt-2'>Course content</b> " +
            "<ul> " +
            "<li>Fundamentals on Service Operations Management; Healthcare service operations; Design and management of service process; Managing service resources; Demand planning and forecasting,</li> " +
            "<li>Basics on Inventory management, Basics on supply chain, Management of queue, Staff scheduling, Project management, Supplier network management, Lean Management, Time and motion study.</li> " +
            "<li>Fundamentals on Quality Management; Understanding customers voices; expectations; perceptions; service quality, survey research; Inspection; Statistical Process Control; Design of Experiments; Six Sigma Methodology, ISO standards.</li> " +
            "</ul> " +
            "<b class='text-dark'>References</b> " +
            "<ul> " +
            "<li>Text book:</li> " +
            "<li>(i) Service Operations Management: Improving Service Delivery, R Johnston, G Clark, M Shluver, Pearson, 4th edition, </li> " +
            "<li>(ii) Operations Management: Processes and Supply Chains, L J Krejewski, L PRitzman, M K Malhotra, S K Srivastava, Pearson, 9th edition</li> " +
            "<li>(iii) Healthcare Operations Management, D B. McLaughlin, Julie M. Hays, AUPHA Press, Washington, DC, 2008(vii) Total Quality Management (TQM), D. H. Bester field et al., Pearson learning, Revised 3rd Edition, 2011</li> " +
            "<li>(iv) Design and Analysis of Experiment (DAE), D. C. Montgomery, 8th Edition, Wiley Publication, 2014</li> " +
            "<li>(v) Experimental Design and Data Analysis for Biologists, G. P. Quinn and M. J. Keough, Cambridge University Press, 2002</li> " +
            "<li>Journal References</li> " +
            "<li>(i) A history of research in service operations: What 302222 s the big idea? R B. Chase, U. M. Apte, Journal of Operations Management, 25 (2007) 375302226386</li> " +
            "<li>(ii) Quality management in healthcare and industry: A comparative review and emerging themes, A Komashie, A Mousavi, J Gore, Journal of Management History, Vol. 13 No. 4, 2007, pp. 359-370</li> " +
            "<li>(iii) Lean Management 302227 The Journey from Toyota to Healthcare., ST Teich, FF Faddoul. RMMJ 2013; Vol. 4 (2):e0007</li> " +
            "<li>(iv) SERVQUAL: A Multiple-Item Scale for Measuring Consumer Perceptions of Service Quality, A. Parasuraman, V. A. Zeithaml, and L. L. Berry, (1988), Journal of Retailing, Vol. 64, pp. 12-40. </li> " +
            "<li>(v) Service quality: a tutorial, J Harvey, Journal of Operations Management 16 _1998.583302226597</li> " +
            "<li>Business Cases</li> " +
            "<li>(i) Apollo Hospitals Differentiation through Hospitality, S Kulkarni et al. (2013), IIM Bangalore, HBR Case</li> " +
            "<li>(ii) Forecasting Demand for Food in Appollo Hospital, S Roychoudhury et al. (2014), IIM Bangalore, HBR Case</li> " +
            "<li>(iii) Six Sigma at Academic Medical Hospital, D. C San et al. (2002), HBR Case(iv) Aravind Eye Care System, S Deo & K Ramdas, 2018, HBR Case Software Minitab, R, Excel</li> " +
            "</ul> " +
            "<div class='rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3 col-md-9'> " +
            "<table class='table table-striped bg-white m-0'> " +
            "<tr> " +
            "<td class='fw-bold' style='width:350px'>Pre-requisite</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>N/A</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Total credits</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>6</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Type</td> " +
            "<td>:</td> " +
            "<td>Theory</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Duration</td> " +
            "<td>:</td> " +
            "<td></td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Name(s) of other Academic units to whom the course may be relevant</td> " +
            "<td>:</td> " +
            "<td>N/A</td> " +
            "</tr> " +
            "</table> " +
            "</div> " +
            "</div>",
    },

    {
        course_id: 'DH899',
        course_title: "DH 899 - Communication Skills",
        course_info: "<div class='px-md-4 px-2 list'> " +
            "<b class='text-dark d-block pt-2'>Course content</b> " +
            "<ul> " +
            "<li>Recognizing our capability and roles as professionals </li> " +
            "<li>Scientific Method: Question and answer aspects of technical communication; Scientific Methodology and its relationship to technical communication; Surveying literature: Categories; reading and organizing scientific literature; search engines and tools.</li> " +
            "<li>Listening and Note taking: 5-R method and mind-mapping.</li> " +
            "<li>Technical writing: Report organization; Journal selection; Introduction, conclusion, and abstract writing. Speaking & Presentation skills: Organization of presentation slides (number, content, and formatting); Oral presentations; Audience/context dependent practices; Nonverbal aspects: body language, eye-contact, personal appearance, facing large audience. Elevator pitch: Pitches for technical audience and policymakers</li> " +
            "<li>Workplace communication: Sensitivity towards gender and diversity; Email communication and netiquettes. Ethics in academic communication: Intellectual Property, copyrights and plagiarism; Authorship; Data ethics; Biases and balanced criticism of literature; </li> " +
            "<li>Suggested additional topics relevant to disciplines: Datare presentation, Group discussion and interviews; accessible scientific writing, report writing using LaTeX, Proofreading, etc.</li> " +
            "</ul> " +

            "<b class='text-dark'>References</b> " +
            "<ul> " +
            "<li>1. Alley, Michael The Craft of Scientific Presentations, Springer (2003).</li> " +
            "<li>2. Booth, Wayne C., Gregory G. Colomb, and Joseph M. Williams, The Craft of Research, The University of Chicago Press, 3rd edition, (2008).</li> " +
            "<li>3. Keshav. S, How to read a paper. ACM SIGCOMM Comp. Commun. Rev., 37:8330222684, 2007.</li> " +
            "<li>4. Monippally, M. M., Pawar, B.S. Academic Writing: A Guide for Management Students and Researchers, Response Books, (2010).</li> " +
            "<li>5. Purdue Online Writing Lab (OWL), <a href='https://owl.purdue.edu/' target='_blank'>https://owl.purdue.edu/</a></li> " +
            "<li>6. Strunk Jr., William; E. B. White, The Elements of Style, Fourth Edition, Longman; 4th edition (1999).</li> " +
            "<li>7. Truss, Lynne Eats, Shoots & Leaves: The Zero Tolerance Approach to Punctuation Gotham;(2006). </li> " +
            "<li>8. White sides, George M. White sides Group: Writinga Paper, Advanced Materials 16 137530222677 (2004)</li> " +
            "</ul> " +

            "<div class='rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3 col-md-9'> " +
            "<table class='table table-striped bg-white m-0'> " +
            "<tr> " +
            "<td class='fw-bold' style='width:350px'>Pre-requisite</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>N/A</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Total credits</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>6</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Type</td> " +
            "<td>:</td> " +
            "<td>Non credit</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Duration</td> " +
            "<td>:</td> " +
            "<td></td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Name(s) of other Academic units to whom the course may be relevant</td> " +
            "<td>:</td> " +
            "<td>N/A</td> " +
            "</tr> " +
            "</table> " +
            "</div> " +
            "</div>",
    },

    {
        course_id: 'ES899',
        course_title: "ES 899/CM 899 - Communication Skills",
        course_info: "<div class='px-md-4 px-2 list'> " +
            "<b class='text-dark d-block pt-2'>Course content</b> " +
            "<ul> " +
            "<li>Context of communication: Recognizing our capability and roles as professionals</li> " +
            "<li>Scientific Method: Question and answer aspects of technical communication; Scientific  Methodology and its relationship to technical communication; Surveying literature: Categories; reading and organizing scientific literature; search engines and tools</li> " +
            "<li>Listening and Note taking: 5-R method and mind-mapping.</li> " +
            "<li>Technical writing: Report organization; Journal selection; Introduction, conclusion, and abstract writing. Speaking & Presentation skills: Organization of presentation slides (number, content, and formatting); Oral presentations; Audience/context dependent practices; Nonverbal aspects:  body language, eye-contact, personal appearance, facing large audience.</li> " +
            "<li>Elevator pitch: Pitches for technical audience and policymakers</li> " +
            "<li>Workplace communication: Sensitivity towards gender and diversity; Email communication and netiquettes.</li> " +
            "<li>Ethics in academic communication: Intellectual Property, copyrights and plagiarism; Authorship; Data ethics; Biases and balanced criticism of literature; Suggested additional topics relevant to disciplines: Data representation, Group discussion and interviews; accessible scientific writing, report writing using LaTeX, Proofreading, etc.</li> " +
            "</ul> " +

            "<b class='text-dark'>References</b> " +
            "<ul> " +
            "<li>Alley, Michael The Craft of Scientific Presentations, Springer (2003).</li> " +
            "<li>Booth, Wayne C., Gregory G. Colomb, and Joseph M. Williams, The Craft of Research, The University of Chicago Press, 3rd edition, (2008).</li> " +
            "<li>Keshav. S, How to read a paper. ACM SIGCOMM Comp. Commun. Rev., 37:8330222684, 2007.</li> " +
            "<li>Monippally, M. M., Pawar, B.S. Academic Writing:A Guide for Management Students and  Researchers, Response Books, (2010).</li> " +
            "<li>Purdue Online Writing Lab (OWL), <a href='https://owl.purdue.edu/' target='_blank'>https://owl.purdue.edu/</a></li> " +
            "<li>Strunk Jr., William; E. B. White, The Elements of Style, Fourth Edition, Longman; 4th edition (1999)</li> " +
            "<li>Truss, Lynne Eats, Shoots & Leaves: The Zero Tolerance Approach to Punctuation Gotham (2006)</li> " +
            "<li>White sides, George M. White sides Group: Writing a Paper, Advanced Materials 16 137530222677 (2004).</li> " +
            "</ul> " +

            "<div class='rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3 col-md-9'> " +
            "<table class='table table-striped bg-white m-0'> " +
            "<tr> " +
            "<td class='fw-bold' style='width:350px'>Pre-requisite</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>N/A</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Total credits</td> " +
            "<td style='width:20px'>:</td> " +
            "<td></td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Type</td> " +
            "<td>:</td> " +
            "<td></td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Duration</td> " +
            "<td>:</td> " +
            "<td>Full Semester</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Name(s) of other Academic units to whom the course may be relevant</td> " +
            "<td>:</td> " +
            "<td>N/A</td> " +
            "</tr> " +
            "</table> " +
            "</div> " +
            "</div>",
    },

    {
        course_id: 'HS633',
        course_title: "HS 633 - Econometrics of Programme Evaluation",
        course_info: "<div class='px-md-4 px-2 list'> " +
            "<b class='text-dark d-block pt-2'>Course content</b> " +
            "<ul> " +
            "<li>Problem of Causal Inference and Randomized Experiments: Cause and effects, randomized and non-randomized trials, treatment effect and selection bias</li> " +
            "<li>Regression Fundamentals and Causality: Introduction to regression analysis, ordinary least squares regression, linear regression and conditional expectation, inference and hypothesis testing in regression, regression and causality </li> " +
            "<li>Matching: Counterfactuals, propensity score matching, average treatment effect, quantile treatment effect, regression and matching, ordered and continuous treatment</li> " +
            "<li>Instrumental Variables Regression: Instrumental variables and causality, Two-stage least squares, 2SLS inference, IV with heterogeneous potential outcomes </li> " +
            "<li>Difference-in-difference Estimator: Individual fixed effects, difference-in-difference, regression DD</li> " +
            "</ul> " +

            "<b class='text-dark'>References</b> " +
            "<ul> " +
            "<li>Angrist J.D. and J. Pischke. 2009. Mostly Harmless Econometrics. Princeton University Press. </li> " +
            "<li>Cameron A.C. and P.K.Trivedi. 2005. Microeconometrics: Methods and Applications. Cambridge University Press. New York.</li> " +
            "<li>Wooldridge J.M. 2010. Econometric Analysis of Cross Section and Panel Data (2nd Ed.). The MIT Press. Massachusetts.</li> " +
            "<li>Heckman J.J. and E.J.Vytlacil. 2007. Chapter 70 Econometric Evaluation of Social Programs, Part I: Causal Models, Structural Models and Econometric Policy Evaluation. Handbook of Econometrics. Vol.6, Part B. pp. 4779-4874</li> " +
            "<li>Heckman J.J. and E.J.Vytlacil. 2007. Chapter 71 Econometric Evaluation of Social Programs, Part II: Using the Marginal Treatment Effect to Organize Alternative Econometric Estimators to Evaluate Social Programs, and to Forecast Their Effects in New Environments. Handbook of Econometrics. Vol.6, Part B. pp. 4875-5143</li> " +
            "</ul> " +

            "<div class='rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3 col-md-9'> " +
            "<table class='table table-striped bg-white m-0'> " +
            "<tr> " +
            "<td class='fw-bold' style='width:250px'>Pre-requisite</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>Background in statistic/econometrics</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold' style='width:120px'>Total credits</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>6</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Type</td> " +
            "<td>:</td> " +
            "<td></td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Duration</td> " +
            "<td>:</td> " +
            "<td>Autumn 2022</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold' style='width:120px'>Name(s) of other Academic units to whom the course may be relevant </td> " +
            "<td style='width:20px'>:</td> " +
            "<td></td> " +
            "</tr> " +
            "</table> " +
            "</div> " +
            "</div>",
    },

    {
        course_id: 'HS638',
        course_title: "HS 638 Financial Econometrics",
        course_info: "<div class='px-md-4 px-2 list'> " +
            "<b class='text-dark d-block pt-2'>Course content</b> " +
            "<ul> " +
            "<li>Statistical Properties of Financial Returns, Univariate Time Series and it’s Applications in Finance, Multivariate Time Series Analysis and its Application in Finance, Co integration and Error Correction Model and its Application in Finance, Univariate Volatility Modeling – ARCH and GARCH Model, Historical Volatility, Realized Volatility and Implied Volatility, Markov and Regime Switching Model and its Application in Finance, Multivariate Volatility and Correlations Model and its application in finance</li> " +
            "</ul> " +

            "<b class='text-dark'>References</b> " +
            "<ul> " +
            "<li>C. Dougherty, 302223Introduction to Econometrics302224,Oxford Publication, Third Edition, 2007.</li> " +
            "<li>R. S Tsay, 302223Analysis of Financial Time Series302224, John Wiley & Sons, Inc, Second Edition, 2002.</li> " +
            "<li>Brooks C, 302223 Introductory Econometrics for Finance302224, Cambridge University Press, Second  Edition, 2008.</li> " +
            "<li>Green W.H, 302223Econometric Analysis302224, Prentice Hall, Fifth Edition, 2006</li> " +
            "</ul> " +

            "<div class='rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3 col-md-9'> " +
            "<table class='table table-striped bg-white m-0'> " +
            "<tr> " +
            "<td class='fw-bold' style='width:350px'>Pre-requisite</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>N/A</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Total credits</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>6</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Type</td> " +
            "<td>:</td> " +
            "<td></td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Duration</td> " +
            "<td>:</td> " +
            "<td>Spring 2023</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Name(s) of other Academic units to whom the course may be relevant</td> " +
            "<td>:</td> " +
            "<td>N/A</td> " +
            "</tr> " +
            "</table> " +
            "</div> " +
            "</div>",
    },

    {
        course_id: 'HS426',
        course_title: "HS 426 - Theory and Policy of Managerial Finance",
        course_info: "<div class='px-md-4 px-2 list'> " +
            "<b class='text-dark d-block pt-2'>Course content</b> " +
            "<ul> " +
            "<li>Introduction to financial statements. Concepts of compounding and discounting, Valuation of securities. </li> " +
            "<li>Sources of finance-Trade credit. Bank Finance. Term finance. Stock market. Dividend policy. Share valuation. Leverages. Theories of capital structure. Cost of capital. Capital budgeting. Cash flow analysis. </li> " +
            "<li>Methods of depreciation. Methods of capital appraisal. Risk and Uncertainty in capital budgeting. </li> " +
            "<li>Introduction to working capital management. Issues in financial planning. Tax planning. Break even analysis. International aspects of financial management. Foreign exchange Market. Exchange rates. Currency risks etc.</li> " +
            "</ul> " +

            "<b class='text-dark'>References</b> " +
            "<ul> " +
            "<li>R. Brealey and S. Myers, S., Principles of corporate Finance, McGraw-Hill, 1987.</li> " +
            "<li>J. C. Van Horne, Financial Management and Policy, Prentice Hall, 1971.</li> " +
            "<li>L. M. Bhole, Financial Markets and Institutions, Second Edition, Tata McGraw-Hill, 1992.</li> " +
            "<li>Prasanna Chandra, Financial Management, Third Edition, Tata McGraw-Hill, 1993.</li> " +
            "</ul> " +

            "<div class='rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3 col-md-9'> " +
            "<table class='table table-striped bg-white m-0'> " +
            "<tr> " +
            "<td class='fw-bold' style='width:350px'>Pre-requisite</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>Finance and Econometrics knowledge is necessary</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Total credits</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>6</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Maximum Strength</td> " +
            "<td>:</td> " +
            "<td>50</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Type</td> " +
            "<td>:</td> " +
            "<td></td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Duration</td> " +
            "<td>:</td> " +
            "<td>Autumn 2022</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Name(s) of other Academic units to whom the course may be relevant</td> " +
            "<td>:</td> " +
            "<td>N/A</td> " +
            "</tr> " +
            "</table> " +
            "</div> " +
            "</div>",
    },

    {
        course_id: 'IE709',
        course_title: "IE 709 - IEOR for Health Care",
        course_info: "<div class='px-md-4 px-2 list'> " +
            "<b class='text-dark d-block pt-2'>Course content</b> " +
            "<ul> " +
            "<li>Scheduling appointments of patients with primary health care providers and specialists</li> " +
            "<li>Resource planning for equipment, rooms and sta013</li> " +
            "<li>Capacity planning and inventory management in health care supply chains</li> " +
            "<li>Planning and managing emergency services</li> " +
            "<li>Designing and operating organ exchanges</li> " +
            "<li>Modeling spread of epidemics and planning response</li> " +
            "<li>Mathematical modeling of biological systems</li> " +
            "<li>Design of optimal radiotherapy and other treatments</li> " +
            "<li>Financial planning and pricing in health care</li> " +
            "<li>Economics of community health care</li> " +
            "<li>Designing experiments and statistical analysis of clinical data</li> " +
            "<li>Managing health care during calamities</li> " +
            "</ul> " +

            "<b class='text-dark'>References</b> " +
            "<ul> " +
            "<li>Handbook of Healthcare Operations Management: Methods and Applications, Brian T. Denton, ed., 2013, Springer New York.</li> " +
            "<li>Operations Research and Health Care: A Handbook of Methods and Applications, Mar- garet L. Brandeau, Francois Sainfort, William P. Pierskalla, eds., 2004, Kluwer Academic Publishers, Massachusetts.</li> " +
            "<li>Ambulance allocation and relocation models (A Review), L. Brotcorne, G. Laporte and F. Semet, European Journal of Operational Research, 147, 2003, pp 451-463. </li> " +
            "<li>Operational Research in management of the operating theatre: A survey, F. Guerriero and R. Guido, Health Care Management Science, 14, 2011, pp 89-114. </li> " +
            "<li>Appointment scheduling in health care: Challenges and opportunities, D. Gupta and B. Denton, IIE Transactions, 40, 2008, pp 800-819. 1 </li> " +
            "<li>Mathematics of infectious diseases, H. W. Hethcote, SIAM Review, 42(4), 2000, pp. 599-633.</li> " +
            "<li>Optimizing the delivery of radiation therapy to cancer patients, D. M. Shepard, M. C. Ferris, G. H. Olivera, T. R. Mackie, SIAM Review, 41(4), 1999, pp. 721-744.</li> " +
            "</ul> " +

            "<div class='rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3 col-md-9'> " +
            "<table class='table table-striped bg-white m-0'> " +
            "<tr> " +
            "<td class='fw-bold' style='width:120px'>Total credits</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>6 credits - Lecture</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Type</td> " +
            "<td>:</td> " +
            "<td>Department elective</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Duration</td> " +
            "<td>:</td> " +
            "<td>Full Semester</td> " +
            "</tr> " +
            "</table> " +
            "</div> " +
            "</div>",
    },

    {
        course_id: 'PS619',
        course_title: "PS 619 - Health Policy: An Introduction",
        course_info: "<div class='px-md-4 px-2 list'> " +
            "<b class='text-dark d-block pt-2'>Course content</b> " +
            "<ul> " +
            "<li> " +
            "Health and Public Policy – fundamentals. " +
            "<ul class='m-0'> " +
            "<li>What is meant by health? What qualifies as health policy? Who are the stakeholders when we talk about health policy? </li> " +
            "<li>Role of policy and law in public health and health care. </li> " +
            "<li>Social determinants of health and effect of policy in these spheres.</li> " +
            "<li>Role of health policy in development, with a focus on the sustainable development goals.</li> " +
            "</ul> " +
            "</li> " +
            "<li> " +
            "Overview of existing health policies in India. All or some of these policies may be discussed in detail, depending on student interest. " +
            "<ul class='m-0'> " +
            "<li>History of health policy in India. </li> " +
            "<li>National immunization programmes. Pulse Polio program.</li> " +
            "<li>Tuberculosis, Leprosy and HIV. </li> " +
            "<li>Malaria and other vector borne disease eradication programmes. </li> " +
            "<li>Family planning and population control policies. </li> " +
            "<li>Policies for non-communicable diseases. </li> " +
            "<li>Nutrition and maternal and child health policies.</li> " +
            "<li>Policies concerning sanitation and access to water.</li> " +
            "<li>Policy initiatives for mental health and well-being.</li> " +
            "</ul> " +
            "</li> " +
            "<li> " +
            "Epidemiology, Health Statistics, and Health Policy. " +
            "<ul class='m-0'> " +
            "<li>Introduction to concepts of epidemiology- mortality, morbidity rates, odds ratios, natural history of diseases, finding causal connections. </li> " +
            "<li>Understanding the techniques of collection and reporting of health statistics. </li> " +
            "<li>Overview of the national health surveys. </li> " +
            "<li>Designing and implementing surveys and healthcare interventions. </li> " +
            "</ul> " +
            "</li> " +
            "<li> " +
            "Health Policy, Finance and Budgeting. " +
            "<ul class='m-0'> " +
            "<li>Budgetary outlays for health care and health policy implementation. </li> " +
            "<li>Insurance and cost distribution for health care and preventive health services.</li> " +
            "</ul> " +
            "</li> " +
            "<li> " +
            "Health Bureaucracy and Public Health Institutions. " +
            "<ul class='m-0'> " +
            "<li>Overview of the health care and public health related human resource at the level of the center, state, and district. </li> " +
            "<li>Role of international and national nonprofits in public health and health care delivery and monitoring.</li> " +
            "</ul> " +
            "</li> " +
            "</ul> " +

            "<b class='text-dark'>References</b> " +
            "<ul> " +
            "<li>Taneja, D. (2017). Health policies and programmes in India-15 edition.</li> " +
            "<li>Doctors Publications.</li> " +
            "<li>Sengupta, K. (2016). Determinants of health status in India. Springer India. Brimnes, N. (2016).</li> " +
            "<li>Languished Hopes: Tuberculosis, the State and International Assistance in Twentieth-century India. Orient black swan (indisk). Sundar, D. K., Garg, S., & Garg, I. (Eds.). (2015). </li> " +
            "<li>Public Health in India: Technology, Governance and Service Delivery. Routledge. Farmer, P. (2004). </li> " +
            "<li>Pathologies of power: Health, human rights, and the new war on the poor (Vol. 4). Univ of California Press.</li> " +
            "</ul> " +

            "<div class='rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3 col-md-9'> " +
            "<table class='table table-striped bg-white m-0'> " +
            "<tr> " +
            "<td class='fw-bold' style='width:120px'>Total credits</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>6 credits - Lecture  </td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Type</td> " +
            "<td>:</td> " +
            "<td>Department elective</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Duration</td> " +
            "<td>:</td> " +
            "<td>Full Semester</td> " +
            "</tr> " +
            "</table> " +
            "</div> " +
            "</div>",
    },

    {
        course_id: 'SOM633',
        course_title: "SOM 633 - Quality Management",
        course_info: "<div class='px-md-4 px-2 list'> " +
            "<b class='text-dark d-block pt-2'>Course content</b> " +
            "<ul> " +
            "<li>Evolution of Quality Management; Concepts of Product and Service Quality; Dimensions of Quality; PDCA Cycle. Deming, Juran’s, Crosby, TQM Philosophy ; 7 QC tools;</li> " +
            "<li>Statistical Control charts- for attributes and variables characteristics; Process capability analysis; Acceptance sampling and inspection plan; Measurement System Analysis; ANOVA; Multiple Linear Regression; Basic concept on Design of Experiment (DOE) ; Factorial Design; Six Sigma Quality; QS 9000 and ISO 9000-2008; Taguchi’s Method; QFD; Reliability Cases and Project Component.</li> " +
            "</ul> " +

            "<b class='text-dark'>References</b> " +
            "<ul> " +
            "<li>Bester field, D H et al., Total Quality Management, 3rd Edition, Pearson Education, 2008.</li> " +
            "<li>Montgomery, D. C., Introduction to Statistical Quality Control, John Wiley & Sons, 3rd Ed.</li> " +
            "<li>Mitra A., Fundamentals of Quality Control and Improvement, PHI, 2nd Ed., 1998.</li> " +
            "<li>Montgomery D. C., Design and Analysis of Experiment, 5302222th Edition, John Wiley & Sons. Total Quality Management, Autor - D H Bester field, Pearson, 4th ed., 2017</li> " +
            "</ul> " +

            "<div class='rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3 col-md-9'> " +
            "<table class='table table-striped bg-white m-0'> " +
            "<tr> " +
            "<td class='fw-bold' style='width:350px'>Pre-requisite</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>Basic Statistics</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Total credits</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>3</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Maximum Strength</td> " +
            "<td>:</td> " +
            "<td>60</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Type</td> " +
            "<td>:</td> " +
            "<td></td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Duration</td> " +
            "<td>:</td> " +
            "<td>Autumn 2022. September 2022 - November 2022</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Name(s) of other Academic units to whom the course may be relevant</td> " +
            "<td>:</td> " +
            "<td>N/A</td> " +
            "</tr> " +
            "</table> " +
            "</div> " +
            "</div>",
    },

    {
        course_id: 'BB681',
        course_title: "BB 681 - Physical Biology at Microscopic scale",
        course_info: "<div class='px-md-4 px-2 list'> " +
            "<b class='text-dark d-block pt-2'>Course content</b> " +
            "<p>Forces at microscopic Scale Effect of surrounding medium on functioning of a biological system at microscopic scale Thermal Energy at microscopic scale Diffusion, drift, dissipation and drive at microscopic scale Machines and mechanisms at microscopic scales Feedback, Switches and Oscillators at microscopic scales</p> " +
            "<b class='text-dark'>References</b> " +
            "<p>Biological Physics Student Edition: Energy, Information, Life, Philip Nelson, Chiliagon Science, 2020ISBN 057868702X, 9780578687025 Physical Models of Living Systems, Philip Nelson, WH Freeman; 1st ed. 2 edition (20 December 2014) ISBN-10 : 1464140294, ISBN-13 : 978-1464140297 Physical Biology of the Cell, Rob Phillips, Jan303251 Kondev, Julie Theriot, Hernan Garcia, Jane Kondev, Garland Science; 2nd edition (21 November 2), ISBN-10 : 9780815344506, ISBN-13 : 978-0815344506 Mechanics of Motor Proteins and the Cytoskeleton, Jonathon Howard, OUP USA; 2005 edition ISBN-10 : 0878933336, ISBN-13 : 978-0878933334</p> " +

            "<div class='rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3 col-md-9'> " +
            "<table class='table table-striped bg-white m-0'> " +
            "<tr> " +
            "<td class='fw-bold' style='width:200px'>Total credits</td> " +
            "<td style='width:20px'>:</td> " +
            "<td> 6 - Lecture and Tutorial</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Type</td> " +
            "<td>:</td> " +
            "<td>Department Elective</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Duration</td> " +
            "<td>:</td> " +
            "<td>Full Semester</td> " +
            "</tr> " +
            "</table> " +
            "</div> " +
            "</div>",
    },

    {
        course_id: 'CS768',
        course_title: "CS 768 - Learning with Graphs",
        course_info: "<div class='px-md-4 px-2 list'> " +
            "<b class='text-dark d-block pt-2'>Course content</b> " +
            "In the first half of the semester, I plan to introduce dynamics of the complex networks. This will mainly deal with (i) various graph generative models, (ii) simple link prediction methods and (iii) diffusion processes on networks. More specifically, " +
            "<ul> " +
            "<li>Overview of network science in the context of real life networks, and limitations of classical graph theory to address them.</li> " +
            "<li>Generative models on graphs: Classical (physics based) random graph generative models - Price model, Barabasi Albert model and Erdos-Renyl model, with their statistical properties and generating functions. Modern graph generative models - Kronecker and Forest Fire models. Inference of Kronecker graphs. Difference between Forest Fire and Price models</li> " +
            "<li>Link prediction heuristics: Adamic adar, jaccard coefficients, supervised random walk</li> " +
            "<li>Processes over networks: Classical information cascade models, epidemic processes and their application on viral marketing.</li> " +
            "</ul> " +

            "In the second half of the semester, I plan to introduce deep learning on graphs. In particular, " +
            "<ul> " +
            "<li>Representation learning of graphs - Various node representation methods e.g. Node2Vec, LINE, DeepWalk.</li> " +
            "<li>Inductive node representation methods - GraphSAGE and graph convolutional network</li> " +
            "<li>Graph neural networks</li> " +
            "<li>Deep generative models on graphs - variational autoencoder on graphs and generative adversarial networks on graphs.</li> " +
            "</ul> " +

            "<b class='text-dark'>References</b> " +
            "<ul> " +
            "<li>Newman, Mark Ed, Albert-L303241szl303263 Ed Barab303241si, and Duncan J. Watts. The structure and dynamics of networks. Princeton university press, 2006.</li> " +
            "<li>Easley, David, and Jon Kleinberg. Networks, crowds, and markets. Vol. 8. Cambridge: Cambridge university press, 2010.</li> " +
            "<li>Zhang, Ziwei, Peng Cui, and Wenwu Zhu. 'Deep learning on graphs: A survey'. arXiv preprint arXiv:1812.04202 (2018).</li> " +
            "<li>Hamilton, William L., Rex Ying, and Jure Leskovec. 'Representation learning on graphs: Methods and applications.' arXiv preprint arXiv:1709.05584 (2017).</li> " +
            "</ul> " +

            "<div class='rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3 col-md-9'> " +
            "<table class='table table-striped bg-white m-0'> " +
            "<tr> " +
            "<td class='fw-bold' style='width:200px'>Total credits</td> " +
            "<td style='width:20px'>:</td> " +
            "<td> 6 - Lecture and Tutorial</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Type</td> " +
            "<td>:</td> " +
            "<td>Department Elective</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Duration</td> " +
            "<td>:</td> " +
            "<td>Full Semester</td> " +
            "</tr> " +
            "</table> " +
            "</div> " +
            "</div>",
    },

    {
        course_id: 'DH302',
        course_title: "DH 302: Introduction to Public Health Informatics",
        course_info: "<div class='px-md-4 px-2 list'> " +
            "<b class='text-dark d-block pt-2'>Course content</b> " +
            "<ul> " +
            "<li>Introduction to public health with emphasis on health promotion and disease prevention.</li> " +
            "<li>History of public health work, Role of government in health, public health policies and interventions</li> " +
            "<li>Introduction to global health, health inequities, Epidemic and pandemic preparedness, Environmental issues in public health.</li> " +
            "<li>Mapping and surveillance techniques for controlling diseases, models for efficient and knowledge-based public health work</li> " +
            "<li>Introduction to biomedical informatics and public health informatics, the role of informatics in public health and global health Standards in Biomedical and public health Informatics</li> " +
            "<li>Evaluation of Biomedical and Health Information Resources, Patient-Centered Care Systems, Patient Monitoring Systems, Clinical Research Informatics</li> " +
            "<li>Introduction to ethics in healthcare and its importance. Issues in Clinical ethics and Research ethics.</li> " +
            "<li>Studying the application of healthcare technology and ethical issues. Main principles of medical ethics</li> " +
            "<li>Overview of the Clinical Research Process. WHO Principles of Good Clinical Practice. Ethical Conduct Good Clinical Practice. Protocol.</li> " +
            "<li>Review by Independent Ethics Committee/ Independent Review Board. Protocol Compliance. Informed Consent Roles and responsibilities of Principal. Investigator Qualifications. Staff Qualifications. Records.</li> " +
            "<li>Confidentiality and data /Privacy.</li> " +
            "</ul> " +
            "<b class='text-dark'>References</b> " +
            "<ul> " +
            "<li>Edward H. Shortliffe, James J. Cimino, Biomedical Informatics  Computer Applications in Health Care and Biomedicine  Fourth Edition, Springer</li> " +
            "<li>Schneider, M. (2011). Introduction to Public Health. United States: Jones & Bartlett Learning.</li> " +
            "<li>Principles of clinical ethics and their application to practice. Basil Varkey, Med. Princ. Pract. 2021:30:17-28</li> " +
            "<li>Selected papers and reading material will be shared</li> " +
            "<li>Good clinical practice: Online resource for certification <a href='https://gcp.nidatraining.org/' target='_blank'><u>www.gcp.nidatraining.org</u> </a> </li> " +
            "</ul>  " +
            "<div class='rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3 col-md-9'> " +
            "<table class='table table-striped bg-white m-0'> " +
            "<tr> " +
            "<td class='fw-bold' style='width:300px'>Pre-requisite</td> " +
            "<td style='width: 20px;'>:</td> " +
            "<td>BB101</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Total credits</td> " +
            "<td>:</td> " +
            "<td>6 credits - Lecture</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Type</td> " +
            "<td>:</td> " +
            "<td></td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Duration</td> " +
            "<td>:</td> " +
            "<td>Full Semester</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='border-0 fw-bold'>Name(s) of other Academic units to whom the course may be relevant</td> " +
            "<td class='border-0'>:</td> " +
            "<td class='border-0'></td> " +
            "</tr> " +
            "</table> " +
            "</div> " +
            "</div>",
    },

    {
        course_id: 'DH307',
        course_title: "DH 307: R&D Project",
        course_info: "<div class='px-md-4 px-2 list'> " +
            "<b class='text-dark d-block pt-2'>Course content</b> " +
            "<p>In DH 307, participants are required to undertake a research project in their chosen domain. The program is tailored to meet the specific needs and interests of participants, offering a diverse range of topics to explore. Participants can choose from a wide array of interdisciplinary research topics.</p> " +
            "<p>The R&D course's curriculum is meticulously designed to integrate theoretical knowledge with hands-on experiences, fostering a practical learning environment. Participants undergo comprehensive training in multiple research stages, encompassing problem identification, literature review, hypothesis formulation, experimental design, data analysis, and findings presentation. Regular interactions with faculty members enrich the students' learning journey.</p> " +
            "<p>Overall, this course equips participants with the skills and knowledge necessary to engage in independent and rigorous research, contribute to the advancement of knowledge, and prepare them for further research pursuits or professional careers in academia, industry, or other fields.</p> " +
            "</div> " +
            "</div>",
    },

    {
        course_id: 'ES601',
        course_title: "ES 601- Environmental Health and Safety",
        course_info: "<div class='px-md-4 px-2 list'> " +
            "<b class='text-dark d-block pt-2'>Course content</b> " +
            "<p>Regulations for Environment, Health and Safety: Factories Act and Rules, Environmental Pollution Act, Oil Industry Safety Directorate (OISD), Indian Electricity Acts and Rules, Mines Acts and Rules, Workmen Compensation Act, OSHA Standards, IS & BS Standards Occupational Health and Hygiene: Physical hazards - Noise and vibration, Ionizing and non-ionizing radiations, Health effects, surveying methods, Control measures and protective equipment Chemical Hazards – Hazard identification, TLV for air, gas and chemical contaminants, Assessment of physical and chemical hazards; Occupational Health - Workplace health hazards, Industrial toxicology, and Risk assessment. Safety performance: Indian and International standards; Hazard analysis - Cost effectiveness in hazard elimination, logical analysis – HAZOP; Probabilistic reliability considerations. Safety management techniques: Safety inspection – safety action, safety survey disaster control, Fire hazard and firefighting, Case study in construction, chemical and oil/gas industry. Environmental Monitoring and Pollution Control: Environmental exposure standards, Human exposure and dose assessment, Study designs, Environmental monitoring of air, water, food, soil and microorganisms, Personal monitoring. Air, Water and Solid waste pollutants’ classification, major sources, control and mitigation and transport in the environment.</p> " +
            "<b class='text-dark'>References</b> " +
            "<p>Tillman C., Principles of Occupational Health and Hygiene, Allen & Unwin, NSW, Australia, 2008 Berglund M., Elinder C.-G., Jarup L. Human Exposure Assessment, World Health Organization (WHO), Geneva, 2001 Leelakrishnan P., Environmental Law in India, LexisNexis, Gurgaon, India, 2016 Panda  B., Industrial Safety, Health Environment and Security, University Sciences Press, New Delhi, 2013 Henrich, H.W., Industrial Accident Prevention, McGraw Hill, 1980. Encyclopedia of Occupational Health and Safety, Vol. I and II. International Labour Organisation, Geneva, 1985.</p> " +

            "<div class='rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3 col-md-6'> " +
            "<table class='table table-striped bg-white m-0'> " +
            "<tr> " +
            "<td class='fw-bold' style='width:200px'>Total credits</td> " +
            "<td style='width:20px'>:</td> " +
            "<td> 6 - Lecture and Tutorial</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Type</td> " +
            "<td>:</td> " +
            "<td>Department Elective</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Duration</td> " +
            "<td>:</td> " +
            "<td>Full Semester</td> " +
            "</tr> " +
            "</table> " +
            "</div> " +
            "</div>",
    },

    {
        course_id: 'EE782',
        course_title: "EE 782 - Advanced Machine Learning",
        course_info: "<div class='px-md-4 px-2 list'> " +
            "<b class='text-dark d-block pt-2'>Course content</b> " +
            "<p>Penalized and generalized linear models: Logistic regression, L1 and L2 penalization, elastic net, SCAD penalty, application to high dimensional low sample size problems. Intro to neural networks: Artificial neuron, single hidden layer, multiple hidden layer, back propagation, momentum, loss functions, relation with support vector machines and penalized logistic regression. Convolutional neural networks: Convolutional layers, pooling layers, drop out, VGGnet, inception modules, residual networks, deconv nets, applications to object recognition. Why deep learning works: Role of depth, closeness of local minima to global minimal, predominance of saddle points and ridges vs. local minima. Recurrent neural networks and LSTMs: lateral connections, LSTM units, gated recurrent networks, applications to NLP. Probabilistic Graphical Models: Factor graphs and belief networks,  Deep belief networks and Boltzmann machines, sampling methods including Gibbs sampling, contrastive divergence, generative adversarial networks.</p> " +
            "<b class='text-dark'>References</b><br/> " +
            "Textbooks: " +
            "<ol class='mb-4'> " +
            "<li>Deep Learning by Goodfellow, Bengio and Courville.<br></li> " +
            "<li>Probabilistic Graphical Models: Principles and Techniques by Koller and Friedman</li> " +
            "</ol> " +

            "<div class='rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3 col-md-9'> " +
            "<table class='table table-striped bg-white m-0'> " +
            "<tr> " +
            "<td class='fw-bold' style='width:200px'>Pre-requisite</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>N/A</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold' style='width:200px'>Total credits</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>6</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Type</td> " +
            "<td>:</td> " +
            "<td></td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Duration</td> " +
            "<td>:</td> " +
            "<td>Full Semester</td> " +
            "</tr> " +
            "</table> " +
            "</div> " +
            "</div>",
    },

    {
        course_id: 'GNR650',
        course_title: "GNR 650 - Advanced topics in deep learning for image analysis",
        course_info: "<div class='px-md-4 px-2 list'> " +
            "<b class='text-dark d-block pt-2'>Course content</b> " +
            "<p>The course will deal with advanced deep learning techniques used to solve critical inference problems in remote sensing, image analysis, and computer vision. We plan to cover the following learning paradigms which consider learning a classification model under different levels of supervision. Meta- learning and few-shot learning Zero-shot learning Self-supervised learning Multi-modal learning Multi-task learning Continual learning Domain adaptation and generalization Uncertainty estimation in deep learning We will consider the application of the aforementioned learning algorithms for visual inference tasks with focus to image classification and semantic segmentation. To this end, land-cover classification from remote sensing images of varied modalities would be the running application example that will be discussed at length for all the algorithms. In addition, we will consider a few challenging classification problems in remote sensing (cross-sensor data retrieval, change detection, and land cover map generation for geographically disjoint and large areas) and show how some of these learning techniques (domain adaptation, uncertainty estimate) can be modified to solve these problems. Finally, annotation is a major problem in remote sensing, we plan to discuss how off-the-shelves self-supervision can be used as an alternative in this regard. Like the examples from the remote sensing area, we will consider examples from natural image and video analysis literature concerning very large-scale datasets (Image Net, Domain Net, to name a few)</p> " +
            "<b class='text-dark'>References</b> " +
            "<p>Goodfellow, Ian, Yoshua Bengio, and Aaron Courville.302240Deep learning. MIT press, 2016.Research papers from IEEE T. Geoscience & Remote Sensing, IEEE T. PAMI, IJCV, CVPR, ICCV, ECCV, NeurIPS, ICML, BMVC, ICLR.Deep learning lectures from Stanford university, NYU (available on youtube), NPTEL</p> " +

            "<div class='rounded-3 border bg-light border-bottom position-relative overflow-hidden mb-3 col-md-9'> " +
            "<table class='table table-striped bg-white m-0'> " +
            "<tr> " +
            "<td class='fw-bold' style='width:200px'>Pre-requisite</td> " +
            "<td style='width:20px'>:</td> " +
            "<td>N/A</td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold' style='width:200px'>Total credits</td> " +
            "<td style='width:20px'>:</td> " +
            "<td></td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Type</td> " +
            "<td>:</td> " +
            "<td></td> " +
            "</tr> " +
            "<tr> " +
            "<td class='fw-bold'>Duration</td> " +
            "<td>:</td> " +
            "<td>Full Semester</td> " +
            "</tr> " +
            "</table> " +
            "</div> " +
            "</div>",
    },

]
export { Course }
