import React, { useState, useEffect } from "react";
import global from "../../global-variables";
import Header from "../layout/header";
import { Link } from "react-router-dom";
import $ from "jquery";
import WOW from "wowjs";

import { Container, Row, Col } from "reactstrap";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Modal from 'react-bootstrap/Modal';
import { Course } from './course-popup.js';
import globalVariables from "../../global-variables";
import Metatag from "../../hooks/Metatag";

export default function MSByResearch() {
    const [show, setShow] = useState(false);
    const [getcourseid, setCourseid] = useState('');
    const handleClose = () => setShow(false);
    const handleShow = (e, course_id) => {
        e.preventDefault();
        setShow(true);
        //get Course id
        setCourseid(course_id);
    }

    useEffect(() => {
        // add class for home header and remove inner class
        $(".fixed-top").addClass("bg-header");

        // initiate the wow effect
        new WOW.WOW({
            live: false,
        }).init();

    }, []);

    useEffect(() => {

        window.initializeFancyboxMedia();
    }, [])

    return (
        <>
            <Header main_className="bg-header"></Header>

            <Metatag title='M.S. by Research in Healthcare Informatics'
                description='M.S. by Research in Healthcare Informatics by KCDH IIT-B'
                keywords="M.S. by Research in Healthcare Informatics, M.S. by Research Program, M.S. by Research in Informatics and Data Analytics, MS Program at KCDH IIT-B"
            />

            {/* Visual Panel */}
            <Container fluid className="bg-visual-inside p-0" >
                <div className="visual-inside-big">
                    <Row className="g-md-0">
                        <Col md={6} className="order-2 order-md-1">
                            <Container className="d-flex align-items-end h-100">
                                <div className="d-inline-block m-0 align-self-end col-lg-6 col-xl-6 py-xl-3 p-2 text-white">
                                    <h1 className="fw-light text-white m-0 align-self-center wow fadeInUp">
                                        M.S. by Research
                                    </h1>
                                </div>
                            </Container>
                        </Col>
                        <div className="col-md-6 order-1 order-md-2">
                            <img src={global.variables.strDomainUrl + "images/visual-students-corner.jpg"} className="img-fluid" alt="" title="" />
                        </div>
                    </Row>
                </div>
            </Container>


            {/* Container */}
            <Container className="my-5">
                <Row>
                    <Col md={3}>
                        <h2 className="text-primary wow fadeInUp">Program Overview</h2>
                    </Col>
                    <Col md={9} className="ps-md-5">
                        <p>Our M.S. by Research program provides a flexible and enriching R&D experience, acting as a stepping stone for individuals keen on exploring research without an immediate commitment to a Ph.D. journey. This program delivers a versatile and immersive educational experience, enriching students' academic journey while preparing them for success in both academic and industrial R&D environments.</p>
                        <p>At KCDH, we foster collaborations with leading hospital systems, government departments, healthcare research institutions, and academic organizations to meet the escalating demand for skilled professionals in this field.</p>
                    </Col>
                </Row>
            </Container>
            <Container>
                <hr className="px-5" />
            </Container>
            <Container className="my-5">
                <Row>
                    <Col md={3}>
                        <h2 className="text-primary wow fadeInUp">
                            Important dates related to M.S. by Research admission
                        </h2>
                    </Col>
                    <Col md={9} className="ps-md-5 list">
                        <ul>
                            <li>Online Written Test: April 21, 2024 </li>
                            <li>Interviews for shortlisted candidates : April 30 / May 1, 2024</li>
                        </ul>
                    </Col>
                </Row>
            </Container>
            <Container>
                <hr className="px-5" />
            </Container>
            <Container className="my-5">
                <Row>
                    <Col md={3}>
                        <h2 className="text-primary wow fadeInUp">
                            Information Brochure
                        </h2>
                    </Col>
                    <Col md={9} className="ps-md-5">
                        <p>For important guidelines, important dates, eligibility criteria, admission process, fee structure or any other information related to M.S. by Research program at IIT Bombay, refer to <Link to="https://www.iitb.ac.in/newacadhome/msResearch.jsp" target="_blank" rel="noreferrer" className="text-primary text-decoration-underline">https://www.iitb.ac.in/newacadhome/msResearch.jsp</Link></p>
                    </Col>
                </Row>
            </Container>
            <Container>
                <hr className="px-5" />
            </Container>

            <Container className="my-5">
                <Row>
                    <Col md={3}>
                        <h2 className="text-primary wow fadeInUp">
                            Apply on IIT-B Portal
                        </h2>
                    </Col>
                    <Col md={9} className="ps-md-5">
                        <p>To apply for admission for M.S. by Research Program for Academic year (2024-25) - visit: <Link to="https://portal.iitb.ac.in/" target="_blank" rel="noreferrer" className="text-primary text-decoration-underline">https://portal.iitb.ac.in/</Link></p>
                    </Col>
                </Row>
            </Container>
            <Container>
                <hr className="px-5" />
            </Container>

            <Container className="my-5">
                <Row>
                    <Col md={3}>
                        <h2 className="text-primary wow fadeInUp">
                            Potential career paths for students
                        </h2>
                    </Col>
                    <Col md={9} className="ps-md-5">
                        <p>The field of healthcare informatics is vast and rapidly evolving. These are just a few examples of the many sectors where opportunities exist for healthcare informatics professionals. The skills and knowledge you gain from the M.S. by Research program could also be applied to many other roles within these sectors. The possibilities are truly endless! </p>
                        <ol>
                            <li><b>Healthcare Sector:</b> Hospitals, clinics, and health departments often employ healthcare informatics professionals to manage and analyze health data, implement and optimize clinical information systems, and oversee IT projects. </li>
                            <li><b>Pharmaceutical Industry:</b> Pharmaceutical companies need healthcare informatics professionals to manage and analyze clinical trial data, develop software for drug discovery, and ensure regulatory compliance. </li>
                            <li><b>Biotechnology Industry:</b> Biotech firms employ bioinformatics specialists to analyze complex biological data, such as genomic and proteomic data.</li>
                            <li><b>Health Insurance Industry:</b> Health insurance companies need healthcare informatics professionals to analyze claims data, manage electronic health records, and develop predictive models for risk assessment. </li>
                            <li><b>Government Agencies:</b> Government health departments and agencies employ healthcare informatics professionals to manage public health data, conduct epidemiological studies, and develop health policy.</li>
                            <li><b>Research Institutions:</b> Universities and research institutions employ healthcare informatics professionals to conduct research, analyze research data, and teach healthcare informatics.</li>
                            <li><b>Health IT Companies:</b> Companies that develop health IT solutions, such as electronic health records systems, telemedicine platforms, and health apps, employ healthcare informatics professionals for software development, data analysis, and project management. </li>
                            <li><b>Consulting Firms:</b> Consulting firms that specialize in healthcare often employ healthcare informatics professionals to provide advice to healthcare organizations on how to best use information technology to meet their healthcare objectives. </li>
                        </ol>
                    </Col>
                </Row>
            </Container>
            <Container>
                <hr className="px-5" />
            </Container>

            <Container className="my-5">
                <Row>
                    <Col md={3}>
                        <h2 className="text-primary wow fadeInUp">
                            Future Research Opportunities
                        </h2>
                    </Col>
                    <Col md={9} className="ps-md-5">
                        <p>The field of Healthcare Informatics is rapidly evolving, offering numerous opportunities for groundbreaking research. Here are some potential areas for future exploration: </p>
                        <ol>
                            <li><b>Personalized Medicine:</b> With the rise of genomics and proteomics, there’s a growing need for informatics solutions that can help deliver personalized treatment plans based on a patient’s unique genetic makeup. </li>
                            <li><b>Predictive Analytics:</b> The use of machine learning and AI to predict disease outbreaks, patient outcomes, and healthcare trends is a promising area of research. </li>
                            <li><b>Telemedicine:</b> As healthcare becomes more digitized, there’s a need for research into effective telemedicine practices, including patient privacy, data security, and the efficacy of remote patient monitoring. </li>
                            <li><b>Healthcare Interoperability:</b> With numerous electronic health record systems in use, research into data interoperability standards and solutions is crucial. </li>
                            <li><b>AI and Machine Learning in Healthcare:</b> The application of AI and machine learning in diagnosing diseases, predicting patient outcomes, and optimizing treatment plans is a rapidly growing field of research. </li>
                            <li><b>Blockchain in Healthcare:</b> Research into how blockchain technology can be used to secure patient data and improve interoperability is an emerging field. </li>
                            <li><b>Ethics of Health Informatics:</b> As more health data is collected and analyzed, there’s a growing need for research into the ethical implications, including data privacy, consent, and the use of AI in healthcare decisions. </li>
                        </ol>
                    </Col>
                </Row>
            </Container>
            <Container>
                <hr className="px-5" />
            </Container>

            <Container className="my-5">
                <Row>
                    <Col md={3}>
                        <h2 className="text-primary wow fadeInUp">
                            Eligibility Criteria
                        </h2>
                    </Col>
                    <Col md={9} className="ps-md-5">
                        <p>Candidates with First class or 60% (55% marks for SC/ST) marks in </p>
                        <ol>
                            <li>B.E./B.Tech. /AMIE or equivalent in any engineering discipline.  OR M.Sc. or equivalent in any science discipline.  OR MCA (with Physics & Mathematics at B.Sc. level)  WITH equivalent Valid GATE score in any discipline is required for all applicants except commissioned officers of the armed forces and those having B.Tech. Degree from IITs with CGPA/CPI of 8.00 and above (on 0-10 scale). OR  </li>
                            <li>MBBS, BDS, BPharm, BVSc, BPTh, BOTh (degree programme to be of 4 years  or more) with 60 percent aggregate AND qualifying All India level post graduate entrance examination for corresponding disciplines such as AIIMS/NEET-PG/MCI/JIPMER/ PGI Chandigarh/AFMC­Pune/ for MBBS/BDS or GPAT (for Pharmacy graduates) or a valid CSIR/UGC/DBT/ICMR JRF not linked to ICMR project (for FA any fellowship that will provide scholarship for 2 years </li>
                        </ol>
                        <p>Note: The Center may additionally conduct <b>a written test and/or an interview for all applicants to shortlist candidates to be selected.</b> </p>
                    </Col>
                </Row>
            </Container>
            <Container>
                <hr className="px-5" />
            </Container>


            <Container className="my-5">
                <Row>
                    <Col md={3}>
                        <h2 className="text-primary wow fadeInUp">
                            Program Structure
                        </h2>
                    </Col>
                    <Col md={9} className="ps-md-5 list">
                        <p>Students are expected to register for at least: </p>
                        <ul>
                            <li>30 credits in semester I. </li>
                            <li>22 credits in semester II. </li>
                            <li>6 credits in semester III. </li>
                        </ul>

                        <div className="rounded-3 border-bottom overflow-hidden mb-4 mt-2">
                            <div className="table-responsive">
                                <table className="table table-bordered table-striped m-0">
                                    <thead className="table-primary">
                                        <tr>
                                            <th>Sem I</th>
                                            <th></th>
                                            <th>Sem II</th>
                                            <th></th>
                                            <th>Sem III</th>
                                            <th></th>
                                            <th>Sem IV/V/VI</th>
                                            <th>Total Credits </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Courses</td>
                                            <td>Credits</td>
                                            <td>Courses</td>
                                            <td>Credits</td>
                                            <td>Courses</td>
                                            <td>Credits</td>
                                            <td>Courses</td>
                                            <td>Credits</td>
                                        </tr>
                                        <tr>
                                            <td>DH XYZ: R&D project-I</td>
                                            <td>6</td>
                                            <td>Seminar (DHS801)</td>
                                            <td>4</td>
                                            <td>Elective VI from any group</td>
                                            <td>6</td>
                                            <td>MS Thesis</td>
                                            <td>--</td>
                                        </tr>
                                        <tr>
                                            <td>DH 302 (Compulsory)</td>
                                            <td>6</td>
                                            <td>Elective IV from any group </td>
                                            <td>6</td>
                                            <td>MS Thesis</td>
                                            <td>--</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Elective I from group 1</td>
                                            <td>6</td>
                                            <td>Elective V from any group </td>
                                            <td>6</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Elective II from group 2</td>
                                            <td>6</td>
                                            <td>DH XYZ R&D project II</td>
                                            <td>6</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Elective III from group3 </td>
                                            <td>6</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td>DH 899: Communication Skills </td>
                                            <td>PP/NP 6**</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Total-Credits</td>
                                            <td>30</td>
                                            <td></td>
                                            <td>22+6**</td>
                                            <td></td>
                                            <td>6</td>
                                            <td></td>
                                            <td>58+6**</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <p>A student undertaking the M.S. by Research Program will be exposed to a broad range of theoretical and practical issues related to Healthcare Informatics. While the Program is interdisciplinary, courses and research will be organized around four broad tracks/baskets:</p>


                        <p className="m-0"><b>Group 1:</b> Healthcare Standards, Clinical applications & Bio related courses</p>
                        <div className="rounded-3 border-bottom overflow-hidden mb-4 mt-2">
                            <div className="table-responsive">
                                <table className="table table-bordered table-striped m-0">
                                    <thead className="table-primary">
                                        <tr>
                                            <th style={{ width: "120px" }}>Course Code</th>
                                            <th style={{ width: "350px" }}>Course Name</th>
                                            <th style={{ width: "75px" }}>Credits</th>
                                            <th style={{ width: "auto" }}>Department/Center</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>BB 603</td>
                                            <td>
                                                <Link onClick={(e) => handleShow(e, "BB603")}>Physiology for Engineers</Link>
                                            </td>
                                            <td>6</td>
                                            <td>Biosciences and Bioengineering</td>
                                        </tr>
                                        <tr>
                                            <td>BB 607</td>
                                            <td>
                                                <Link onClick={(e) => handleShow(e, "BB607")}>Proteomics: Principles and Techniques</Link>
                                            </td>
                                            <td>6</td>
                                            <td>Biosciences and Bioengineering</td>
                                        </tr>
                                        <tr>
                                            <td>BB 619</td>
                                            <td>
                                                <Link onClick={(e) => handleShow(e, "BB619")}>Mathematics for Biologists</Link>
                                            </td>
                                            <td>6</td>
                                            <td>Biosciences and Bioengineering</td>
                                        </tr>
                                        <tr>
                                            <td>BB 624</td>
                                            <td>
                                                <Link onClick={(e) => handleShow(e, "BB624")}>Microfluidics: Physics and Applications</Link>
                                            </td>
                                            <td>6</td>
                                            <td>Biosciences and Bioengineering</td>
                                        </tr>
                                        <tr>
                                            <td>BB 626</td>
                                            <td>
                                                <Link onClick={(e) => handleShow(e, "BB626")}>Modeling Biological Systems and Processes</Link>
                                            </td>
                                            <td>6</td>
                                            <td>Biosciences and Bioengineering</td>
                                        </tr>
                                        <tr>
                                            <td>BB 627</td>
                                            <td>
                                                <Link onClick={(e) => handleShow(e, "BB627")}>Medical Imaging
                                                    Methods</Link>
                                            </td>
                                            <td>3</td>
                                            <td>Biosciences and Bioengineering</td>
                                        </tr>
                                        <tr>
                                            <td>BB 633 </td>
                                            <td>
                                                <Link onClick={(e) => handleShow(e, "BB633")}>Movement Neuroscience</Link>
                                            </td>
                                            <td>6</td>
                                            <td>Biosciences and Bioengineering </td>
                                        </tr>
                                        <tr>
                                            <td>BB 640</td>
                                            <td>
                                                <Link onClick={(e) => handleShow(e, "BB640")}>Biologics</Link>
                                            </td>
                                            <td>3</td>
                                            <td>Biosciences and Bioengineering</td>
                                        </tr>
                                        <tr>
                                            <td>BB 645</td>
                                            <td>
                                                <Link onClick={(e) => handleShow(e, "BB645")}>Drug Discovery and Development</Link>
                                            </td>
                                            <td>3</td>
                                            <td>Biosciences and Bioengineering</td>
                                        </tr>
                                        <tr>
                                            <td>BB 656</td>
                                            <td>
                                                <Link onClick={(e) => handleShow(e, "BB656")}>Introduction
                                                    to Mechanobiology</Link>
                                            </td>
                                            <td>3</td>
                                            <td>Biosciences and Bioengineering</td>
                                        </tr>
                                        <tr>
                                            <td>BB 663</td>
                                            <td>
                                                <Link onClick={(e) => handleShow(e, "BB663")}>Medical Imaging
                                                    Physics</Link>
                                            </td>
                                            <td>3</td>
                                            <td>Biosciences and Bioengineering</td>
                                        </tr>
                                        <tr>
                                            <td>CL 662</td>
                                            <td>
                                                <Link onClick={(e) => handleShow(e, "CL662")}>Introduction to
                                                    Computational Biology</Link>
                                            </td>
                                            <td>6</td>
                                            <td>Chemical Engineering</td>
                                        </tr>
                                        <tr>
                                            <td>DH 301</td>
                                            <td>
                                                <Link onClick={(e) => handleShow(e, "DH301")}>Basic Epidemiology</Link>
                                            </td>
                                            <td>6</td>
                                            <td>Koita Centre for Digital Health</td>
                                        </tr>
                                        <tr>
                                            <td>DH 803</td>
                                            <td>
                                                <Link onClick={(e) => handleShow(e, "DH803")}>Wearable Health Technologies</Link>
                                            </td>
                                            <td>6</td>
                                            <td>Koita Centre for Digital Health</td>
                                        </tr>
                                        <tr>
                                            <td>DH 804</td>
                                            <td>
                                                <Link onClick={(e) => handleShow(e, "DH804")}>Magnetic Resonance Imaging: From Physics to Physiology</Link>
                                            </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td>DH 805</td>
                                            <td>
                                                <Link onClick={(e) => handleShow(e, "DH805")}>X-ray based Imaging in Healthcare</Link>
                                            </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td>ME 724</td>
                                            <td>
                                                <Link onClick={(e) => handleShow(e, "ME724")}>Essentials of Turbulence</Link>
                                            </td>
                                            <td>6</td>
                                            <td>Mechanical Engineering</td>
                                        </tr>
                                        <tr>
                                            <td>ME 780</td>
                                            <td>
                                                <Link onClick={(e) => handleShow(e, "ME780")}>Biofluid Mechanics</Link>
                                            </td>
                                            <td>6</td>
                                            <td>Mechanical Engineering</td>
                                        </tr>
                                        <tr className="border-bottom-0">
                                            <td>TD 617</td>
                                            <td>
                                                <Link onClick={(e) => handleShow(e, "TD617")}>Healthtech Innovation and Design</Link>
                                            </td>
                                            <td>6</td>
                                            <td>Centre for Technology Alternatives for Rural Areas (CTARA)</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <p className="m-0"><b>Group 2:</b> Healthcare informatics & analytics</p>
                        <div className="rounded-3 border overflow-hidden mb-4 mt-2">
                            <div className="table-responsive">
                                <table className="table table-bordered table-striped m-0">
                                    <thead className="table-primary">
                                        <tr>
                                            <th style={{ width: "120px" }}>Course Code</th>
                                            <th style={{ width: "350px" }}>Course Name</th>
                                            <th style={{ width: "75px" }}>Credits</th>
                                            <th style={{ width: "auto" }}>Department/Center</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>CS 419</td>
                                            <td>
                                                <Link onClick={(e) => handleShow(e, "CS419")}>Introduction to Machine Learning</Link>
                                            </td>
                                            <td>6</td>
                                            <td>Computer Science & Engineering </td>
                                        </tr>
                                        <tr className="border-bottom-0">
                                            <td>CS 631</td>
                                            <td>
                                                <Link onClick={(e) => handleShow(e, "CS631")}>Implementation Techniques for Relational Database Systems </Link>
                                            </td>
                                            <td>6</td>
                                            <td>Computer Science & Engineering </td>
                                        </tr>
                                        <tr className="border-bottom-0">
                                            <td>CS 663</td>
                                            <td>
                                                <Link onClick={(e) => handleShow(e, "CS663")}>Digital Image
                                                    Processing</Link>
                                            </td>
                                            <td>6</td>
                                            <td>Computer Science & Engineering </td>
                                        </tr>
                                        <tr className="border-bottom-0">
                                            <td>CS 725</td>
                                            <td>
                                                <Link onClick={(e) => handleShow(e, "CS725")}>Foundations of
                                                    Machine Learning</Link>
                                            </td>
                                            <td>6</td>
                                            <td>Computer Science & Engineering </td>
                                        </tr>
                                        <tr className="border-bottom-0">
                                            <td>CS 726</td>
                                            <td>
                                                <Link onClick={(e) => handleShow(e, "CS726")}>Advanced
                                                    Machine Learning</Link>
                                            </td>
                                            <td>6</td>
                                            <td>Computer Science & Engineering </td>
                                        </tr>
                                        <tr>
                                            <td>CS 736</td>
                                            <td>
                                                <Link onClick={(e) => handleShow(e, "CS736")}>Medical Image
                                                    Computing</Link>
                                            </td>
                                            <td>6</td>
                                            <td>Computer Science & Engineering </td>
                                        </tr>
                                        <tr>
                                            <td>CS 754</td>
                                            <td>
                                                <Link onClick={(e) => handleShow(e, "CS754")}>Advanced Image
                                                    Processing</Link>
                                            </td>
                                            <td>6</td>
                                            <td>Computer Science & Engineering </td>
                                        </tr>
                                        <tr className="border-bottom-0">
                                            <td>CS 769</td>
                                            <td>
                                                <Link onClick={(e) => handleShow(e, "CS769")}>Optimization in
                                                    Machine Learning</Link>
                                            </td>
                                            <td>6</td>
                                            <td>Computer Science & Engineering </td>
                                        </tr>
                                        <tr>
                                            <td>DH 306</td>
                                            <td>
                                                <Link onClick={(e) => handleShow(e, "DH306")}>Healthcare Performance Metrics</Link>
                                            </td>
                                            <td>6</td>
                                            <td>Koita Centre for Digital Health</td>
                                        </tr>
                                        <tr className="border-bottom-0">
                                            <td>DH 308</td>
                                            <td>
                                                <Link onClick={(e) => handleShow(e, "DH308")}>Clinical Data Management</Link>
                                            </td>
                                            <td>6</td>
                                            <td>Koita Centre for Digital Health</td>
                                        </tr>
                                        <tr>
                                            <td>DH 602</td>
                                            <td>
                                                <Link onClick={(e) => handleShow(e, "DH602")}>Machine Learning and Statistical Methods in Healthcare</Link>
                                            </td>
                                            <td> </td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td>DH 607</td>
                                            <td>
                                                <Link onClick={(e) => handleShow(e, "DH607")}>Introduction to Computational Multi-omics</Link>
                                            </td>
                                            <td>6</td>
                                            <td> </td>
                                        </tr>
                                        <tr>
                                            <td>DH 801</td>
                                            <td>
                                                <Link onClick={(e) => handleShow(e, "DH801")}>Biostatistics in Healthcare</Link>
                                            </td>
                                            <td>6</td>
                                            <td>Koita Centre for Digital Health</td>
                                        </tr>
                                        <tr>
                                            <td>DS 303</td>
                                            <td>
                                                <Link onClick={(e) => handleShow(e, "DS303")}>
                                                    Introduction to Machine Learning
                                                </Link>
                                            </td>
                                            <td>6</td>
                                            <td>Centre for Machine Intelligence and Data Science</td>
                                        </tr>
                                        <tr>
                                            <td>EE 610</td>
                                            <td>
                                                <Link onClick={(e) => handleShow(e, "EE610")}>Image Processing</Link>
                                            </td>
                                            <td>6</td>
                                            <td>Electrical Engineering</td>
                                        </tr>
                                        <tr>
                                            <td>EE 769</td>
                                            <td>
                                                <Link onClick={(e) => handleShow(e, "EE769")}>Introduction to Machine Learning</Link>
                                            </td>
                                            <td>6</td>
                                            <td>Electrical Engineering</td>
                                        </tr>
                                        <tr>
                                            <td>GNR 652</td>
                                            <td>
                                                <Link onClick={(e) => handleShow(e, "GNR652")}>
                                                    Machine Learning for Remote Sensing 1
                                                </Link>
                                            </td>
                                            <td>6</td>
                                            <td>Centre of Studies in Resources Engineering</td>
                                        </tr>
                                        <tr className="border-bottom-0">
                                            <td>IE 501</td>
                                            <td>
                                                <Link onClick={(e) => handleShow(e, "IE501")}>Optimization Models</Link>
                                            </td>
                                            <td>6</td>
                                            <td>Industrial Engineering and Operations Research</td>
                                        </tr>
                                        <tr>
                                            <td>IE 615</td>
                                            <td>
                                                <Link onClick={(e) => handleShow(e, "IE615")}>Data Analytics in Operations Research</Link>
                                            </td>
                                            <td>6</td>
                                            <td>Industrial Engineering and Operational Research </td>
                                        </tr>
                                        <tr className="border-bottom-0">
                                            <td>IE 643</td>
                                            <td>
                                                <Link onClick={(e) => handleShow(e, "IE643")}>Deep Learning Theory & Practice</Link>
                                            </td>
                                            <td>6</td>
                                            <td>Industrial Engineering and Operations Research</td>
                                        </tr>
                                        <tr className="border-bottom-0">
                                            <td>ME 781</td>
                                            <td>
                                                <Link onClick={(e) => handleShow(e, "ME781")}>Statistical
                                                    Machine Learning and Data Mining</Link>
                                            </td>
                                            <td>6</td>
                                            <td>Mechanical Engineering</td>
                                        </tr>
                                        <tr>
                                            <td>SI 422</td>
                                            <td>
                                                <Link onClick={(e) => handleShow(e, "SI422")}>Regression
                                                    Analysis</Link>
                                            </td>
                                            <td>8</td>
                                            <td>Mathematics</td>
                                        </tr>
                                        <tr>
                                            <td>SI 541</td>
                                            <td>
                                                <Link onClick={(e) => handleShow(e, "SI541")}>Statistical Epidemiology</Link>
                                            </td>
                                            <td>6</td>
                                            <td>Mathematics</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <p><b>Group 3:</b> Health systems & Policy & Ethics</p>
                        <div className="rounded-3 border overflow-hidden mb-4 mt-2">
                            <div className="table-responsive">
                                <table className="table table-bordered table-striped m-0">
                                    <thead className="table-primary">
                                        <tr>
                                            <th style={{ width: "120px" }}>Course Code</th>
                                            <th style={{ width: "350px" }}>Course Name</th>
                                            <th style={{ width: "75px" }}>Credits</th>
                                            <th style={{ width: "auto" }}>Department/Center</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="border-bottom-0">
                                            <td>DH 304</td>
                                            <td>
                                                <Link onClick={(e) => handleShow(e, "DH304")}>Economics of Health Care</Link>
                                            </td>
                                            <td>6</td>
                                            <td>Koita Centre for Digital Health</td>
                                        </tr>

                                        <tr className="border-bottom-0">
                                            <td>DH 802</td>
                                            <td>
                                                <Link onClick={(e) => handleShow(e, "DH802")}>Service Operations and Quality Management in Healthcare</Link>
                                            </td>
                                            <td>6</td>
                                            <td>Koita Centre for Digital Health</td>
                                        </tr>
                                        <tr className="border-bottom-0">
                                            <td>DH 899</td>
                                            <td>
                                                <Link onClick={(e) => handleShow(e, "DH899")}>Communication Skills</Link>
                                            </td>
                                            <td>6</td>
                                            <td>Koita Centre for Digital Health</td>
                                        </tr>
                                        <tr className="border-bottom-0">
                                            <td>ES 899/CM 899</td>
                                            <td>
                                                <Link onClick={(e) => handleShow(e, "ES899")}>Communication Skills</Link>
                                            </td>
                                            <td>6</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>HS 633</td>
                                            <td>
                                                <Link onClick={(e) => handleShow(e, "HS633")}>Econometrics of Programme Evaluation</Link>
                                            </td>
                                            <td>6</td>
                                            <td>Humanities and Social Science</td>
                                        </tr>
                                        <tr className="border-bottom-0">
                                            <td>HS 638</td>
                                            <td>
                                                <Link onClick={(e) => handleShow(e, "HS638")}>
                                                    Financial
                                                    Econometrics
                                                </Link>
                                            </td>
                                            <td>6</td>
                                            <td>Humanities and Social Sciences</td>
                                        </tr>
                                        <tr className="border-bottom-0">
                                            <td>HS 426</td>
                                            <td>
                                                <Link onClick={(e) => handleShow(e, "HS426")}>
                                                    Theory and
                                                    Policy of Managerial Finance
                                                </Link>
                                            </td>
                                            <td>6</td>
                                            <td>Humanities and Social Sciences</td>
                                        </tr>
                                        <tr>
                                            <td>IE 709</td>
                                            <td>
                                                <Link onClick={(e) => handleShow(e, "IE709")}>
                                                    IEOR for Health
                                                    Care
                                                </Link>
                                            </td>
                                            <td>8</td>
                                            <td>Industrial Engineering and Operational Research</td>
                                        </tr>
                                        <tr>
                                            <td>PS 619</td>
                                            <td>
                                                <Link onClick={(e) => handleShow(e, "PS619")}>
                                                    Health Policy:
                                                    An Introduction
                                                </Link>
                                            </td>
                                            <td>6</td>
                                            <td>Ashank Desai Centre for Policy Studies</td>
                                        </tr>
                                        <tr className="border-bottom-0">
                                            <td>SOM 633</td>
                                            <td>
                                                <Link onClick={(e) => handleShow(e, "SOM633")}>
                                                    Quality
                                                    Management
                                                </Link>
                                            </td>
                                            <td>3</td>
                                            <td>SJM School of Management</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <p><b>Group 4:</b> R&D Project </p>
                        <div className="rounded-3 border overflow-hidden mb-4 mt-2">
                            <div className="table-responsive">
                                <table className="table table-bordered table-striped m-0">
                                    <thead className="table-primary">
                                        <tr>
                                            <th style={{ width: "120px" }}>Course Code</th>
                                            <th style={{ width: "350px" }}>Course Name</th>
                                            <th style={{ width: "75px" }}>Credits</th>
                                            <th style={{ width: "auto" }}>Department/Center</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>DH 307 </td>
                                            <td>
                                                <Link onClick={(e) => handleShow(e, "DH307")}>R&D Project </Link>
                                            </td>
                                            <td>6</td>
                                            <td>Koita Centre for Digital Health</td>
                                        </tr>
                                        <tr className="border-bottom-0">
                                            <td>DH 604 </td>
                                            <td>
                                                <Link onClick={(e) => handleShow(e, "DH604")}>R&D Project - I  </Link>
                                            </td>
                                            <td></td>
                                            <td>  </td>
                                        </tr>
                                        <tr className="border-bottom-0">
                                            <td>DH 605 </td>
                                            <td>
                                                <Link onClick={(e) => handleShow(e, "DH605")}>R&D Project - II  </Link>
                                            </td>
                                            <td></td>
                                            <td>  </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>


                    </Col>
                </Row>
            </Container>

            <Container>
                <hr className="px-5" />
            </Container>

            <Container className="my-5">
                <Row>
                    <Col md={3}>
                        <h2 className="text-primary wow fadeInUp">
                            Course Offered in Autumn 2024 - 25
                        </h2>
                    </Col>
                    <Col md={9} className="ps-md-5 list">
                        <div className="rounded-3 border border-bottom position-relative overflow-hidden mb-3">
                            <div className="table-responsive">
                                <table className="table table-bordered table-striped m-0">
                                    <thead className="table-primary">
                                        <tr>
                                            <th style={{ width: "120px" }}>Course Code</th>
                                            <th style={{ width: "auto" }}>Course Name</th>
                                            <th style={{ width: "350px" }}>Instructor</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>DH 307</td>
                                            <td><Link onClick={(e) => handleShow(e, "DH307")}> R & D Project </Link></td>
                                            <td>Prof. Ganesh Ramakrishnan</td>
                                        </tr>
                                        <tr>
                                            <td>DH 301</td>
                                            <td><Link onClick={(e) => handleShow(e, "DH301")}>Basic Epidemiology </Link></td>
                                            <td>Prof. Ganesh Ramakrishnan and <br></br> Dr. Kalyani Addya and Dr. Sandip Mandal</td>
                                        </tr>
                                        <tr>
                                            <td>DH 803</td>
                                            <td><Link onClick={(e) => handleShow(e, "DH803")}>Wearable Health Technologies</Link></td>
                                            <td>Dr. Nirmal Punjabi</td>
                                        </tr>
                                        <tr>
                                            <td>DH 604</td>
                                            <td><Link onClick={(e) => handleShow(e, "DH604")}>R&D Project - I </Link></td>
                                            <td>Prof. Ganesh Ramakrishnan</td>
                                        </tr>
                                        <tr>
                                            <td>DH 607</td>
                                            <td><Link onClick={(e) => handleShow(e, "DH607")}>Introduction to Computational Multi-omics  </Link></td>
                                            <td>Prof. Saket Choudhary </td>
                                        </tr>
                                        <tr>
                                            <td>DH 804</td>
                                            <td><Link onClick={(e) => handleShow(e, "DH804")}>Magnetic Resonance Imaging: From Physics to Physiology (Second Half) </Link></td>
                                            <td>Prof. Ashutosh Kumar </td>
                                        </tr>
                                        <tr>
                                            <td>CL 662 </td>
                                            <td><Link onClick={(e) => handleShow(e, "CL662")}>Introduction to Computational Biology</Link></td>
                                            <td>Prof. Pramod Wangikar </td>
                                        </tr>
                                        <tr>
                                            <td>BB 633</td>
                                            <td><Link onClick={(e) => handleShow(e, "BB633")}>Movement Neuroscience</Link></td>
                                            <td>Prof. Neeta Kanekar</td>
                                        </tr>
                                        <tr>
                                            <td>BB 607 </td>
                                            <td><Link onClick={(e) => handleShow(e, "BB607")}>Proteomics: Principles and Techniques </Link></td>
                                            <td>Prof. Sanjeeva Srivastava </td>
                                        </tr>
                                        <tr>
                                            <td>IE 643</td>
                                            <td><Link onClick={(e) => handleShow(e, "IE643")}>Deep Learning: Theory and Practice</Link></td>
                                            <td>Prof. Balamurugan </td>
                                        </tr>
                                        <tr>
                                            <td>EE 769</td>
                                            <td><Link onClick={(e) => handleShow(e, "EE769")}>Introduction to Machine Learning</Link></td>
                                            <td>Prof. Amit Sethi </td>
                                        </tr>
                                        <tr>
                                            <td>CS 663</td>
                                            <td><Link onClick={(e) => handleShow(e, "CS663")}>Digital Image Processing </Link></td>
                                            <td>Prof. Ajit Rajwade </td>
                                        </tr>
                                        <tr>
                                            <td>Si 515 </td>
                                            <td>Multivariate Analysis</td>
                                            <td>Prof. Siuli Mukhopadhyay  </td>
                                        </tr>
                                        <tr>
                                            <td>BB 525</td>
                                            <td>Biological Thermodynamics and Kinetic</td>
                                            <td>Prof. Ranjith Padinhateeri</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

            {/* Breadcrumb */}
            <Breadcrumb className="breadcrumb-panel mt-auto">
                <Breadcrumb.Item href={globalVariables.variables.strDomainUrl}>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Students' Corner</Breadcrumb.Item>
                <Breadcrumb.Item active>M.S. by Research</Breadcrumb.Item>
            </Breadcrumb>


            {/* TEAM DETAILS */}
            <Modal show={show} size="xl" centered onHide={handleClose} dialogClassName="p-2 popup-students">
                {
                    Course.map((val) => {
                        return (
                            <div>
                                {
                                    val.course_id == getcourseid ?
                                        <>
                                            <div key={val.course_id}>
                                                <Modal.Header closeButton className="border-0 align-items-start">
                                                    <h3 className="bg-primary text-white py-2 px-md-4 px-2 m-0 w-100">{val.course_title}</h3>
                                                </Modal.Header>
                                                <Modal.Body className="py-0" style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
                                                    <span dangerouslySetInnerHTML={{ __html: val.course_info }}></span>
                                                </Modal.Body>
                                            </div>
                                        </> : null
                                }
                            </div>
                        )
                    })

                }
            </Modal >
            {/* TEAM DETAILS */}
        </>
    );
}