const SpeakersPanelists = [
    {
        speaker_id: 'Rajesh_Gokhale',
        speaker_info: ` <div class="container py-0">
        <div class="row">
            <div class="col-md-4 col-lg-2 text-md-start">
            <img src="images/kcdh-inauguration/rajesh-gokhale.jpg" alt="Rajesh S. Gokhale" title="Rajesh S. Gokhale" class="img-fluid rounded-3 mb-2"/>
            </div>
            <div class="col">
                <h4 class="text-primary m-0">
                    Rajesh S. Gokhale
                </h4>
                <p>
                    <b>Secretary, Department of Biotechnology<br /> Ministry of Science &
                        Technology, GoI </b>
                </p> 
                <p>
                    Shri Rajesh S. Gokhale is the Secretary at Department of Biotechnology in
                    the Ministry of Science & Technology, Government of India. He is presently
                    on deputation from Indian Institute of Science Education & Research IISER
                    Pune. Prior to this, he was at National Institute of Immunology NII and was
                    also Director of CSIR-Institute of Genomics and Integrative Biology
                    CSIR-IGIB for seven-and-half years. During his tenure he established the
                    South Campus of CSIR-IGIB, where he led interdisciplinary initiatives in
                    translational genomics research programs focused in delineating a variety of
                    complex diseases.</p>
                <p> Dr. Gokhale is trained as a chemical biologist from Indian Institute of
                    Science IISc , Bangalore and Stanford University, USA. His significant
                    research contributions are in discovering novel metabolites and their
                    pathways, which dictate pathophysiology of human diseases. Recent work from
                    his laboratory have identified two novel metabolites from infectious
                    pathogen Mycobacterium tuberculosis, that are crucial for initiating complex
                    infection process. His group have also significantly contributed to the
                    understanding of autoimmune disease Vitiligo. Studies from his group has
                    elucidated complex interplay between metabolic reprogramming and immune
                    system, to develop novel therapeutic strategies that can tackle the
                    underlying causes, rather than just the symptoms. Scientific work from his
                    laboratory has been published in prestigious journals like Nature, Nature
                    Chemical Biology, Molecular Cell, The Proceedings of the National Academy of
                    Sciences etc. He has mentored more than 200 students and about 25 students
                    have completed PhD thesis from his group. Dr Gokhale co-founded Vyome
                    Biosciences Pvt. Ltd. VYOME in 2010, a biopharmaceutical company developing
                    best in class drugs for dermatology. This company is presently completing
                    Phase IIb clinical trial for drug-resistant acne and has launched OTC
                    products in the market. Dr. Gokhale was a Wellcome Trust Senior Research
                    Fellow, UK and an International HHMI Fellow, USA. He is recipient of several
                    awards, including Infosys Prize, Shanti Swarup Bhatnagar Prize, National
                    Bioscience Award, J C Bose National Fellowship and IIT Bombay Distinguished
                    Alumni Award. He is also Fellow of all the three Indian National Science
                    Academies. 
                </p> 
            </div>
        </div>
    </div>`
    },

    {
        speaker_id: 'Madhuri_Kanitkar',
        speaker_info: `<div class="container py-0">
        <div class="row">
            <div class="col-md-4 col-lg-2 text-md-start">
                <img src="images/kcdh-inauguration/madhuri-kanitkar1.jpg" alt="Lt Gen Dr. Madhuri Kanitkar" title="Lt Gen Dr. Madhuri Kanitkar" class="img-fluid rounded-3 mb-2"/>
            </div>
            <div class="col">
                <h4 class="text-primary m-0">
                    Lt Gen Dr. Madhuri Kanitkar
                </h4>
                <p>
                    <b>Vice-Chancellor, Maharashtra University of Health </b>
                </p>

                <p>
                    Lt. Gen Madhuri Kanitkar, PVSM, AVSM, VSM (Retd) has assumed the prestigious
                    appointment of Hon’ble Vice-Chancellor of Maharashtra University of Health
                    Sciences, Nashik. The Gen Officer was commissioned in AMC on 27 Dec 1982.
                    She is a medical graduate of Armed Forces Medical College, Pune University
                    and Postgraduate in MD (Paediatric) from Indian Naval Hospital Ship (INHS),
                    Asvini. She has had the distinction of being first in Pune University in all
                    the three phases of MBBS and was awarded the President’s Gold Medal &amp;
                    Kalinga Trophy for the best outgoing student of the graduate wing in
                    academics and extra-curricular activities in 1982.</p>
                <p> DThe Hon’ble Vice-Chancellor is a Paediatric Nephrologist who has done her
                    postgraduate in Paediatric from Mumbai in 1990 and Training in Paediatric
                    Nephrology from All India Institute of Medical Sciences (AIIMS), New Delhi,
                    followed by Fellowships at NUH Singapore and GOS London. She has also
                    completed the FAIMER Fellowship in Medical Education. She has been a guest
                    speaker at various National &amp; International conferences.

                </p>
                <p>The Hon’ble Vice-Chancellor has been Professor and Head of Paediatric at AFMC
                    and also the Dean at her Alma Mater. She has over 22 years of teaching
                    experience and has been awarded best Teacher of MUHS in 2008. She has guided
                    many postgraduate students and examiner of Paediatrics. She has been known
                    to work for students welfare.
                    For her distinguished service Gen Kanitkar has been awarded Param Vishist
                    Seva Medal in 2022, Ati Vishist Seva Medal in 2018, Vishist Seva Medal in
                    2014, Commendation by Chief of the Army Staff in 2000, 2003, 2004, 2012
                    (twice) and Commendation by GOC-in-C-in 2008.</p> 
            </div>
        </div>
    </div>`
    },

    {
        speaker_id: 'Aparna_Gupta',
        speaker_info: `<div class="container py-0">
        <div class="row">
            <div class="col-md-4 col-lg-2 text-md-start">
                <img src="images/kcdh-inauguration/aparna-gupta.jpg" alt="Aparna Gupta" title="Aparna Gupta"
                    class="img-fluid rounded-3 mb-2">
            </div>
            <div class="col">
                <h4 class="text-primary m-0">
                    Aparna Gupta
                </h4>
                <p class="">
                    <b>Executive Director, Microsoft</b>
                </p>
                <p>
                    Aparna Gupta is Executive Director, Customer Success for Microsoft India.
                    She is responsible for guiding customers to innovate, expand, and realize
                    real business value from their investments in Microsoft cloud platform. She
                    leads a strong technical team that helps customers reach their desired
                    outcomes across the end-end cloud journey. The team enables customers across
                    the entire lifecycle of projects with industry leading architecture, design,
                    resiliency, performance, scale, operational policies and processes. Prior to
                    this, Aparna was the General Manager, India for Commercial Software
                    Engineering (CSE) at Microsoft. Before joining Microsoft, Aparna Gupta was
                    the Vice President, Product Development & Managing Director, India at
                    FirstRain Inc. Early in her career and before moving into software, Aparna
                    worked at a biotechnology research lab focusing on protein sequencing and
                    developing a vaccine for Malaria. Aparna holds a master’s degree in
                    Engineering Sciences from the Indian Institute of Technology (IIT) Mumbai.
                </p> 
            </div>
        </div>
    </div>`
    },

    {
        speaker_id: 'CS_Pramesh',
        speaker_info: `<div class="container py-0">
        <div class="row">
            <div class="col-md-4 col-lg-2 text-md-start">
                <img src="images/kcdh-inauguration/cs-pramesh.jpg" alt="C S Pramesh" title="C S Pramesh"
                    class="img-fluid rounded-3 mb-2">
            </div>
            <div class="col">
                <h4 class="text-primary m-0">
                    Dr. C S Pramesh
                </h4>
                <p class="">
                    <b>Director, Tata Memorial</b>
                </p>

                <p>
                    C S Pramesh is the Director of the Tata Memorial Hospital and the Professor
                    and Head of Thoracic Surgery at the Tata Memorial Centre, Mumbai. He serves
                    on the Board of Directors of the Union for International Cancer Control
                    (UICC). He is the convener for the National Cancer Grid, a large network of
                    294 cancer centres in India <a href="https://tmc.gov.in/ncg">(https://tmc.gov.in/ncg)</a>. Pramesh is highly
                    committed to efforts towards reducing inequities in cancer care and making
                    cancer treatment accessible to all geographic regions and strata of society.
                    He is also a visiting professor at the Division of Cancer Studies, King’s
                    College London and the Institute of Cancer Policy, King’s Health Partners,
                    London. His broader research interests include cancer policy, health equity,
                    value-based care and health services research. 
                </p> 
            </div>
        </div>
    </div>`
    },

    {
        speaker_id: 'Devi_Shetty',
        speaker_info: `<div class="container py-0">
        <div class="row">
            <div class="col-md-4 col-lg-2 text-md-start">
                <img src="images/kcdh-inauguration/dr-shetty.jpg" alt="Dr. Devi Prasad Shetty" title="Dr. Devi Prasad Shetty"
                    class="img-fluid rounded-3 mb-2">
            </div>
            <div class="col">
                <h4 class="text-primary m-0">
                    Dr. Devi Prasad Shetty
                </h4>
                <p class="">
                    <b>Chairman, Narayana Health</b>
                </p>

                <p>
                    Dr. Devi Prasad Shetty, is a cardiac surgeon of repute and a successful
                    entrepreneur, Dr Shetty is a highly respected luminary for his ingenious
                    ideas for reforms in healthcare sector. His visionary leadership to make
                    quality healthcare affordable for all has drawn global recognition. Dr
                    Shetty is the Founder and Chairman at Narayana Hrudayalaya Group of
                    Hospitals. The group now has 47 healthcare facilities with close to 7,000
                    beds. He is the recipient of several prestigious awards including the Padma
                    Bhushan, Padma Shri, The Economist Innovation Award, Financial Times Arcelor
                    Mittal ‘Boldness in Business’ Award, Nikkei Asia Prize for Economic and
                    Business Innovation. He has also been awarded honorary doctorates from
                    University of Minnesota and IIT Madras.

                </p>
                <p>Dr. Shetty is the current Chairman, Board of Governors, Indian Institute of
                    Management, Bangalore. He is a member of Health Insurance Consultative
                    Committee constituted by IRDAI. </p> 
            </div>
        </div>
    </div>`
    },

    {
        speaker_id: 'N_Jagannathan',
        speaker_info: `<div class="container py-0">
        <div class="row">
            <div class="col-md-4 col-lg-2 text-md-start">
                <img src="images/kcdh-inauguration/nr-jagannathan.jpg" alt="Prof. N R Jagannathan" title="Prof. N R Jagannathan" class="img-fluid rounded-3 mb-2">
            </div>
            <div class="col">
                <h4 class="text-primary m-0"> Prof. N R Jagannathan </h4>
                <p class="">
                    <b>Visiting Professor, KCDH IIT Bombay</b>
                </p>

                <p>
                    Dr. N R Jagannathan is a Distinguished Visiting Professor, KCDH, IIT Bombay.
                    Prof. Jagannathan’s research work in clinical and pre-clinical cancer
                    research using molecular imaging methods like magnetic resonance imaging
                    (MRI) and NMR Spectroscopy, metabolomics, instrumentation and biophysics is
                    internationally known. He is author or co-author of over 300 publications
                    and 8 edited volumes. He received the prestigious J. C. Bose National
                    Research Fellowship of DST for outstanding contribution to science in 2015.
                    Dr. Jagannathan also received the highly prestigious S. K. Mitra Birth
                    Centenary Gold Medal Award for significant and lifetime contribution to the
                    development of Science and Technology in the country of the Indian Science
                    Congress Association. Further, he is a recipient of Drs. Kunti & Om Prakash
                    Oration Award for significant contribution in the field of biomedical
                    sciences from Indian Council of Medical Research (ICMR).
                </p> 
            </div>
        </div>
    </div>`
    },

    {
        speaker_id: 'Sanjay_Arora',
        speaker_info: ` <div class="container py-0">
        <div class="row">
            <div class="col-md-4 col-lg-2 text-md-start">
                <img src="images/kcdh-inauguration/sanjay-arora.jpg" alt="Dr. Sanjay Arora" title="Dr. Sanjay Arora"
                    class="img-fluid rounded-3 mb-2">
            </div>
            <div class="col">
                <h4 class="text-primary m-0">
                Dr. Sanjay Arora
                </h4>
                <p class="">
                    <b>Founder - Suburban Diagnostics, a leading diagnostics centre and lab
                        across western India </b><br />
                    <b>Group Medical Director - Dr. Lal PathLabs</b>
                </p>

                <p>
                    TEDx Speaker, Dr. Arora has spoken at various conferences and events,
                    sharing his entrepreneurial journey, experiences and learnings.<br />
                    Dr. Arora is known to be a thought leader in the Diagnostics and Healthcare
                    industry. His visionary approach towards women’s health has helped bring a
                    change and improve the health outcomes and health indices in the
                    country.<br />

                    Recently, he has been awarded as an “Influential Leader of New India 2021”
                    by Teammarksmen in association with CNN-News18.
                    Dr. Arora has graduated from Grant Medical College and JJ Hospital, with a
                    special interest in Cytopathology. He has trained at esteemed Tata Hospital,
                    Johns Hopkins Hospital and Henry Ford Hospital, USA<br />
                    He is a global traveller, amateur chef, travel photographer and DJ who loves
                    sharing his entrepreneurial journey, experiences and learnings. His demeanor
                    has also led him to be a constant student of leadership who is actively
                    learning to be a better version of himself from global gurus like Dr.
                    Marshall Goldsmith. 
                </p> 
            </div>
        </div>
    </div>`
    },

    {
        speaker_id: 'Manish_Gupta',
        speaker_info: `<div class="container py-0">
        <div class="row">
            <div class="col-md-4 col-lg-2 text-md-start">
                <img src="images/kcdh-inauguration/manish-gupta.jpg" alt="Dr. Manish Gupta" title="Dr. Manish Gupta"
                    class="img-fluid rounded-3 mb-2">
            </div>
            <div class="col">
                <h4 class="text-primary m-0">
                    Manish Gupta
                </h4>
                <p class="">
                    <b>Director, Google Research India</b>
                </p>

                <p>
                    Manish Gupta is the Director of Google Research India and a co-lead of a
                    technology area in Google Research with teams across India, US and Europe.
                    Previously, Manish has led VideoKen, a video technology startup, and the
                    research centers for Xerox and IBM in India. As a Senior Manager at the IBM
                    T.J. Watson Research Center in Yorktown Heights, New York, Manish led the
                    team developing system software for the Blue Gene/L supercomputer. IBM was
                    awarded a National Medal of Technology and Innovation for Blue Gene by the
                    then US President Barack Obama in 2009. Manish holds a Ph.D. in Computer
                    Science from the University of Illinois at Urbana Champaign. He has
                    co-authored about 75 papers, with more than 7,000 citations in Google
                    Scholar, and has been granted 19 US patents. While at IBM, Manish received
                    two Outstanding Technical Achievement Awards, an Outstanding Innovation
                    Award and the Lou Gerstner Team Award for Client Excellence. Manish is a
                    recipient of a Distinguished Alumnus Award from IIT Delhi. 
                </p> 
            </div>
        </div>
    </div>`
    },

    {
        speaker_id: 'Marcus_Ranney',
        speaker_info: `<div class="container py-0">
        <div class="row">
            <div class="col-md-4 col-lg-2 text-md-start">
                <img src="images/kcdh-inauguration/marcus-ranney.jpg" alt="Dr. Marcus Ranney" title="Dr. Marcus Ranney"
                    class="img-fluid rounded-3 mb-2">
            </div>
            <div class="col">
                <h4 class="text-primary m-0">
                    Dr. Marcus Ranney
                </h4>
                <p class="">
                    <b>Founder & CEO, Humanedge</b>
                </p>

                <p>
                    Marcus Ranney is a big believer of leveraging the power of digital
                    biomarkers to hack the human operating system—priming it for a longer
                    healthspan. A physician who believes in the power of lifestyle
                    interventions, he launched Human Edge, an investor backed venture, with the
                    aim of helping individuals be the best version of themselves—health, brain,
                    and body—regardless of their challenges at the workplace. From sick care to
                    wellbeing, Dr. Ranney’s career journey over the past two decades has led him
                    from the patient’s bedside to the extreme outdoors. A published bestselling
                    author, keynote speaker, and public health commentator, he has been a World
                    Economic Forum’s Global Shaper and a Senior Fellow to the Atlantic Council. 
                </p> 
            </div>
        </div>
    </div>`
    },

    {
        speaker_id: 'Narendra_Ahuja',
        speaker_info: `<div class="container py-0">
        <div class="row">
            <div class="col-md-4 col-lg-2 text-md-start">
                <img src="images/kcdh-inauguration/narendra-ahuja.jpg" alt="Narendra Ahuja" title="Narendra Ahuja"
                    class="img-fluid rounded-3 mb-2">
            </div>
            <div class="col">
                <h4 class="text-primary m-0">
                    Dr. Narendra Ahuja
                </h4>
                <p class="">
                    <b>Professor, University of Illinois<br /> USA</b>
                </p>

                <p>
                    Narendra Ahuja is a Research Professor in the Dept. of Electrical and
                    Computer Engineering, Dept. of Computer Science, and Coordinated Science
                    Laboratory, University of Illinois at Urbana-Champaign (UIUC). He received
                    B.E. with honors in electronics engineering from BITS, Pilani, India, M.E.
                    with distinction in electrical communication engineering from IISc,
                    Bangalore, India, and Ph.D. in computer science from University of Maryland,
                    College Park, USA. In 1979, he joined UIUC where he was Donald Biggar Willet
                    Professor of Engineering until 2012. In past he has served as the Founding
                    Director of International Institute of Information Technology, Hyderabad,
                    and the Founding Director of Information Technology Research Academy,
                    Ministry of Electronics and Information Technology, Government of India.
                </p>
                <p>He has co-authored three books, several hundred papers, and received 4
                    patents. His algorithms/prototype systems have been used by about a dozen
                    companies/organizations, including industrial systems at General Electric,
                    Westinghouse, Lockheed and Honeywell. He is a fellow of IEEE, American
                    Association for Artificial Intelligence, International Association for
                    Pattern Recognition, Association for Computing Machinery, American
                    Association for the Advancement of Science, and International Society for
                    Optical Engineering. He received the Emanuel R. Piore award of the IEEE, the
                    Technology Achievement Award of the International Society for Optical
                    Engineering, TA Stewart-Dyer/Frederick Harvey Trevithick Prize of the
                    Institution of Mechanical Engineers, and several Best Paper Awards.
                </p> 
            </div>
        </div>
    </div>`
    },

    {
        speaker_id: 'Neeraj_Sinha',
        speaker_info: `<div class="container py-0">
        <div class="row">
            <div class="col-md-4 col-lg-2 text-md-start">
                <img src="images/kcdh-inauguration/neeraj-sinha.jpg" alt="Neeraj Sinha" title="Neeraj Sinha"
                    class="img-fluid rounded-3 mb-2">
            </div>
            <div class="col">
                <h4 class="text-primary m-0">
                    Prof. Neeraj Sinha
                </h4>
                <p class="">
                    <b>Professor, CBMR Lucknow</b>
                </p>

                <p>
                    Neeraj Sinha is the Professor and Dean in the Centre of Biomedical Research
                    SGPGIMS Campus. He is widely known for his work on NMR based metabolomics
                    related to acute respiratory distress syndrome (ARDS), sepsis and chronic
                    kidney disease (CKD). Solid state NMR structural studies of bones and
                    cartilage. Dynamic Nuclear Polarization (DNP) of biomaterials. He has
                    several publication to his fame. 
                </p> 
            </div>
        </div>
    </div>`
    },

    {
        speaker_id: 'Nimalan_Arinaminpathy',
        speaker_info: `<div class="container py-0">
        <div class="row">
            <div class="col-md-4 col-lg-2 text-md-start">
                <img src="images/kcdh-inauguration/nimalan-arinaminpathy.jpg" alt="Nim Arinaminpathy"
                    title="Nim Arinaminpathy" class="img-fluid rounded-3 mb-2">
            </div>
            <div class="col">
                <h4 class="text-primary m-0">
                    Prof. Nimalan Arinaminpathy (Nim)
                </h4>
                <p class="">
                    <b>Faculty of Medicine,School of Public Health<br /> Imperial College London</b>
                </p>

                <p>
                    Nim Arinaminpathy is Professor of Mathematical Epidemiology at Imperial
                    College London. In his research he applies mathematical modelling to study
                    the spread and control of infectious diseases, with a focus on human
                    tuberculosis (TB). He works closely with national TB programmes in
                    high-burden countries, particularly India and Kenya. He also works with the
                    WHO South-East Asian Regional Office (SEAR) on TB control priorities for the
                    region, and serves on the WHO Strategic and Technical Advisory Group for TB.
                    Additionally, since 2020 he has been working closely with the Indian Council
                    of Medical Research, providing advice in support of the COVID-19 response in
                    India. 
                </p> 
            </div>
        </div>
    </div>`
    },

    {
        speaker_id: 'S_Subramanian',
        speaker_info: `<div class="container py-0">
        <div class="row">
            <div class="col-md-4 col-lg-2 text-md-start">
                <img src="images/kcdh-inauguration/s-subramanium.jpg" alt="Dr. S. Subramanian" title="Dr. S. Subramanian" class="img-fluid rounded-3 mb-2">
            </div>
            <div class="col">
                <h4 class="text-primary m-0"> Dr. S. Subramanian </h4>
                <p class="">
                    <b>Sr. Consultant, ICMR-Vector Control Research Centre</b>
                </p>
                <p>
                    Dr. S. Subramanian is a Senior Consultant at ICMR-Vector Control Research
                    Centre (ICMR-VCRC), one of the permanent institutes of the Indian Council of
                    Medical Research (ICMR), Department of Health Research, Ministry of Health
                    and Family Welfare, Govt. of India. Prior to the current assignment Dr
                    Subramanian was a Deputy Director (Senior Grade) and heading the Division of
                    Epidemiology, Biostatistics and Operational Research at the ICMR-VCRC. He is
                    a recipient WHO/TDR training grant award (1993-1998) and Prof. V. Baliah
                    Gold Medal for securing the University Rank in MSc (Statistics) at Annamalai
                    University Chidambaram.
                </p>
                <p>Dr Subramanian has nearly 40 years of research experience in modelling
                    epidemiology and control of VBDs, handled 33 projects (intra and extramural)
                    and published more than 80 research articles. His modelling and operational
                    research studies contributed to the national programme and WHO to develop
                    guidelines for monitoring and evaluation of LF elimination programme. He
                    served twice (2007 and 2014) as expert member of the team to review the
                    National Programme for LF elimination and the WHO Joint Monitoring Mission
                    (WHO-JMM) for LF (2022). He was conferred with “Prof. BG. Prasad Gold medal
                    award (2005) from the Indian Society for Medical Statistics (ISMS).
                </p> 
            </div>
        </div>
    </div>`
    },

    {
        speaker_id: 'Sujoy_Kar',
        speaker_info: `<div class="container py-0">
        <div class="row">
            <div class="col-md-4 col-lg-2 text-md-start">
                <img src="images/kcdh-inauguration/sujoy-kar.jpg" alt="Dr. Sujoy Kar" title="Dr. Sujoy Kar" class="img-fluid rounded-3 mb-2">
            </div>
            <div class="col">
                <h4 class="text-primary m-0">
                    Dr. Sujoy Kar
                </h4>
                <p class="">
                    <b>Chief Medical Information Officer and Vice President, Apollo Hospitals</b>
                </p>

                <p>
                    Sujoy Kar is the Chief Medical Information Officer and the Vice-President of
                    Apollo Hospitals, previously Medical Director of Apollo Gleneagles
                    Hospitals, Kolkata. He is a Clinical Microbiologist by background with
                    subsequent post graduations from Indian Statistical Institute and MIT Sloan
                    School. He is an Adjunct Faculty for Healthcare Analytics and Management at
                    IIM Calcutta, IIT Kharagpur and ISB Hyderabad. His current and ongoing
                    research interests are in the development and adoption of Artificial
                    Intelligence in Healthcare and Digital Health Platforms. In his current
                    role, Sujoy actively collaborates with multiple organizations like MIT,
                    Microsoft, Google, World Economic Forum, ICMR, Maastricht University etc 
                </p> 
            </div>
        </div>
    </div>`
    },

    {
        speaker_id: 'Surajit_Nundy',
        speaker_info: `<div class="container py-0">
        <div class="row">
            <div class="col-md-4 col-lg-2 text-md-start">
                <img src="images/kcdh-inauguration/surajit-nundy.jpg" alt="Surajit Nundy" title="Surajit Nundy"
                    class="img-fluid rounded-3 mb-2">
            </div>
            <div class="col">
                <h4 class="text-primary m-0">
                    Dr. Surajit Nundy
                </h4>
                <p class="">
                    <b>Chief Executive Officer, Raxa Health</b>
                </p>

                <p>
                    Surajit Nundy MD PhD MPH is Board-certified in Internal Medicine, has public
                    health certification with a focus on health economics, created ANNs that
                    autonomously learned to navigate virtual environments during his PhD in
                    (Computational) Neuroscience, and algorithmically segmented high-throughput
                    nano-imaging data as a post-doc. He grew up in Delhi as a faculty brat at a
                    medical college, was then US-trained and subsequently returned to India,
                    where as a doctor he has worked in underserved slum, rural areas and urban
                    hospitals and where he now runs Raxa, a health tech social platform that is
                    inventing AI to improve the healthcare of all Indians. 
                </p> 
            </div>
        </div>
    </div>`
    },


    {
        speaker_id: 'Vidur_Mahajan',
        speaker_info: `<div class="container py-0">
        <div class="row">
            <div class="col-md-4 col-lg-2 text-md-start">
                <img src="images/kcdh-inauguration/vidur-mahajan.jpg" alt="Dr. Vidur Mahajan" title="Dr. Vidur Mahajan"
                    class="img-fluid rounded-3 mb-2">
            </div>
            <div class="col">
                <h4 class="text-primary m-0">
                    Dr. Vidur Mahajan
                </h4>
                <p class="">
                    <b>CEO, CARPL.ai</b>
                </p>

                <p>
                    Dr. Vidur Mahajan is the Chief Executive Officer of CARPL.ai – the world’s
                    first end-to-end platform for testing and deployment of medical imaging AI
                    solutions. CARPL works with more than 60 partners across the world spanning
                    leading research groups in academia, industry and startups. In the past, he
                    ran Mahajan Imaging, India’s leading radiology chain, and has published more
                    than 120 academic and conference papers in the field of AI and imaging
                    across the world. He has done an MBA with dual majors in finance and
                    healthcare management from the Wharton School of Business and studied
                    medicine from Sion Hospital, Mumbai. 
                </p> 
            </div>
        </div>
    </div>`
    },


    {
        speaker_id: 'Vishnu_Vardhan',
        speaker_info: `<div class="container py-0">
        <div class="row">
            <div class="col-md-4 col-lg-2 text-md-start">
                <img src="images/kcdh-inauguration/vishnu.jpg" alt="Vishnu Vardhan" title="Vishnu Vardhan"
                    class="img-fluid rounded-3 mb-2">
            </div>
            <div class="col">
                <h4 class="text-primary m-0">
                    Dr. Vishnu Vardhan
                </h4>
                <p class="">
                    <b>Founder & CEO, Vizzy Inc</b>
                </p>

                <p>
                    Vishnu Vardhan is a Orthopedic and Ballistic Trauma surgeon transformed into
                    digital health evangelist & HealthTech entrepreneur. Double masters from
                    Massachusetts institute of technology (MIT) I have worked on Artificial
                    intelligence, IoTs, healthcare analytics, Bigdata and Blockchain technology
                    and consulted in Mass General hosp, Harvard medical school, Tufts univ and
                    BCG. He is the Founder & CEO of Vizzhy, a pioneer company which is using
                    Multiomics approach to manage metabolic health. He is the Co- founder pf
                    CARE365 and working with US Medicare in solving their complex care
                    management problems using IoTs, AI and Virtual patient management.
                </p> 
            </div>
        </div>
    </div>`
    },


    {
        speaker_id: 'Vivek_Tiwari',
        speaker_info: `<div class="container py-0">
        <div class="row">
            <div class="col-md-4 col-lg-2 text-md-start">
                <img src="images/kcdh-inauguration/vivek-tiwari.jpg" alt="Dr. Vivek Tiwari" title="Dr. Vivek Tiwari"
                    class="img-fluid rounded-3 mb-2">
            </div>
            <div class="col">
                <h4 class="text-primary m-0">
                    Prof. Vivek Tiwari
                </h4>
                <p class="">
                    <b>Professor, IISER Berhampur</b>
                </p>

                <p>
                    Vivek Tiwari is an Assistant Professor at the Indian Institute of Science
                    Education and Research (IISER) Berhampur. Dr. Tiwari is a brain research
                    enthusiast working at the interface of understanding neuroanatomic,
                    functional and metabolic indicators of human brain health and diseases. Dr.
                    Tiwari pursued PhD in Neurochemistry and NMR spectroscopy from CSIR-Centre
                    for Cellular and Molecular Biology, Hyderabad. Thereafter, he moved to the
                    University of Texas Southwestern Medical Center (UTSW), Dallas, Texas, USA
                    for his postdoctoral research work in the department of Advanced Imaging
                    Research Centre to understand human brain tumors using Magnetic Resonance
                    Imaging (MRI) and Magnetic Resonance Spectroscopy (MRS).
                </p> 
            </div>
        </div>
    </div>`
    },


    {
        speaker_id: 'Rimjhim_Agrawal',
        speaker_info: `<div class="container py-0">
        <div class="row">
            <div class="col-md-4 col-lg-2 text-md-start">
                <img src="images/kcdh-inauguration/rimjhim.jpg" alt="Dr. Rimjhim Agrawal" title="Dr. Rimjhim Agrawal"
                    class="img-fluid rounded-3 mb-2">
            </div>
            <div class="col">
                <h4 class="text-primary m-0">
                    Dr. Rimjhim Agrawal
                </h4>
                <p class="">
                    <b>Co-founder & CTO, Brainsight Tech Pvt. Ltd</b>
                </p>

                <p>
                    Dr. Rimjhim Agrawal is the Co-founder and CTO of BrainSight AI, a 4 years
                    old neuro-tech start-up, which works in making brain maps used by
                    radiologists.<br />
                    She has done a Ph.D. from NIMHANS on how AI can advance the field of
                    psychiatry and neurology. She has over 10+ years of experience in
                    neurosciences, granted patents, and has 10+ published papers on topics such
                    as computational psychiatry, and machine learning algorithms for analyzing,
                    classification, and regression on neuroimaging. 
                </p> 
            </div>
        </div>
    </div>`
    },


    {
        speaker_id: 'Manishaa_Soin',
        speaker_info: `<div class="container py-0">
        <div class="row">
            <div class="col-md-4 col-lg-2 text-md-start">
                <img src="images/kcdh-inauguration/manishaa-soin.jpg" alt="Dr. Manishaa Soin" title="Dr. Manishaa Soin"
                    class="img-fluid rounded-3 mb-2">
            </div>
            <div class="col">
                <h4 class="text-primary m-0">
                    Dr. Manishaa Soin
                </h4>
                <p class="">
                    <b>Chief Executive Officer, CanceRX</b>
                </p>

                <p>
                    Manishaa Soin is the Founder at CANCERX, she is focussed on developing
                    digital health solutions to improve healthcare delivery, health outcomes
                    within the country. An Entrepreneur with a purpose, Manisha believes that
                    Entrepreneurship is the best way possible to solve large scale problems that
                    impact a young country like ours with a billion plus population.
                </p> 
            </div>
        </div>
    </div>`
    },


    {
        speaker_id: 'Anant_Patel',
        speaker_info: `<div class="container py-0">
        <div class="row">
            <div class="col-md-4 col-lg-2 text-md-start">
                <img src="images/kcdh-inauguration/anant_patel.jpg" alt="Prof. Anant B. Patel" title="Prof. Anant B. Patel"
                    class="img-fluid rounded-3 mb-2">
            </div>
            <div class="col">
                <h4 class="text-primary m-0">
                    Prof. Anant B. Patel
                </h4>
                <p class="">
                    <b>Professor, CCMB Hyderabad</b>
                </p>

                <p>
                    Anant B Patel is the Chief Scientist & Group Leader - CSIR-Centre for
                    Cellular and Molecular Biology, Professor, Academy of Scientific and
                    Innovative Research (AcSIR). His research interest are in the area of
                    Neurotransmitter Energetics in Depression, Amyotrophic Lateral, Alzheimer’s
                    Disease, and Parkinson’s Disease; Development of non-radioactive
                    tracer-based approach for metabolic analysis; 13C NMR Spectroscopy in Brain.
                    He has over 80 publication and 3500 + citations to his credit. Prof. Patel
                    is well know internationally for his research work and has received various
                    awards as a research scientist over the years namely Prof. S. Subramanian's
                    60th Birthday Lecture Award 2017, & Young investigator award from Gordon
                    Research Conference, etc. He has received a Gold Medal at Banaras Hindu
                    University, Varanasi, India for his PhD work. Further, he has been the
                    Associate Editor For ‘Frontiers in Aging Neuroscience’, Journal of
                    Alzheimer's Disease, National Magnetic Resonance Society of India, and many
                    more. 
                </p> 
            </div>
        </div>
    </div>`
    },

    {
        speaker_id: 'Sasank_Chilamkurthy',
        speaker_info: `<div class="container py-0">
        <div class="row">
            <div class="col-md-4 col-lg-2 text-md-start">
                <img src="images/kcdh-inauguration/sasank.jpg" alt="Sasank Chilamkurthy" title="Sasank Chilamkurthy"
                    class="img-fluid rounded-3 mb-2">
            </div>
            <div class="col">
                <h4 class="text-primary m-0">
                    Sasank Chilamkurthy
                </h4>
                <p class="">
                    <b>Chief Technology Officer, Qure.AI</b>
                </p>

                <p>
                    Sasank Chilamkurthy is a founding member and CTO at Qure.ai. He is also a
                    co-author of PyTorch AI framework. His work involves deep learning on
                    medical images obtained from radiology. His passion lies at the intersection
                    of AI, healthcare and open source technologies. As a founding member of
                    Qure.ai, he led and contributed to developing AI for healthcare. 
                </p> 
            </div>
        </div>
    </div>`
    },

    {
        speaker_id: 'Swapnil_Rane',
        speaker_info: `<div class="container py-0">
        <div class="row">
            <div class="col-md-4 col-lg-2 text-md-start">
                <img src="images/kcdh-inauguration/swapnil-rane.jpg" alt="Dr Swapnil Rane" title="Dr Swapnil Rane"
                    class="img-fluid rounded-3 mb-2">
            </div>
            <div class="col">
                <h4 class="text-primary m-0">
                    Dr Swapnil Rane
                </h4>
                <p class="">
                    <b>Professor, Tata Memorial Centre</b>
                </p>

                <p>
                    Swapni Rane is a Professor & Pathologist at Tata memorial Hospital, Mumbai.
                    having obtained my M.D. & D.N.B. during my residency program from PGIMER,
                    Chandigarh. He has obtained my post-doctoral specialist training in renal
                    and transplant pathology from PGIMER, Chandigarh upto 2012 and in
                    Oncopathology from Tata Memorial Centre, Mumbai. He has also obtained a MRes
                    in Translational Cancer Medicine degree from King’s College, London in 2014.
                    He is keenly interested in bioinformatics and computational pathology in
                    addition to conventional anatomic pathology, cytopathology and molecular
                    pathology. He has an advanced understanding of statistics and modelling
                    techniques and have been using machine learning to make sense of biological
                    and clinical information. He is an expert at data mining, data processing,
                    medical statistics including survival analysis.He has established a
                    Computational Pathology/AI lab at ACTREC. He has been awarded Facebook
                    Ethics in AI award along with Amit Sethi for developing a patient-centric
                    framework for ethics of AI in medicine. 
                </p> 
            </div>
        </div>
    </div>`
    },


]
export { SpeakersPanelists }
