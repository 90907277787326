import React from "react";
// import { Navigate } from "react-router-dom";

import Home from "../pages/home";
import NewsEvents from "../pages/news-events";
import NewsDetails from "../pages/news-events/news-details";
import KCDHinauguration from "../pages/news-events/kcdh-inauguration";
import Careers from "../pages/careers/";
import CareerDetails from "../pages/careers/career-details";
// import WhyKcdh from "../pages/careers/why-kcdh";
import People from "../pages/people";
import PeopleDetails from "../pages/people/people-details";
import Research from "../pages/research";
import KCDHProjects from "../pages/research/kcdh-projects";
import Publications from "../pages/research/publications";
import Publications2022 from "../pages/research/publications-2022";
import KCDHProjectDetails from "../pages/research/kcdhproject-details";
import AboutUs from "../pages/aboutus";
import Academics from "../pages/academics";
import Partnerships from "../pages/partnerships";
import KCDHPartners from "../pages/partnerships/kcdh-partners";
import HospitalMediacalColleges from "../pages/partnerships/hospitals-medical-colleges";
import AcademicInstitutions from "../pages/partnerships/academic-institutions";
import GovernmentOrganizations from "../pages/partnerships/government-organizations";
import NationalIntitative from "../pages/partnerships/national-intitative";
import PHDProgram from "../pages/students-corner/ph-d-program";
import IDDDP from "../pages/students-corner/interdisciplinary-dual-degree-program";
import MinorProgram from "../pages/students-corner/minor-program-healthcare-informatics";
import OpportunitiesDigitalHealthcare from "../pages/students-corner/opportunities-digital-healthcare";
import Internships from "../pages/students-corner/kcdh-internships";
import MappedResearch from "../pages/research/mapped-category-deatils";
import PeopleDepartment from "../pages/people/people-department";
import Search from "../pages/layout/searchresults";
import Sitemap from "../pages/sitemap";
import PageNotFound from "../pages/pageNotFound/pageNotFound";
import ErrorPage from "../pages/layout/errorPage";
import MSByResearch from "../pages/students-corner/ms-by-research-healthcare-informatics";

const websiteRoutes = [
    { path: "/", component: <Home /> },
    { path: "/home", component: <Home /> },
    { path: "/news-events", component: <NewsEvents /> },
    { path: "/kcdh-inauguration", component: <KCDHinauguration /> },
    // { path: "/news-details/:page_name", component: <NewsDetails /> },
    { path: "/careers", component: <Careers /> },
    { path: "/careers/:career_pagename", component: <CareerDetails /> },
    { path: "/people/:pcategory_pagename/:ps_category_pagename", component: <PeopleDetails /> },
    { path: "/people/:pcategory_pagename/:ps_category_pagename/:department_pagename", component: <PeopleDepartment /> },
    { path: "/people/:pcategory_pagename", component: <People /> },
    { path: "/research", component: <Research /> },
    { path: "/research/kcdh-projects", component: <KCDHProjects /> },
    { path: "/research/kcdh-projects/:rtype_pagename", component: <KCDHProjectDetails /> },
    { path: "/research/:rcategory_pagename", component: <MappedResearch /> },
    
    { path: "/publications", component: <Publications /> },
    { path: "/publications-2022", component: <Publications2022 /> },
    { path: "/aboutus", component: <AboutUs /> },
    { path: "/academics", component: <Academics /> },
    { path: "/partnerships", component: <Partnerships /> },
    { path: "/kcdhpartners", component: <KCDHPartners /> },
    { path: "/hospitals-medical-colleges", component: <HospitalMediacalColleges /> },
    { path: "/academic-institutions", component: <AcademicInstitutions /> },
    { path: "/government-organizations", component: <GovernmentOrganizations /> },
    { path: "/national-intitative", component: <NationalIntitative /> },
    { path: "/ph-d-program", component: <PHDProgram /> },
    { path: "/interdisciplinary-dual-degree-program", component: <IDDDP /> },
    { path: "/minor-program-healthcare-informatics", component: <MinorProgram /> },
    { path: "/ms-by-research-healthcare-informatics", component: <MSByResearch /> },
    { path: "/opportunities-digital-healthcare", component: <OpportunitiesDigitalHealthcare /> },
    { path: "/kcdh-internships", component: <Internships /> },
    
    { path: "/search-result/:str", component: <Search/> },

    { path: "/sitemap", component: <Sitemap /> },

    { path: '/error-page' , component: <ErrorPage/> },
    { path: "*" , component: <PageNotFound/>}
    
];

export { websiteRoutes };