import React, { useEffect } from 'react';
import WOW from 'wowjs';
import { Link } from "react-router-dom";
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { HiOutlineMenuAlt2 } from "react-icons/hi";


export default function KCDHPartnersNav(props) {
    const submenutitle = props.submenutitle;
    useEffect(() => {
        // initiate the wow effect
        new WOW.WOW({
            live: false
        }).init();

    }, []);

    return (
        <Navbar collapseonselect expand="md" className='d-block p-0'>
            <Navbar.Toggle className="offcanvas-toggle shadow-none w-100 animated fadeInUp" aria-controls={'offcanvas-menu'}>
                <Link className="btn btn-outline-secondary rounded-pill px-4 py-2 w-100 text-start d-flex justify-content-between align-items-center d-md-none">{submenutitle} <HiOutlineMenuAlt2 /></Link>
            </Navbar.Toggle>
            <Navbar.Offcanvas id={'offcanvasTop'} aria-labelledby={'offcanvasTop'} placement="start" responsive="md" className="p-md-0 p-2 d-block">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <ul className='left-navbar bg-white rounded-5 py-md-0 py-3 px-2 w-100'>
                    <li><Link to="/kcdhpartners" className={props.kcdhpartners_className}>All KCDH Partners</Link></li>
                    <li><Link to="/hospitals-medical-colleges" className={props.hospitals_className}>Hospitals & Medical Colleges</Link></li>
                    <li><Link to="/academic-institutions" className={props.academic_className}>Academic Institutions</Link></li>
                    <li><Link to="/government-organizations" className={props.ngos_className}>NGOs, NPOs & Government Organizations</Link></li>
                </ul>
            </Navbar.Offcanvas>
        </Navbar>
    )
} 