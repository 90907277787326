import React,{useState,useEffect} from "react";
import axios from "axios";
import globalVariables from "../global-variables";
import {Helmet} from 'react-helmet'
function TrackingCode(props){
    // const [getScript,setScript] = useState(null);
    const [trackingCode,setTrackingCode] = useState(null);

    const [get_setting_conversion_code,set_setting_conversion_code] = useState(null);
    
    useEffect(()=>{
        let _obj =new Object();
        _obj.setting_id = globalVariables.variables.strSetting_id
        axios.post(globalVariables.variables.strApiUrl +'/settingApi/getCompanySettingList',_obj)
        .then((response)=>{
            //    (response);
                // setScript(response.data[0][0].setting_google_analytics)
                setTrackingCode( response.data[0][0].setting_tracking_code)
                set_setting_conversion_code(response.data[0][0].setting_conversion_code)

        })
    },[])
    
    useEffect(()=>{
    
        //create element
        const divTag =  document.createElement("div")
        
        //create innerhtml to insert the value
        divTag.insertAdjacentHTML("afterbegin",`${trackingCode}`);
        if(props.conversion_code != 2)
        {
            divTag.insertAdjacentHTML("beforeend",get_setting_conversion_code);
        }
        

        //append the div tag
        document.body.appendChild(divTag)

        //remove function on unmounting phase
        return()=>{
            document.body.removeChild(divTag)
        }
        
       },[trackingCode,get_setting_conversion_code])
      
    return(
        <>
        {/* implementation for head tag  */}
            {/* <Helmet>
                <script async src={trackingCode}></script>  
                <script>{getScript}</script>
               
            </Helmet> */}
        </>
        );
    
}

export default TrackingCode;