import React, { useState, useEffect } from "react";
import global from "../../global-variables";
import Header from "../layout/header";
import { Link ,useNavigate} from "react-router-dom";
import $ from "jquery";
import WOW from "wowjs";
import { FaLink, FaImage, FaRegFilePdf, FaVideo, FaChevronRight } from "react-icons/fa";
import axios from "axios";
import { Container, Row, Col } from "reactstrap";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Metatag from "../../hooks/Metatag";
import { HashLink } from "react-router-hash-link";

export default function Sitemap() {

  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {
    // add class for home header and remove inner class
    $(".fixed-top").addClass("bg-header");

    // initiate the wow effect
    new WOW.WOW({
      live: false,
    }).init();


  }, []);

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -134;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
}

const [peopleCategory, setPeopleCategoryList] = useState([]);

useEffect(() => {
    let _obj = new Object();
    _obj.pcategory_status = 1;
    // setTimeout(() => {

        axios.post(global.variables.strApiUrl + "/peoplecategoryApi/getcategoryHavingPeopleList", _obj)
            .then((response_list) => {
              if(response_list.data === 'error')
              {
                navigate('/error-page')
              }
              else
              {
                // pass the result in the success function of the respective page
                setPeopleCategoryList(response_list.data[0]);
              }
            });
    // }, 300);

}, [])

  return (
    <>
      <Header main_className="bg-header"></Header>
      <Metatag title='About Koita Centre for Digital Health' keywords='About Koita Centre for Digital Health' description='About Koita Centre for Digital Health' />
      {/* Visual Panel */}
      <Container fluid className="bg-visual-inside p-0" >
        <div className="visual-inside-big">
          <Row className="g-md-0">
            <Col md={6} className="order-2 order-md-1">
              <Container className="d-flex align-items-end h-100">
                <div className="d-inline-block m-0 align-self-end col-lg-6 col-xl-6 py-xl-3 p-2 text-white">
                  <h1 className="fw-light text-white m-0 align-self-center wow fadeInUp">
                    Site Map
                  </h1>
                </div>
              </Container>
            </Col>
            <div className="col-md-6 order-1 order-md-2">
              <img src={global.variables.strDomainUrl + "images/visual-sitemap.jpg"} className="img-fluid" alt="" title="" />
            </div>
          </Row>
        </div>
      </Container>

      {/* Container */}
      <Container className="my-5 list">
        <Row className="row-cols-lg-4 g-5">
            <Col>
              <ul>
                <li><Link to={global.variables.strDomainUrl}>Home</Link></li>
                <li><Link to={global.variables.strDomainUrl +"news-events"}>News & Events</Link></li>
                <li><Link to={global.variables.strDomainUrl +"careers"}>Careers</Link></li>
                <li><HashLink to="#contact" className="nav-link" scroll={el => scrollWithOffset(el)}>Contact Us</HashLink></li>
                <li><Link to={global.variables.strDomainUrl +"aboutus"}>About Us</Link></li>
                <li><Link to={global.variables.strDomainUrl +"academics"}>Academics</Link></li>
              </ul>
            </Col>
            <Col>
              <b>People</b>
              <ul>
                {
                  peopleCategory.map((val)=>{
                    return(

                      <li><Link to={global.variables.strDomainUrl + 'people/' + val.pcategory_pagename}>{val.pcategory_name}</Link></li>
                    )
                  })
                }
                {/* <li><Link to="people/kcdh-team.htm">KCDH Team</Link></li>
                <li><Link to="people/advisory-board.htm">Advisory Board</Link></li>
                <li><Link to="people/students.htm">Students</Link></li> */}
              </ul>
              <b>Research</b>
              <ul>
                <li><Link to={global.variables.strDomainUrl +"research"}>Overview</Link></li>
                <li><Link to={global.variables.strDomainUrl +"research/kcdh-projects"}>KCDH Projects</Link></li>
                <li><Link to={global.variables.strDomainUrl +"publications"}>Publications</Link></li>
              </ul>
            </Col>
            <Col>
              <b>Partnerships</b>
              <ul>
                <li><Link to={global.variables.strDomainUrl +"partnerships"}>Overview</Link></li>
                <li><Link to={global.variables.strDomainUrl +"kcdhpartners"}>KCDH Partners</Link></li>
                <li><Link to={global.variables.strDomainUrl +"national-intitative"}>National Intitative</Link></li>
              </ul>
              <b>Students' Corner</b>
              <ul>
                <li><Link to={global.variables.strDomainUrl +"ph-d-program"}>Ph.D. Program</Link></li>
                <li>
                  <Link to={global.variables.strDomainUrl +"interdisciplinary-dual-degree-program"}>
                    Interdisciplinary Dual
                    Degree Program
                  </Link>
                </li>
                <li><Link to={global.variables.strDomainUrl + "minor-program-healthcare-informatics"}>Minor Program</Link></li>
                <li><Link to={global.variables.strDomainUrl + "ms-by-research-healthcare-informatics"}>M.S. by Research</Link></li>
                <li><Link to={global.variables.strDomainUrl + "opportunities-digital-healthcare"}>Opportunities in Digital Healthcare</Link></li>
                <li><Link to={global.variables.strDomainUrl + "kcdh-internships"}>KCDH Internships</Link></li>
              </ul>
            </Col>
        </Row>
      </Container>

      {/* Breadcrumb */}
      <Breadcrumb className="breadcrumb-panel mt-auto">
        <Breadcrumb.Item href={global.variables.strDomainUrl}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Site Map</Breadcrumb.Item>
      </Breadcrumb>
    </>
  );
}