import React, { useState, useEffect, useMedia } from "react";
import global from "../../global-variables";
import Header from "../layout/header";
import { Link, useLocation } from "react-router-dom";
import $ from "jquery";
import WOW from "wowjs";
import { HashLink } from "react-router-hash-link";

import { Container, Row, Col } from "reactstrap";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Metatag from "../../hooks/Metatag";
import globalVariables from "../../global-variables";


export default function Partnerships() {
  const [show, setShow] = useState(false);
  const [index, setIndex] = useState(0);
  const pathname = useLocation();

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -134;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
}

  useEffect(() => {
    // Go to Top OR Scroll to top
    document.documentElement.scrollTo({
        top: 0,
        left: 0,
        behavior: "instant", // Optional if you want to skip the scrolling animation (comment behavior if u want to animate)
    });
}, [pathname]);

  useEffect(() => {
    // add class for home header and remove inner class
    $(".fixed-top").addClass("bg-header");

    // initiate the wow effect
    new WOW.WOW({
      live: false,
    }).init();


  }, []);


  return (
    <>
      <Header main_className="bg-header"></Header>

      <Metatag title='Partnership Opportunities for Digital Health in India'
               description='KCDH at IIT Bombay offers partnerships to Hospitals & Healthcare Providers, HealthTech Organizations, State Governments and Central Government'
               keywords="Partnership Opportunities for Digital Health in India, Partnership Opportunities for Digital Healthcare in India, Partnership Opportunities for Healthcare Informatics in India, KCDH Partnership, Partner with KCDH, Partner with IIT Bombay, Partnership for Digital Health Programs, Digital Health Partnership with Hospitals, Digital Health Partnership with Healthcare Providers, Digital Health Partnership with HealthTech Organizations, Digital Health Partnership with State Government, Digital Health Partnership with Central Government, Digital Health Academics Partnership, Digital Health Research Partnership, Digital Health Funding Partnership"
               />

      {/* Visual Panel */}
      <Container fluid className="bg-visual-inside p-0" >
        <div className="visual-inside-big">
          <Row className="g-md-0">
            <Col md={6} className="order-2 order-md-1">
              <Container className="d-flex align-items-end h-100">
                <div className="d-inline-block m-0 align-self-end col-lg-6 col-xl-6 py-xl-3 p-2 text-white">
                  <h1 className="fw-light text-white m-0 align-self-center wow fadeInUp">
                    Partnerships
                  </h1>
                </div>
              </Container>
            </Col>
            <div className="col-md-6 order-1 order-md-2">
              <img src={global.variables.strDomainUrl + "images/visual-partnership.jpg"} className="img-fluid" alt="" title="" />
            </div>
          </Row>
        </div>
      </Container>


      {/* Container */}
      <Container className="my-5">
        <Row>
          <Col lg={6}>
            <p>KCDH is establishing partnerships with healthcare organizations (Digital Health Partners) to
              foster research and innovation in digital health, and access clinical expertise and data. These
              DHP partnerships are critical for KCDH to
              create rich research and academic opportunities in Digital Health. </p>
          </Col>
          <Col lg={6} className="list">
            IIT Bombay and its Digital Health Partners (DHPs) plays a key role in advancing healthcare
            informatics in India
            <ul>
              <li>Groom exceptional professionals</li>
              <li>Drive world-class research</li>
              <li>Promote entrepreneurship in the space </li>
            </ul>
          </Col>
        </Row>
      </Container>


      <Container className="mb-5">
        <Row className="row-cols-1 row-cols-lg-1 g-4 g-lg-5">
          <Col>
            <div className="border rounded-3 px-md-5 p-3 pt-md-5 h-100">
              <h2 className="text-primary mb-3 text-center">KCDH - DHP Partner Ecosystem</h2>
              <Row className="row-cols-1 row-cols-lg-3 g-4 g-xl-5 mt-4">
                <Col className="my-5">
                  <div className="bg-light p-4 h-100 rounded-3 list">
                    <div className="icon-circle bg-primary d-flex align-items-center justify-content-center m-auto mb-3 icon-mt-n80 border border-white border-5 wow fadeInUp">
                      <img src={global.variables.strDomainUrl + "images/icon-hospitals-healthcare-providers.png"} alt="" title="" className="img-fluid px-3 wow fadeInUp" />
                    </div>
                    <h5 className="text-dark">Hospitals & Healthcare Providers</h5>
                    Establish partnerships with leading hospitals to:
                    <ul className="m-0">
                      <li>Drive joint research and academic programs</li>
                      <li>Get access to clinical and patient information</li>
                    </ul>
                  </div>
                </Col>
                <Col className="my-5">
                  <div className="bg-light p-4 h-100 rounded-3 list">
                    <div className="icon-circle bg-primary d-flex align-items-center justify-content-center m-auto mb-3 icon-mt-n80 border border-white border-5 wow fadeInUp">
                      <img src={global.variables.strDomainUrl + "images/icon-healthtech-organizations.png"} alt="" title="" className="img-fluid px-3 wow fadeInUp" />
                    </div>
                    <h5 className="text-dark">HealthTech Organizations</h5>
                    Collaborate with leading Indian healthcare research, global medical devices and
                    software companies, and NGOs to:
                    <ul className="m-0">
                      <li>Drive joint research programs and internships</li>
                      <li>Conduct Consortiums / lectures</li>
                    </ul>
                  </div>
                </Col>
                <Col className="my-5">
                  <div className="bg-light p-4 h-100 rounded-3 list">
                    <div className="icon-circle bg-primary d-flex align-items-center justify-content-center m-auto mb-3 icon-mt-n80 border border-white border-5 wow fadeInUp">
                      <img src={global.variables.strDomainUrl + "images/icon-government.png"} alt="" title="" className="img-fluid px-3 wow fadeInUp" />
                    </div>
                    <h5 className="text-dark">Government (State/Central)</h5>
                    Collaborate with government organizations to:
                    <ul className="m-0">
                      <li>Support public health initiatives</li>
                      <li>Collaborate with public health organizations</li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>

          <Col>
            <div className="border rounded-3 px-md-5 p-4 pt-md-5 h-100">
              <h2 className="text-primary mb-3 text-center">KCDH - DHP: Potential Areas of Collaboration</h2>
              <Col md={10} className="mb-4 m-auto text-center">The partnership between KCDH and Digital Health
                Partners is driving significant collaboration across all entities</Col>
              <Row className="row-cols-1 row-cols-lg-1 row-cols-xl-1 g-4 g-xl-4 mx-lg-5">
                <Col className="list">
                  <Row className="rounded-3 overflow-hidden border">
                    <Col xl={2} md={3} className="bg-light">
                      <h5 className="text-dark p-2 wow fadeInUp">Academics</h5>
                    </Col>
                    <Col xl={10} md={9} className="py-2">
                      <ul className="m-0">
                        <li>Develop academics curriculum spanning both Engineering/Math’s and
                          Medical/Pharma domains</li>
                        <li>Faculty from KCDH - DHP can teach joint courses</li>
                      </ul>
                    </Col>
                  </Row>
                </Col>
                <Col className="list">
                  <Row className="rounded-3 overflow-hidden border">
                    <Col xl={2} md={3} className="bg-light">
                      <h5 className="text-dark p-2 wow fadeInUp">Research</h5>
                    </Col>
                    <Col xl={10} md={9} className="py-2">
                      <ul className="m-0">
                        <li>Focus on broader initiatives encompassing capabilities, facilities of
                          all partners</li>
                        <li>Broader access to clinical data and expertise</li>
                        <li>Ability to implement joint research programs and pilots</li>
                      </ul>
                    </Col>
                  </Row>
                </Col>
                <Col className="list">
                  <Row className="rounded-3 overflow-hidden border">
                    <Col xl={2} md={3} className="bg-light">
                      <h5 className="text-dark p-2 wow fadeInUp">Policy</h5>
                    </Col>
                    <Col xl={10} md={9} className="py-2">
                      <ul className="m-0">
                        <li>Greater ability to collaborate with regulators and public health
                          professionals to support national health programs</li>
                        <li>Support roll-out of NDHM and other GOI initiatives</li>
                      </ul>
                    </Col>
                  </Row>
                </Col>
                <Col className="list">
                  <Row className="rounded-3 overflow-hidden border">
                    <Col xl={2} md={3} className="bg-light">
                      <h5 className="text-dark p-2 wow fadeInUp">Funding</h5>
                    </Col>
                    <Col xl={10} md={9} className="py-2">
                      <ul className="m-0">
                        <li>Access to broader sources of funding / grants</li>
                        <li>Ability to work with local, state and national organizations</li>
                      </ul>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>

      {/* Breadcrumb */}
      <Breadcrumb className="breadcrumb-panel mt-auto">
        <Breadcrumb.Item href={globalVariables.variables.strDomainUrl}>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Partnerships</Breadcrumb.Item>
        <Breadcrumb.Item active>Overview</Breadcrumb.Item>
      </Breadcrumb>
    </>
  );
}