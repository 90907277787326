

module.exports = global.config = {
    variables: {
        // local
        // strDomainUrl: "/" ,
        // strApiUrl: "http://localhost:3002",

        //192
        // strDomainUrl: "http://192.168.2.2/k/kcdh-react/website/",
        // strApiUrl: "http://192.168.2.2:3011",
        
        //IIT
         strDomainUrl: "https://www.kcdh.iitb.ac.in/",
         strApiUrl: "https://www.kcdh.iitb.ac.in/site",


        // rest of your translation object  /a/1_Akshay/kcdo-react-web

        //road block id
        strRoadBlock_id: "73a98b08-d383-11ed-918d-5254fbc0d86c",
        strSetting_id :"ebbf9735-dce5-11ed-9246-10e7c607eeca" ,
        //Ganeral news type id
        strNewsTypeGeneral_id : "5c8432d2-34ea-11ee-ac53-f8b156be9900",
        

    }
    // other global config variables you wish
};