import React, { useEffect } from "react";
import global from "../../global-variables";
import Header from "../layout/header";
import { Link } from "react-router-dom";
import $ from "jquery";
import WOW from "wowjs";


import { Container, Row, Col } from "reactstrap";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import PublicationsNav from "../layout/publications-nav";
import Metatag from "../../hooks/Metatag";
import globalVariables from "../../global-variables";

export default function Publications() {

  useEffect(() => {
    // add class for home header and remove inner class
    $(".fixed-top").addClass("bg-header");

    // initiate the wow effect
    new WOW.WOW({
      live: false,
    }).init();

  }, []);

  return (
    <>
      <Header main_className="bg-header"></Header>

      <Metatag title='Publications by Koita Centre for Digital Health'
               description='Publications by Koita Centre for Digital Health'
               keywords="Publications, Publications by KCDH"
               />
      {/* Visual Panel */}
      <Container fluid className="bg-visual-inside p-0" >
        <div className="visual-inside-big">
          <Row className="g-md-0">
            <Col md={6} className="order-2 order-md-1">
              <Container className="d-flex align-items-end h-100">
                <div className="d-inline-block m-0 align-self-end col-lg-6 col-xl-6 py-xl-3 p-2 text-white">
                  <h1 className="fw-light text-white m-0 align-self-center wow fadeInUp">
                    Publications
                  </h1>
                  <p className="mb-lg-0 wow fadeInUp">
                    Papers published in Journals
                  </p>
                </div>
              </Container>
            </Col>
            <div className="col-md-6 order-1 order-md-2">
              <img src={global.variables.strDomainUrl + "images/visual-research2.jpg"} className="img-fluid" alt="" title="" />
            </div>
          </Row>
        </div>
      </Container>

      {/* Container */}
      <Container className="my-5 position-relative">
        <Row>
          <Col md={4} lg={3} className="mb-3">
            <PublicationsNav submenutitle="2023" publications2023_class="active"></PublicationsNav>
          </Col>
          <Col md={8} lg={9} className="ps-md-4">
            <div className="rounded-3 border p-md-4">
              <h2 className="text-primary mb-0">2023</h2>
              <Row className="row-cols-1 publications">
                <Col>
                  Anchal Purbey, Apoorva Nambiar, Dripta Roy Choudhury, Thirumal Vennam, Khushboo Balani, Satish Balram Agnihotri, "Stillbirth rates and its spatial patterns in India: An exploration of HMIS data", The Lancet Regional Health - Southeast Asia, Vol 9 (2023), <Link href="https://doi.org/10.1016/j.lansea.2022.100116" className="text-decoration-underline text-primary" target="_blank">https://doi.org/10.1016/j.lansea.2022.100116</Link>
                </Col>
                <Col>
                  Piyush Singh Pasi, Karthikeya Battepati, Preethi Jyothi, Ganesh Ramakrishnan, Tanmay Mahapatra, Manoj Singh, “Temporally Aligning Long Audio Interviews with Questions: A Case Study in Multimodal Data Integration”, In Proceedings of The 32nd International Joint Conference on Artificial Intelligence, (IJCAI 2023)  <Link href="https://ijcai-23.org/special-track-on-ai-for-good/ " className="text-decoration-underline text-primary" target="_blank">https://ijcai-23.org/special-track-on-ai-for-good/ </Link>
                </Col>
                <Col>
                  Susweta Das , Ria Paul , Soumyajit Sarkar , Makrand Rakshe , Prasanna Gandhi, and Hari M Varma, “A microfluidic based cerebral perfusion phantom for laser speckle imaging in small animals, Oral Presentation, European Conferences on Biomedical Optics 2023, 25-29 June, 2023
                </Col>
                <Col>
                  Hari M varma “Bio-imaging using noise: Application of laser speckles for deep tissue blood flow imaging” BRICS workshop on Biophotonics, 2023 (online among different BRICS countries)
                </Col>
                <Col>
                  Ria Paul, Soumyajit Sarkar, Shruti D. Marathe, Murali K, Susweta Das, Nixon M. Abraham, and Hari M Varma, "Functional imaging of olfactory bulb and somatosensory cortex in mice using small-animal blood flow imaging platform" Oral Presentation "Dynamics and Fluctuations in Biomedical Photonics XX, SPIE Photonic West, San Francisco, 28th 2 January 2023.
                </Col>
                <Col>
                  Prof Sanjeeva Srivastava et al., Advances in Drug Discovery based on Genomics, Proteomics and Bioinformatics in Malaria, Current Topics in Medicinal Chemistry, Mar, 2023
                </Col>
                <Col>
                  Prof Sanjeeva Srivastava et al., Identification of potential biomarkers in glioblastoma through omics technologies and big data analysis (Book Chapter), New Insights Into Glioblastoma Diagnosis, Therapeutics and Theranostics, May, 2023
                </Col>
                <Col>
                  Prof Sanjeeva Srivastava et al., Colon Adenocarcinoma Quantitative Proteomics Reveals Dysregulation in Key Cancer Signaling Pathways and a Candidate Protein Marker Panel, OMICS: A Journal of Integrative Biology, Feb, 2023
                </Col>
                <Col>
                  Prof Sanjeeva Srivastava et al., Integrative Proteomic and Pharmacological Analysis of Colon Cancer Reveals the Classical Lipogenic Pathway with Prognostic and Therapeutic Opportunities, Journal of proteome research, Feb, 2023
                </Col>
                <Col>
                  Prof Sanjeeva Srivastava et al., Humoral Immune Response Profile of COVID-19 Reveals Severity and Variant-Specific Epitopes: Lessons from SARSCoV- 2 Peptide Microarray, MDPI viruses, Jan, 2023
                </Col>
                <Col>
                  Prof Sanjeeva Srivastava et al., Multiomics data analysis workflow to assess severity in longitudinal plasma samples of COVID-19 patients, Data in Brief, Feb, 2023
                </Col>
                <Col>
                  Prof Sanjeeva Srivastava et al., Metaproteomic analysis of nasopharyngeal swab samples to identify microbial peptides and potential co-infection status in COVID-19 patients (pre-print), bioRxiv, Jan, 2023
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>

      {/* Breadcrumb */}
      <Breadcrumb className="breadcrumb-panel mt-auto">
        <Breadcrumb.Item href={globalVariables.variables.strDomainUrl}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Research</Breadcrumb.Item>
        <Breadcrumb.Item active>Publications</Breadcrumb.Item>
      </Breadcrumb>
    </>
  );
}