import React, { useEffect, useState } from "react";
import axios from "axios";
import global from "../../global-variables";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { BsChevronRight } from "react-icons/bs";
import Header from "../layout/header";
import { Container, Row, Col } from "reactstrap";
import { json, useParams } from "react-router-dom";
import Parser from "html-react-parser";
import globalVariables from "../../global-variables";
import $ from "jquery";
import WOW from "wowjs";
import Metatag from '../../hooks/Metatag'
export default function Searchresults() {
  // secation name initialise
  let val_section_name = "";
  let bln_ul_opened = false;
  let _str_html = "";

  //Getting id from url
  let { str } = useParams();
  const qs_str = str;
  
  const [search, setSearchList] = useState([]);
  
  useEffect(() => {
    let _obj = new Object();
    _obj.searchstr = qs_str;
    setTimeout(() => {
      axios
        .post(global.variables.strApiUrl + "/searchApi/searchApi", _obj)
        .then((response_list) => {
          // pass the result in the success function of the respective page
          setSearchList(response_list.data[0]);
        });
    }, 300);
  }, []);

  useEffect(() => {
    // add class for home header and remove inner class
    $(".fixed-top").addClass("bg-header");

    // initiate the wow effect
    new WOW.WOW({
      live: false,
    }).init();


  }, []);

  return (
    <>
      <Header main_className="bg-header"></Header>
      <Metatag title={`Search results for ${qs_str}`} keywords={`Search results for ${qs_str}`} description={`Search results for ${qs_str}`}/>
      {/* Visual Container */}
      <Container fluid className="bg-visual-inside p-0" >
          <div className="visual-inside-big">
            <Row className="g-md-0">
              <Col md={6} className="order-2 order-md-1">
                <Container className="d-flex align-items-end h-100">
                  <div className="d-inline-block m-0 align-self-end col-lg-6 col-xl-6 py-xl-3 p-2 text-white">
                    <h1 className="fw-light text-white m-0 align-self-center wow fadeInUp">
                      Search Results
                    </h1>
                  </div>
                </Container>
              </Col>
              <div className="col-md-6 order-1 order-md-2">
                <img src={global.variables.strDomainUrl + "images/visual-research2.jpg"} className="img-fluid" alt="" title="" />
              </div>
            </Row>
          </div>
        </Container>

      {/* Body Panel */}
      <div className="container-fluid px-0 my-md-5">
        <Container className="py-lg-5">
          <div className="border  rounded-3 p-5 list">
            <h4 className="text-dark mb-4">
              {" "}
              Search results for '{qs_str}' -{" "}
              <small>({search.length} Records found)</small>
            </h4>
            {search.length > 0
              ? search.map((val, index) => {
                
                  // check if the section name doesnot match then
                  if (val_section_name != val.section_name) {
                    // set the new section value
                    val_section_name = val.section_name;

                    // check if the previous ul is opened then close it and reset the variable
                    if (bln_ul_opened === true) {
                      _str_html += "</ul>";
                      bln_ul_opened = false;
                    }
                  }

                  // check if ul is not opened then open it and bind the section name and reset the variable
                  if (bln_ul_opened === false) {
                    _str_html +=
                      "<h6 class='fw-bold' key='" +
                      index +
                      "'>" +
                      val.section_name +
                      "</h6>";
                    _str_html += "<ul class='mb-4'>";
                    bln_ul_opened = true;
                  }

                  // bind the li node for the result
                  _str_html += "<li key='" + index + "'>";
                  _str_html +=
                    "<a href='"+ globalVariables.variables.strDomainUrl + val.link + "'>" + val.result + "</a>";
                  _str_html += "</li>";
                })
              : null}

            <>{Parser(_str_html)}</>
          </div>
        </Container>
      </div>

      {/* Breadcrumb */}
      <Breadcrumb className="breadcrumb-panel">
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Search Results</Breadcrumb.Item>
      </Breadcrumb>
    </>
  );
}
